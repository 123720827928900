import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { observer } from 'mobx-react-lite';
import { campaignRoutes } from 'routing';
import { useAppStore } from 'store/app-store.hook';
import { useDebounce } from 'use-debounce';
import { TopNavigation } from 'components/material/TopNavigation';
import { Grid } from '@material-ui/core';
import { ScutiInput } from 'components/material/ScutiInput';
import { CampaignType, Filter_Type } from 'types/__generated__/types';
import { useToasts } from 'react-toast-notifications';
import { PaginationLayout } from 'layouts/PaginationLayout';
import { usePaging } from 'hooks/usePaging';
import { useCampaignsTableColumns } from './useCampaignsTableColumns';
import { useSortBy, useTable } from 'react-table';
import { useSorting } from 'hooks/useSorting';
import { ScutiTable } from 'components/ScutiTable';
import { defaultSorting } from 'types/app-types';
import { CampaignListItemFragment, useCampaignsQuery } from 'graphql/__generated__/campaign.hooks';

export const CampaignsListPage: React.FC = observer(() => {
  const { selectedItem } = useAppStore().uiStore;
  const { addToast } = useToasts();
  const paging = usePaging();
  const [searchInput, setSearchInput] = useState<string>('');
  const [inputValue] = useDebounce(searchInput, 500);
  const [campaigns, setCampaigns] = useState<CampaignListItemFragment[]>([]);
  const columns = useCampaignsTableColumns();
  const tableInstance = useTable({ columns, data: campaigns, manualSortBy: true }, useSortBy);
  const sorting = useSorting(tableInstance.state.sortBy, defaultSorting);

  const filters = useMemo(() => {
    const response = [
      {
        name: 'type',
        operator: Filter_Type.In,
        value: [
          CampaignType.Organization,
          CampaignType.ShopAdvertisement,
          CampaignType.ShopBrand,
          CampaignType.SingleItem,
        ],
      },
    ];
    if (inputValue) {
      response.push({
        name: 'search',
        operator: Filter_Type.Like,
        value: [inputValue as any],
      });
    }
    return response;
  }, [inputValue]);

  const campaignsQuery = useCampaignsQuery(
    {
      id: selectedItem.id,
      paging,
      filters,
      sorting,
    },
    {
      enabled: !!sorting.length,
      keepPreviousData: true,
      onSuccess: ({ shopCampaigns }) => setCampaigns(shopCampaigns.nodes),
    },
  );

  useEffect(() => {
    if (campaignsQuery.error) addToast(campaignsQuery.error.message, { appearance: 'error', autoDismiss: false });
  }, [addToast, campaignsQuery.error]);

  const isLoading = campaignsQuery.isLoading;
  const showCampaigns = !!campaigns.length && !campaignsQuery.isLoading;
  const pagingResponse = campaignsQuery.data?.shopCampaigns.paging;

  return (
    <PaginationLayout
      isLoading={isLoading}
      paging={pagingResponse}
      header={
        <TopNavigation title="Campaigns management">
          <Grid container justifyContent="flex-end" alignItems="center" spacing={1}>
            <Grid item md={4}>
              <ScutiInput
                placeholder="Search..."
                value={searchInput}
                onChange={({ target }) => setSearchInput(target.value)}
              />
            </Grid>
            <Grid item md={3}>
              <Link to={`${campaignRoutes.CAMPAIGN_NEW()}?shopId=${selectedItem.id}`}>
                <Button className="w-100" color="primary">
                  Add new campaign
                </Button>
              </Link>
            </Grid>
          </Grid>
        </TopNavigation>
      }
      content={showCampaigns && <ScutiTable table={tableInstance} />}
    />
  );
});
