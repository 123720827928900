import React from 'react';

interface Props {
  className?: string;
}

export const ShopsIcon: React.FC<Props> = ({ className = '' }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          className={className}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.12232 0.166748H13.8779V2.97841L16.195 7.03341H-0.194824L2.12232 2.97841V0.166748ZM3.12232 1.16675V3.24397L1.52836 6.03341H14.4718L12.8779 3.24397V1.16675H3.12232ZM2.12232 7.98897H13.8779V15.8334H2.12232V7.98897ZM7.03343 14.8334H8.96676V11.9223H7.03343V14.8334ZM9.96676 14.8334V10.9223H6.03343V14.8334H3.12232V8.98897H12.8779V14.8334H9.96676Z"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="#ffffff" />
        </clipPath>
      </defs>
    </svg>
  );
};
