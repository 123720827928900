import React from 'react';
import { Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import { gamesRoutes } from 'routing';

interface Props {
  games: any[];
  onDelete?: (userId: string) => void;
  onEdit?: (userId: string) => void;
}

export const GamesTable: React.FC<Props> = ({ games, onEdit, onDelete }) => {
  const editGame = onEdit ? (id: string) => () => onEdit(id) : undefined;
  const deleteGame = onDelete ? (id: string) => () => onDelete(id) : undefined;
  return (
    <Table responsive striped hover>
      <thead>
        <tr>
          <th>Name</th>
          <th>Owner</th>
          <th>Admins</th>
          <th>Attributes</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {games.map((game) => {
          return (
            <tr key={`user_${game.id}`}>
              <td data-label="Name">
                <Link to={gamesRoutes.GAME(game.id)}>{game.name}</Link>
              </td>
              <td data-label="Owner"></td>
              <td data-label="Admins">{game.admins.join(', ')}</td>
              <td data-label="Attributes">
                <div className="attr-list"></div>
              </td>
              <td className="col-90">
                <div className="row-actions">
                  {editGame && (
                    <span className="link-edit" title="Edit" onClick={editGame(game.id)}>
                      Edit
                    </span>
                  )}
                  {deleteGame && (
                    <span className="link-delete" title="Delete" onClick={deleteGame(game.id)}>
                      Delete
                    </span>
                  )}
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
