import React from 'react';
import { Switch } from 'react-router-dom';
import { campaignRoutes } from 'routing';
import { PrivateRoute } from 'components/PrivateRoute';
import { CampaignsListPage } from './CampaignsListPage';
import { CampaignEditPage } from './CampaignEditPage';

export const CampaignsRoutes: React.FC = () => {
  return (
    <Switch>
      <PrivateRoute exact path={campaignRoutes.CAMPAIGNS} component={CampaignsListPage} />
      <PrivateRoute exact path={campaignRoutes.CAMPAIGN_NEW()} component={CampaignEditPage} />
      <PrivateRoute exact path={campaignRoutes.CAMPAIGN_EDIT()} component={CampaignEditPage} />
    </Switch>
  );
};
