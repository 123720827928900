import React from 'react';

interface Props {
  className?: string;
}

export const UserIcon: React.FC<Props> = ({ className = '' }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        className={className}
        d="M7.99993 0C3.58174 0 0 3.58185 0 8.00003C0 12.4181 3.58174 16.0002 7.99993 16.0002C12.4181 16.0002 16.0001 12.4181 16.0001 8.00003C16.0001 3.58174 12.4181 0 7.99993 0ZM8.0243 11.7743L7.97545 11.7742H4.55819C4.55819 9.27516 6.75302 9.27574 7.24007 8.62229L7.2958 8.32431C6.61152 7.97753 6.12847 7.14145 6.12847 6.16362C6.12847 4.87537 6.96646 3.83087 7.99993 3.83087C9.03339 3.83087 9.87139 4.87537 9.87139 6.16362C9.87139 7.13313 9.39692 7.96398 8.72176 8.31625L8.78523 8.65487C9.31959 9.27665 11.4414 9.31719 11.4414 11.7743H8.0243Z"
      />
    </svg>
  );
};
