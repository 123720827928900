import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { gamesRoutes } from 'routing';
import { GamesListPage } from './GamesListPage';
import { GameOverviewPage } from './GameOverviewPage';
import { GameEditPage } from './GameEditPage';
import { useAppStore } from 'store/app-store.hook';
import { observer } from 'mobx-react-lite';
import { GamesGridPage } from './GamesGridPage';
import { hasUserRole } from 'utils/roles.utils';
import { Role } from 'types/__generated__/types';

export const GamesRoutes: React.FC = observer(() => {
  const { permissions } = useAppStore();

  return (
    <Switch>
      {permissions.gamesAccess.view && (
        <Route
          exact
          path={gamesRoutes.GAMES}
          component={hasUserRole(permissions.userRoles, Role.GameOwner) ? GamesGridPage : GamesListPage}
        />
      )}
      {permissions.gamesAccess.view && <Route exact path={gamesRoutes.GAME()} component={GameOverviewPage} />}
      {permissions.gamesAccess.edit && <Route exact path={gamesRoutes.GAME_NEW} component={GameEditPage} />}
      {permissions.gamesAccess.edit && <Route exact path={gamesRoutes.GAME_EDIT()} component={GameEditPage} />}
    </Switch>
  );
});
