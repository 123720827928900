import * as Types from '../../../../types/__generated__/types';

import { ApolloError } from 'apollo-client';
import { useQuery, UseQueryOptions, useMutation, UseMutationOptions } from 'react-query';
import { fetcher } from 'api';
export type ProductsQueryVariables = Types.Exact<{
  filters?: Types.Maybe<Array<Types.FilterInput> | Types.FilterInput>;
  paging?: Types.Maybe<Types.PagingInput>;
  sorting?: Types.Maybe<Array<Types.SortFieldInput> | Types.SortFieldInput>;
  id: Types.Scalars['ID'];
}>;

export type ProductsQuery = {
  shopProducts: {
    sorting?: Types.Maybe<Array<{ name: string; dir: string }>>;
    paging: { offset: number; limit: number; totalCount: number };
    nodes: Array<{
      id: string;
      name: string;
      tags?: Types.Maybe<Array<string>>;
      images?: Types.Maybe<Array<string>>;
      shop: { id: string; shopifyName?: Types.Maybe<string> };
      variants: Array<{
        id: string;
        image?: Types.Maybe<string>;
        inStock: number;
        price: { amount: number; currency: string };
      }>;
      productListings: Array<{
        id: string;
        status: Types.CampaignStatus;
        durationStartDate?: Types.Maybe<any>;
        durationEndDate?: Types.Maybe<any>;
      }>;
    }>;
  };
};

export type SyncEbridgeMutationVariables = Types.Exact<{
  input: Types.SyncEbridgeInput;
}>;

export type SyncEbridgeMutation = { syncEbridge?: Types.Maybe<{ date: any }> };

export const ProductsDocument = `
    query Products($filters: [FilterInput!], $paging: PagingInput, $sorting: [SortFieldInput!], $id: ID!) {
  shopProducts(filters: $filters, paging: $paging, sorting: $sorting, id: $id) {
    sorting {
      name
      dir
    }
    paging {
      offset
      limit
      totalCount
    }
    nodes {
      id
      name
      tags
      images
      shop {
        id
        shopifyName
      }
      variants {
        id
        image
        price {
          amount
          currency
        }
        inStock
      }
      productListings {
        id
        status
        durationStartDate
        durationEndDate
      }
    }
  }
}
    `;
export const useProductsQuery = <TData = ProductsQuery, TError = ApolloError>(
  variables: ProductsQueryVariables,
  options?: UseQueryOptions<ProductsQuery, TError, TData>,
) =>
  useQuery<ProductsQuery, TError, TData>(
    ['Products', variables],
    fetcher<ProductsQuery, ProductsQueryVariables>(ProductsDocument, variables),
    options,
  );
export const SyncEbridgeDocument = `
    mutation SyncEbridge($input: SyncEbridgeInput!) {
  syncEbridge(input: $input) {
    date
  }
}
    `;
export const useSyncEbridgeMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<SyncEbridgeMutation, TError, SyncEbridgeMutationVariables, TContext>,
) =>
  useMutation<SyncEbridgeMutation, TError, SyncEbridgeMutationVariables, TContext>(
    (variables?: SyncEbridgeMutationVariables) =>
      fetcher<SyncEbridgeMutation, SyncEbridgeMutationVariables>(SyncEbridgeDocument, variables)(),
    options,
  );
