import { SelectClassKey, StyleRules } from '@material-ui/core';
import { scutiColors, scutiSizes } from './variables';

export const MuiPaper: Partial<StyleRules<SelectClassKey, {}>> = {
  root: {
    '&.MuiAutocomplete-paper': {
      background: scutiColors.menuBackground,
      border: `1px solid ${scutiColors.borderFocused}`,
      color: scutiColors.textMain,
    },
    '&.MuiAutocomplete-paper > .MuiAutocomplete-listbox': {
      paddingTop: 0,
      paddingBottom: 0,
      '& li': {
        fontSize: scutiSizes.inputFontSize,
        cursor: 'default',
        '&:hover': {
          cursor: 'default',
          opacity: 1,
          backgroundColor: scutiColors.menuItemBackgroundHover,
        },
      },
    },
  },
};
