import { Role } from 'types/__generated__/types';
import { FormValidationSchema } from 'utils/form-utils';
import * as Yup from 'yup';

export interface GameInvitesForm {
  invites: {
    email: string;
    roles: Role[];
    accepted: boolean;
  }[];
}

export const inviteDefaults = (): GameInvitesForm['invites'][0] => ({
  email: '',
  roles: [Role.GameAdmin],
  accepted: false,
});

export const mapInviteForInput = (invites: GameInvitesForm['invites'], gameId: string) =>
  invites
    .filter(({ email }) => !!email)
    .map(({ accepted, ...user }) => ({
      id: gameId,
      user: {
        email: user.email,
        roles: user.roles,
      },
    }));

export const validationSchema = (): FormValidationSchema<GameInvitesForm> => {
  return Yup.object({
    invites: Yup.array().of(
      Yup.object({
        email: Yup.string().email('Email is not valid'),
        roles: Yup.array().of(Yup.mixed().oneOf(Object.values(Role))),
        accepted: Yup.boolean().required(),
      }),
    ),
  });
};
