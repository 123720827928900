import React from 'react';
import { FormGroup, CustomInput } from 'reactstrap';
import { COUNTRIES_SELECT_OPTIONS, USA_STATES_SELECT_OPTIONS } from 'types/select-types';
import { Box, Card, CardContent, CardHeader } from '@material-ui/core';
import { ScutiSelect } from 'components/material/ScutiSelect';
import { ScutiMultipleSelect } from 'components/material/ScutiMultipleSelect';
import { Controller, UseFormReturn } from 'react-hook-form';
import { CampaignFormData } from './CampaignEditForm.utils';

interface Props {
  form: UseFormReturn<CampaignFormData>;
  paused?: boolean;
}

export function LocationDetails({ form, paused }: Props) {
  const values = form.watch();

  const [countrySpecific, setCountrySpecific] = React.useState(!!values.location?.country);
  const [stateSpecific, setStateSpecific] = React.useState(!!values.location?.states);

  const toggleCountry = () => {
    if (countrySpecific) form.setValue('location', {});
    setCountrySpecific(!countrySpecific);
  };

  const toggleState = () => {
    if (stateSpecific) form.setValue('location.states', null);
    setStateSpecific(!stateSpecific);
  };

  const isUS = values.location?.country === 'US';

  return (
    <Card>
      <CardHeader title="Location" />
      <CardContent>
        <Box mt={2}>
          <FormGroup>
            <label htmlFor={`location.country`}>
              <CustomInput
                id="country-box"
                type="checkbox"
                label="Country specific"
                onChange={toggleCountry}
                checked={countrySpecific}
                disabled={!paused}
              />
            </label>
            <Controller
              control={form.control}
              name="location.country"
              render={({ field, fieldState }) => (
                <ScutiSelect
                  placeholder="Country"
                  value={values?.location?.country || ''}
                  options={COUNTRIES_SELECT_OPTIONS}
                  onChange={(e) => {
                    field.onChange(e);
                    form.setValue('location.states', null);
                  }}
                  onBlur={field.onBlur}
                  error={fieldState.error?.message}
                  disabled={!countrySpecific || !paused}
                />
              )}
            />
          </FormGroup>
        </Box>
        <Box mt={2}>
          <FormGroup>
            <label htmlFor={`demographic.gender`}>
              <CustomInput
                id="state-box"
                type="checkbox"
                label="State specific"
                onChange={toggleState}
                checked={stateSpecific}
                disabled={!isUS || !paused}
              />
            </label>
            <Controller
              control={form.control}
              name="location.states"
              render={({ field, fieldState }) => (
                <ScutiMultipleSelect
                  name="location.states"
                  placeholder="States"
                  value={(field.value || [])[0] || ''}
                  values={field.value || []}
                  options={USA_STATES_SELECT_OPTIONS}
                  onChange={({ target }) => field.onChange([...(values?.location?.states || []), target.value])}
                  onBlur={field.onBlur}
                  disabled={!isUS || !stateSpecific || !paused}
                  onRemove={(value) => {
                    field.onChange([...(values?.location?.states || [])].filter((s) => s !== value));
                  }}
                  error={fieldState.error?.message}
                />
              )}
            />
          </FormGroup>
        </Box>
      </CardContent>
    </Card>
  );
}
