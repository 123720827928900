import { websiteRegEx } from 'utils/form-utils';
import * as Yup from 'yup';
import isMobilePhone from 'validator/lib/isMobilePhone';
import { taxIdValidator } from 'utils/tax-id-validator';
import { OrganizationInput, OrganizationType, UpdateOrganizationInput } from 'types/__generated__/types';

export type OrganizationFormData = OrganizationInput & Omit<UpdateOrganizationInput, 'id'> & { id?: string };

export const organizationFormDefaults = (): OrganizationFormData => {
  return {
    id: undefined,
    address1: '',
    address2: '',
    city: '',
    zipCode: '',
    country: '',
    state: null,
    name: '',
    website: '',
    identificationNumber: '',
    dbaName: '',
    phone: '',
    owner: null,
    types: [],
  };
};

const fullNameRegEx = /^([a-zA-ZÀ-ÖØ-öø-ÿ]|\d){2,}\s([a-zA-ZÀ-ÖØ-öø-ÿ]|\d){2,}$/;

export const organizationSchema: Yup.ObjectSchema<OrganizationFormData> = Yup.object()
  .shape({
    id: Yup.string(),
    address1: Yup.string()
      .min(3, 'Address should be at least 3 characters.')
      .max(25, 'Address can be 25 characters or less.')
      .required('Address is required.'),
    address2: Yup.string().nullable(),
    city: Yup.string().min(2, 'City should be at least 2 characters.').required('City is required.'),
    zipCode: Yup.string()
      .min(2, 'Zip code is not correct')
      .max(8, 'Zip code is not correct')
      .required('Zip code is required.'),
    country: Yup.string().min(2, 'Country is required.').required('Country is required.'),
    state: Yup.string().when('country', {
      is: (val: string) => val === 'US',
      then: (schema) => schema.min(1, 'State is required').required('State is required'),
      otherwise: (schema) =>
        schema.min(2, 'Province should be at least 2 characters.').required('Province is required'),
    }),
    name: Yup.string()
      .min(3, 'Name should be at least 3 characters.')
      .max(25, 'Name can be 25 characters or less.')
      .required('Name is required.'),
    website: Yup.string().matches(websiteRegEx, 'Website URL is not correct').nullable().required(),
    identificationNumber: Yup.string()
      .test('identificationNumber', 'Number is not correct', function (value) {
        return value ? taxIdValidator(value, this.parent.country) : false;
      })
      .required('Number is required.'),
    dbaName: Yup.string()
      .min(3, 'Name should be at least 3 characters.')
      .max(25, 'Name can be 25 characters or less.')
      .required()
      .transform((value) => (value === null ? '' : value)),
    phone: Yup.string()
      .test('phone', 'Phone is not correct', function (value) {
        return isMobilePhone(`${value}`.replace(/(\(|\)|\s|-)/g, ''), undefined, { strictMode: true });
      })
      .required()
      .transform((value) => (value === null ? '' : value)),
    owner: Yup.object().shape({
      fullName: Yup.string().matches(fullNameRegEx, 'Full name is not correct').required('Name is required.'),
      email: Yup.string().email('Email is not correct').required('Email is required.'),
    }),
    types: Yup.array()
      .min(1, 'At least one Organization Type is required.')
      .of(Yup.string().oneOf(Object.values(OrganizationType)).required())
      .required('At least one Organization Type is required.'),
  })
  .required();
