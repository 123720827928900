import React from 'react';
import classNames from 'classnames';
import { Button, Card, CardBody, Form, FormFeedback, FormGroup, InputGroup, Input } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoginRequest } from '../../../types/auth-types';
import { NavLink } from 'react-router-dom';

import { EmailIcon } from 'components/svg-icons/Email';
import { LockIcon } from 'components/svg-icons/Lock';
import { appRoutes } from 'routing';
import { validators, validate } from 'utils/form-utils';

interface FormValues {
  email: string;
  password: string;
}

const validationSchema = () =>
  Yup.object().shape({
    email: validators.email,
    password: Yup.string().required(),
  });

const initialValues = {
  email: '',
  password: '',
};

interface LoginInFormProps {
  error?: string;
  onLogin: (params: LoginRequest) => void;
}

export const LoginInForm: React.FC<LoginInFormProps> = ({ onLogin, error }) => {
  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
    },
    onSubmit: onLogin,
    isInitialValid: false,
    // @ts-ignore
    validate: validate(validationSchema),
  });
  const { values, errors, touched, handleChange, handleBlur, handleSubmit, isValid } = formik;
  return (
    <Card className="w-23">
      <CardBody>
        <div className="card-headline">
          <h1>Login</h1>
          <p>Please fill out your information to enter.</p>
        </div>
        <div className="w-100">
          <Form onSubmit={handleSubmit}>
            <FormGroup
              className={classNames(
                'form-group-addon',
                { 'input-active': values.email },
                { 'input-invalid': touched.email && !!errors.email },
                { 'input-touched': touched.email },
                { 'input-valid': touched.email && !errors.email },
              )}
            >
              <label htmlFor="email" className="ico-label">
                <EmailIcon />
              </label>
              <Input
                type="email"
                name="email"
                id="email"
                placeholder="Email address"
                autoComplete="email"
                valid={touched.email && !errors.email}
                invalid={touched.email && !!errors.email}
                required
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
              <i className="glyphicon glyphicon-user form-control-feedback" />
              <FormFeedback>{errors.email}</FormFeedback>
            </FormGroup>
            <FormGroup
              className={classNames(
                'form-group-addon',
                { 'input-active': values.password },
                {
                  'input-invalid': touched.password && !!errors.password,
                },
                { 'input-touched': touched.password },
                { 'input-valid': touched.password && !errors.password },
              )}
            >
              <InputGroup>
                <label htmlFor="password" className="ico-label">
                  <LockIcon />
                </label>
                <Input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Your password"
                  valid={touched.password && !errors.password}
                  invalid={touched.password && !!errors.password}
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                />
              </InputGroup>
              <FormFeedback>{errors.password}</FormFeedback>
            </FormGroup>
            <div className="form-bar d-flex align-items-center">
              <span className="mr-1">Forgot your password?</span>
              <NavLink to={appRoutes.FORGOT_PASSWORD}>Reset</NavLink>
            </div>
            <div className="actions">
              {error && <div className="text-center text-danger mb-1">{error}</div>}
              <FormGroup className="d-flex justify-content-center">
                <Button className="btn-block" type="submit" color="primary" disabled={!isValid}>
                  Login
                </Button>
              </FormGroup>
            </div>
            {/* <div className="d-flex align-items-center justify-content-center">
              <span className="mr-1">Need an account?</span>
              <NavLink className="text-center" to={appRoutes.REGISTRATION}>
                Register
              </NavLink>
            </div> */}
          </Form>
        </div>
      </CardBody>
    </Card>
  );
};
