import React, { Suspense } from 'react';

import { ScutiNewLogo } from '../components/svg-icons/ScutiNewLogo';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from 'layouts/ErrorFallback';

export const EmailVerificationLayout: React.FC = ({ children }) => {
  return (
    <div className="non-auth-container">
      <strong className="logo">
        <ScutiNewLogo />
      </strong>

      {children}
    </div>
  );
};

const loading = () => (
  <div className="animated fadeIn pt-1 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

export const withNewLogoLayout = (Page: React.ComponentType) => () => (
  <EmailVerificationLayout>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense fallback={loading()}>
        <Page />
      </Suspense>
    </ErrorBoundary>
  </EmailVerificationLayout>
);
