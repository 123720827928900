import React from 'react';

interface Props {
  className?: string;
}

export const OrdersIcon: React.FC<Props> = ({ className = '' }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        className={className}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9641 4.19069L14.6787 13.1943C14.734 13.9166 14.4839 14.6345 13.9925 15.1651C13.501 15.6957 12.8043 16 12.0811 16H4.60515C3.88194 16 3.18528 15.6957 2.69384 15.1651C2.2024 14.6345 1.95225 13.9166 2.00756 13.1955L2.72231 4.18947C2.76669 3.61059 3.25728 3.15622 3.83906 3.15622H5.25037V3.09278C5.25037 1.38741 6.63778 0 8.34315 0C10.0485 0 11.4359 1.38741 11.4359 3.09278V3.15622H12.8473C13.429 3.15622 13.9196 3.61063 13.9641 4.19069ZM8.34315 0.937187C7.15459 0.937187 6.18756 1.90419 6.18756 3.09278V3.15622H10.4987V3.09278C10.4987 1.90422 9.53172 0.937187 8.34315 0.937187ZM12.0811 15.0628C12.551 15.0628 12.9856 14.873 13.3049 14.5283C13.6242 14.1835 13.7802 13.7357 13.7443 13.2672L13.0298 4.26359C13.0224 4.16762 12.9423 4.09341 12.8473 4.09341H11.4359V5.24762C11.4359 5.50641 11.2261 5.71622 10.9673 5.71622C10.7086 5.71622 10.4987 5.50641 10.4987 5.24762V4.09341H6.18756V5.24762C6.18756 5.50641 5.97775 5.71622 5.71897 5.71622C5.46019 5.71622 5.25037 5.50641 5.25037 5.24762V4.09341H3.83906C3.74403 4.09341 3.6639 4.16759 3.65665 4.26234L2.94197 13.2684C2.90609 13.7357 3.06215 14.1836 3.38144 14.5283C3.70075 14.873 4.13531 15.0628 4.60519 15.0628H12.0811ZM9.79188 7.94643C9.97485 7.76343 10.2716 7.76343 10.4545 7.94643C10.6375 8.12943 10.6375 8.42611 10.4545 8.60914L8.03079 11.0328C7.93932 11.1244 7.81935 11.1701 7.69948 11.1701C7.5796 11.1701 7.45966 11.1243 7.36817 11.0328L6.23226 9.89692C6.04926 9.71393 6.04926 9.41724 6.23226 9.23424C6.41526 9.05124 6.71198 9.05124 6.89495 9.23424L7.69951 10.0388L9.79188 7.94643Z"
      />
    </svg>
  );
};
