import {
  CampaignBudgetOption,
  CampaignStatus,
  CampaignType,
  ContentCategory,
  NewCampaignInput,
  RequestEntityType,
  UpdateCampaignInput,
} from 'types/__generated__/types';
import { UserItem } from 'types/user-types';
import { transformedNumber } from 'utils/form-utils';
import { getImgDimensions, uploadImage } from 'utils/upload-image.utils';
import * as Yup from 'yup';

export const isPaused = (status?: CampaignStatus | null) =>
  !status || status === CampaignStatus.Paused || status === CampaignStatus.Pending;

const FILE_SIZE = 3 * 1024 * 1024;
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

export const campaignSchema = (entity: RequestEntityType) =>
  Yup.object().shape({
    id: Yup.string(),
    name: Yup.string()
      .min(3, 'Name should be at least 3 characters.')
      .max(25, 'Name can be 25 characters or less.')
      .required('Name is required!'),
    productListingId: Yup.string(),
    status: Yup.mixed<CampaignStatus>().oneOf(Object.values(CampaignStatus)).nullable(),
    type: Yup.mixed<CampaignType>()
      .oneOf([
        CampaignType.Organization,
        CampaignType.ShopAdvertisement,
        CampaignType.ShopBrand,
        CampaignType.SingleItem,
      ])
      .required('Campaign type is required!'),
    product: Yup.object()
      .shape({
        id: Yup.string(),
        name: Yup.string(),
      })
      .test('product', 'Product is required', (value, { parent }) =>
        entity === RequestEntityType.Shop && parent.type === CampaignType.SingleItem ? !!value.id : true,
      ),
    advertisement: Yup.object()
      .shape({
        id: Yup.string(),
        name: Yup.string(),
      })
      .test('advertisement', 'Advertisement is required', (value, { parent }) =>
        entity === RequestEntityType.Brand && parent.type === CampaignType.SingleItem ? !!value.id : true,
      ),
    reward: Yup.object()
      .shape({
        scutiPercentage: Yup.number().min(0).max(100),
      })
      .nullable(),
    duration: Yup.object().shape({
      start: Yup.date().nullable(),
      end: Yup.date().nullable(),
      inventory: Yup.number().nullable(),
      runUntilStockZero: Yup.boolean().nullable(),
    }),
    demographic: Yup.object().shape({
      gender: Yup.string().nullable(),
      minAge: Yup.number().min(0).max(100).required('Min age required'),
      maxAge: Yup.number().min(0).max(100).required('Man age required'),
    }),
    location: Yup.object()
      .shape({
        country: Yup.string().nullable(),
        states: Yup.array().of(Yup.string().required()).nullable(),
      })
      .required('Location required'),
    category: Yup.string().nullable(),
    budget: Yup.object()
      .shape({
        metricType: Yup.mixed<'cpc' | 'cpm'>().oneOf(['cpc', 'cpm']).required('Option required'),
        option: Yup.mixed<CampaignBudgetOption>()
          .oneOf(Object.values(CampaignBudgetOption))
          .required('Option required'),
        maxSpend: transformedNumber.min(0, 'Cannot be 0 or less').required('Max Spend is required!'),
        maxDailySpend: transformedNumber.min(0, 'Cannot be 0 or less').required('Max Spend is required!'),
        limitOfImpressions: transformedNumber.min(6, 'Cannot be 0 or less').required('Daily Max Spend is required!'),
      })
      .required('Budget details required'),
    extras: Yup.object()
      .shape({
        moreExposure: Yup.boolean().required('More exposure option required'),
        interstitials: Yup.boolean().required('Interstitials option required'),
      })
      .required('Extras details required'),
    game: Yup.object().shape({
      aiTargetRun: Yup.boolean(),
      contentCategory: Yup.mixed<ContentCategory>().oneOf(Object.values(ContentCategory)).nullable(),
    }),
    media: Yup.object()
      .shape({
        banner: Yup.mixed<File>()
          .nullable()
          .test('fileSize', 'File too large', (value) => {
            if (!value) return true;
            return typeof value === 'string' || value.size <= FILE_SIZE;
          })
          .test('fileFormat', 'Unsupported Format', (value) => {
            if (!value) return true;
            return typeof value === 'string' || SUPPORTED_FORMATS.includes(value.type);
          })
          .test('aspectRatio', 'Wrong dimensions', async (value) => {
            if (!value) return true;
            if (typeof value === 'string') return true;
            const { width, height } = await getImgDimensions(value);
            return width === 728 && height === 90;
          }),
        vertical: Yup.mixed<File>()
          .nullable()
          .test('fileSize', 'File too large', (value) => {
            if (!value) return true;
            return typeof value === 'string' || value.size <= FILE_SIZE;
          })
          .test('fileFormat', 'Unsupported Format', (value) => {
            if (!value) return true;
            return typeof value === 'string' || SUPPORTED_FORMATS.includes(value.type);
          })
          .test('aspectRatio', 'Wrong dimensions', async (value) => {
            if (!value) return true;
            if (typeof value === 'string') return true;
            const { width, height } = await getImgDimensions(value);
            return width === 300 && height === 600;
          }),
        tile: Yup.mixed<File>()
          .nullable()
          .test('fileSize', 'File too large', (value) => {
            if (!value) return true;
            return typeof value === 'string' || value.size <= FILE_SIZE;
          })
          .test('fileFormat', 'Unsupported Format', (value) => {
            if (!value) return true;
            return typeof value === 'string' || SUPPORTED_FORMATS.includes(value.type);
          })
          .test('aspectRatio', 'Wrong dimensions', async (value) => {
            if (!value) return true;
            if (typeof value === 'string') return true;
            const { width, height } = await getImgDimensions(value);
            return width === 300 && height === 250;
          }),
      })
      .nullable(),
  });

export type CampaignFormData = Yup.InferType<ReturnType<typeof campaignSchema>>;

export const campaignFormDataDefaults = (): CampaignFormData => {
  return {
    name: '',
    type: CampaignType.SingleItem,
    product: {},
    advertisement: {},
    reward: {
      scutiPercentage: 0,
    },
    duration: {
      start: null,
      end: null,
      inventory: null,
      runUntilStockZero: null,
    },
    demographic: {
      maxAge: 100,
      minAge: 0,
      gender: null,
    },
    location: {
      country: null,
      states: [],
    },
    category: null,
    game: {},
    budget: {
      metricType: 'cpc',
      option: CampaignBudgetOption.Normal,
      maxSpend: 0,
      maxDailySpend: 0,
      limitOfImpressions: 6,
    },
    extras: { moreExposure: false, interstitials: false },
    media: {
      banner: null,
      vertical: null,
      tile: null,
    },
  };
};

const mapMedia = async (media: CampaignFormData['media']) => {
  if (!media) return media;
  const { vertical, tile, banner } = media;
  return {
    vertical:
      vertical && typeof vertical === 'object'
        ? (await uploadImage(vertical, { height: 600, width: 300 })).url
        : vertical,
    tile: tile && typeof tile === 'object' ? (await uploadImage(tile, { height: 250, width: 300 })).url : tile,
    banner: banner && typeof banner === 'object' ? (await uploadImage(banner, { height: 90, width: 728 })).url : banner,
  };
};

export const mapCampaignToApiInput =
  (shopId: string) =>
  async ({
    product,
    advertisement,
    ...campaign
  }: CampaignFormData): Promise<NewCampaignInput | UpdateCampaignInput> => {
    return {
      ...campaign,
      shopId,
      reward: { scutiPercentage: campaign.reward?.scutiPercentage },
      productId: product.id ? product.id : null,
      advertisementId: advertisement.id ? advertisement.id : null,
      extras: campaign.extras,
      location: { country: campaign.location?.country, states: campaign.location?.states },
      media: campaign.media && (await mapMedia(campaign.media)),
    };
  };
