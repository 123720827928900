import React from 'react';
import { ShopsIcon } from 'components/svg-icons/Shops';
import {
  dashboardRoutes,
  organizationsRoutes,
  productsRoutes,
  gamesRoutes,
  ordersRoutes,
  campaignRoutes,
  settingsRoutes,
  shopsRoutes,
  productListingRoutes,
  advertisementRoutes,
  brandsRoutes,
} from 'routing';
import { DashboardIcon } from 'components/svg-icons/Dashboard';
import { OrdersIcon } from 'components/svg-icons/Orders';
import { CampaignIcon } from 'components/svg-icons/Campaign';
import { GamesIcon } from 'components/svg-icons/Games';
import { SettingsIcon } from 'components/svg-icons/Settings';
import { hasOneOfRoles } from 'utils/roles.utils';
import { RequestEntityType, Role } from 'types/__generated__/types';
import { UserItem } from 'types/user-types';

interface NavItem {
  route: string;
  title: string;
  isActive: (pathname: string) => boolean;
  icon: React.ReactElement;
}

export const getNavigation = (roles: Role[], selectedItem: UserItem): NavItem[] => {
  const nav = [
    {
      route: dashboardRoutes.HOME,
      title: 'Dashboard',
      isActive: (pathname: string): boolean =>
        pathname.startsWith(dashboardRoutes.DASHBOARD) || pathname === dashboardRoutes.HOME,
      icon: <DashboardIcon className="ico-dashboard" />,
    },
  ];

  if (hasOneOfRoles(roles, [Role.ScutiAdmin, Role.OrganizationAdmin, Role.OrganizationOwner])) {
    nav.push({
      route: organizationsRoutes.ORGANIZATIONS,
      title: 'Organizations',
      isActive: (pathname: string): boolean => pathname.startsWith(organizationsRoutes.ORGANIZATIONS),
      icon: <ShopsIcon className="ico-shops" />,
    });
  }
  if (hasOneOfRoles(roles, [Role.ScutiAdmin, Role.OrganizationAdmin, Role.OrganizationOwner])) {
    nav.push({
      route: brandsRoutes.BRANDS,
      title: 'Brands',
      isActive: (pathname: string): boolean => pathname.startsWith(brandsRoutes.BRANDS),
      icon: <ShopsIcon className="ico-shops" />,
    });
  }

  if (hasOneOfRoles(roles, [Role.ScutiAdmin, Role.ShopAdmin, Role.ShopOwner])) {
    nav.push({
      route: shopsRoutes.SHOPS,
      title: 'Shops',
      isActive: (pathname: string): boolean => pathname.startsWith(shopsRoutes.SHOPS),
      icon: <ShopsIcon className="ico-products" />,
    });
  }
  if (
    hasOneOfRoles(roles, [Role.ScutiAdmin, Role.ShopAdmin, Role.ShopOwner]) &&
    selectedItem.itemType === RequestEntityType.Shop
  ) {
    nav.push({
      route: productsRoutes.PRODUCTS,
      title: 'Products',
      isActive: (pathname: string): boolean => pathname.startsWith(productsRoutes.PRODUCTS),
      icon: <ShopsIcon className="ico-products" />,
    });
  }

  if (hasOneOfRoles(roles, [Role.ScutiAdmin, Role.GameAdmin, Role.GameOwner])) {
    nav.push({
      route: gamesRoutes.GAMES,
      title: 'Games',
      isActive: (pathname: string): boolean => pathname.startsWith(gamesRoutes.GAMES),
      icon: <GamesIcon className="ico-games" />,
    });
  }

  if (
    hasOneOfRoles(roles, [Role.ScutiAdmin, Role.ShopAdmin, Role.ShopOwner]) &&
    [RequestEntityType.Shop, RequestEntityType.Brand].some((s) => s === selectedItem.itemType)
  ) {
    nav.push(
      {
        route: productListingRoutes.PRODUCT_LISTING,
        title: 'Product Listings',
        isActive: (pathname: string): boolean => pathname.startsWith(productListingRoutes.PRODUCT_LISTING),
        icon: <CampaignIcon className="ico-campaign" />,
      },
      {
        route: campaignRoutes.CAMPAIGNS,
        title: 'Campaigns',
        isActive: (pathname: string): boolean => pathname.startsWith(campaignRoutes.CAMPAIGNS),
        icon: <CampaignIcon className="ico-campaign" />,
      },
      {
        route: advertisementRoutes.ADVERTISEMENTS,
        title: 'Advertisements',
        isActive: (pathname: string): boolean => pathname.startsWith(advertisementRoutes.ADVERTISEMENTS),
        icon: <CampaignIcon className="ico-campaign" />,
      },
      {
        route: ordersRoutes.ORDERS,
        title: 'Orders',
        isActive: (pathname: string): boolean => pathname.startsWith(ordersRoutes.ORDERS),
        icon: <OrdersIcon className="ico-orders" />,
      },
    );
  }

  return [
    ...nav,
    {
      route: settingsRoutes.SETTINGS,
      title: 'Settings',
      isActive: (pathname: string): boolean => pathname.startsWith(settingsRoutes.SETTINGS),
      icon: <SettingsIcon className="ico-settings" />,
    },
  ];
};
