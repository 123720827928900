import { Box, Card, CardHeader, CardContent } from '@material-ui/core';
import { ScutiInput } from 'components/material/ScutiInput';
import { Controller, UseFormReturn } from 'react-hook-form';
import { ScutiSelect } from 'components/material/ScutiSelect';
import { useProductCategories } from 'hooks/useProductCategories';
import { LeadGenAdvertisementFormData } from './LeadGenAdvertisementEditForm.utils';

interface Props {
  form: UseFormReturn<LeadGenAdvertisementFormData>;
}

export function LeadGenAdvertisementAffiliate({ form }: Props) {
  const productCategoriesOptions = useProductCategories();

  return (
    <Card>
      <CardHeader title="App Download Ids" />
      <CardContent>
        <Box mt={2}>
          <Controller
            control={form.control}
            name="affiliateUrl"
            render={({ field, fieldState }) => (
              <ScutiInput title="Affiliate URL *" placeholder="http://" {...field} error={fieldState.error?.message} />
            )}
          />
        </Box>
        <Box mt={2}>
          <Controller
            control={form.control}
            name="category"
            render={({ field, fieldState }) => (
              <ScutiSelect
                title="Category *"
                placeholder="Select category"
                {...field}
                options={productCategoriesOptions}
                error={fieldState.error?.message}
              />
            )}
          />
        </Box>
      </CardContent>
    </Card>
  );
}
