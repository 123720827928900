import * as Yup from 'yup';
import { FormValidationSchema } from 'utils/form-utils';
import { CreateBrandInput, UpdateBrandInput } from 'types/__generated__/types';

export type BrandFormData = Omit<CreateBrandInput, 'organizationId'> &
  Omit<UpdateBrandInput, 'id'> & { id?: string; organizationId?: string };

export const brandFormDefaults = (): BrandFormData => ({
  id: undefined,
  organizationId: undefined,
  name: '',
  description: undefined,
  credentials: {
    login: '',
    pin: '',
  },
  mediaInvestmentEnabled: false,
  directResponse: { unit: 'cpm', amount: 0 },
  engagement: { unit: 'cpm', amount: 0 },
  video: { unit: 'cpm', amount: 0 },
});

export const brandSchema: FormValidationSchema<BrandFormData> = Yup.object().shape({
  id: Yup.string(),
  name: Yup.string()
    .min(2, 'Brand Name is too short. Min size is 2 characters.')
    .max(100, 'Brand Name is too long. Max size is 100 characters.'),
  description: Yup.string()
    .min(12, 'Description is too short. Min size is 12 characters.')
    .max(10000, 'Description is too long. Max size is 10000 characters.')
    .nullable(),
  credentials: Yup.object().shape({
    login: Yup.string()
      .max(2, 'Scuti Login is too short. Min size is 2 characters.')
      .max(30, 'Scuti Login is too long. Max size is 30 characters.'),
    pin: Yup.string()
      .max(2, 'Scuti Pin is too short. Min size is 2 characters.')
      .max(30, 'Scuti Pin is too long. Max size is 30 characters.'),
  }),
  mediaInvestmentEnabled: Yup.boolean(),
  directResponse: Yup.object().shape({
    unit: Yup.string(),
    amount: Yup.number(),
  }),
  engagement: Yup.object().shape({
    unit: Yup.string(),
    amount: Yup.number(),
  }),
  video: Yup.object().shape({
    unit: Yup.string(),
    amount: Yup.number(),
  }),
});
