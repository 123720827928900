import React from 'react';
import { Grid, FormGroup, Box } from '@material-ui/core';
import { ScutiInput } from 'components/material/ScutiInput';
import { ScutiSelect } from 'components/material/ScutiSelect';
import { GameInvitesForm } from './GameInvitesEditForm.utils';
import { Button } from 'reactstrap';
import { Role } from 'types/__generated__/types';

interface Props {
  invite: GameInvitesForm['invites'][0];
  onRemove: (email: string | null) => void;
  onResend: (email: string) => void;
}

export const GameInvitesInvitedRow: React.FC<Props> = ({ invite, onRemove, onResend }) => {
  return (
    <Grid item container spacing={2} alignItems="center">
      <Grid item container xs={9} spacing={2}>
        <Grid item xs={8}>
          <ScutiInput value={invite.email} disabled />
        </Grid>
        <Grid item xs={4}>
          <ScutiSelect
            value={Role.GameAdmin}
            placeholder="Select Role"
            options={[{ label: 'Game Admin', value: Role.GameAdmin }]}
            disabled
          />
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <Box display="flex">
          <Box mt={1} mr={1}>
            <label>{invite.accepted ? '' : 'Not '}Accepted</label>
          </Box>
          {!invite.accepted && (
            <Button color="primary" onClick={() => onResend(invite.email)}>
              Resend Invite
            </Button>
          )}
        </Box>
      </Grid>
      <Grid item xs={1}>
        <FormGroup className="d-flex justify-content-center">
          <Button className="btn-close" type="button" onClick={() => onRemove(invite.email)} />
        </FormGroup>
      </Grid>
    </Grid>
  );
};
