import React from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { ordersRoutes } from 'routing';
import { useAppStore } from 'store/app-store.hook';
import { useParams } from 'react-router';
import { Loader } from 'components/Loader';
import { PageContentLayout } from 'layouts/PageContentLayout';
import { CustomerDetails } from './CustomerDetails';
import { PreviousOrdersTable } from './PreviousOrdersTable';
import styles from './OrderSummaryPage.module.scss';
import { applyFilters } from '../Orders.utils';
import { useOrdersQuery, useShopOrderQuery } from '../__generated__/orders.hooks';
import { Grid } from '@material-ui/core';
import { usePaging } from 'hooks/usePaging';

export const OrderSummaryPage: React.FC = observer(() => {
  const { orderId } = useParams<{ orderId: string }>();
  const { selectedItem } = useAppStore().uiStore;
  const shopId = selectedItem.id;
  const paging = usePaging();
  const useOrder = useShopOrderQuery({ id: orderId });

  const order = useOrder.data?.order;

  const useOrders = useOrdersQuery({
    id: shopId,
    paging,
    filters: applyFilters(null, null, order?.user.email),
  });

  const orders = useOrders.data?.shopOrders.nodes || [];

  return (
    <PageContentLayout>
      {order && (
        <PageContentLayout.Header className="d-flex align-items-center justify-content-between mb-4">
          <div className="d-flex align-items-center">
            <Link className="btn btn-back" to={ordersRoutes.ORDER_OVERVIEW(order.id)} />
            <h1 className={styles.page_title}>{order.user.fullName}</h1>
          </div>
        </PageContentLayout.Header>
      )}
      <PageContentLayout.Content>
        {useOrder.isLoading && <Loader />}
        {order && (
          <Grid container spacing={2}>
            <Grid item md={4}>
              <CustomerDetails order={order} />
            </Grid>
            <Grid item md={8}>
              <PreviousOrdersTable orders={orders} />
            </Grid>
          </Grid>
        )}
      </PageContentLayout.Content>
    </PageContentLayout>
  );
});
