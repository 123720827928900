import React from 'react';
import { Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import { FieldArray, FormikProps } from 'formik';
import { inviteDefaults, GameInvitesForm } from './GameInvitesEditForm.utils';
import { InfoPopup } from 'components/InfoPopup';
import { GameInvitesEditNewRow } from './GameInvitesEditNewRow';
import { GameInvitesInvitedRow } from './GameInvitesInvitedRow';

interface Props {
  existedInvites?: GameInvitesForm['invites'];
  formik: FormikProps<GameInvitesForm>;
  onRemove: (email: string) => void;
  onResend: (email: string) => void;
}

export const GameInvitesEditForm: React.FC<Props> = ({ formik, existedInvites = [], onRemove, onResend }) => {
  const [deleteEmail, setDeleteEmail] = React.useState<string | null>(null);
  return (
    <Card>
      <CardHeader title="Invite Users" />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item container spacing={2}>
            <Grid item container xs={9} spacing={2}>
              <Grid item xs={8}>
                <label>Email</label>
              </Grid>
              <Grid item xs={4}>
                <label>Permission</label>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <label>Status</label>
            </Grid>
            <Grid item xs={1} />
          </Grid>
          {existedInvites.map((invite, index) => (
            <GameInvitesInvitedRow key={index} invite={invite} onResend={onResend} onRemove={setDeleteEmail} />
          ))}
          <Grid item container spacing={2}>
            <Grid item container xs={9} spacing={2}>
              <Grid item xs={8}></Grid>
              <Grid item xs={4}></Grid>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={1} />
          </Grid>
          <FieldArray
            name="invites"
            render={(arrayHelpers) => {
              return (
                <Grid container item spacing={2}>
                  {formik.values.invites.map((invite, index) => {
                    return (
                      <GameInvitesEditNewRow
                        key={index}
                        rowIndex={index}
                        formik={formik}
                        invite={invite}
                        onAdd={() => {
                          arrayHelpers.insert(index + 1, inviteDefaults());
                        }}
                        onRemove={() => {
                          arrayHelpers.remove(index);
                        }}
                      />
                    );
                  })}
                </Grid>
              );
            }}
          />
        </Grid>
        {deleteEmail && (
          <InfoPopup
            title="Remove user from shop?"
            description=""
            proceedText="Remove"
            cancelText="Cancel"
            onProceed={() => {
              onRemove(deleteEmail);
              setDeleteEmail(null);
            }}
            onCancel={() => setDeleteEmail(null)}
          />
        )}
      </CardContent>
    </Card>
  );
};
