// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiPopover-paper.MuiPaper-rounded {
  background-color: rgb(42, 44, 52);
}
`, "",{"version":3,"sources":["webpack://./src/material-theme/MuiPaper.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;AACnC","sourcesContent":[".MuiPopover-paper.MuiPaper-rounded {\n  background-color: rgb(42, 44, 52);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
