import { Box, Card, CardHeader, CardContent } from '@material-ui/core';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import { Controller, UseFormReturn } from 'react-hook-form';
import { CouponAdvertisementFormData } from './CouponAdvertisementEditForm.utils';
import { ScutiMultipleImgInput } from 'components/material/ScutiMultipleImgInput';
import { FormHelperText } from '@mui/material';

interface Props {
  form: UseFormReturn<CouponAdvertisementFormData>;
}

export function CouponAdvertisementImages({ form }: Props) {
  return (
    <Card>
      <CardHeader title="Images" />
      <CardContent>
        <Box mt={2}>
          <Controller
            control={form.control}
            name="couponImages"
            render={({ field, fieldState }) => (
              <Box>
                <ScutiMultipleImgInput
                  images={field.value || []}
                  onChange={(images) => field.onChange(images)}
                  defaultImage={field.value?.[0]}
                  onDefaultImageUpdate={(image) => {
                    field.onChange([image, ...(field.value || [])]);
                  }}
                >
                  <Box
                    mt={10}
                    width="100%"
                    height="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                  >
                    <PhotoLibraryIcon style={{ width: '12rem', height: '7.5rem' }} width="100%" color="secondary" />
                  </Box>
                </ScutiMultipleImgInput>
                <FormHelperText error>{fieldState.error?.message}</FormHelperText>
              </Box>
            )}
          />
        </Box>
      </CardContent>
    </Card>
  );
}
