import React from 'react';
import { LoginInForm } from '../../../components/auth//LoginForm';
import { TwoStepVerificationPhoneForm } from '../../../components/auth//TwoStepVerificationPhoneForm';
import { useAppStore } from '../../../store/app-store.hook';
import { observer } from 'mobx-react-lite';
import { Redirect } from 'react-router-dom';
import { appRoutes } from 'routing';
import { LoginRequest } from 'types/auth-types';

export const LoginPage: React.FC = observer(() => {
  const { authStore } = useAppStore();
  const { userInfo, error, cleanError } = authStore;

  React.useEffect(() => {
    cleanError();
  }, [cleanError]);

  const onLogin = React.useCallback(
    (params: LoginRequest) => {
      localStorage.clear();
      authStore.logIn(params);
    },
    [authStore],
  );

  if (userInfo.email) {
    return <Redirect to={appRoutes.HOME} />;
  }

  if (authStore.need2Fa) {
    return (
      <div className="animated fadeIn">
        <TwoStepVerificationPhoneForm onVerify={authStore.logInWith2FA} sendSms={authStore.sendSms} error={error} />
      </div>
    );
  }

  return (
    <div>
      <LoginInForm onLogin={onLogin} error={error} />
    </div>
  );
});
