import React from 'react';

interface Props {
  className?: string;
}

export const SettingsIcon: React.FC<Props> = ({ className = '' }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      strokeWidth="1"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={className}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99984 10.3334C9.2885 10.3334 10.3332 9.28875 10.3332 8.00008C10.3332 6.71142 9.2885 5.66675 7.99984 5.66675C6.71117 5.66675 5.6665 6.71142 5.6665 8.00008C5.6665 9.28875 6.71117 10.3334 7.99984 10.3334ZM15.3332 8.00008C15.3332 7.4348 14.8998 6.9639 14.3365 6.91708L13.1998 6.82208C13.0663 6.23216 12.8327 5.66947 12.5092 5.15841L13.2482 4.28508C13.6133 3.85368 13.5868 3.21442 13.1872 2.81475C12.7875 2.41515 12.1482 2.38863 11.7168 2.75375L10.8435 3.49275C10.332 3.16823 9.76861 2.93394 9.17784 2.80008L9.08284 1.66341C9.03602 1.10007 8.56512 0.666748 7.99984 0.666748C7.43456 0.666748 6.96366 1.10007 6.91684 1.66341L6.82184 2.80008C6.23191 2.93362 5.66922 3.16722 5.15817 3.49075L4.28484 2.75341C3.85344 2.3883 3.21418 2.41482 2.8145 2.81441C2.41491 3.21409 2.38839 3.85335 2.7535 4.28475L3.4925 5.15808C3.16816 5.66906 2.93387 6.23189 2.79984 6.82208L1.6615 6.91708C1.09882 6.96472 0.666504 7.43539 0.666504 8.00008C0.666504 8.56536 1.09983 9.03626 1.66317 9.08308L2.8015 9.17808C2.93505 9.768 3.16865 10.3307 3.49217 10.8417L2.75317 11.7151C2.38805 12.1465 2.41457 12.7857 2.81417 13.1854C3.21385 13.585 3.8531 13.6115 4.2845 13.2464L5.15784 12.5074C5.66881 12.8318 6.23164 13.066 6.82184 13.2001L6.91684 14.3384C6.96447 14.9011 7.43514 15.3334 7.99984 15.3334C8.56512 15.3334 9.03602 14.9001 9.08284 14.3367L9.17784 13.1984C9.76776 13.0649 10.3305 12.8313 10.8415 12.5077L11.7148 13.2467C12.1462 13.6119 12.7855 13.5853 13.1852 13.1857C13.5848 12.7861 13.6113 12.1468 13.2462 11.7154L12.5072 10.8421C12.8315 10.3311 13.0658 9.76828 13.1998 9.17808L14.3382 9.08308C14.9009 9.03545 15.3332 8.56478 15.3332 8.00008Z"
        stroke="#ffffff"
      />
    </svg>
  );
};
