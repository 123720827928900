import React from 'react';

interface Props {
  className?: string;
}

export const CheckEmailIcon: React.FC<Props> = ({ className = '' }) => {
  return (
    <svg width="118" height="118" viewBox="0 0 118 118" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.81055 41.1367H98.8349V96.5755H8.81055V41.1367Z" fill="#D6D6D6" />
      <path d="M8.81055 96.5755V41.1367L53.8227 68.8561L8.81055 96.5755Z" fill="#E9E9E9" />
      <path d="M98.8354 41.1367V96.5755L53.8232 68.8561L98.8354 41.1367Z" fill="#E9E9E9" />
      <path d="M8.81055 41.1367L53.8225 79.642L98.8349 41.1367H8.81055Z" fill="white" />
      <path
        d="M87.1402 50.2468C83.284 53.3327 79.4375 56.43 75.6259 59.5679C68.2847 65.5694 61.0426 71.686 53.8222 77.8285C46.6001 71.6883 39.3576 65.5715 32.0165 59.5709C28.2048 56.4327 24.3587 53.3357 20.5021 50.2499C16.6029 47.2148 12.7642 44.1083 8.81055 41.1365C12.3587 44.5825 16.0219 47.8936 19.6245 51.276C23.2704 54.6077 26.9257 57.9283 30.6163 61.2075C37.9747 67.7932 45.4383 74.2553 52.9222 80.6938L53.8222 81.468L54.7225 80.6938C62.2088 74.258 69.6724 67.7955 77.0315 61.2106C80.7217 57.931 84.3774 54.6108 88.0233 51.279C91.6246 47.8953 95.288 44.5842 98.8349 41.1365C94.8795 44.1066 91.0408 47.2131 87.1402 50.2468Z"
        fill="#9F9F9F"
      />
      <path
        d="M92.1412 55.519C101.556 55.519 109.189 47.8866 109.189 38.4715C109.189 29.0565 101.556 21.4241 92.1412 21.4241C82.7262 21.4241 75.0938 29.0565 75.0938 38.4715C75.0938 47.8866 82.7262 55.519 92.1412 55.519Z"
        fill="#0059FF"
      />
      <path
        d="M92.2245 43.5166C90.9255 43.5166 90.0244 44.5263 90.0244 45.8245C90.0244 47.1952 90.9255 48.1323 92.2245 48.1323C93.5225 48.1323 94.4954 47.1952 94.4954 45.8245C94.4954 44.5263 93.5225 43.5166 92.2245 43.5166Z"
        fill="#E6E7E8"
      />
      <path
        d="M92.1929 42.6777C92.8719 42.6777 93.4362 42.1632 93.4961 41.4868C93.9465 36.4041 94.9655 30.9264 94.9655 30.4465C94.9177 29.0997 93.4752 28.8113 92.1764 28.8113C90.8311 28.8113 89.4365 29.1475 89.4365 30.3986C89.4365 30.8783 90.4427 36.4712 90.8896 41.4874C90.9497 42.1632 91.5144 42.6777 92.1929 42.6777Z"
        fill="#E6E7E8"
      />
    </svg>
  );
};
