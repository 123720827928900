import React from 'react';
import { RegisterPhoneForm } from '../../../components/auth/RegisterPhoneForm';
import { observer } from 'mobx-react-lite';
import { useAppStore } from '../../../store/app-store.hook';
import { Redirect } from 'react-router-dom';
import { appRoutes } from 'routing';

export const RegisterPhonePage: React.FC = observer(() => {
  const { authStore } = useAppStore();
  const { userInfo, cleanError } = authStore;
  React.useEffect(() => {
    cleanError();
  }, [cleanError]);

  if (userInfo.phone) {
    return <Redirect to={appRoutes.HOME} />;
  }

  return (
    <div className="animated fadeIn">
      <RegisterPhoneForm onRegister={authStore.phoneRegister} error={authStore.error} />
    </div>
  );
});
