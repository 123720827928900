import * as Types from '../../../../types/__generated__/types';

import { ApolloError } from 'apollo-client';
import { useQuery, UseQueryOptions } from 'react-query';
import { fetcher } from 'api';
export type ProductOverviewQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type ProductOverviewQuery = {
  product: {
    id: string;
    name: string;
    description?: Types.Maybe<string>;
    defaultImage?: Types.Maybe<string>;
    reviewUrl?: Types.Maybe<string>;
    category?: Types.Maybe<string>;
    tags?: Types.Maybe<Array<string>>;
    images?: Types.Maybe<Array<string>>;
    option1?: Types.Maybe<string>;
    option2?: Types.Maybe<string>;
    option3?: Types.Maybe<string>;
    chargeTaxes: boolean;
    shop: { id: string; shopifyName?: Types.Maybe<string> };
    variants: Array<{
      id: string;
      image?: Types.Maybe<string>;
      sku: string;
      barcode?: Types.Maybe<string>;
      inStock: number;
      option1?: Types.Maybe<string>;
      option2?: Types.Maybe<string>;
      option3?: Types.Maybe<string>;
      price: { amount: number; currency: string };
      compareAt?: Types.Maybe<{ amount: number; currency: string }>;
      weight?: Types.Maybe<{ amount: number; unit: Types.WeightUnit }>;
      fulfillmentMethod: { id: string; name: string; type: Types.FulfillmentType };
    }>;
  };
};

export const ProductOverviewDocument = `
    query ProductOverview($id: String!) {
  product(id: $id) {
    id
    name
    description
    defaultImage
    reviewUrl
    category
    tags
    images
    option1
    option2
    option3
    shop {
      id
      shopifyName
    }
    chargeTaxes
    variants {
      id
      image
      price {
        amount
        currency
      }
      compareAt {
        amount
        currency
      }
      sku
      barcode
      weight {
        amount
        unit
      }
      inStock
      fulfillmentMethod {
        id
        name
        type
      }
      option1
      option2
      option3
    }
  }
}
    `;
export const useProductOverviewQuery = <TData = ProductOverviewQuery, TError = ApolloError>(
  variables: ProductOverviewQueryVariables,
  options?: UseQueryOptions<ProductOverviewQuery, TError, TData>,
) =>
  useQuery<ProductOverviewQuery, TError, TData>(
    ['ProductOverview', variables],
    fetcher<ProductOverviewQuery, ProductOverviewQueryVariables>(ProductOverviewDocument, variables),
    options,
  );
