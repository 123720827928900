export enum StorageKeys {
  selectedItem = 'selectedItem',
}

export const getFromStorage = <T>(key: StorageKeys): T | null => JSON.parse(localStorage.getItem(key) || 'null');

export const removeFromStorage = (key: StorageKeys): void => localStorage.removeItem(key);

export const saveToStorage = <T>(key: StorageKeys, object: T): T => {
  localStorage.setItem(key, JSON.stringify(object));
  return object;
};
