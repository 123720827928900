import * as Types from '../../../../types/__generated__/types';

import { ApolloError } from 'apollo-client';
import { useQuery, UseQueryOptions, useMutation, UseMutationOptions } from 'react-query';
import { fetcher } from 'api';
export type FullProductFragment = {
  id: string;
  name: string;
  description?: Types.Maybe<string>;
  defaultImage?: Types.Maybe<string>;
  reviewUrl?: Types.Maybe<string>;
  category?: Types.Maybe<string>;
  tags?: Types.Maybe<Array<string>>;
  images?: Types.Maybe<Array<string>>;
  option1?: Types.Maybe<string>;
  option2?: Types.Maybe<string>;
  option3?: Types.Maybe<string>;
  chargeTaxes: boolean;
  shop: { id: string; shopifyName?: Types.Maybe<string> };
  variants: Array<{
    id: string;
    image?: Types.Maybe<string>;
    sku: string;
    barcode?: Types.Maybe<string>;
    inStock: number;
    option1?: Types.Maybe<string>;
    option2?: Types.Maybe<string>;
    option3?: Types.Maybe<string>;
    price: { amount: number; currency: string };
    compareAt?: Types.Maybe<{ amount: number; currency: string }>;
    weight?: Types.Maybe<{ amount: number; unit: Types.WeightUnit }>;
    fulfillmentMethod: { id: string; name: string; type: Types.FulfillmentType };
  }>;
};

export type ProductQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type ProductQuery = { product: FullProductFragment };

export type UpdateProductMutationVariables = Types.Exact<{
  input: Types.UpdateProductInput;
}>;

export type UpdateProductMutation = { updateProduct: FullProductFragment };

export type CreateProductMutationVariables = Types.Exact<{
  input: Types.ProductInput;
}>;

export type CreateProductMutation = { createProduct: FullProductFragment };

export type FulfillmentMethodsQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type FulfillmentMethodsQuery = {
  shopFulfillmentMethods: Array<{ id: string; name: string; type: Types.FulfillmentType }>;
};

export const FullProductFragmentDoc = `
    fragment FullProduct on Product {
  id
  name
  description
  defaultImage
  reviewUrl
  category
  tags
  images
  option1
  option2
  option3
  shop {
    id
    shopifyName
  }
  chargeTaxes
  variants {
    id
    image
    price {
      amount
      currency
    }
    compareAt {
      amount
      currency
    }
    sku
    barcode
    weight {
      amount
      unit
    }
    inStock
    fulfillmentMethod {
      id
      name
      type
    }
    option1
    option2
    option3
  }
}
    `;
export const ProductDocument = `
    query Product($id: String!) {
  product(id: $id) {
    ...FullProduct
  }
}
    ${FullProductFragmentDoc}`;
export const useProductQuery = <TData = ProductQuery, TError = ApolloError>(
  variables: ProductQueryVariables,
  options?: UseQueryOptions<ProductQuery, TError, TData>,
) =>
  useQuery<ProductQuery, TError, TData>(
    ['Product', variables],
    fetcher<ProductQuery, ProductQueryVariables>(ProductDocument, variables),
    options,
  );
export const UpdateProductDocument = `
    mutation UpdateProduct($input: UpdateProductInput!) {
  updateProduct(input: $input) {
    ...FullProduct
  }
}
    ${FullProductFragmentDoc}`;
export const useUpdateProductMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<UpdateProductMutation, TError, UpdateProductMutationVariables, TContext>,
) =>
  useMutation<UpdateProductMutation, TError, UpdateProductMutationVariables, TContext>(
    (variables?: UpdateProductMutationVariables) =>
      fetcher<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, variables)(),
    options,
  );
export const CreateProductDocument = `
    mutation CreateProduct($input: ProductInput!) {
  createProduct(input: $input) {
    ...FullProduct
  }
}
    ${FullProductFragmentDoc}`;
export const useCreateProductMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<CreateProductMutation, TError, CreateProductMutationVariables, TContext>,
) =>
  useMutation<CreateProductMutation, TError, CreateProductMutationVariables, TContext>(
    (variables?: CreateProductMutationVariables) =>
      fetcher<CreateProductMutation, CreateProductMutationVariables>(CreateProductDocument, variables)(),
    options,
  );
export const FulfillmentMethodsDocument = `
    query FulfillmentMethods($id: String!) {
  shopFulfillmentMethods(id: $id) {
    id
    name
    type
  }
}
    `;
export const useFulfillmentMethodsQuery = <TData = FulfillmentMethodsQuery, TError = ApolloError>(
  variables: FulfillmentMethodsQueryVariables,
  options?: UseQueryOptions<FulfillmentMethodsQuery, TError, TData>,
) =>
  useQuery<FulfillmentMethodsQuery, TError, TData>(
    ['FulfillmentMethods', variables],
    fetcher<FulfillmentMethodsQuery, FulfillmentMethodsQueryVariables>(FulfillmentMethodsDocument, variables),
    options,
  );
