// @ts-nocheck
import React from 'react';
import { Button, Card, CardBody, Form, FormFeedback, FormGroup, Input, CardHeader } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { validators, validate } from 'utils/form-utils';

interface FormValues {
  fullName: string;
}

const validationSchema = () =>
  Yup.object().shape({
    fullName: validators.fullName,
  });

const initialValues = {
  fullName: '',
};

interface Props {
  error?: string;
  basicDetails: FormValues | null;
  onSave: (params: FormValues) => void;
}

export const AccountBasicDetails: React.FC<Props> = ({ onSave, basicDetails, error }) => {
  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: basicDetails || { ...initialValues },
    onSubmit: onSave,
    isInitialValid: false,
    // @ts-ignore
    validate: validate(validationSchema),
  });
  const { values, errors, touched, handleChange, handleBlur, handleSubmit, isValid } = formik;
  return (
    <Card className="bg-custom">
      <CardHeader>Basic details</CardHeader>
      <CardBody>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <label htmlFor="fullName">Full name</label>
            <Input
              className="input-sm bg-light"
              type="text"
              name="fullName"
              placeholder="Enter full name"
              autoComplete="email"
              valid={touched.fullName && !errors.fullName}
              invalid={touched.fullName && !!errors.fullName}
              required
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.fullName}
            />
            <FormFeedback>{errors.fullName}</FormFeedback>
          </FormGroup>
          {error && <div className="text-center text-danger mb-1">{error}</div>}
          <div className="actions">
            <FormGroup className="d-flex justify-content-center">
              <Button className="btn-block" type="submit" color="primary" disabled={!isValid}>
                Update details
              </Button>
            </FormGroup>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
};
