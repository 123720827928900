import React from 'react';
import { FormikProps } from 'formik';
import { GameEditDetails } from './GameEditDetails';
import { GameEditCurrency } from './GameEditCurrency';
import { GameEditDemographics } from './GameEditDemographics';
import { GameForm } from './GameEditForm.utils';
import { Grid } from '@mui/material';

interface Props {
  formik: FormikProps<GameForm>;
}

export const GameEditForm: React.FC<Props> = ({ formik }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={4}>
        <GameEditDetails {...formik} />
      </Grid>
      <Grid item xs={12} lg={4}>
        <GameEditDemographics {...formik} />
      </Grid>
      <Grid item xs={12} lg={4}>
        <GameEditCurrency {...formik} />
      </Grid>
    </Grid>
  );
};
