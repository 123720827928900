import React from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory, useLocation, useParams } from 'react-router';
import { GameEditForm } from './GameEditForm';
import { useToasts } from 'react-toast-notifications';
import { hasId } from 'types/util-types';
import { gamesRoutes } from 'routing';
import { Box } from '@material-ui/core';
import { GameInvitesEditForm } from './GameInvitesForm';
import {
  GameForm,
  gameFormDefaults,
  validationSchema as gameSchema,
  mapGameForCreateInput,
  mapGameForUpdateInput,
} from './GameEditForm/GameEditForm.utils';
import queryString from 'query-string';
import {
  useCreateGameMutation,
  useGameInvitesQuery,
  useGameQuery,
  useInviteGameUserMutation,
  useRemoveFromGameMutation,
  useResendInviteMutation,
  useUpdateGameMutation,
} from './__generated__/game-edit-page.hooks';
import {
  GameInvitesForm,
  inviteDefaults,
  mapInviteForInput,
  validationSchema as invitesSchema,
} from './GameInvitesForm/GameInvitesEditForm.utils';
import { Formik } from 'formik';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { hasOneOfRoles } from 'utils/roles.utils';
import { useAppStore } from 'store/app-store.hook';
import { Role } from 'types/__generated__/types';

export const GameEditPage: React.FC = observer(() => {
  const { userRoles } = useAppStore().permissions;
  const { gameId } = useParams<{ gameId: string }>();
  const { search } = useLocation();
  const { organizationId } = queryString.parse(search);
  const history = useHistory();
  const { addToast } = useToasts();
  const title = `${gameId ? 'Edit' : 'Create new'} game`;
  const isAdmin = hasOneOfRoles(userRoles, [Role.ScutiAdmin, Role.ShopOwner]);

  const { data, error, refetch } = useGameQuery({ id: gameId }, { enabled: !!gameId });
  const gameInvitesQuery = useGameInvitesQuery({ id: gameId }, { enabled: !!gameId });

  const useUpdateGame = useUpdateGameMutation();
  const useCreateGame = useCreateGameMutation();
  const useInviteGameUser = useInviteGameUserMutation();
  const useRemoveFromGame = useRemoveFromGameMutation();
  const useResendInvite = useResendInviteMutation();

  React.useEffect(() => {
    if (error) addToast(error.message, { appearance: 'error', autoDismiss: false });
  }, [addToast, error]);

  const saveOrUpdate = React.useCallback(
    async ({ invites, ...game }: GameForm & GameInvitesForm) => {
      try {
        if (hasId(game)) {
          await Promise.all(mapInviteForInput(invites, gameId).map((v) => useInviteGameUser.mutateAsync(v)));
          const { updateGame } = await useUpdateGame.mutateAsync({ input: await mapGameForUpdateInput(game) });
          history.push(gamesRoutes.GAME_OVERVIEW(updateGame.id));
          refetch();
          addToast('Game has been updated!', { appearance: 'success', autoDismiss: true });
        } else {
          const { createGame } = await useCreateGame.mutateAsync({
            input: { ...(await mapGameForCreateInput(game)), organizationId: organizationId as string },
          });
          addToast('Game has created!', { appearance: 'success', autoDismiss: true });
          history.push(gamesRoutes.GAME_OVERVIEW(createGame.id));
        }
      } catch (e) {
        // @ts-expect-error
        addToast(e.message, { appearance: 'error', autoDismiss: false });
      }
    },
    [addToast, gameId, history, organizationId, refetch, useCreateGame, useInviteGameUser, useUpdateGame],
  );

  const removeUser = React.useCallback(
    async (email: string) => {
      await useRemoveFromGame.mutateAsync({ email, gameId });
      Promise.all([refetch(), gameInvitesQuery.refetch()]);
    },
    [useRemoveFromGame, gameId, refetch, gameInvitesQuery],
  );

  const existedInvites = [
    ...(data?.game?.admins || []).map(({ email, roles }) => ({ email, roles, accepted: true })),
    ...(gameInvitesQuery.data?.gameInvites.nodes || []),
  ];
  return (
    <Box>
      <Formik
        initialValues={{
          ...(data?.game || gameFormDefaults()),
          invites: [inviteDefaults()],
        }}
        validationSchema={gameSchema().concat(invitesSchema())}
        onSubmit={async (formData, helpers) => {
          // @ts-expect-error
          await saveOrUpdate(formData);
          helpers.resetForm();
        }}
        enableReinitialize
        validateOnMount
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <div className="topbar d-sm-flex justify-content-sm-between">
              <div className="col-heading">
                <Link className="btn btn-back" to={gamesRoutes.GAMES} />
                <h1 className="page-title">{title}</h1>
              </div>
              <div className="col-action">
                <Button color="outline-secondary" onClick={formik.handleReset}>
                  Cancel
                </Button>
                <Button color="primary" type="submit">
                  Save changes
                </Button>
              </div>
            </div>
            {/* @ts-ignore */}
            <GameEditForm formik={formik} onSave={saveOrUpdate} />
            {isAdmin && (
              <Box mt={2}>
                <GameInvitesEditForm
                  existedInvites={existedInvites}
                  // @ts-ignore
                  formik={formik}
                  onRemove={removeUser}
                  onResend={async (email: string) => {
                    await useResendInvite.mutateAsync({ email });
                    addToast('Invite has been resent!', { appearance: 'success', autoDismiss: true });
                  }}
                />
              </Box>
            )}
          </form>
        )}
      </Formik>
    </Box>
  );
});
