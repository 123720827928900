import { computed, observable } from 'mobx';
import { AuthStore } from 'store/auth-store';
import { Role } from 'types/__generated__/types';
import { hasUserRole, hasOneOfRoles } from 'utils/roles.utils';

export class PermissionStore {
  @observable authStore: AuthStore;

  constructor(authStore: AuthStore) {
    this.authStore = authStore;
  }

  get userRoles() {
    return this.authStore.userInfo.roles;
  }

  @computed
  get gamesAccess() {
    return {
      view:
        hasUserRole(this.userRoles, Role.ScutiAdmin) ||
        hasOneOfRoles(this.userRoles, [Role.GameOwner, Role.GameAdmin, Role.OrganizationAdmin, Role.OrganizationOwner]),
      edit: hasOneOfRoles(this.userRoles, [
        Role.ScutiAdmin,
        Role.GameOwner,
        Role.GameAdmin,
        Role.OrganizationAdmin,
        Role.OrganizationOwner,
      ]),
    };
  }

  @computed
  get financesAccess() {
    return {
      view: hasOneOfRoles(this.userRoles, [Role.GameOwner, Role.ShopOwner]),
      edit: hasOneOfRoles(this.userRoles, [Role.GameOwner, Role.ShopOwner]),
    };
  }

  @computed
  get shopsAccess() {
    return {
      view: hasOneOfRoles(this.userRoles, [
        Role.ScutiAdmin,
        Role.ShopOwner,
        Role.ShopAdmin,
        Role.OrganizationAdmin,
        Role.OrganizationOwner,
      ]),
      edit: hasOneOfRoles(this.userRoles, [
        Role.ScutiAdmin,
        Role.ShopOwner,
        Role.ShopAdmin,
        Role.OrganizationAdmin,
        Role.OrganizationOwner,
      ]),
      editShopifyName: hasOneOfRoles(this.userRoles, [Role.ScutiAdmin]),
    };
  }

  @computed
  get productsAccess() {
    return {
      view: hasOneOfRoles(this.userRoles, [Role.ShopOwner, Role.ShopAdmin, Role.ScutiAdmin]),
      edit: hasOneOfRoles(this.userRoles, [Role.ShopOwner, Role.ShopAdmin, Role.ScutiAdmin]),
    };
  }

  @computed
  get ordersAccess() {
    return {
      view:
        hasOneOfRoles(this.userRoles, [Role.ScutiAdmin]) ||
        hasOneOfRoles(this.userRoles, [Role.ShopOwner, Role.ShopAdmin]),
      edit:
        hasOneOfRoles(this.userRoles, [Role.ScutiAdmin]) ||
        hasOneOfRoles(this.userRoles, [Role.ShopOwner, Role.ShopAdmin]),
    };
  }

  @computed
  get campaignsAccess() {
    return {
      view: hasOneOfRoles(this.userRoles, [Role.ShopOwner, Role.ShopAdmin, Role.ScutiAdmin]),
      edit: hasOneOfRoles(this.userRoles, [Role.ShopOwner, Role.ShopAdmin, Role.ScutiAdmin]),
    };
  }

  @computed
  get organizationsAccess() {
    return {
      view: hasOneOfRoles(this.userRoles, [Role.OrganizationOwner, Role.OrganizationAdmin, Role.ScutiAdmin]),
      edit: hasOneOfRoles(this.userRoles, [Role.OrganizationOwner, Role.OrganizationAdmin, Role.ScutiAdmin]),
    };
  }

  @computed
  get usersAccess() {
    return {
      view: false,
      edit: false,
    };
  }
}
