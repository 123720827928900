import * as Types from '../../../../types/__generated__/types';

import { ApolloError } from 'apollo-client';
import { useQuery, UseQueryOptions, useMutation, UseMutationOptions } from 'react-query';
import { fetcher } from 'api';
export type GameDetailsFragment = {
  id: string;
  name: string;
  category: string;
  tags: Array<string>;
  banner?: Types.Maybe<string>;
  thumbnail?: Types.Maybe<string>;
  ageFrom: number;
  ageTo: number;
  contentCategory: Types.ContentCategory;
  genderPercent?: Types.Maybe<{ male: number; female: number }>;
  currency?: Types.Maybe<{ name: string; thumbnail?: Types.Maybe<string>; exchangeRate: number }>;
  admins: Array<{ id: string; email: string; roles: Array<Types.Role>; fullName?: Types.Maybe<string> }>;
};

export type GameOverviewQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type GameOverviewQuery = { game: GameDetailsFragment };

export type GameTokenQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type GameTokenQuery = { gameToken?: Types.Maybe<{ id: string; token: string }> };

export type CreateGameTokenMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type CreateGameTokenMutation = { createGameToken: { id: string; token: string } };

export const GameDetailsFragmentDoc = `
    fragment GameDetails on Game {
  id
  name
  category
  tags
  banner
  thumbnail
  ageFrom
  ageTo
  contentCategory
  genderPercent {
    male
    female
  }
  currency {
    name
    thumbnail
    exchangeRate
  }
  admins {
    id
    email
    roles
    fullName
  }
}
    `;
export const GameOverviewDocument = `
    query GameOverview($id: String!) {
  game(id: $id) {
    ...GameDetails
  }
}
    ${GameDetailsFragmentDoc}`;
export const useGameOverviewQuery = <TData = GameOverviewQuery, TError = ApolloError>(
  variables: GameOverviewQueryVariables,
  options?: UseQueryOptions<GameOverviewQuery, TError, TData>,
) =>
  useQuery<GameOverviewQuery, TError, TData>(
    ['GameOverview', variables],
    fetcher<GameOverviewQuery, GameOverviewQueryVariables>(GameOverviewDocument, variables),
    options,
  );
export const GameTokenDocument = `
    query GameToken($id: String!) {
  gameToken(id: $id) {
    id
    token
  }
}
    `;
export const useGameTokenQuery = <TData = GameTokenQuery, TError = ApolloError>(
  variables: GameTokenQueryVariables,
  options?: UseQueryOptions<GameTokenQuery, TError, TData>,
) =>
  useQuery<GameTokenQuery, TError, TData>(
    ['GameToken', variables],
    fetcher<GameTokenQuery, GameTokenQueryVariables>(GameTokenDocument, variables),
    options,
  );
export const CreateGameTokenDocument = `
    mutation CreateGameToken($id: String!) {
  createGameToken(id: $id) {
    id
    token
  }
}
    `;
export const useCreateGameTokenMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<CreateGameTokenMutation, TError, CreateGameTokenMutationVariables, TContext>,
) =>
  useMutation<CreateGameTokenMutation, TError, CreateGameTokenMutationVariables, TContext>(
    (variables?: CreateGameTokenMutationVariables) =>
      fetcher<CreateGameTokenMutation, CreateGameTokenMutationVariables>(CreateGameTokenDocument, variables)(),
    options,
  );
