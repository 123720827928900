import React, { useEffect } from 'react';

import { CheckEmail } from 'components/auth/CheckEmail';

export const CheckEmailPage: React.FC = () => {
  useEffect(() => {
    localStorage.clear();
  });

  return (
    <div className="animated fadeIn">
      <CheckEmail />
    </div>
  );
};
