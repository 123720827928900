import React from 'react';
import { ProductListingFormData } from './ProductListingEditForm.utils';
import { Card, CardHeader, CardContent, FormGroup, Box } from '@material-ui/core';
import { ScutiSelect } from 'components/material/ScutiSelect';
import { OFFER_SELECT_OPTIONS, PROMOTION_SELECT_OPTIONS } from 'types/select-types';
import { CustomInput } from 'reactstrap';
import { PromotionType } from 'types/__generated__/types';
import { ScutiInput } from 'components/material/ScutiInput';
import { Controller, UseFormReturn } from 'react-hook-form';

interface Props {
  form: UseFormReturn<ProductListingFormData>;
  paused?: boolean;
}

export function PromotionDetails({ form, paused }: Props) {
  const values = form.watch();

  const [promotionSpecific, setPromotionSpecific] = React.useState(!!values.promotion?.promotionType);

  const togglePromotion = () => {
    if (promotionSpecific) form.setValue('promotion', {});
    else form.setValue('promotion.promotionType', PromotionType.HotPrice);
    setPromotionSpecific(!promotionSpecific);
  };

  const promotionType = values.promotion?.promotionType;
  const isOfferPromotion = promotionType === PromotionType.SpecialOffer;
  const isCopyOffer = isOfferPromotion && values.promotion?.offerType;
  const isHotPrice = promotionType === PromotionType.HotPrice;

  return (
    <Card>
      <CardHeader title="Promotion" />
      <CardContent>
        <FormGroup>
          <label>
            <CustomInput
              id="promotion-box"
              type="checkbox"
              label="Promotion"
              onChange={togglePromotion}
              checked={promotionSpecific}
              disabled={!paused}
            />
          </label>
          <Controller
            control={form.control}
            name="promotion.promotionType"
            render={({ field }) => (
              <ScutiSelect
                title="Offers"
                name="promotion.promotionType"
                placeholder="Select type"
                value={promotionType || ''}
                options={PROMOTION_SELECT_OPTIONS}
                onChange={({ target }) => {
                  form.setValue('promotion', { promotionType: target.value as PromotionType });
                }}
                onBlur={field.onBlur}
                disabled={!promotionSpecific || !paused}
              />
            )}
          />
        </FormGroup>
        {isOfferPromotion && (
          <Box mt={2}>
            <Controller
              control={form.control}
              name="promotion.offerType"
              render={({ field, fieldState }) => (
                <ScutiSelect
                  placeholder="Select offer"
                  options={OFFER_SELECT_OPTIONS}
                  disabled={!paused}
                  {...field}
                  error={fieldState.error?.message}
                />
              )}
            />
          </Box>
        )}
        {isCopyOffer && (
          <Box mt={2}>
            <Controller
              control={form.control}
              name="promotion.offerCopy"
              render={({ field, fieldState }) => (
                <ScutiInput placeholder="Copy" {...field} error={fieldState.error?.message} disabled={!paused} />
              )}
            />
          </Box>
        )}
        {isHotPrice && (
          <Box mt={2}>
            <Controller
              control={form.control}
              name="promotion.discount"
              render={({ field, fieldState }) => (
                <ScutiInput
                  placeholder="Discount %"
                  type="number"
                  {...field}
                  error={fieldState.error?.message}
                  disabled={!paused}
                />
              )}
            />
          </Box>
        )}
      </CardContent>
    </Card>
  );
}
