import { EntityRequestInput, RequestActionName, RequestEntityType, RequesterType } from 'types/__generated__/types';
import * as Yup from 'yup';

export const CONTROL_LEVELS = {
  FULL_ADMIN: 'FULL_ADMIN',
  CAMPAIGN_ONLY: 'CAMPAIGN_ONLY',
  READ_ONLY: 'READ_ONLY',
};

export const connectSchema = Yup.object().shape({
  id: Yup.string(),
  entityId: Yup.string()
    .min(2, 'Shop ID is too short. Min size is 2 characters.')
    .max(100, 'Shop ID is too long. Max size is 100 characters.')
    .required(),
  controlLevel: Yup.string()
    .min(2, 'Shop ID is too short. Min size is 2 characters.')
    .max(100, 'Shop ID is too long. Max size is 100 characters.')
    .required(),
  referralPercentage: Yup.number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(0, 'Rewards Fee cannot be negative')
    .max(100, 'Rewards Fee cannot greater than 100')
    .nullable(),
  directResponse: Yup.object()
    .shape({
      unit: Yup.string().required(),
      amount: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .min(0)
        .nullable(),
    })
    .nullable(),
  engagement: Yup.object()
    .shape({
      unit: Yup.string().required(),
      amount: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .min(0)
        .nullable(),
    })
    .nullable(),
  video: Yup.object().shape({
    unit: Yup.string().required(),
    amount: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .min(0)
      .nullable(),
  }),
});

export type ConnectFormData = Yup.InferType<typeof connectSchema>;

export const connectFormDefaults = (): ConnectFormData => ({
  entityId: '',
  controlLevel: CONTROL_LEVELS.READ_ONLY,
  referralPercentage: undefined,
  directResponse: { unit: 'cpm', amount: 0 },
  engagement: { unit: 'cpm', amount: 0 },
  video: { unit: 'cpm', amount: 0 },
});

const mapMediaInvestment = (investmentForm: { unit: string; amount?: number | null } | null) => {
  if (!investmentForm) return null;

  if (investmentForm.amount) {
    return {
      unit: investmentForm.unit,
      amount: investmentForm.amount,
    };
  }
  return null;
};

export const mapFormToApiInput =
  (organizationId: string, entityType: RequestEntityType) =>
  (form: ConnectFormData): EntityRequestInput => {
    const { entityId, ...others } = form;
    return {
      entityId: form.entityId,
      requesterId: organizationId,
      entityType,
      actionName: RequestActionName.Connect,
      requesterType: RequesterType.Organization,
      params: {
        ...others,
        referralPercentage: others.referralPercentage ? others.referralPercentage / 100 : 0,
        engagement: mapMediaInvestment(others.engagement),
        directResponse: mapMediaInvestment(others.directResponse),
        video: mapMediaInvestment(others.video),
      },
    };
  };
