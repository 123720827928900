// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:400,400i,500,600,700,700i&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:400,400i,500,600,700,700i&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OrderOverviewPage_page_title__3V2Mm {
  max-width: 18.75rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0 0 0.625rem;
}

.OrderOverviewPage_button__pO3O1 {
  text-transform: capitalize;
  margin-left: 0.625rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/Orders/OrderOverviewPage/OrderOverviewPage.module.scss"],"names":[],"mappings":"AAGA;EACE,mBAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;EACA,sBAAA;AAAF;;AAGA;EACE,0BAAA;EACA,qBAAA;AAAF","sourcesContent":["@import '@scss/vendors/_variables';\n@import '@scss/_variables';\n\n.page_title {\n  max-width: rem(300);\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  margin: 0 0 0 rem(10);\n}\n\n.button {\n  text-transform: capitalize;\n  margin-left: rem(10);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page_title": `OrderOverviewPage_page_title__3V2Mm`,
	"button": `OrderOverviewPage_button__pO3O1`
};
export default ___CSS_LOADER_EXPORT___;
