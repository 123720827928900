import React from 'react';
import { ShopEditDetails } from './ShopEditDetails';
import { Grid } from '@material-ui/core';
import { ShopReturnPolicy } from './ShopReturnPolicy';
import { ShopFinancials } from './ShopFinancials';
import { UseFormReturn } from 'react-hook-form';
import { ShopMediaInvestment } from './ShopMediaInvestment';
import { Stack } from '@mui/material';
import { ShopFormData } from './ShopEditForm.utils';
import { ShopEditBrands } from './ShopEditBrands';
import { ShopEditShipping } from './ShopEditShipping';

interface Props {
  form: UseFormReturn<ShopFormData>;
}

export const ShopEditForm: React.FC<Props> = ({ form }) => {
  return (
    <Grid container spacing={2}>
      <Grid container item spacing={2}>
        <Grid item sm={12} md={4} lg={4}>
          <ShopEditDetails form={form} />
        </Grid>
        <Grid item sm={12} md={4} lg={4}>
          <Stack spacing={2}>
            <ShopReturnPolicy form={form} />
            <ShopEditBrands form={form} />
          </Stack>
        </Grid>
        <Grid item sm={12} md={4} lg={4}>
          <Stack spacing={2}>
            <ShopFinancials form={form} />
            <ShopMediaInvestment form={form} />
            <ShopEditShipping form={form} />
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
};
