import { Card, FormGroup } from 'reactstrap';
import { CardHeader, CardContent } from '@material-ui/core';
import { ScutiInput } from 'components/material/ScutiInput';
import { Controller, UseFormReturn } from 'react-hook-form';
import { CampaignFormData } from './CampaignEditForm.utils';

interface Props {
  form: UseFormReturn<CampaignFormData>;
  paused?: boolean;
  showInventory?: boolean;
}

export function RewardDetails({ form, paused }: Props) {
  return (
    <Card className="bg-custom">
      <CardHeader title="Rewards" />
      <CardContent>
        <FormGroup className="pt-6">
          <label htmlFor={`reward.scutiPercentage`}>
            <Controller
              control={form.control}
              name="reward.scutiPercentage"
              render={({ field, fieldState }) => (
                <ScutiInput
                  type="number"
                  title="Scuti Rewards Percentage"
                  placeholder="% of Scuti$"
                  inputProps={{ min: 0, max: 100 }}
                  {...field}
                  error={fieldState.error?.message}
                  disabled={!paused}
                />
              )}
            />
          </label>
        </FormGroup>
      </CardContent>
    </Card>
  );
}
