import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useAppStore } from 'store/app-store.hook';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { AnalyticsRange } from 'types/app-types';
import { OfferImpressionsShop } from 'components/material/analytics/OfferImpressionsShop';
import { ShopStats } from './ShopStats';
import { AnalyticsPeriodSelector } from 'components/AnalyticsPeriodSelector';
import { TovFiveSet } from './TovFiveSet';

export const ShopDashboardPage: React.FC = observer(() => {
  const { uiStore } = useAppStore();
  const [range, setRange] = useState<AnalyticsRange>({ from: moment().startOf('year'), to: moment().endOf('year') });

  return (
    <Box>
      <div className="topbar topbar--alt">
        <div className="col-filter-btns">
          <AnalyticsPeriodSelector range={range} onSelect={setRange} />
        </div>
      </div>
      <ShopStats range={range} shopId={uiStore.selectedItem.id} />
      <Box mt={4}>
        <TovFiveSet range={range} shopId={uiStore.selectedItem.id} />
      </Box>
      <Box mt={4}>
        <OfferImpressionsShop range={range} shopId={uiStore.selectedItem.id} />
      </Box>
    </Box>
  );
});
