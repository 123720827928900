import React from 'react';
import { ScutiInput } from 'components/material/ScutiInput';
import { FormikProps } from 'formik';
import { ConnectFormData } from './ConnectEditForm.utils';
import { Box, Card, CardContent, CardHeader } from '@material-ui/core';
import { values } from 'mobx';
import { Controller, UseFormReturn } from 'react-hook-form';

interface Props {
  form: UseFormReturn<ConnectFormData>;
}

export function ConnectReferralPercentage({ form }: Props) {
  const disabled = !!form.watch('id');

  return (
    <Card>
      <CardHeader title="Referral" />
      <CardContent>
        <Box mt={2}>
          <Controller
            control={form.control}
            name="referralPercentage"
            render={({ field, fieldState }) => (
              <ScutiInput
                title="Referral Percentage"
                placeholder="% Referral fee"
                {...field}
                error={fieldState.error?.message}
                disabled={disabled}
              />
            )}
          />
        </Box>
      </CardContent>
    </Card>
  );
}
