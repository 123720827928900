import React from 'react';
import { Box, Card, CardContent, CardHeader } from '@material-ui/core';
import { ScutiInput } from 'components/material/ScutiInput';
import { FormikProps } from 'formik';
import { ProductForm } from './ProductEditForm.utils';

export const ProductEditDetail: React.FC<FormikProps<ProductForm>> = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) => {
  return (
    <Card>
      <CardHeader title="Detail" />
      <CardContent>
        <Box>
          <ScutiInput
            type="text"
            title="Name *"
            name="name"
            placeholder="Name of your product"
            value={values.name}
            error={touched.name && errors.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Box>
        <Box mt={2}>
          <ScutiInput
            title="Description *"
            type="text"
            name="description"
            multiline
            rows={8}
            placeholder="Description"
            value={values.description}
            error={touched.description && errors.description}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Box>
        <Box mt={2}>
          <ScutiInput
            title="Option 1 Name"
            type="text"
            name="option1"
            placeholder="Option 1"
            value={values.option1 || ''}
            error={errors.option1}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Box>
        <Box mt={2}>
          <ScutiInput
            title="Option 2 Name"
            type="text"
            name="option2"
            placeholder="Option 2"
            value={values.option2 || ''}
            error={errors.option2}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Box>
        <Box mt={2}>
          <ScutiInput
            title="Option 3 Name"
            type="text"
            name="option3"
            placeholder="Option 3"
            value={values.option3 || ''}
            error={errors.option3}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Box>
      </CardContent>
    </Card>
  );
};
