import { MenuClassKey, StyleRules } from '@material-ui/core';
import { scutiColors, scutiSizes } from './variables';

export const MuiMenu: Partial<StyleRules<MenuClassKey, {}>> = {
  paper: {
    background: scutiColors.menuBackground,
    border: `1px solid ${scutiColors.borderFocused}`,
    color: scutiColors.textMain,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    '& li': {
      fontSize: scutiSizes.inputFontSize,
      cursor: 'default',
      '&:hover': {
        cursor: 'default',
        opacity: 1,
        backgroundColor: scutiColors.menuItemBackgroundHover,
      },
    },
  },
};
