import React from 'react';
import { PrivateRoute } from 'components/PrivateRoute';
import { Switch } from 'react-router-dom';
import { ordersRoutes } from 'routing';
import { OrdersListPage } from './OrdersListPage';
import { useAppStore } from 'store/app-store.hook';
import { observer } from 'mobx-react-lite';
import { OrderOverviewPage } from './OrderOverviewPage';
import { OrderOverviewRefundPage } from './OrderOverviewRefundPage';
import { OrderSummaryPage } from './OrderSummaryPage';

export const OrdersRoutes: React.FC = observer(() => {
  const { permissions } = useAppStore();
  return (
    <Switch>
      {permissions.ordersAccess.view && <PrivateRoute exact path={ordersRoutes.ORDERS} component={OrdersListPage} />}
      {permissions.ordersAccess.view && (
        <PrivateRoute exact path={ordersRoutes.ORDER_OVERVIEW()} component={OrderOverviewPage} />
      )}
      {permissions.ordersAccess.view && (
        <PrivateRoute exact path={ordersRoutes.ORDER_SUMMARY()} component={OrderSummaryPage} />
      )}
      {permissions.ordersAccess.view && (
        <PrivateRoute exact path={ordersRoutes.ORDER_OVERVIEW_REFUNDS()} component={OrderOverviewRefundPage} />
      )}
    </Switch>
  );
});
