import { Box, Card, CardContent, CardHeader } from '@material-ui/core';
import React from 'react';
import { OrderFragment } from '../__generated__/orders.hooks';

interface Props {
  order: OrderFragment;
}

export const OrderCustomerInformation: React.FC<Props> = ({ order }) => {
  const { user, shippingInformation } = order;
  const { fullName, email } = user;
  return (
    <Card>
      <CardHeader title="Customer information" />
      <CardContent>
        <Box>
          <Box>
            <strong>{fullName}</strong>
          </Box>
          <Box mt={1}>
            <a href={`mailto:${email}`}>{email}</a>
          </Box>
          <Box mt={1}>Phone: {shippingInformation?.phone || '---'}</Box>
        </Box>
      </CardContent>
    </Card>
  );
};
