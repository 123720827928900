import { Grid } from '@mui/material';
import { StatsCard } from 'components/material/analytics/StatsCard';
import { AvgAdsImpressions, AvgProductsEngaged, TotalOrders, useStatQuery } from './ShopStats.hooks';
import { toThreeFraction } from 'utils/string.utils';
import { AnalyticsRange } from 'types/app-types';

interface Props {
  range: AnalyticsRange;
  shopId: string;
}

export const ShopStats: React.FC<Props> = ({ range, shopId }) => {
  const useTotalOrders = useStatQuery<TotalOrders>({ shopId, range, metric: 'total-orders' });
  const useAvgProductsEngaged = useStatQuery<AvgProductsEngaged>({ shopId, range, metric: 'avg-products-clicks' });
  const useAvgAdsEngaged = useStatQuery<AvgProductsEngaged>({ shopId, range, metric: 'avg-ads-clicks' });
  const useAvgAdsImpressions = useStatQuery<AvgAdsImpressions>({ shopId, range, metric: 'avg-ads-impressions' });

  const avgProductsEngaged =
    useAvgProductsEngaged.data && useAvgProductsEngaged.data[0]
      ? toThreeFraction(useAvgProductsEngaged.data[0].clickedOffer / (useAvgProductsEngaged.data[0].openedScuti || 1))
      : 0;

  const avgAdsEngaged =
    useAvgAdsEngaged.data && useAvgAdsEngaged.data[0]
      ? toThreeFraction(useAvgAdsEngaged.data[0].clickedOffer / (useAvgAdsEngaged.data[0].openedScuti || 1))
      : 0;

  const avgAdsImpressions =
    useAvgAdsImpressions.data && useAvgAdsImpressions.data[0]
      ? toThreeFraction(useAvgAdsImpressions.data[0].adsImpressions / (useAvgAdsImpressions.data[0].openedScuti || 1))
      : 0;

  const totalOrders = useTotalOrders.data ? useTotalOrders.data.totalOrders : 0;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} lg>
        <StatsCard title="Average Products Engaged" value={avgProductsEngaged} />
      </Grid>
      <Grid item xs={12} md={6} lg>
        <StatsCard title="Average Ad Engaged" value={avgAdsEngaged} />
      </Grid>
      <Grid item xs={12} md={6} lg>
        <StatsCard title="Average Ad Shown" value={avgAdsImpressions} />
      </Grid>
      <Grid item xs={12} md={6} lg>
        <StatsCard title="Total Orders" value={totalOrders} />
      </Grid>
    </Grid>
  );
};
