import React from 'react';
import { Box, Card, CardContent, CardHeader } from '@material-ui/core';
import { ScutiInput } from 'components/material/ScutiInput';
import { ShopFormData } from './ShopEditForm.utils';
import { Controller, UseFormReturn } from 'react-hook-form';

interface Props {
  form: UseFormReturn<ShopFormData>;
}

export const ShopReturnPolicy: React.FC<Props> = ({ form }) => {
  return (
    <Card>
      <CardHeader title="Returns" />
      <CardContent>
        <Box>
          <Controller
            control={form.control}
            name="returnPolicy"
            render={({ field, fieldState }) => (
              <ScutiInput
                fullWidth
                size="small"
                variant="outlined"
                title="Return Policy"
                placeholder="Return Policy"
                multiline
                minRows={8}
                error={fieldState.error?.message}
                {...field}
              />
            )}
          />
        </Box>
        <Box mt={2}>
          <Controller
            control={form.control}
            name="returnPolicyUrl"
            render={({ field, fieldState }) => (
              <ScutiInput
                title="Returns Policy URL"
                placeholder="http://"
                error={fieldState.error?.message}
                {...field}
              />
            )}
          />
        </Box>
      </CardContent>
    </Card>
  );
};
