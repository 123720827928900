import React from 'react';
import { Box, Card, CardHeader, CardContent } from '@material-ui/core';
import { ScutiSelect } from 'components/material/ScutiSelect';
import { CAMPAIGN_BUDGET_SELECT_OPTIONS, CAMPAIGN_METRIC_SELECT_OPTIONS } from 'types/select-types';
import { CustomInput, FormGroup } from 'reactstrap';
import { ScutiInput } from 'components/material/ScutiInput';
import { CampaignBudgetOption } from 'types/__generated__/types';
import { useProductCategories } from 'hooks/useProductCategories';
import { Controller, UseFormReturn } from 'react-hook-form';
import { CampaignFormData, isPaused } from './CampaignEditForm.utils';

interface Props {
  form: UseFormReturn<CampaignFormData>;
  paused?: boolean;
  showBudget?: boolean;
}

export function BudgetDetails({ form, paused, showBudget }: Props) {
  const values = form.watch();

  const [categorySpecific, setCategorySpecific] = React.useState(!!values.category);
  const productCategoriesOptions = useProductCategories();

  const toggleCategory = () => {
    if (categorySpecific) form.setValue('category', null);
    else if (productCategoriesOptions) form.setValue('category', productCategoriesOptions[0].value);
    setCategorySpecific(!categorySpecific);
  };

  return (
    <Card>
      <CardHeader title="Budget" />
      <CardContent>
        {showBudget && (
          <Box>
            <Controller
              control={form.control}
              name="budget.option"
              render={({ field, fieldState }) => (
                <ScutiSelect
                  title="Option"
                  name="budget.option"
                  placeholder="Select type"
                  value={values.budget?.option || ''}
                  options={CAMPAIGN_BUDGET_SELECT_OPTIONS}
                  onChange={({ target }) => {
                    form.setValue('budget', {
                      ...values.budget,
                      option: target.value as CampaignBudgetOption,
                    });
                    form.setValue('category', null);
                  }}
                  onBlur={field.onBlur}
                  error={fieldState.error?.message}
                  disabled={!paused}
                />
              )}
            />
          </Box>
        )}
        {values.budget?.option === CampaignBudgetOption.Featured && (
          <Box mt={2}>
            <FormGroup>
              <label>
                <CustomInput
                  id="category-box"
                  type="checkbox"
                  label="Category"
                  onChange={toggleCategory}
                  checked={categorySpecific}
                  disabled={!paused}
                />
              </label>
              <Controller
                control={form.control}
                name="category"
                render={({ field, fieldState }) => (
                  <ScutiSelect
                    title="Category"
                    placeholder="Select category"
                    options={productCategoriesOptions}
                    {...field}
                    error={fieldState.error?.message}
                    disabled={!categorySpecific || !paused}
                  />
                )}
              />
            </FormGroup>
          </Box>
        )}
        <Box mt={2}>
          <Controller
            control={form.control}
            name="budget.maxSpend"
            render={({ field, fieldState }) => (
              <ScutiInput
                type="number"
                title="Maximum spend"
                placeholder="The most you'll spend"
                {...field}
                error={fieldState.error?.message}
                disabled={!paused}
              />
            )}
          />
        </Box>
        <Box mt={2}>
          <Controller
            control={form.control}
            name="budget.maxDailySpend"
            render={({ field, fieldState }) => (
              <ScutiInput
                type="number"
                title="Maximum daily spend"
                placeholder="The cap for how much you’ll spend in one day"
                {...field}
                error={fieldState.error?.message}
                disabled={!paused}
              />
            )}
          />
        </Box>
        <Box mt={2}>
          <Controller
            control={form.control}
            name="budget.limitOfImpressions"
            render={({ field, fieldState }) => (
              <ScutiInput
                type="number"
                title="Limit of repeat impressions"
                placeholder="6 is the minimum"
                {...field}
                error={fieldState.error?.message}
                disabled={!paused}
              />
            )}
          />
        </Box>
        <Box mt={2}>
          <Controller
            control={form.control}
            name="budget.metricType"
            render={({ field, fieldState }) => (
              <ScutiSelect
                title="Metric"
                placeholder="Select metric"
                options={CAMPAIGN_METRIC_SELECT_OPTIONS}
                {...field}
                error={fieldState.error?.message}
              />
            )}
          />
        </Box>
      </CardContent>
    </Card>
  );
}
