// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ScutiImgInput_scuti_img_input__PAP9Q {
  border: 1px solid #3b3d44;
  border-radius: 8px;
}

.ScutiImgInput_scuti_img_input_wrapper__ilSsj {
  cursor: pointer;
}

.ScutiImgInput_scuti_img_input_wrapper_disabled__lzBbr {
  opacity: 0.5;
}

.ScutiImgInput_scuti_img_input_error__9DY9U {
  border: 1px solid #f86c6b;
}

.ScutiImgInput_file_input__owiC4 {
  visibility: hidden;
  height: 100%;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/material/ScutiImgInput/ScutiImgInput.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,kBAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,kBAAA;EACA,YAAA;EACA,WAAA;AACF","sourcesContent":[".scuti_img_input {\n  border: 1px solid #3b3d44;\n  border-radius: 8px;\n}\n\n.scuti_img_input_wrapper {\n  cursor: pointer;\n}\n\n.scuti_img_input_wrapper_disabled {\n  opacity: 0.5;\n}\n\n.scuti_img_input_error {\n  border: 1px solid #f86c6b;\n}\n\n.file_input {\n  visibility: hidden;\n  height: 100%;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scuti_img_input": `ScutiImgInput_scuti_img_input__PAP9Q`,
	"scuti_img_input_wrapper": `ScutiImgInput_scuti_img_input_wrapper__ilSsj`,
	"scuti_img_input_wrapper_disabled": `ScutiImgInput_scuti_img_input_wrapper_disabled__lzBbr`,
	"scuti_img_input_error": `ScutiImgInput_scuti_img_input_error__9DY9U`,
	"file_input": `ScutiImgInput_file_input__owiC4`
};
export default ___CSS_LOADER_EXPORT___;
