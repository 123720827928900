import React from 'react';
import cn from 'classnames';
import styles from './PageContentLayout.module.scss';

interface Props {
  className?: string;
}

const Header: React.FC<Props> = ({ className, children }) => {
  return <div className={cn(styles.page_header, className)}>{children}</div>;
};

const Content: React.FC<Props> = ({ className, children }) => {
  return <div className={cn(styles.page_content, className)}>{children}</div>;
};

const Footer: React.FC<Props> = ({ className, children }) => {
  return <div className={cn(styles.page_footer, className)}>{children}</div>;
};

interface Parts {
  Header: React.FC<Props>;
  Content: React.FC<Props>;
  Footer: React.FC<Props>;
}

export const PageContentLayout: React.FC<Props> & Parts = ({ className, children }) => {
  return <div className={cn(styles.page_wrapper, className)}>{children}</div>;
};

PageContentLayout.Header = Header;
PageContentLayout.Content = Content;
PageContentLayout.Footer = Footer;
