import React from 'react';
import { Box, Card, CardContent, CardHeader } from '@material-ui/core';
import { CustomInput } from 'reactstrap';
import { ScutiInput } from 'components/material/ScutiInput';
import { FormikProps } from 'formik';
import { ShopFormData } from './ShopEditForm.utils';
import { Controller, UseFormReturn } from 'react-hook-form';

interface Props {
  form: UseFormReturn<ShopFormData>;
}

export const ShopFinancials: React.FC<Props> = ({ form }) => {
  return (
    <Card>
      <CardHeader title="Financials" />
      <CardContent>
        <Box>
          <Controller
            control={form.control}
            name="referralFee"
            render={({ field, fieldState }) => (
              <ScutiInput
                title="Referral Fee"
                type="number"
                placeholder="%"
                error={fieldState.error?.message}
                {...field}
              />
            )}
          />
        </Box>
        <Box mt={2}>
          <Box mb={1} display="flex" justifyContent="space-between">
            Rewards Included
            <Controller
              control={form.control}
              name="rewardsIncluded"
              render={({ field }) => (
                <CustomInput
                  type="checkbox"
                  id="rewards-box"
                  checked={field.value || undefined}
                  onChange={({ target }) => field.onChange(target.checked)}
                />
              )}
            />
          </Box>
          <Controller
            control={form.control}
            name="rewardsPercentage"
            render={({ field, fieldState }) => (
              <ScutiInput type="number" placeholder="%" error={fieldState.error?.message} {...field} />
            )}
          />
        </Box>
      </CardContent>
    </Card>
  );
};
