import React from 'react';

interface Props {
  value: number;
}

export const Progress: React.FC<Props> = ({ value }) => {
  const style = { width: `${value}%` };
  return (
    <div className="progress-holder">
      <div className="progress-bar">
        <div style={style} />
      </div>
    </div>
  );
};
