import { Box } from '@mui/material';
import { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import cn from 'classnames';
import styles from './ImgPreview.module.scss';

interface Props {
  url: string;
  onDelete: () => void;
  disabled?: boolean;
}

export const ImgPreview: React.FC<Props> = ({ url, disabled, onDelete }) => {
  const [hovered, setHovered] = useState<boolean>(false);

  return (
    <Box
      width="100%"
      height="100%"
      position="relative"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <img
        className={cn({ [styles.img_hovered]: hovered && !disabled, [styles.disabled]: disabled })}
        width="100%"
        height="100%"
        src={url}
        alt=""
      />
      {!disabled && hovered && <DeleteIcon className={styles.delete_icon} onClick={onDelete} />}
    </Box>
  );
};
