import React from 'react';
import { InputGroup, Input, Button } from 'reactstrap';
import { observer } from 'mobx-react-lite';
import { FormikProps } from 'formik';
import { FormFulfillmentMethod, ProductForm } from './ProductEditForm.utils';
import { ScutiInput } from 'components/material/ScutiInput';
import { ScutiSelect } from 'components/material/ScutiSelect';
import { Box } from '@material-ui/core';
import { ScutiImgInput } from 'components/material/ScutiImgInput';
import ImageIcon from '@mui/icons-material/Image';
import { WeightUnit } from 'types/__generated__/types';

interface Props extends FormikProps<ProductForm['variants'][0]> {
  index: number;
  fulfillmentMethods: FormFulfillmentMethod[];
  onDelete: () => void;
}

export const ProductVariantEditRow: React.FC<Props> = observer((props) => {
  const { index, values, touched, errors, handleChange, handleBlur, setFieldValue, onDelete } = props;
  return (
    <tr className="variant-row edit-mode">
      <td>
        <ScutiInput
          name={`variants[${index}].sku`}
          value={values.sku || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="SKU"
          hideHelper
          // @ts-ignore
          error={touched.sku && errors?.variants?.[index]?.sku}
        />
      </td>
      <td>
        <Box height="2.5rem" width="2.5rem">
          <ScutiImgInput
            image={values.image}
            onChange={(file) => setFieldValue(`variants[${index}].image`, file, false)}
            // @ts-ignore
            error={errors?.variants?.[index]?.image}
          >
            <ImageIcon color="secondary" style={{ width: '100%', height: '100%' }} />
          </ScutiImgInput>
        </Box>
      </td>
      <td>
        <ScutiInput
          name={`variants[${index}].option1`}
          // @ts-ignore
          error={touched.option1 && errors?.variants?.[index]?.option1}
          value={values.option1 || ''}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </td>
      <td>
        <ScutiInput
          name={`variants[${index}].option2`}
          // @ts-ignore
          error={touched.option2 && errors?.variants?.[index]?.option2}
          value={values.option2 || ''}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </td>
      <td>
        <ScutiInput
          name={`variants[${index}].option3`}
          // @ts-ignore
          error={touched.option3 && errors?.variants?.[index]?.option3}
          value={values.option3 || ''}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </td>
      <td>
        <ScutiInput
          placeholder="$"
          name={`variants[${index}].price.amount`}
          type="number"
          value={values.price.amount || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          // @ts-ignore
          error={touched.price?.amount && errors?.variants?.[index]?.price?.amount}
          hideHelper
        />
      </td>
      <td>
        <ScutiInput
          placeholder="$"
          name={`variants[${index}].compareAt.amount`}
          type="number"
          value={values.compareAt?.amount || ''}
          onChange={({ target }) => {
            if (!target.value) {
              setFieldValue(`variants[${index}].compareAt`, undefined, true);
            } else {
              if (+target.value === 0) {
                setFieldValue(`variants[${index}].compareAt`, undefined, true);
              } else {
                setFieldValue(`variants[${index}].compareAt`, { amount: +target.value, currency: 'USD' }, true);
              }
            }
          }}
          onBlur={handleBlur}
          // @ts-ignore
          error={touched.compareAt?.amount && errors?.variants?.[index]?.compareAt?.amount}
        />
      </td>
      <td>
        <ScutiInput
          name={`variants[${index}].barcode`}
          // @ts-ignore
          error={touched.barcode && errors?.variants?.[index]?.barcode}
          value={values.barcode || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Barcode"
          hideHelper
        />
      </td>
      <td>
        <Box display="flex">
          <ScutiInput
            type="number"
            name={`variants[${index}].weight.amount`}
            // @ts-ignore
            error={touched.weight && errors?.variants?.[index]?.weight?.amount}
            value={values.weight?.amount === null ? '' : values.weight?.amount}
            onChange={({ target }) => {
              if (!target.value) setFieldValue(`variants[${index}].weight`, undefined, true);
              else
                setFieldValue(
                  `variants[${index}].weight`,
                  { amount: +target.value, unit: values.weight?.unit || WeightUnit.Kg },
                  true,
                );
            }}
            onBlur={handleBlur}
            placeholder="Weight"
            hideHelper
          />
          <ScutiSelect
            placeholder="Unit"
            name={`variants[${index}].weight.unit`}
            value={values.weight?.unit || ''}
            options={Object.values(WeightUnit).map((value) => ({
              label: value,
              value,
            }))}
            onChange={handleChange}
            onBlur={handleBlur}
            // @ts-ignore
            error={touched.weight && errors?.variants?.[index]?.weight?.unit}
          />
        </Box>
      </td>
      <td>
        <InputGroup>
          <Input
            className="input-sm bg-light"
            type="number"
            name={`variants[${index}].inStock`}
            // @ts-ignore
            invalid={touched.inStock && !!errors?.variants?.[index]?.inStock}
            value={values.inStock === null ? '' : values.inStock}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="In Stock"
          />
        </InputGroup>
      </td>
      <td>
        <ScutiSelect
          onBlur={handleBlur}
          value={values.fulfillmentMethod?.id || ''}
          options={[
            { value: '', label: '' },
            ...props.fulfillmentMethods.map((method) => ({
              value: method.id,
              label: method.name,
            })),
          ]}
        />
      </td>
      <td>
        <div className="row-actions">
          {index !== 0 && <Button className="link-delete" title="Delete" onClick={onDelete} />}
        </div>
      </td>
    </tr>
  );
});
