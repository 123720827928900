import React, { useMemo } from 'react';
import { Box, Card, CardHeader, CardContent } from '@material-ui/core';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { ScutiAutocomplete } from 'components/material/ScutiAutoComplete';
import { useDebounce } from 'use-debounce/lib';
import { CampaignType, Filter_Type, RequestEntityType } from 'types/__generated__/types';
import { FormGroup } from 'reactstrap';
import { ScutiSelect } from 'components/material/ScutiSelect';
import { CAMPAIGN_TYPE_OPTIONS } from 'types/select-types';
import { CampaignFormData } from './CampaignEditForm.utils';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useAdvertisementsQuery } from 'graphql/__generated__/advertisement.hooks';
import { useProductListingsQuery } from 'pages/ProductListing/ProductListingListPage/__generated__/product-listing-list-page.hooks';
import { UserItem } from 'types/user-types';
import { useProductCategories } from 'hooks/useProductCategories';

interface Props {
  form: UseFormReturn<CampaignFormData>;
  userItem: UserItem;
  paused?: boolean;
}

export const TypeDetails = ({ form, paused, userItem }: Props) => {
  const { search } = useLocation();
  const { shopId } = queryString.parse(search);

  const [searchInput, setSearchInput] = React.useState<string>('');
  const productCategoriesOptions = useProductCategories();
  const [inputValue] = useDebounce(searchInput, 500);

  const campaignType = form.watch('type');
  const isSingleItem = campaignType === CampaignType.SingleItem;

  const productListingsQuery = useProductListingsQuery(
    {
      id: shopId as string,
      filters: [
        {
          name: 'type',
          operator: Filter_Type.Eq,
          value: [CampaignType.ProductListing],
        },
        {
          name: 'search',
          operator: Filter_Type.Like,
          value: [inputValue],
        },
      ],
    },
    {
      keepPreviousData: true,
      enabled: userItem.itemType === RequestEntityType.Shop && isSingleItem,
    },
  );

  const advertisementsQuery = useAdvertisementsQuery(
    {
      filters: [
        {
          name: 'name',
          operator: Filter_Type.Contains,
          value: [inputValue],
        },
      ],
    },
    {
      keepPreviousData: true,
      enabled: userItem.itemType === RequestEntityType.Brand && isSingleItem,
    },
  );

  const listings = productListingsQuery.data?.shopCampaigns.nodes;
  const advertisements = advertisementsQuery.data?.advertisements.nodes;

  const options = useMemo(() => {
    if (userItem.itemType === RequestEntityType.Shop && listings && isSingleItem) {
      return listings
        .reduce<{ id: string; name: string }[]>((acc, { product }) => {
          return product ? [...acc, product] : acc;
        }, [])
        .map((p) => ({ value: [p.id, undefined], label: p.name }))
        .filter((option, index, self) => index === self.findIndex((p) => p.value[0] === option.value[0]));
    }

    if (userItem.itemType === RequestEntityType.Brand && advertisements && isSingleItem) {
      return advertisements
        .map((a) => ({ value: [a.id, undefined], label: a.name }))
        .filter((option, index, self) => index === self.findIndex((p) => p.value[0] === option.value[0]));
    }

    return [];
  }, [listings, advertisements, userItem]);

  const selectedProduct = form.watch('product');
  const selectedAdvertisement = form.watch('advertisement');

  return (
    <Card>
      <CardHeader title="Type" />
      <CardContent>
        <Box>
          <FormGroup>
            <label htmlFor={`type`}>Campaign Type</label>
            <Controller
              control={form.control}
              name="type"
              render={({ field, fieldState }) => (
                <ScutiSelect
                  placeholder="Campaign Type"
                  options={CAMPAIGN_TYPE_OPTIONS}
                  {...field}
                  error={fieldState.error?.message}
                  disabled={!paused}
                />
              )}
            />
          </FormGroup>
        </Box>
        {userItem.itemType === RequestEntityType.Shop && isSingleItem && (
          <>
            <Box mt={2}>
              <Controller
                control={form.control}
                name="product"
                render={({ field, fieldState }) => (
                  <ScutiAutocomplete
                    placeholder="Select Product"
                    title="Product *"
                    getOptionLabel={(option) => option.label}
                    value={options.find(({ value }) => value[0] === selectedProduct.id) || null}
                    options={options}
                    onInputChange={(_, value, reason) => {
                      if ('input' === reason) setSearchInput(value);
                    }}
                    onChange={(_, value, reason) => {
                      if ('selectOption' === reason && !!value) {
                        field.onChange({ id: value.value[0], name: value.label });
                        form.setValue('advertisement', { id: '', name: '' });
                      }
                      if ('clear' === reason || !value) {
                        field.onChange({ id: '', name: '' });
                      }
                    }}
                    onBlur={field.onBlur}
                    error={fieldState.error?.message}
                    disabled={!paused}
                  />
                )}
              />
            </Box>
            <Box mt={2}>
              <Controller
                control={form.control}
                name="category"
                render={({ field, fieldState }) => (
                  <ScutiSelect
                    title="Category *"
                    placeholder="Select category"
                    {...field}
                    options={productCategoriesOptions}
                    error={fieldState.error?.message}
                    disabled={!paused}
                  />
                )}
              />
            </Box>
          </>
        )}
        {userItem.itemType === RequestEntityType.Brand && isSingleItem && (
          <Box mt={2}>
            <Controller
              control={form.control}
              name="advertisement"
              render={({ field, fieldState }) => (
                <ScutiAutocomplete
                  placeholder="Select Advertisement"
                  title="Advertisement *"
                  getOptionLabel={(option) => option.label}
                  value={options.find(({ value }) => value[0] === selectedAdvertisement.id) || null}
                  options={options}
                  onInputChange={(_, value, reason) => {
                    if ('input' === reason) setSearchInput(value);
                  }}
                  onChange={(event, value, reason) => {
                    if ('selectOption' === reason && !!value) {
                      field.onChange({ id: value.value[0], name: value.label });
                      form.setValue('product', { id: '', name: '' });
                    }
                    if ('clear' === reason || !value) {
                      field.onChange({ id: '', name: '' });
                    }
                  }}
                  onBlur={field.onBlur}
                  error={fieldState.error?.message}
                  disabled={!paused}
                />
              )}
            />
          </Box>
        )}
      </CardContent>
    </Card>
  );
};
