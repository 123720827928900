import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Grid } from '@material-ui/core';
import { useParams } from 'react-router';
import { TopNavigation } from 'components/material/TopNavigation';
import { OrganizationType } from 'types/__generated__/types';
import { useOrganizationQuery } from 'graphql/__generated__/organization.hooks';
import { OrganizationShops } from './OrganizationShops';
import { OrganizationGames } from './OrganizationGames';
import { OrganizationBrands } from './OrganizationBrands';
import { OrganizationConnections } from './OrganizationConnections';

export const OrganizationOverviewPage: React.FC<{ onBack?: () => void }> = observer(({ onBack }) => {
  const { id } = useParams<{ id: string }>();
  const organizationQuery = useOrganizationQuery({ id }, { enabled: !!id });

  const organization = organizationQuery.data?.organization;

  return (
    <Box>
      <TopNavigation title="Organization Overview" />
      <Box mt={2}>
        <Grid container spacing={4}>
          {organization?.types.includes(OrganizationType.Retailer) && (
            <Grid item xs={12} lg={6}>
              <OrganizationShops organizationId={id} />
            </Grid>
          )}
          {organization?.types.includes(OrganizationType.GamePublisher) && (
            <Grid item xs={12} lg={6}>
              <OrganizationGames organizationId={id} />
            </Grid>
          )}
          {organization?.types.includes(OrganizationType.Advertiser) && (
            <Grid item xs={12} lg={6}>
              <OrganizationBrands organizationId={id} />
            </Grid>
          )}
          {organization?.types.includes(OrganizationType.Agency) && (
            <Grid item xs={12} lg={6}>
              <OrganizationConnections organizationId={id} />
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
});
