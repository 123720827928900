import React from 'react';
import { GameGridItem } from './GameGridItem';
import { Row, Col } from 'reactstrap';
import { GameItemFragment } from 'pages/Games/__generated__/games-list-page.hooks';

interface Props {
  games: GameItemFragment[];
  onDelete: (userId: string) => void;
}

export const GamesGrid: React.FC<Props> = ({ games, onDelete }) => {
  return (
    <div className="game-grid">
      <Row className="gap-card same-height">
        {games.map((game) => {
          return (
            <Col sm={6} xl={4} key={game.id}>
              <GameGridItem key={game.id} game={game} onDelete={onDelete} />
            </Col>
          );
        })}
      </Row>
    </div>
  );
};
