import { Grid } from '@mui/material';
import { campaignRoutes, productsRoutes } from 'routing';
import { AnalyticsRange } from 'types/app-types';
import { useStatQuery, TopProducts, TopAds } from './ShopStats.hooks';
import { TopFive } from './TopFive';

interface Props {
  range: AnalyticsRange;
  shopId: string;
}

const productItemMap = (p: TopProducts[0]) => {
  const { productId, count, name, images } = p;
  return {
    id: productId,
    href: productsRoutes.PRODUCT_OVERVIEW(productId),
    image: images[0],
    name,
    count,
  };
};

const adItemMap = (p: TopAds[0]) => {
  const { campaignId, count, mediaBanner, mediaTile, mediaVertical } = p;
  return {
    id: campaignId,
    href: campaignRoutes.CAMPAIGN_EDIT(campaignId),
    image: mediaBanner || mediaTile || mediaVertical,
    count,
    name: '',
  };
};

export const TovFiveSet: React.FC<Props> = ({ shopId, range }) => {
  const useTopProductsImpressions = useStatQuery<TopProducts>({ shopId, range, metric: 'top-products-impressions' });
  const useTopProductsEngaged = useStatQuery<TopProducts>({ shopId, range, metric: 'top-products-clicks' });
  const useTopAdsImpressions = useStatQuery<TopAds>({ shopId, range, metric: 'top-ad-campaigns-impressions' });
  const useTopAdsEngaged = useStatQuery<TopAds>({ shopId, range, metric: 'top-ad-campaigns-clicks' });

  const topProductsImpressions = useTopProductsImpressions.data || [];
  const topProductsEngaged = useTopProductsEngaged.data || [];
  const topAdsImpressions = useTopAdsImpressions.data || [];
  const topAdsEngaged = useTopAdsEngaged.data || [];

  return (
    <Grid container spacing={2}>
      <Grid item md={6}>
        <TopFive
          title="Top Products Impressions"
          items={topProductsImpressions.map(productItemMap)}
          isLoading={useTopProductsImpressions.isLoading}
        />
      </Grid>
      <Grid item md={6}>
        <TopFive
          title="Top Products Engaged"
          items={topProductsEngaged.map(productItemMap)}
          isLoading={useTopProductsEngaged.isLoading}
        />
      </Grid>
      <Grid item md={6}>
        <TopFive
          title="Top Ads Impressions"
          items={topAdsImpressions.map(adItemMap)}
          isLoading={useTopAdsImpressions.isLoading}
        />
      </Grid>
      <Grid item md={6}>
        <TopFive title="Top Ads Engaged" items={topAdsEngaged.map(adItemMap)} isLoading={useTopAdsEngaged.isLoading} />
      </Grid>
    </Grid>
  );
};
