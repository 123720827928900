import { ProductListingItemFragment } from './__generated__/product-listing-list-page.hooks';
import { useMemo } from 'react';
import { Column } from 'react-table';
import { Link } from 'react-router-dom';
import { productListingRoutes } from 'routing';
import capitalize from 'lodash/capitalize';
import { Progress } from 'components/Progress';
import moment from 'moment';
import { CURRENCIES } from 'types/app-types';
import noPicUrl from 'assets/svgs/no-pic-sm.svg';

export function useProductListingsTableColumns(onDelete?: (id: string) => void): Column<ProductListingItemFragment>[] {
  return useMemo<Column<ProductListingItemFragment>[]>(
    () => [
      {
        Header: 'Image',
        id: 'image',
        disableSortBy: true,
        Cell: ({ row }: any) => {
          const { product } = row.original;
          return (
            <div className="variant-pic">
              <img src={product?.product.images?.[0] || noPicUrl} alt="" />
            </div>
          );
        },
      },
      {
        Header: 'Name',
        id: 'productName',
        disableSortBy: true,
        Cell: ({ row }: any) => {
          const { product } = row.original;
          return <div className="promoted-link">{capitalize(product?.product.name || '')}</div>;
        },
      },
      {
        Header: 'Status',
        id: 'status',
        accessor: (row) => <div className="promoted-link">{capitalize(row.status).replace(/_/g, ' ')}</div>,
      },
      {
        Header: 'Duration',
        id: 'duration',
        disableSortBy: true,
        Cell: ({ row }: any) => {
          const { duration } = row.original;
          const campaignDuration =
            duration.start && duration.end ? moment(duration.end).diff(duration.start, 'days') : 0;
          const campaignDaysPass = duration.start
            ? Math.max(moment(new Date()).diff(moment(duration.start), 'days'), 0)
            : 0;
          const campaignDaysProgress =
            campaignDaysPass && campaignDuration ? Math.round((campaignDaysPass / campaignDuration) * 100) : 0;
          return (
            <>
              <Progress value={campaignDaysProgress} />
              <strong className="progress-title">{`${campaignDaysPass || 0}/ ${campaignDuration || 0} days`}</strong>
            </>
          );
        },
      },
      {
        Header: 'Impressions',
        id: 'impressions',
        Cell: () => <strong className="progress-title">-</strong>,
        disableSortBy: true,
      },
      {
        Header: 'Sales Units',
        id: 'salesUnits',
        Cell: () => <strong className="progress-title">-</strong>,
        disableSortBy: true,
      },
      {
        Header: 'Sales',
        id: 'sales',
        disableSortBy: true,
        Cell: () => <strong className="progress-title">{`${CURRENCIES.USD}${0}`}</strong>,
      },
      {
        Header: '',
        id: 'delete',
        width: '6%',
        disableSortBy: true,
        Cell: ({ row }: any) => {
          const { id, shop } = row.original;
          return (
            <div className="row-actions">
              <Link
                className="link-edit"
                title="Edit"
                to={`${productListingRoutes.PRODUCT_LISTING_EDIT(id)}?shopId=${shop?.id}`}
              >
                Edit
              </Link>
              {onDelete && (
                <span className="link-delete" title="Delete" onClick={() => onDelete(id)}>
                  Delete
                </span>
              )}
            </div>
          );
        },
      },
    ],
    [onDelete],
  );
}
