import { AdvertisementType, NewAdvertisementInput, UpdateAdvertisementInput } from 'types/__generated__/types';
import { getImgAspectRatio, uploadImage } from 'utils/upload-image.utils';
import * as Yup from 'yup';

const FILE_SIZE = 3 * 1024 * 1024;
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

export const couponAdvertisementSchema = Yup.object().shape({
  id: Yup.string(),
  name: Yup.string().required('Field is required'),
  cpa: Yup.number()
    .min(0, 'Cannot be less than 0')
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable(),
  category: Yup.string().required('Field is required'),
  couponLabel: Yup.string().required('Field is required'),
  couponDescription: Yup.string().required('Field is required'),
  couponImages: Yup.mixed<(File | string)[]>()
    .test('fileSize', 'File too large', (value) => {
      if (!value) return true;
      return value.every((v: string | File) => typeof v === 'string' || v.size <= FILE_SIZE);
    })
    .test('fileFormat', 'Unsupported Format', (value) => {
      if (!value) return true;
      return value.every((v: string | File) => typeof v === 'string' || SUPPORTED_FORMATS.includes(v.type));
    })
    .required('Field is required'),
});

export type CouponAdvertisementFormData = Yup.InferType<typeof couponAdvertisementSchema>;

export const couponAdvertisementFormDataDefaults = (): CouponAdvertisementFormData => ({
  id: undefined,
  name: '',
  cpa: undefined,
  category: '',
  couponLabel: '',
  couponDescription: '',
  couponImages: [],
});

export const mapAdvertisementCouponToApiInput =
  (brandId: string) =>
  async (formData: CouponAdvertisementFormData): Promise<UpdateAdvertisementInput | NewAdvertisementInput> => {
    const couponImages = await Promise.all(
      formData.couponImages?.map(async (image) =>
        image && typeof image === 'object' ? (await uploadImage(image, { width: 300, height: 300 })).url : image,
      ) || [],
    );

    return {
      type: AdvertisementType.Coupon,
      brandId,
      id: formData.id,
      name: formData.name,
      cpa: formData.cpa || 0,
      category: formData.category,
      coupon: {
        couponLabel: formData.couponLabel,
        couponDescription: formData.couponDescription,
        couponImages,
      },
    };
  };
