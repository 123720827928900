import React from 'react';
import { Table, Button } from 'reactstrap';
import { FieldArray, FormikProps } from 'formik';
import { observer } from 'mobx-react-lite';
import { FormFulfillmentMethod, ProductForm, variantDefaults } from './ProductEditForm.utils';
import { ProductVariantEditRow } from './ProductVariantEditRow';

export const ProductVariantsTable: React.FC<
  FormikProps<ProductForm> & {
    fulfillmentMethods: FormFulfillmentMethod[];
  }
> = observer((props) => {
  const { values, touched, setFieldValue } = props;

  const onAddVariant = React.useCallback(() => {
    setFieldValue('variants', [...values.variants, variantDefaults(props.fulfillmentMethods[0])], true);
  }, [props.fulfillmentMethods, setFieldValue, values.variants]);
  return (
    <div className="products-options">
      <div className="headline">
        <strong>Variant &amp; Options</strong>
        <div className="headline-actions">
          <Button color="primary" type="button" onClick={onAddVariant}>
            Add variant
          </Button>
        </div>
      </div>
      <Table className="products-table" responsive striped>
        <thead>
          <tr>
            <th>SKU *</th>
            <th>Image</th>
            <th>Option 1</th>
            <th>Option 2</th>
            <th>Option 3</th>
            <th>Price $ *</th>
            <th>Compare at $</th>
            <th>Barcode</th>
            <th className="col-weight">Weight</th>
            <th>In Stock</th>
            <th>Fulfillment *</th>
            <th className="col-btns" />
          </tr>
        </thead>
        <tbody>
          <FieldArray
            name="variants"
            render={(arrayHelpers) => {
              return values.variants.map((variantValues, vIndex) => {
                return (
                  // @ts-ignore
                  <ProductVariantEditRow
                    {...props}
                    index={vIndex}
                    key={`product_v_${vIndex}`}
                    touched={touched.variants ? touched.variants[vIndex] || {} : {}}
                    values={variantValues}
                    onDelete={() => arrayHelpers.remove(vIndex)}
                  />
                );
              });
            }}
          />
        </tbody>
      </Table>
    </div>
  );
});
