// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.non-auth-container {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
}

.logo {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translate(-50%, 0);
}`, "",{"version":3,"sources":["webpack://./src/layouts/WithoutAuthLayout/WithoutAuthLayout.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,kBAAA;EACA,qBAAA;AACF;;AACA;EACE,kBAAA;EACA,SAAA;EACA,SAAA;EACA,6BAAA;AAEF","sourcesContent":[".non-auth-container {\n  width: 100vw;\n  min-height: 100vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n  padding-top: 100px;\n  padding-bottom: 100px;\n}\n.logo {\n  position: absolute;\n  top: 40px;\n  left: 50%;\n  transform: translate(-50%, 0);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
