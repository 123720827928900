import './MuiPaging.css';
import './MuiPaper.css';
import { createTheme } from '@material-ui/core';
import { MuiOutlinedInput } from './MuiOutlinedInput';
import { MuiSelect } from './MuiSelect';
import { MuiMenu } from './MuiMenu';
import { MuiCard } from './MuiCard';
import { MuiCardHeader } from './MuiCardHeader';
import { MuiGrid } from './MuiGrid';
import { MuiFormHelperText } from './MuiFormHelperText';
import { MuiPaper } from './MuiPaper';
import { scutiColors } from './variables';

export const theme = createTheme({
  palette: {
    error: {
      main: scutiColors.systemRed,
    },
    primary: {
      main: '#0059ff',
      dark: scutiColors.grey500,
    },
    secondary: {
      main: scutiColors.grey200,
      light: scutiColors.systemGrey,
    },
    text: {
      primary: scutiColors.white,
      secondary: scutiColors.grey200,
    },
  },
  typography: {
    fontFamily: ['Industry, Avenir Next, Arial, sans-serif'].join(','),
    h1: {
      fontWeight: 400,
      fontSize: 28,
      lineHeight: '36px',
    },
    h2: {
      fontWeight: 400,
      fontSize: 22,
      lineHeight: '28px',
    },
    h3: {
      fontWeight: 400,
      fontSize: 20,
      lineHeight: '26px',
    },
    button: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '24px',
      textTransform: 'capitalize',
      color: scutiColors.white,
    },
    body1: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '24px',
    },
    body2: {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '18px',
    },
  },
  overrides: {
    MuiOutlinedInput,
    MuiSelect,
    MuiMenu,
    MuiCard,
    MuiCardHeader,
    MuiGrid,
    MuiFormHelperText,
    MuiPaper,
  },
});
