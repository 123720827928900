import React from 'react';
import { observer } from 'mobx-react-lite';
import { ProductListingEditForm } from './ProductListingEditForm';
import { useHistory, useLocation, useParams } from 'react-router';
import queryString from 'query-string';
import { useToasts } from 'react-toast-notifications';
import {
  ProductListingFormData,
  mapProductListingToApiInput,
} from './ProductListingEditForm/ProductListingEditForm.utils';
import { hasId } from 'types/util-types';
import { productListingRoutes } from 'routing';
import { Loader } from 'components/Loader';
import { ToggleCampaignInput } from 'types/__generated__/types';
import {
  useCreateProductListingMutation,
  useProductListingQuery,
  useToggleListingMutation,
  useUpdateProductListingMutation,
} from 'graphql/__generated__/product-listing.hooks';

export const ProductListingEditPage: React.FC = observer(() => {
  const { listingId } = useParams<{ listingId: string }>();
  const { search } = useLocation();
  const searchQuery = queryString.parse(search);
  const history = useHistory();
  const { addToast } = useToasts();

  const productListingQuery = useProductListingQuery({ id: listingId }, { enabled: !!listingId });
  const productListing = productListingQuery.data?.campaign;
  const shopId = (searchQuery.shopId as string) || productListing?.shop?.id;

  const useUpdateProductListing = useUpdateProductListingMutation();
  const useCreateProductListing = useCreateProductListingMutation();
  const useToggleListing = useToggleListingMutation();

  const isLoading = productListingQuery.isLoading || useToggleListing.isLoading;

  React.useEffect(() => {
    if (productListingQuery.error)
      addToast(productListingQuery.error.message, { appearance: 'error', autoDismiss: false });
  }, [addToast, productListingQuery.error]);

  const saveOrUpdate = React.useCallback(
    async (formData: ProductListingFormData) => {
      const input = mapProductListingToApiInput(shopId!)(formData);

      if (hasId(input)) {
        try {
          await useUpdateProductListing.mutateAsync({ input });
          await productListingQuery.refetch();
          addToast('Product Listing has been updated!', {
            appearance: 'success',
            autoDismiss: true,
          });
        } catch (e) {
          // @ts-expect-error
          addToast(e.message, { appearance: 'error', autoDismiss: false });
        }
      } else {
        try {
          await useCreateProductListing.mutateAsync({ input });
          addToast('Product Listing has created!', { appearance: 'success', autoDismiss: true });
          history.push(productListingRoutes.PRODUCT_LISTING);
        } catch (e) {
          // @ts-expect-error
          addToast(e.message, { appearance: 'error', autoDismiss: false });
        }
      }
    },
    [addToast, history, productListingQuery, shopId, useCreateProductListing, useUpdateProductListing],
  );

  const toggleListing = React.useCallback(
    async (input: ToggleCampaignInput) => {
      try {
        await useToggleListing.mutateAsync({ input });
        await productListingQuery.refetch();
        addToast('Product Listing has been paused!', { appearance: 'success', autoDismiss: true });
      } catch (e) {
        // @ts-expect-error
        addToast(e.message, { appearance: 'error', autoDismiss: false });
      }
    },
    [addToast, productListingQuery, useToggleListing],
  );

  const showForm = listingId ? productListingQuery.isSuccess && !!productListing : true;

  return (
    <div>
      {isLoading && <Loader />}
      {showForm && (
        <ProductListingEditForm
          linkBack={productListingRoutes.PRODUCT_LISTING}
          productListing={productListing}
          onSave={saveOrUpdate}
          onToggle={toggleListing}
        />
      )}
    </div>
  );
});
