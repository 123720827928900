import React, { useEffect, useMemo } from 'react';
import { Form, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { CampaignFormData, campaignFormDataDefaults, campaignSchema, isPaused } from './CampaignEditForm.utils';
import { Box, Grid } from '@material-ui/core';
import { ScutiInput } from 'components/material/ScutiInput';
import { TypeDetails } from './TypeDetails';
import { DurationDetails } from './DurationDetails';
import { AudienceDetails } from './AudienceDetails';
import { EnhancementsDetails } from './EnhancementsDetails';
import { BudgetDetails } from './BudgetDetails';
import { yupResolver } from '@hookform/resolvers/yup';
import { BannerDetails } from './BannerDetails';
import { RewardDetails } from './RewardDetails';
import { TargetedGamesDetails } from './TargetedGamesDetails';
import { LocationDetails } from './LocationDetails';
import { CampaignFragment } from 'graphql/__generated__/campaign.hooks';
import { Controller, useForm } from 'react-hook-form';
import { useAppStore } from 'store/app-store.hook';
import { observer } from 'mobx-react-lite';

interface Props {
  linkBack: string;
  campaign?: CampaignFragment;
  onSave: (campaign: CampaignFormData) => void;
  onToggle: (params: { id: string; isPaused: boolean }) => void;
}

export const CampaignEditForm: React.FC<Props> = observer(({ campaign, linkBack, onSave, onToggle }) => {
  const { selectedItem } = useAppStore().uiStore;

  const campaignFormDefaultValues = useMemo<CampaignFormData>(() => {
    const defaults = campaignFormDataDefaults();

    if (!campaign) return defaults;

    return {
      ...defaults,
      id: campaign.id,
      product: campaign.product || defaults.product,
      reward: campaign.reward || defaults.reward,
    };
  }, []);

  const campaignForm = useForm<CampaignFormData>({
    resolver: yupResolver(campaignSchema(selectedItem.itemType)),
    defaultValues: campaignFormDefaultValues,
    mode: 'all',
  });

  useEffect(() => campaignForm.reset(campaignFormDefaultValues), [campaignFormDefaultValues]);

  const { isSubmitting } = campaignForm.formState;

  console.log('errors', campaignForm.formState.errors);

  const status = campaignForm.watch('status');
  const paused = isPaused(status);

  return (
    <Form className="w-100" onSubmit={campaignForm.handleSubmit(onSave)}>
      <div className="topbar d-sm-flex justify-content-sm-between">
        <div className="col-heading">
          <Link className="btn btn-back" to={linkBack}></Link>
          <Box ml={5} width={'50%'}>
            <Controller
              control={campaignForm.control}
              name="name"
              render={({ field, fieldState }) => (
                <ScutiInput
                  placeholder="Campaign name"
                  {...field}
                  error={fieldState.error?.message}
                  disabled={!paused}
                />
              )}
            />
          </Box>
        </div>
        <div className="col-action">
          <Button
            color="outline-secondary"
            onClick={() => campaignForm.reset()}
            disabled={!campaignForm.formState.isDirty}
          >
            Cancel
          </Button>
          {campaign?.id && (
            <>
              <Button
                color={!paused ? 'primary' : 'outline-secondary'}
                className="btn"
                onClick={() => onToggle({ id: campaign.id, isPaused: !paused })}
              >
                {paused ? 'Activate' : 'Pause'}
              </Button>
            </>
          )}
          <Button color="primary" type="submit" disabled={isSubmitting || !paused}>
            Save changes
          </Button>
        </div>
      </div>
      <Box pb={2}>
        <Grid container spacing={2}>
          <Grid item container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
              <TypeDetails form={campaignForm} paused={paused} userItem={selectedItem} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <RewardDetails form={campaignForm} paused={paused} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <DurationDetails form={campaignForm} paused={paused} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <AudienceDetails form={campaignForm} paused={paused} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TargetedGamesDetails form={campaignForm} paused={paused} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <LocationDetails form={campaignForm} paused={paused} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <BudgetDetails form={campaignForm} paused={paused} showBudget />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <EnhancementsDetails form={campaignForm} paused={paused} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <BannerDetails form={campaignForm} paused={paused} />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Form>
  );
});
