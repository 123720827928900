import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, useLocation, useParams } from 'react-router';
import { hasId } from 'types/util-types';
import { organizationsRoutes } from 'routing';
import { Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import {
  ConnectFormData,
  connectFormDefaults,
  connectSchema,
  mapFormToApiInput,
} from './ConnectEditForm/ConnectEditForm.utils';
import { Button } from 'reactstrap';
import { ConnectEditForm } from './ConnectEditForm';
import { useConnectionQuery, useCreateConnectionMutation } from 'graphql/__generated__/connection.hooks';
import * as queryString from 'query-string';
import { useAppStore } from 'store/app-store.hook';

export const ConnectEditPage: React.FC = observer(() => {
  const { addToast } = useToasts();

  const { search } = useLocation();
  const organizationId = queryString.parse(search).organizationId as string;
  const { uiStore } = useAppStore();
  const { id } = useParams<{ id: string }>();
  const itemType = uiStore.selectedItem.itemType;

  const useConnection = useConnectionQuery({ id });
  const useCreateConnection = useCreateConnectionMutation();

  const connectFormDefaultValues = useMemo<ConnectFormData>(() => {
    const connection = useConnection.data?.entityRequest;

    const defaults = connectFormDefaults();
    if (!connection) return defaults;

    return {
      id: connection.id,
      entityId: connection.entityId,
      controlLevel: connection.params.controlLevel,
      referralPercentage: connection.params.referralPercentage
        ? connection.params.referralPercentage * 100
        : defaults.referralPercentage,
      directResponse: connection.params.directResponse || defaults.directResponse,
      engagement: connection.params.engagement || defaults.engagement,
      video: connection.params.video || defaults.video,
    };
  }, [useConnection]);

  const connectForm = useForm<ConnectFormData>({
    resolver: yupResolver(connectSchema),
    defaultValues: connectFormDefaultValues,
    mode: 'all',
  });

  const handleSubmit = async (formData: ConnectFormData) => {
    try {
      const input = mapFormToApiInput(organizationId, itemType)(formData);
      await useCreateConnection.mutateAsync({ input });
      addToast('Connection has been created!', { appearance: 'success', autoDismiss: true });
    } catch (err) {
      // @ts-expect-error
      addToast(err.message, { appearance: 'error', autoDismiss: false });
    }
  };

  const { isDirty, isSubmitting } = connectForm.formState;

  const title = 'Create new connect';

  const disabled = !!connectForm.watch('id');

  return (
    <Box>
      <form onSubmit={connectForm.handleSubmit(handleSubmit)}>
        <div className="topbar d-sm-flex justify-content-sm-between">
          <div className="col-heading">
            <Link className="btn btn-back" to={organizationsRoutes.ORGANIZATIONS} />
            <h1 className="page-title">{title}</h1>
          </div>
          <div className="col-action">
            <Button
              color={!isDirty ? 'outline-secondary' : 'secondary'}
              onClick={() => connectForm.reset()}
              disabled={disabled}
            >
              Cancel
            </Button>
            <Button color="primary" type="submit" disabled={isSubmitting || disabled}>
              Save changes
            </Button>
          </div>
        </div>
        <Box sx={{ mt: 2 }}>
          <ConnectEditForm form={connectForm} />
        </Box>
      </form>
    </Box>
  );
});
