import { AdvertisementType, NewAdvertisementInput, UpdateAdvertisementInput } from 'types/__generated__/types';
import * as Yup from 'yup';

export const noActionAdAdvertisementSchema = Yup.object().shape({
  id: Yup.string(),
  name: Yup.string().required(),
  label: Yup.string().required(),
  category: Yup.string().required(),
});

export type NoActionAdAdvertisementFormData = Yup.InferType<typeof noActionAdAdvertisementSchema>;

export const noActionAdAdvertisementSchemaFormDataDefaults = (): NoActionAdAdvertisementFormData => ({
  name: '',
  label: '',
  category: '',
});

export const mapNoActionAdAdvertisementToApiInput =
  (brandId: string) =>
  (formData: NoActionAdAdvertisementFormData): UpdateAdvertisementInput | NewAdvertisementInput => {
    return {
      brandId,
      type: AdvertisementType.NoActionAd,
      cpa: 0,
      id: formData.id,
      name: formData.name,
      category: formData.category,
      noAction: { noActionLabel: formData.label },
    };
  };
