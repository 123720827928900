import React from 'react';
import 'react-tagsinput/react-tagsinput.css';

import TagsInput from 'react-tagsinput';
import { ScutiSelect } from 'components/material/ScutiSelect';
import { Box, Card, CardContent, CardHeader } from '@material-ui/core';
import { CustomInput, FormGroup } from 'reactstrap';
import { FormikProps } from 'formik';
import { ProductForm } from './ProductEditForm.utils';
import { useProductCategories } from 'hooks/useProductCategories';
import { scutiColors } from 'material-theme/variables';

export const ProductEditLogistics: React.FC<FormikProps<ProductForm>> = ({
  values,
  errors,
  touched,
  setFieldValue,
  handleChange,
  handleBlur,
}) => {
  const onTagsChange = React.useCallback(
    (tags: string[]) => {
      setFieldValue('tags', tags, true);
    },
    [setFieldValue],
  );

  const productCategoriesOptions = useProductCategories();

  return (
    <Card>
      <CardHeader title="Logistics" />
      <CardContent>
        <Box>
          <ScutiSelect
            name="category"
            title="Category *"
            placeholder="Select category"
            value={values.category}
            error={touched.category && errors.category}
            onChange={handleChange}
            onBlur={handleBlur}
            options={productCategoriesOptions}
          />
        </Box>
        <Box mt={2}>
          <FormGroup className="d-flex pt-6" style={{ color: scutiColors.white }}>
            <CustomInput
              type="checkbox"
              id="tax-box"
              name="chargeTaxes"
              label="Charge taxes on this product"
              checked={values.chargeTaxes}
              onChange={handleChange}
            />
          </FormGroup>
        </Box>
        <Box mt={2} className="tags-control">
          <label htmlFor="tags">Product tags</label>
          <TagsInput value={values.tags || []} onChange={onTagsChange} addKeys={[',', ' ']} onlyUnique />
        </Box>
      </CardContent>
    </Card>
  );
};
