import moment from 'moment';
import { Grid } from '@mui/material';
import { StatsCard } from 'components/material/analytics/StatsCard';
import {
  AvgAdsImpressions,
  AvgProductsImpressions,
  AvgTimeSpent,
  OpenedScuti,
  TotalOrders,
  useStatQuery,
} from './GameStats.hooks';
import { addDecimals, toThreeFraction } from 'utils/string.utils';
import { AnalyticsRange } from 'types/app-types';

interface Props {
  range: AnalyticsRange;
  gameId: string;
}

export const GameStats: React.FC<Props> = ({ range, gameId }) => {
  const useOpenedScuti = useStatQuery<OpenedScuti>({ gameId, range, metric: 'opened-scuti' });
  const useTotalOrders = useStatQuery<TotalOrders>({ gameId, range, metric: 'total-orders' });
  const useAvgProductsImpressions = useStatQuery<AvgProductsImpressions>({
    gameId,
    range,
    metric: 'avg-products-impressions',
  });
  const useAvgAdsImpressions = useStatQuery<AvgAdsImpressions>({ gameId, range, metric: 'avg-ads-impressions' });
  const useAvgTimeSpent = useStatQuery<AvgTimeSpent>({ gameId, range, metric: 'avg-time-spent' });

  const openedScuti = useOpenedScuti.data?.openedScuti || 0;

  const openedScutiRate = useOpenedScuti.data
    ? addDecimals((useOpenedScuti.data.openedScuti / (useOpenedScuti.data.openedSdk || 1)) * 100)
    : 0;

  const avgProductsImpressions =
    useAvgProductsImpressions.data && useAvgProductsImpressions.data[0]
      ? toThreeFraction(
          useAvgProductsImpressions.data[0].clickedOffer / (useAvgProductsImpressions.data[0].openedScuti || 1),
        )
      : 0;

  const avgAdsImpressions =
    useAvgAdsImpressions.data && useAvgAdsImpressions.data[0]
      ? toThreeFraction(useAvgAdsImpressions.data[0].adsImpressions / (useAvgAdsImpressions.data[0].openedScuti || 1))
      : 0;

  const avgTimeSpent = useAvgTimeSpent.data
    ? moment.utc(useAvgTimeSpent.data.avgDurationSeconds * 1000).format('HH:mm:ss')
    : 0;

  const totalOrders = useTotalOrders.data ? useTotalOrders.data.totalOrders : 0;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md>
        <StatsCard
          title="Opened Scuti"
          value={openedScuti}
          subValue={`Open rate ${openedScutiRate}%`}
          isLoading={useOpenedScuti.isLoading}
        />
      </Grid>
      <Grid item xs={12} md>
        <StatsCard
          title="Average Products Shown"
          value={`${avgProductsImpressions}`}
          isLoading={useAvgProductsImpressions.isLoading}
        />
      </Grid>
      <Grid item xs={12} md>
        <StatsCard title="Average Ad Shown" value={`${avgAdsImpressions}`} isLoading={useAvgAdsImpressions.isLoading} />
      </Grid>
      <Grid item xs={12} md>
        <StatsCard title="Average Time Spend" value={avgTimeSpent} isLoading={useAvgTimeSpent.isLoading} />
      </Grid>
      <Grid item xs={12} md>
        <StatsCard title="Total Orders" value={totalOrders} isLoading={useTotalOrders.isLoading} />
      </Grid>
    </Grid>
  );
};
