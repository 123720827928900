import { Box, Card, CardHeader, CardContent } from '@material-ui/core';
import { ScutiInput } from 'components/material/ScutiInput';
import { Controller, UseFormReturn } from 'react-hook-form';
import { AppDownloadAdvertisementFormData } from './AppDownloadAdvertisementEditForm.utils';

interface Props {
  form: UseFormReturn<AppDownloadAdvertisementFormData>;
}

export function AppDownloadAdvertisementIds({ form }: Props) {
  return (
    <Card>
      <CardHeader title="App Download Ids" />
      <CardContent>
        <Box mt={2}>
          <Controller
            control={form.control}
            name="androidId"
            render={({ field, fieldState }) => (
              <ScutiInput
                title="Android Id"
                placeholder="com.example.app"
                {...field}
                error={fieldState.error?.message}
              />
            )}
          />
        </Box>
        <Box mt={2}>
          <Controller
            control={form.control}
            name="appleId"
            render={({ field, fieldState }) => (
              <ScutiInput
                type="number"
                title="Apple Id"
                placeholder="02522554"
                {...field}
                error={fieldState.error?.message}
              />
            )}
          />
        </Box>
        <Box mt={2}>
          <Controller
            control={form.control}
            name="pcLink"
            render={({ field, fieldState }) => (
              <ScutiInput title="PC Link" placeholder="https://" {...field} error={fieldState.error?.message} />
            )}
          />
        </Box>
      </CardContent>
    </Card>
  );
}
