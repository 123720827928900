import { AdvertisementType, NewAdvertisementInput, UpdateAdvertisementInput } from 'types/__generated__/types';
import * as Yup from 'yup';

export const internalAdvertisementSchema = Yup.object().shape({
  id: Yup.string(),
  name: Yup.string().required('Field is required'),
  category: Yup.string().required('Field is required'),
  internalLabel: Yup.string().required('Field is required'),
  internalHtml5Url: Yup.string().url('Url is not correct').required('Field is required'),
  internalUnityUrl: Yup.string().url('Url is not correct').required('Field is required'),
});

export type InternalAdvertisementFormData = Yup.InferType<typeof internalAdvertisementSchema>;

export const internalAdvertisementSchemaFormDataDefaults = (): InternalAdvertisementFormData => ({
  name: '',
  category: '',
  internalLabel: '',
  internalHtml5Url: '',
  internalUnityUrl: '',
});

export const mapInternalAdvertisementToApiInput =
  (brandId: string) =>
  (formData: InternalAdvertisementFormData): UpdateAdvertisementInput | NewAdvertisementInput => {
    return {
      brandId,
      type: AdvertisementType.Internal,
      id: formData.id,
      name: formData.name,
      category: formData.category,
      cpa: 0,
      internal: {
        internalLabel: formData.internalLabel,
        internalHtml5Url: formData.internalHtml5Url,
        internalUnityUrl: formData.internalUnityUrl,
      },
    };
  };
