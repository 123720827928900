import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import { GamesTable } from 'components/GamesTable';
import { gamesRoutes } from 'routing';
import { Link } from 'react-router-dom';
import { Filter_Type, OrganizationType } from 'types/__generated__/types';
import { Loader } from 'components/Loader';
import { useGamesQuery } from 'graphql/__generated__/game.hooks';

interface Props {
  organizationId: string;
}

export const OrganizationGames: React.FC<Props> = observer(({ organizationId }) => {
  const { data, isLoading } = useGamesQuery({
    filters: [{ name: 'organizationId', operator: Filter_Type.Eq, value: [organizationId] }],
  });

  const games = data?.games.nodes || [];

  return (
    <Card>
      <CardHeader
        title={
          <Link className="btn btn-primary" to={`${gamesRoutes.GAME_NEW}?organizationId=${organizationId}`}>
            Add new Game
          </Link>
        }
      />
      <CardContent>
        {isLoading && <Loader />}
        {games.length ? (
          <GamesTable
            games={games.map((game) => {
              return {
                ...game,
                thumbnail: game.thumbnail || null,
                admins: game.admins.map(({ fullName }) => fullName || null),
              };
            })}
          />
        ) : (
          <Box pt={20} pb={20}>
            <Typography align="center" variant="h6">
              No created games
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
});
