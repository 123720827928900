import { useCallback } from 'react';
import { Button } from 'reactstrap';
import copy from 'copy-to-clipboard';
import { useToasts } from 'react-toast-notifications';
import { ScutiInput } from 'components/material/ScutiInput';
import { Box, Card, CardContent, CardHeader } from '@mui/material';

interface Props {
  gameId: string;
  gameToken?: string | null;
  generateToken: () => void;
}

export const GameIntegration: React.FC<Props> = ({ gameId, gameToken, generateToken }: Props) => {
  const { addToast } = useToasts();
  const copyToClipboard = useCallback(() => {
    if (gameToken) {
      copy(`${gameToken}`, { format: 'text/plain' });
      addToast('Secret Key copied to clipboard', { appearance: 'success', autoDismiss: true });
    }
  }, [addToast, gameToken]);

  return (
    <Card>
      <CardHeader title="Integration" />
      <CardContent>
        <Box>
          <label htmlFor={`id`}>Game ID</label>
          <ScutiInput value={gameId} disabled />
        </Box>
        <Box mt={1}>
          <label htmlFor={`secretKey`}>Secret Key</label>
          <ScutiInput key={`${gameToken}`} value={gameToken} disabled />
        </Box>
        <Box mt={1}>
          <Button
            type="button"
            color="outline-secondary"
            className="btn-block"
            onClick={copyToClipboard}
            disabled={!gameToken}
          >
            Copy Key
          </Button>
          <Button type="button" color="outline-secondary" className="btn-block" onClick={generateToken}>
            Regenerate Key
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};
