import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useAppStore } from 'store/app-store.hook';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { AnalyticsRange } from 'types/app-types';
import { OfferImpressionsGame } from 'components/material/analytics/OfferImpressionsGame';
import { GameStats } from './GameStats';
import { AnalyticsPeriodSelector } from 'components/AnalyticsPeriodSelector';

export const GameDashboardPage: React.FC = observer(() => {
  const { uiStore } = useAppStore();
  const [range, setRange] = useState<AnalyticsRange>({ from: moment().startOf('year'), to: moment().endOf('year') });

  return (
    <Box>
      <div className="topbar topbar--alt">
        <div className="col-filter-btns">
          <AnalyticsPeriodSelector range={range} onSelect={setRange} />
        </div>
      </div>
      <GameStats range={range} gameId={uiStore.selectedItem.id} />
      <Box mt={4}>
        <OfferImpressionsGame range={range} gameId={uiStore.selectedItem.id} />
      </Box>
    </Box>
  );
});
