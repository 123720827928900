import { deepOrange, lightBlue, yellow, indigo } from '@material-ui/core/colors';

type Palette = typeof deepOrange | typeof lightBlue | typeof yellow | typeof indigo;
type Shade = 50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;

const colorPalettes: Palette[] = [deepOrange, lightBlue, yellow, indigo];
const colorShade: Shade[] = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900];

const getRandomPalette = () => {
  const index = Math.floor(Math.random() * colorPalettes.length);

  return colorPalettes[index];
};

export const getRandomColor = () => {
  const index = Math.floor(Math.random() * colorShade.length);
  const key = colorShade[index];

  return getRandomPalette()[key];
};
