import React from 'react';
import { RegistrationForm } from '../../../components/auth/RegistrationForm';
import { observer } from 'mobx-react-lite';
import { useAppStore } from '../../../store/app-store.hook';
import { Redirect } from 'react-router';
import * as queryString from 'query-string';
import { appRoutes } from 'routing';

export const RegistrationPage: React.FC = observer(() => {
  const { authStore, routerStore } = useAppStore();
  const { error, cleanError } = authStore;
  const { search } = routerStore.location;
  const { invite } = queryString.parse(search);
  let email;

  React.useEffect(() => {
    cleanError();
  }, [cleanError]);

  if (invite) {
    authStore.saveLoginToken(invite as string);
    email = authStore.decodeToken(invite as string).email;
  }

  if (authStore.userInfo.email) {
    return <Redirect to={appRoutes.HOME} />;
  }

  const onRegister = invite ? authStore.inviteVerify : authStore.register;
  return (
    <div className="animated fadeIn">
      <RegistrationForm onRegister={onRegister} email={email} error={error} />
    </div>
  );
});
