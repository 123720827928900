import React from 'react';

interface Props {
  className?: string;
}

export const DashboardIcon: React.FC<Props> = ({ className = '' }) => {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        className={className}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.833496 0.166748H7.21127V9.47786H0.833496V0.166748ZM1.8335 1.16675V8.47786H6.21127V1.16675H1.8335ZM8.16683 0.166748H14.5446V5.56675H8.16683V0.166748ZM9.16683 1.16675V4.56675H13.5446V1.16675H9.16683ZM8.16683 6.5223H14.5446V15.8334H8.16683V6.5223ZM9.16683 7.5223V14.8334H13.5446V7.5223H9.16683ZM0.833496 10.4334H7.21127V15.8334H0.833496V10.4334ZM1.8335 11.4334V14.8334H6.21127V11.4334H1.8335Z"
      />
    </svg>
  );
};
