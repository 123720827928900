// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:400,400i,500,600,700,700i&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NoData_no_data__mRWFw {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.5;
  font-weight: bold;
}
.NoData_no_data__mRWFw.NoData_sm__BDiC6 {
  font-size: 0.875rem;
}
.NoData_no_data__mRWFw.NoData_md__YOwLl {
  font-size: 1rem;
}
.NoData_no_data__mRWFw.NoData_lg__2lNL3 {
  font-size: 1.5rem;
}
.NoData_no_data__mRWFw.NoData_xlg__Xncp4 {
  font-size: 1.75rem;
}`, "",{"version":3,"sources":["webpack://./src/components/common-components/NoData/NoData.module.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,iBAAA;AAAF;AAEE;EACE,mBAAA;AAAJ;AAGE;EACE,eAAA;AADJ;AAIE;EACE,iBAAA;AAFJ;AAKE;EACE,kBAAA;AAHJ","sourcesContent":["@import '@scss/_variables';\n\n.no_data {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  line-height: 1.5;\n  font-weight: bold;\n\n  &.sm {\n    font-size: rem(14);\n  }\n\n  &.md {\n    font-size: rem(16);\n  }\n\n  &.lg {\n    font-size: rem(24);\n  }\n\n  &.xlg {\n    font-size: rem(28);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"no_data": `NoData_no_data__mRWFw`,
	"sm": `NoData_sm__BDiC6`,
	"md": `NoData_md__YOwLl`,
	"lg": `NoData_lg__2lNL3`,
	"xlg": `NoData_xlg__Xncp4`
};
export default ___CSS_LOADER_EXPORT___;
