import React from 'react';
import { Card, CardBody } from 'reactstrap';

import { CheckEmailIcon } from 'components/svg-icons/CheckEmail';

export const CheckEmail = () => {
  return (
    <Card className="d-flex flex-row align-items-center mh-315 w-23">
      <CardBody>
        <div className="text-center">
          <div className="icon mb-med">
            <CheckEmailIcon className="icon" />
          </div>
          <div className="card-headline text-center">
            <h1>Check your inbox</h1>
            <p>We emailed instructions to reset your password.</p>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
