import React, { useEffect, useMemo } from 'react';
import { Form, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@material-ui/core';
import { ScutiInput } from 'components/material/ScutiInput';
import { Controller, useForm } from 'react-hook-form';
import { AdvertisementFragment } from 'graphql/__generated__/advertisement.hooks';
import { NewAdvertisementInput, UpdateAdvertisementInput } from 'types/__generated__/types';
import {
  LeadGenAdvertisementFormData,
  mapLeadGenAdvertisementToApiInput,
  leadGenAdvertisementSchema,
  leadGenAdvertisementSchemaFormDataDefaults,
} from './LeadGenAdvertisementEditForm.utils';
import { LeadGenAdvertisementAffiliate } from './LeadGenAdvertisementAffiliate';
import { LeadGenAdvertisementDetails } from './LeadGenAdvertisementDetails';

interface Props {
  linkBack: string;
  brandId: string;
  advertisement?: AdvertisementFragment;
  onSave: (advertisement: UpdateAdvertisementInput | NewAdvertisementInput) => void;
}

export const LeadGenAdvertisementEditForm: React.FC<Props> = ({ advertisement, brandId, linkBack, onSave }) => {
  const formDefaultValues = useMemo<LeadGenAdvertisementFormData>(() => {
    const defaults = leadGenAdvertisementSchemaFormDataDefaults();

    if (!advertisement) return defaults;

    return {
      ...defaults,
      id: advertisement.id,
      name: advertisement.name,
      affiliateUrl: advertisement.leadGen?.affiliateUrl || defaults.affiliateUrl,
      category: advertisement.category || defaults.category,
    };
  }, [advertisement]);

  const leadGenAdvertisementForm = useForm<LeadGenAdvertisementFormData>({
    resolver: yupResolver(leadGenAdvertisementSchema),
    defaultValues: formDefaultValues,
    mode: 'all',
  });

  useEffect(() => leadGenAdvertisementForm.reset(formDefaultValues), [formDefaultValues]);

  const handleSubmit = async (formData: LeadGenAdvertisementFormData) => {
    return onSave(mapLeadGenAdvertisementToApiInput(brandId)(formData));
  };

  const { isDirty, isSubmitting } = leadGenAdvertisementForm.formState;

  return (
    <Form className="w-100" onSubmit={leadGenAdvertisementForm.handleSubmit(handleSubmit)}>
      <div className="topbar d-sm-flex justify-content-sm-between">
        <div className="col-heading">
          <Link className="btn btn-back" to={linkBack}></Link>
          <Box ml={5} width={'50%'}>
            <Controller
              control={leadGenAdvertisementForm.control}
              name="name"
              render={({ field, fieldState }) => (
                <ScutiInput placeholder="Advertisement name" {...field} error={fieldState.error?.message} />
              )}
            />
          </Box>
        </div>
        <div className="col-action">
          <Button color="outline-secondary" onClick={() => leadGenAdvertisementForm.reset()} disabled={!isDirty}>
            Cancel
          </Button>
          <Button color="primary" type="submit" disabled={isSubmitting}>
            Save changes
          </Button>
        </div>
      </div>
      <Box pb={2}>
        <Grid container spacing={4}>
          <Grid item container spacing={4}>
            <Grid item xs={12} md={6} lg={4}>
              <LeadGenAdvertisementAffiliate form={leadGenAdvertisementForm} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <LeadGenAdvertisementDetails form={leadGenAdvertisementForm} />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Form>
  );
};
