import { Box, Card, CardHeader, CardContent } from '@material-ui/core';
import { ScutiInput } from 'components/material/ScutiInput';
import { Controller, UseFormReturn } from 'react-hook-form';
import { NoActionAdAdvertisementFormData } from './NoActionAdAdvertisementEditForm.utils';
import { ScutiSelect } from 'components/material/ScutiSelect';
import { useProductCategories } from 'hooks/useProductCategories';

interface Props {
  form: UseFormReturn<NoActionAdAdvertisementFormData>;
}

export function NoActionAdAdvertisementDetails({ form }: Props) {
  const productCategoriesOptions = useProductCategories();
  return (
    <Card>
      <CardHeader title="Details" />
      <CardContent>
        <Box mt={2}>
          <Controller
            control={form.control}
            name="label"
            render={({ field, fieldState }) => (
              <ScutiInput title="Label *" placeholder="Label" {...field} error={fieldState.error?.message} />
            )}
          />
        </Box>
        <Box mt={2}>
          <Controller
            control={form.control}
            name="category"
            render={({ field, fieldState }) => (
              <ScutiSelect
                title="Category *"
                placeholder="Select category"
                options={productCategoriesOptions}
                {...field}
                error={fieldState.error?.message}
              />
            )}
          />
        </Box>
      </CardContent>
    </Card>
  );
}
