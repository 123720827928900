export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** LocalDate (YYYY-MM-DD) */
  LocalDate: any;
  /** Arbitrary object */
  Object: any;
  /** Money as float with 2 digit after dot -> 123.23 */
  Money: any;
};

export type AccessInfo = {
  __typename?: 'AccessInfo';
  claims: Scalars['Object'];
  shops?: Maybe<Array<Maybe<ShopAccess>>>;
  games?: Maybe<Array<Maybe<ShopAccess>>>;
  organizations?: Maybe<Array<Maybe<OrganizationAccess>>>;
  roles: Array<Scalars['String']>;
};

export type Address = {
  __typename?: 'Address';
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  zipCode: Scalars['String'];
  country: Scalars['String'];
  state?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  zipCode: Scalars['String'];
  country: Scalars['String'];
  state?: Maybe<Scalars['String']>;
};

export type Advertisement = {
  __typename?: 'Advertisement';
  id: Scalars['ID'];
  name: Scalars['String'];
  brandId: Scalars['String'];
  category?: Maybe<Scalars['String']>;
  type: AdvertisementType;
  cpa?: Maybe<Scalars['Float']>;
  appDownload?: Maybe<AdvertisementAppDownload>;
  video?: Maybe<AdvertisementVideo>;
  leadGen?: Maybe<AdvertisementLeadGen>;
  coupon?: Maybe<AdvertisementCoupon>;
  noAction?: Maybe<AdvertisementNoAction>;
  internal?: Maybe<AdvertisementInternal>;
};

export type AdvertisementAppDownload = {
  __typename?: 'AdvertisementAppDownload';
  appleId?: Maybe<Scalars['Float']>;
  androidId?: Maybe<Scalars['String']>;
  pcLink?: Maybe<Scalars['String']>;
};

export type AdvertisementAppDownloadInput = {
  appleId?: Maybe<Scalars['Float']>;
  androidId?: Maybe<Scalars['String']>;
  pcLink?: Maybe<Scalars['String']>;
};

export type AdvertisementCoupon = {
  __typename?: 'AdvertisementCoupon';
  couponLabel?: Maybe<Scalars['String']>;
  couponDescription?: Maybe<Scalars['String']>;
  couponImages?: Maybe<Array<Scalars['String']>>;
};

export type AdvertisementCouponInput = {
  couponLabel?: Maybe<Scalars['String']>;
  couponDescription?: Maybe<Scalars['String']>;
  couponImages?: Maybe<Array<Scalars['String']>>;
};

export type AdvertisementInfo = {
  __typename?: 'AdvertisementInfo';
  id: Scalars['ID'];
  name: Scalars['String'];
  brandId: Scalars['String'];
  category?: Maybe<Scalars['String']>;
  type: AdvertisementType;
};

export type AdvertisementInternal = {
  __typename?: 'AdvertisementInternal';
  internalLabel?: Maybe<Scalars['String']>;
  internalHtml5Url?: Maybe<Scalars['String']>;
  internalUnityUrl?: Maybe<Scalars['String']>;
};

export type AdvertisementInternalInput = {
  internalLabel?: Maybe<Scalars['String']>;
  internalHtml5Url?: Maybe<Scalars['String']>;
  internalUnityUrl?: Maybe<Scalars['String']>;
};

export type AdvertisementLeadGen = {
  __typename?: 'AdvertisementLeadGen';
  affiliateUrl?: Maybe<Scalars['String']>;
};

export type AdvertisementLeadGenInput = {
  affiliateUrl?: Maybe<Scalars['String']>;
};

export type AdvertisementNoAction = {
  __typename?: 'AdvertisementNoAction';
  noActionLabel?: Maybe<Scalars['String']>;
};

export type AdvertisementNoActionInput = {
  noActionLabel?: Maybe<Scalars['String']>;
};

export type AdvertisementPage = Page & {
  __typename?: 'AdvertisementPage';
  sorting?: Maybe<Array<SortField>>;
  paging: Paging;
  nodes: Array<Advertisement>;
};

export enum AdvertisementType {
  AppDownload = 'APP_DOWNLOAD',
  VideoPromo = 'VIDEO_PROMO',
  LeadGen = 'LEAD_GEN',
  Coupon = 'COUPON',
  NoActionAd = 'NO_ACTION_AD',
  Internal = 'INTERNAL',
}

export type AdvertisementVideo = {
  __typename?: 'AdvertisementVideo';
  videoUrl?: Maybe<Scalars['String']>;
};

export type AdvertisementVideoInput = {
  videoUrl?: Maybe<Scalars['String']>;
};

export type BankAccount = {
  __typename?: 'BankAccount';
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  zipCode: Scalars['String'];
  country: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  organization: Organization;
  name: Scalars['String'];
  bankName: Scalars['String'];
  routingNumber: Scalars['String'];
  accountNumber: Scalars['String'];
  isDefault: Scalars['Boolean'];
  phone?: Maybe<Scalars['String']>;
  owner: UserInfo;
};

export type Banner = {
  __typename?: 'Banner';
  bigUrl?: Maybe<Scalars['String']>;
  tallUrl?: Maybe<Scalars['String']>;
  smallUrl?: Maybe<Scalars['String']>;
};

export type BillingAddress = {
  __typename?: 'BillingAddress';
  fullName: Scalars['String'];
  address1: Scalars['String'];
  address2: Scalars['String'];
  zipCode: Scalars['String'];
};

export type BillingAddressInput = {
  fullName: Scalars['String'];
  address1: Scalars['String'];
  address2: Scalars['String'];
  zipCode: Scalars['String'];
};

export type Brand = {
  __typename?: 'Brand';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  mediaInvestmentEnabled: Scalars['Boolean'];
  directResponse?: Maybe<MediaInvestment>;
  engagement?: Maybe<MediaInvestment>;
  video?: Maybe<MediaInvestment>;
  credentials: BrandCredentials;
};

export type BrandCredentials = {
  __typename?: 'BrandCredentials';
  login: Scalars['String'];
  pin: Scalars['String'];
};

export type BrandCredentialsInput = {
  login: Scalars['String'];
  pin: Scalars['String'];
};

export type BrandPage = Page & {
  __typename?: 'BrandPage';
  sorting?: Maybe<Array<SortField>>;
  paging: Paging;
  nodes: Array<Brand>;
};

export type Campaign = {
  __typename?: 'Campaign';
  id: Scalars['ID'];
  name: Scalars['String'];
  type: CampaignType;
  category?: Maybe<Scalars['String']>;
  contentCategory?: Maybe<ContentCategory>;
  status: CampaignStatus;
  durationStartDate?: Maybe<Scalars['DateTime']>;
  durationEndDate?: Maybe<Scalars['DateTime']>;
  shop?: Maybe<ShopInfo>;
  shopBrand?: Maybe<ShopBrand>;
  product?: Maybe<ProductInfo>;
  advertisement?: Maybe<AdvertisementInfo>;
  productListingId?: Maybe<Scalars['String']>;
  promotion?: Maybe<CampaignPromotion>;
  media?: Maybe<CampaignMedia>;
  demographic: CampaignDemographic;
  duration: CampaignDuration;
  budget?: Maybe<CampaignBudget>;
  game?: Maybe<CampaignGame>;
  location?: Maybe<CampaignLocation>;
  reward?: Maybe<CampaignReward>;
  statistics?: Maybe<CampaignStatistics>;
  extras: CampaignExtras;
  inStock: Scalars['Int'];
};

export type CampaignBudget = {
  __typename?: 'CampaignBudget';
  option?: Maybe<CampaignBudgetOption>;
  maxSpend: Scalars['Float'];
  maxDailySpend: Scalars['Float'];
  dailyFrequencyCap: Scalars['Int'];
  limitOfImpressions: Scalars['Int'];
  metric?: Maybe<Scalars['String']>;
};

export type CampaignBudgetInput = {
  option?: Maybe<CampaignBudgetOption>;
  maxSpend: Scalars['Float'];
  maxDailySpend: Scalars['Float'];
  limitOfImpressions: Scalars['Int'];
  metricType: Scalars['String'];
};

export enum CampaignBudgetOption {
  Normal = 'NORMAL',
  Featured = 'FEATURED',
  InterstitialOnly = 'INTERSTITIAL_ONLY',
}

export type CampaignDemographic = {
  __typename?: 'CampaignDemographic';
  gender?: Maybe<Scalars['String']>;
  minAge: Scalars['Int'];
  maxAge: Scalars['Int'];
};

export type CampaignDemographicInput = {
  gender?: Maybe<Scalars['String']>;
  minAge: Scalars['Int'];
  maxAge: Scalars['Int'];
};

export type CampaignDuration = {
  __typename?: 'CampaignDuration';
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  inventorySpecific?: Maybe<Scalars['Boolean']>;
  inventory?: Maybe<Scalars['Int']>;
  runUntilStockZero?: Maybe<Scalars['Boolean']>;
};

export type CampaignDurationInput = {
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  inventorySpecific?: Maybe<Scalars['Boolean']>;
  inventory?: Maybe<Scalars['Int']>;
  runUntilStockZero?: Maybe<Scalars['Boolean']>;
};

export type CampaignExtras = {
  __typename?: 'CampaignExtras';
  moreExposure?: Maybe<Scalars['Boolean']>;
  interstitials?: Maybe<Scalars['Boolean']>;
  isTodayDeal?: Maybe<Scalars['Boolean']>;
};

export type CampaignExtrasInput = {
  moreExposure?: Maybe<Scalars['Boolean']>;
  isTodayDeal?: Maybe<Scalars['Boolean']>;
  interstitials?: Maybe<Scalars['Boolean']>;
};

export type CampaignGame = {
  __typename?: 'CampaignGame';
  aiTargetRun?: Maybe<Scalars['Boolean']>;
  contentCategory?: Maybe<ContentCategory>;
};

export type CampaignGameInput = {
  aiTargetRun?: Maybe<Scalars['Boolean']>;
  contentCategory?: Maybe<ContentCategory>;
};

export type CampaignInfo = {
  __typename?: 'CampaignInfo';
  id: Scalars['ID'];
  name: Scalars['String'];
  type: CampaignType;
  category?: Maybe<Scalars['String']>;
  contentCategory?: Maybe<ContentCategory>;
  status: CampaignStatus;
  durationStartDate?: Maybe<Scalars['DateTime']>;
  durationEndDate?: Maybe<Scalars['DateTime']>;
};

export type CampaignLocation = {
  __typename?: 'CampaignLocation';
  country?: Maybe<Scalars['String']>;
  states?: Maybe<Array<Scalars['String']>>;
};

export type CampaignLocationInput = {
  country?: Maybe<Scalars['String']>;
  states?: Maybe<Array<Scalars['String']>>;
};

export type CampaignMedia = {
  __typename?: 'CampaignMedia';
  banner?: Maybe<Scalars['String']>;
  vertical?: Maybe<Scalars['String']>;
  tile?: Maybe<Scalars['String']>;
};

export type CampaignMediaInput = {
  banner?: Maybe<Scalars['String']>;
  vertical?: Maybe<Scalars['String']>;
  tile?: Maybe<Scalars['String']>;
};

export type CampaignPage = Page & {
  __typename?: 'CampaignPage';
  sorting?: Maybe<Array<SortField>>;
  paging: Paging;
  nodes: Array<Campaign>;
};

export type CampaignPromotion = {
  __typename?: 'CampaignPromotion';
  promotionType?: Maybe<PromotionType>;
  offerType?: Maybe<OfferType>;
  offerCopy?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Float']>;
};

export type CampaignPromotionInput = {
  promotionType?: Maybe<PromotionType>;
  offerType?: Maybe<OfferType>;
  offerCopy?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Float']>;
};

export type CampaignReward = {
  __typename?: 'CampaignReward';
  scutiPercentage: Scalars['Float'];
};

export type CampaignRewardInput = {
  scutiPercentage?: Maybe<Scalars['Float']>;
};

export type CampaignStatistics = {
  __typename?: 'CampaignStatistics';
  totalSpend: Scalars['Float'];
  totalRevenue: Scalars['Float'];
  roi: Scalars['Float'];
};

export enum CampaignStatus {
  Pending = 'PENDING',
  Paused = 'PAUSED',
  Active = 'ACTIVE',
  Completed = 'COMPLETED',
  Deleted = 'DELETED',
}

export enum CampaignType {
  SingleItem = 'SINGLE_ITEM',
  ShopBrand = 'SHOP_BRAND',
  ShopAdvertisement = 'SHOP_ADVERTISEMENT',
  Organization = 'ORGANIZATION',
  ProductListing = 'PRODUCT_LISTING',
  Column6Ad = 'COLUMN6_AD',
  Product = 'PRODUCT',
  Video = 'VIDEO',
  Banner = 'BANNER',
  Takeover = 'TAKEOVER',
  InternalUrl = 'INTERNAL_URL',
  AppDownload = 'APP_DOWNLOAD',
}

export type CartDataInput = {
  items: Array<CartItemInput>;
};

export type CartInput = {
  gameId: Scalars['String'];
  items: Array<CheckoutItemInput>;
  shippingInfo: ShippingInformationInput;
  scutisToApply?: Maybe<Scalars['Int']>;
  promoCodes: Array<PromoCodesInput>;
};

export type CartItem = {
  __typename?: 'CartItem';
  variantId: Scalars['String'];
  offerId: Scalars['String'];
  quantity: Scalars['Int'];
};

export type CartItemInput = {
  cartId?: Maybe<Scalars['String']>;
  variantId: Scalars['String'];
  offerId: Scalars['String'];
  quantity: Scalars['Int'];
};

export type CartOutput = {
  __typename?: 'CartOutput';
  products: Array<CartOutputProduct>;
};

export type CartOutputProduct = {
  __typename?: 'CartOutputProduct';
  productId: Scalars['String'];
  productName: Scalars['String'];
  productDescription?: Maybe<Scalars['String']>;
  option1?: Maybe<Scalars['String']>;
  option2?: Maybe<Scalars['String']>;
  option3?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Scalars['String']>>;
  variant: CartOutputVariant;
  reward: CartReward;
};

export type CartOutputVariant = {
  __typename?: 'CartOutputVariant';
  offerId: Scalars['String'];
  variantId: Scalars['String'];
  price: Scalars['Money'];
  option1?: Maybe<Scalars['String']>;
  option2?: Maybe<Scalars['String']>;
  option3?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  inStock: Scalars['Int'];
  image?: Maybe<Scalars['String']>;
};

export type CartReward = {
  __typename?: 'CartReward';
  scutis?: Maybe<Scalars['Int']>;
};

export type CheckoutEncryption = {
  __typename?: 'CheckoutEncryption';
  keyId: Scalars['String'];
  publicKey: Scalars['String'];
};

export type CheckoutInput = {
  gameId: Scalars['String'];
  items: Array<CheckoutItemInput>;
  shippingInfo: ShippingInformationInput;
  scutisToApply?: Maybe<Scalars['Int']>;
  promoCodes: Array<PromoCodesInput>;
  source: PaymentSource;
};

export type CheckoutItemInput = {
  variantId: Scalars['String'];
  offerId: Scalars['String'];
  quantity: Scalars['Int'];
};

export type ClaimsInfo = {
  __typename?: 'ClaimsInfo';
  claims: Scalars['Object'];
};

export enum ContentCategory {
  RatingPending = 'RATING_PENDING',
  EarlyChildhood = 'EARLY_CHILDHOOD',
  Everyone = 'EVERYONE',
  Everyone_10Plus = 'EVERYONE_10_PLUS',
  Teen = 'TEEN',
  AdultsOnly = 'ADULTS_ONLY',
  Mature = 'MATURE',
}

export type CreateBankAccountInput = {
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  zipCode: Scalars['String'];
  country: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  bankName: Scalars['String'];
  routingNumber: Scalars['String'];
  accountNumber: Scalars['String'];
  isDefault: Scalars['Boolean'];
  organizationId: Scalars['String'];
};

export type CreateBrandInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  mediaInvestmentEnabled?: Maybe<Scalars['Boolean']>;
  directResponse?: Maybe<MediaInvestmentInput>;
  engagement?: Maybe<MediaInvestmentInput>;
  video?: Maybe<MediaInvestmentInput>;
  credentials: BrandCredentialsInput;
  organizationId: Scalars['String'];
};

export type CreditCard = {
  expiryMonth: Scalars['Int'];
  expiryYear: Scalars['Int'];
  name: Scalars['String'];
  encrypted: EncryptedInput;
  billingAddress: AddressInput;
};

export type Currency = {
  __typename?: 'Currency';
  name: Scalars['String'];
  thumbnail?: Maybe<Scalars['String']>;
  /** How much vbucks do I get for 1 dollar? */
  exchangeRate: Scalars['Float'];
  /** How much vbucks do I get for 1 scuti? */
  scutiExchangeRate: Scalars['Float'];
};

export type CurrencyInput = {
  name: Scalars['String'];
  thumbnail?: Maybe<Scalars['String']>;
  /** Exchange rate for US dollar */
  exchangeRate: Scalars['Float'];
};

export type EditShopBrandsInput = {
  id: Scalars['ID'];
  brands: Array<ShopBrandInput>;
};

export type EncryptedInput = {
  encryptedData: Scalars['String'];
  keyId: Scalars['String'];
};

export type EntityRequest = {
  __typename?: 'EntityRequest';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  requesterId: Scalars['String'];
  requesterType: Scalars['String'];
  entityId: Scalars['String'];
  entityType: Scalars['String'];
  actionName: Scalars['String'];
  params: EntityRequestParams;
};

export type EntityRequestInput = {
  entityType: RequestEntityType;
  entityId: Scalars['String'];
  actionName: RequestActionName;
  requesterId: Scalars['String'];
  requesterType: RequesterType;
  params: EntityRequestParamsInput;
};

export type EntityRequestPage = Page & {
  __typename?: 'EntityRequestPage';
  sorting?: Maybe<Array<SortField>>;
  paging: Paging;
  nodes: Array<EntityRequest>;
};

export type EntityRequestParams = {
  __typename?: 'EntityRequestParams';
  controlLevel: Scalars['String'];
  referralPercentage?: Maybe<Scalars['Float']>;
  directResponse?: Maybe<MediaInvestment>;
  engagement?: Maybe<MediaInvestment>;
  video?: Maybe<MediaInvestment>;
};

export type EntityRequestParamsInput = {
  controlLevel: Scalars['String'];
  referralPercentage?: Maybe<Scalars['Float']>;
  directResponse?: Maybe<MediaInvestmentInput>;
  engagement?: Maybe<MediaInvestmentInput>;
  video?: Maybe<MediaInvestmentInput>;
};

export enum EntityType {
  Shop = 'SHOP',
  Game = 'GAME',
  Brand = 'BRAND',
  Organization = 'ORGANIZATION',
}

export enum Filter_Type {
  Ieq = 'IEQ',
  Eq = 'EQ',
  Like = 'LIKE',
  Contains = 'CONTAINS',
  Gt = 'GT',
  Lt = 'LT',
  GtEq = 'GT_EQ',
  LtEq = 'LT_EQ',
  In = 'IN',
  NotIn = 'NOT_IN',
  Between = 'BETWEEN',
  Includes = 'INCLUDES',
  IsNull = 'IS_NULL',
  IsNotNull = 'IS_NOT_NULL',
}

export type FilterInput = {
  name: Scalars['String'];
  operator: Filter_Type;
  value?: Maybe<Array<Scalars['String']>>;
  allowNulls?: Maybe<Scalars['Boolean']>;
};

export type FulfillmentMethod = {
  __typename?: 'FulfillmentMethod';
  id: Scalars['ID'];
  shop: Shop;
  name: Scalars['String'];
  type: FulfillmentType;
};

export type FulfillmentMethodInput = {
  shopId: Scalars['String'];
  name: Scalars['String'];
  type: FulfillmentType;
};

export enum FulfillmentType {
  Shipping = 'SHIPPING',
  Pickup = 'PICKUP',
  Digital = 'DIGITAL',
}

export type Game = {
  __typename?: 'Game';
  id: Scalars['ID'];
  name: Scalars['String'];
  category: Scalars['String'];
  tags: Array<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  banner?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
  contentCategory: ContentCategory;
  owner: UserInfo;
  admins: Array<UserWithRoles>;
  ageFrom: Scalars['Int'];
  ageTo: Scalars['Int'];
  genderPercent?: Maybe<GenderPercent>;
  whitelistedShops?: Maybe<Array<ShopWhitelist>>;
};

export type GameInfo = {
  __typename?: 'GameInfo';
  id: Scalars['ID'];
  name: Scalars['String'];
  category: Scalars['String'];
  tags: Array<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  banner?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
  contentCategory: ContentCategory;
};

export type GameInput = {
  name: Scalars['String'];
  category: Scalars['String'];
  tags?: Maybe<Array<Scalars['String']>>;
  thumbnail?: Maybe<Scalars['String']>;
  banner?: Maybe<Scalars['String']>;
  currency?: Maybe<CurrencyInput>;
  ageFrom: Scalars['Int'];
  ageTo: Scalars['Int'];
  contentCategory: ContentCategory;
  genderPercent?: Maybe<GenderPercentInput>;
  organizationId?: Maybe<Scalars['String']>;
};

export type GamePage = Page & {
  __typename?: 'GamePage';
  sorting?: Maybe<Array<SortField>>;
  paging: Paging;
  nodes: Array<Game>;
};

export type GenderPercent = {
  __typename?: 'GenderPercent';
  male: Scalars['Float'];
  female: Scalars['Float'];
};

export type GenderPercentInput = {
  male: Scalars['Float'];
  female: Scalars['Float'];
};

export type Invite = {
  __typename?: 'Invite';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  email: Scalars['String'];
  roles: Array<Role>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<Scalars['String']>;
  accepted: Scalars['Boolean'];
  hide: Scalars['Boolean'];
};

export type InviteInput = {
  email: Scalars['String'];
  roles: Array<Role>;
  twoFactorEnabled: Scalars['Boolean'];
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<EntityType>;
};

export type InvitePage = Page & {
  __typename?: 'InvitePage';
  sorting?: Maybe<Array<SortField>>;
  paging: Paging;
  nodes: Array<Invite>;
};

export type MediaInvestment = {
  __typename?: 'MediaInvestment';
  unit: Scalars['String'];
  amount: Scalars['Float'];
};

export type MediaInvestmentInput = {
  unit: Scalars['String'];
  amount: Scalars['Float'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** [SCUTI_USER] - update my personal information */
  updateMyPersonalInfo: PersonalInfo;
  /** [SCUTI_USER] - sets my welcome tour completed */
  setWelcomeTourCompleted: User;
  /** [SCUTI_USER] - update my preferences */
  updateMyPreferences: Preferences;
  /** [SCUTI_USER] - update my shipping information */
  updateMyShippingInfo: ShippingInfo;
  /** [SCUTI_USER] - exchange my scutis */
  exchange: ScutiExchange;
  /** [SHOP_OWNER,SCUTI_ADMIN,ORGANIZATION_ADMIN,ORGANIZATION_OWNER] - create shop */
  createShop: Shop;
  /** [SHOP_OWNER] - delete shop by id */
  deleteShop?: Maybe<Scalars['String']>;
  /** [SHOP_OWNER,SCUTI_ADMIN,SHOP_ADMIN,ORGANIZATION_ADMIN,ORGANIZATION_OWNER] - update shop */
  updateShop?: Maybe<Shop>;
  /** [SHOP_OWNER,SCUTI_ADMIN,SHOP_ADMIN,ORGANIZATION_ADMIN,ORGANIZATION_OWNER] - edit shop brands */
  editShopBrands: Array<ShopBrand>;
  /** [SHOP_OWNER,SCUTI_ADMIN,SHOP_ADMIN,ORGANIZATION_ADMIN,ORGANIZATION_OWNER] - sync shop with eBridge data */
  syncEbridge?: Maybe<SyncEbridgeResult>;
  inviteToShop: Array<Role>;
  removeFromShop: Scalars['Boolean'];
  /** [SCUTI_ADMIN,SHOP_ADMIN,SHOP_OWNER] - create product */
  createProduct: Product;
  /** [SCUTI_ADMIN,SHOP_ADMIN,SHOP_OWNER] - update product */
  updateProduct: Product;
  /** [SCUTI_ADMIN,SHOP_ADMIN,SHOP_OWNER] - delete product by id */
  deleteProduct?: Maybe<Scalars['String']>;
  /** [SCUTI_ADMIN,GAME_OWNER,ORGANIZATION_ADMIN,ORGANIZATION_OWNER] - create/generate game token */
  createGameToken: Token;
  /** [SCUTI_ADMIN,GAME_OWNER] - delete game token(s) by game id */
  deleteGameToken: Scalars['String'];
  /** [SCUTI_ADMIN,GAME_OWNER,ORGANIZATION_ADMIN,ORGANIZATION_OWNER] - create game for organization */
  createGame: Game;
  /** [SCUTI_ADMIN,GAME_OWNER,GAME_ADMIN,ORGANIZATION_ADMIN,ORGANIZATION_OWNER] - update game */
  updateGame: Game;
  /** [SCUTI_ADMIN,GAME_OWNER] - delete game by id */
  deleteGame?: Maybe<Scalars['String']>;
  inviteToGame: Array<Role>;
  removeFromGame: Scalars['Boolean'];
  whitelistShops: Array<ShopWhitelist>;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - create new campaign */
  createCampaign: Campaign;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - update campaign by id */
  updateCampaign: Campaign;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - delete campaign by id */
  deleteCampaign?: Maybe<Scalars['String']>;
  /** [SCUTI_ADMIN] - set today deals by campaign ids */
  setTodayDeals: Array<Campaign>;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - toggle campaign status (pause/unpause) by id */
  toggleCampaign: Campaign;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - create new advertisement */
  createAdvertisement: Advertisement;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - update advertisement by id */
  updateAdvertisement: Advertisement;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - delete advertisement by id */
  deleteAdvertisement?: Maybe<Scalars['String']>;
  /** [SCUTI_ADMIN,SHOP_OWNER] - create new bank account for organization */
  createBankAccount: BankAccount;
  /** [SCUTI_ADMIN,SHOP_OWNER] - update bank account for organization */
  updateBankAccount: BankAccount;
  /** [SCUTI_ADMIN,SHOP_OWNER] - delete bank account for organization */
  deleteBankAccount?: Maybe<Scalars['String']>;
  /** @deprecated subject to change */
  createFulfillmentMethod: FulfillmentMethod;
  /** @deprecated subject to change */
  updateFulfillmentMethod: FulfillmentMethod;
  /** @deprecated subject to change */
  deleteFulfillmentMethod?: Maybe<Scalars['String']>;
  createPaymentMethod: PaymentMethod;
  updatePaymentMethod: PaymentMethod;
  deletePaymentMethod?: Maybe<Scalars['String']>;
  /** [SCUTI_USER] - activate/claim my rewards */
  activateRewards: Array<Reward>;
  /** [ORGANIZATION_OWNER,SCUTI_ADMIN] - create organization/business details */
  createOrganization: Organization;
  /** [ORGANIZATION_OWNER,SCUTI_ADMIN] - update organization/business details */
  updateOrganization: Organization;
  inviteToOrganization: Array<Role>;
  removeFromOrganization: Scalars['Boolean'];
  /**
   * [SCUTI_ADMIN,ORGANIZATION_ADMIN,ORGANIZATION_OWNER,SHOP_OWNER,GAME_OWNER] -
   * pass organization ownership. Also pass ownership for organization shops and games.
   */
  setOwner: UserInfo;
  /** [SCUTI_USER,SCUTI_ADMIN,SHOP_ADMIN,GAME_ADMIN,SHOP_OWNER,GAME_OWNER,ORGANIZATION_OWNER,ORGANIZATION_ADMIN] - accept invite */
  acceptInvite?: Maybe<Invite>;
  invite: Invite;
  resendInviteMail: Scalars['Boolean'];
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - set order status by order id */
  setOrderStatus: OrderHistory;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - update order  by order id */
  updateOrder: Order;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - set  vendor order id   by order id */
  setVendorOrderID: Order;
  /** [SCUTI_USER] - checkout shopping cart */
  checkout: Receipt;
  /** [SCUTI_USER] - calculate cart */
  calculateCart: Receipt;
  /** [SCUTI_USER] - create credit card */
  createOrReplaceMyCard: UserCard;
  /** [SCUTI_USER] - delete my cards by card ids */
  deleteMyCards: Array<UserCard>;
  /** [SCUTI_USER] - set my default card by id */
  setMyDefaultCard: UserCard;
  /** [SCUTI_USER] - stripe checkout shopping cart */
  stripeCheckout?: Maybe<StripePaymentIntent>;
  /** [SCUTI_USER] - validate cart promo codes */
  validateCodes: PromoCodes;
  /** [SHOP_OWNER,SCUTI_ADMIN,SHOP_ADMIN,GAME_OWNER,GAME_ADMIN,ORGANIZATION_ADMIN,ORGANIZATION_OWNER] - create entity request */
  createEntityRequest: EntityRequest;
  /** [SCUTI_USER,SCUTI_ADMIN,SHOP_ADMIN,GAME_ADMIN,SHOP_OWNER,GAME_OWNER,ORGANIZATION_OWNER,ORGANIZATION_ADMIN] - accept entity request */
  acceptRequest?: Maybe<EntityRequest>;
  /** [SCUTI_USER,SCUTI_ADMIN,SHOP_ADMIN,GAME_ADMIN,SHOP_OWNER,GAME_OWNER,ORGANIZATION_OWNER,ORGANIZATION_ADMIN] - hide entity request */
  hideRequest?: Maybe<EntityRequest>;
  /** [SCUTI_ADMIN,ORGANIZATION_ADMIN,ORGANIZATION_OWNER] - create brand */
  createBrand: Brand;
  inviteToBrand: Array<Role>;
  /** [SCUTI_ADMIN,ORGANIZATION_ADMIN,ORGANIZATION_OWNER] - update brand */
  updateBrand: Brand;
  /** [SCUTI_ADMIN,ORGANIZATION_ADMIN,ORGANIZATION_OWNER] - delete brand */
  deleteBrand?: Maybe<Scalars['String']>;
  /** [SCUTI_USER?] - update cart */
  updateCart: CartOutput;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN?] -  create or update  refund order   */
  produceRefundOrder: RefundOrder;
};

export type MutationUpdateMyPersonalInfoArgs = {
  input: PersonalInformationInput;
};

export type MutationUpdateMyPreferencesArgs = {
  input: PreferencesInput;
};

export type MutationUpdateMyShippingInfoArgs = {
  input: ShippingInformationInput;
};

export type MutationExchangeArgs = {
  gameId: Scalars['String'];
  amount: Scalars['Int'];
  gameUserId: Scalars['String'];
};

export type MutationCreateShopArgs = {
  input: ShopInput;
};

export type MutationDeleteShopArgs = {
  id: Scalars['String'];
};

export type MutationUpdateShopArgs = {
  input: UpdateShopInput;
};

export type MutationEditShopBrandsArgs = {
  input: EditShopBrandsInput;
};

export type MutationSyncEbridgeArgs = {
  input: SyncEbridgeInput;
};

export type MutationInviteToShopArgs = {
  id: Scalars['String'];
  user: UserRoleInput;
};

export type MutationRemoveFromShopArgs = {
  shopId: Scalars['String'];
  email: Scalars['String'];
};

export type MutationCreateProductArgs = {
  input: ProductInput;
};

export type MutationUpdateProductArgs = {
  input: UpdateProductInput;
};

export type MutationDeleteProductArgs = {
  id: Scalars['String'];
};

export type MutationCreateGameTokenArgs = {
  id: Scalars['String'];
};

export type MutationDeleteGameTokenArgs = {
  id: Scalars['String'];
};

export type MutationCreateGameArgs = {
  input: GameInput;
};

export type MutationUpdateGameArgs = {
  input: UpdateGameInput;
};

export type MutationDeleteGameArgs = {
  id: Scalars['String'];
};

export type MutationInviteToGameArgs = {
  id: Scalars['String'];
  user: UserRoleInput;
};

export type MutationRemoveFromGameArgs = {
  gameId: Scalars['String'];
  email: Scalars['String'];
};

export type MutationWhitelistShopsArgs = {
  id: Scalars['String'];
  ids: Array<Scalars['String']>;
};

export type MutationCreateCampaignArgs = {
  input: NewCampaignInput;
};

export type MutationUpdateCampaignArgs = {
  input: UpdateCampaignInput;
};

export type MutationDeleteCampaignArgs = {
  id: Scalars['String'];
};

export type MutationSetTodayDealsArgs = {
  shopId: Scalars['String'];
  ids: Array<Scalars['String']>;
};

export type MutationToggleCampaignArgs = {
  input: ToggleCampaignInput;
};

export type MutationCreateAdvertisementArgs = {
  input: NewAdvertisementInput;
};

export type MutationUpdateAdvertisementArgs = {
  input: UpdateAdvertisementInput;
};

export type MutationDeleteAdvertisementArgs = {
  id: Scalars['String'];
};

export type MutationCreateBankAccountArgs = {
  input: CreateBankAccountInput;
};

export type MutationUpdateBankAccountArgs = {
  input: UpdateBankAccountInput;
};

export type MutationDeleteBankAccountArgs = {
  organizationId: Scalars['String'];
  id: Scalars['String'];
};

export type MutationCreateFulfillmentMethodArgs = {
  input: FulfillmentMethodInput;
};

export type MutationUpdateFulfillmentMethodArgs = {
  input: UpdateFulfillmentMethodInput;
};

export type MutationDeleteFulfillmentMethodArgs = {
  id: Scalars['String'];
};

export type MutationCreatePaymentMethodArgs = {
  input: PaymentMethodInput;
};

export type MutationUpdatePaymentMethodArgs = {
  input: UpdatePaymentMethodInput;
};

export type MutationDeletePaymentMethodArgs = {
  id: Scalars['String'];
};

export type MutationActivateRewardsArgs = {
  rewardId: Array<Scalars['String']>;
};

export type MutationCreateOrganizationArgs = {
  input: OrganizationInput;
};

export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput;
};

export type MutationInviteToOrganizationArgs = {
  id: Scalars['String'];
  user: UserRoleInput;
};

export type MutationRemoveFromOrganizationArgs = {
  organizationId: Scalars['String'];
  email: Scalars['String'];
};

export type MutationSetOwnerArgs = {
  organizationId: Scalars['String'];
  userEmail: Scalars['String'];
};

export type MutationAcceptInviteArgs = {
  inviteId: Scalars['String'];
};

export type MutationInviteArgs = {
  input: InviteInput;
};

export type MutationResendInviteMailArgs = {
  email: Scalars['String'];
};

export type MutationSetOrderStatusArgs = {
  id: Scalars['String'];
  status: OrderStatus;
};

export type MutationUpdateOrderArgs = {
  id: Scalars['String'];
  input: UpdateOrderInput;
};

export type MutationSetVendorOrderIdArgs = {
  id: Scalars['String'];
  vendorOrderId: Scalars['String'];
};

export type MutationCheckoutArgs = {
  input: CheckoutInput;
};

export type MutationCalculateCartArgs = {
  input: CartInput;
};

export type MutationCreateOrReplaceMyCardArgs = {
  input: CreditCard;
};

export type MutationDeleteMyCardsArgs = {
  cardIds: Array<Scalars['String']>;
};

export type MutationSetMyDefaultCardArgs = {
  id: Scalars['String'];
};

export type MutationStripeCheckoutArgs = {
  input: StripeCheckoutInput;
};

export type MutationValidateCodesArgs = {
  newCode: Scalars['String'];
  activeCodes: Array<PromoCodesInput>;
};

export type MutationCreateEntityRequestArgs = {
  input: EntityRequestInput;
};

export type MutationAcceptRequestArgs = {
  id: Scalars['String'];
};

export type MutationHideRequestArgs = {
  id: Scalars['String'];
};

export type MutationCreateBrandArgs = {
  input: CreateBrandInput;
};

export type MutationInviteToBrandArgs = {
  id: Scalars['String'];
  user: UserRoleInput;
};

export type MutationUpdateBrandArgs = {
  input: UpdateBrandInput;
};

export type MutationDeleteBrandArgs = {
  id: Scalars['String'];
};

export type MutationUpdateCartArgs = {
  input: CartDataInput;
};

export type MutationProduceRefundOrderArgs = {
  input: RefundOrderInput;
};

export type NewAdvertisementInput = {
  name?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  cpa?: Maybe<Scalars['Float']>;
  appDownload?: Maybe<AdvertisementAppDownloadInput>;
  video?: Maybe<AdvertisementVideoInput>;
  leadGen?: Maybe<AdvertisementLeadGenInput>;
  coupon?: Maybe<AdvertisementCouponInput>;
  noAction?: Maybe<AdvertisementNoActionInput>;
  internal?: Maybe<AdvertisementInternalInput>;
  type: AdvertisementType;
  brandId: Scalars['String'];
};

export type NewCampaignInput = {
  name: Scalars['String'];
  category?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['ID']>;
  advertisementId?: Maybe<Scalars['ID']>;
  shopBrandId?: Maybe<Scalars['ID']>;
  productListingId?: Maybe<Scalars['String']>;
  duration: CampaignDurationInput;
  demographic: CampaignDemographicInput;
  location?: Maybe<CampaignLocationInput>;
  promotion?: Maybe<CampaignPromotionInput>;
  media?: Maybe<CampaignMediaInput>;
  budget?: Maybe<CampaignBudgetInput>;
  game?: Maybe<CampaignGameInput>;
  reward?: Maybe<CampaignRewardInput>;
  extras: CampaignExtrasInput;
  type: CampaignType;
  shopId: Scalars['String'];
};

export type Offer = {
  __typename?: 'Offer';
  id: Scalars['ID'];
  type: Scalars['String'];
  category?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  shop?: Maybe<ShopInfo>;
  product?: Maybe<OfferProduct>;
  brand?: Maybe<ShopBrand>;
  promotions: OfferPromotions;
  media: OfferMedia;
  review?: Maybe<OfferReview>;
  reward?: Maybe<OfferReward>;
  createdAt: Scalars['DateTime'];
  internalUrl?: Maybe<Scalars['String']>;
  appleId?: Maybe<Scalars['Float']>;
  androidId?: Maybe<Scalars['String']>;
  pcLink?: Maybe<Scalars['String']>;
};

export type OfferCategoryCount = {
  __typename?: 'OfferCategoryCount';
  category: Scalars['String'];
  count: Scalars['Float'];
};

export type OfferMedia = {
  __typename?: 'OfferMedia';
  banner?: Maybe<Banner>;
  tile?: Maybe<Scalars['String']>;
  vertical?: Maybe<Scalars['String']>;
  videoUrl?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Scalars['String']>>;
};

export type OfferPage = Page & {
  __typename?: 'OfferPage';
  sorting?: Maybe<Array<SortField>>;
  paging: Paging;
  nodes: Array<Offer>;
  seed?: Maybe<Scalars['Int']>;
};

export type OfferProduct = {
  __typename?: 'OfferProduct';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  category: Scalars['String'];
  tags?: Maybe<Array<Scalars['String']>>;
  images?: Maybe<Array<Scalars['String']>>;
  chargeTaxes: Scalars['Boolean'];
  scutisExclusive?: Maybe<Scalars['Boolean']>;
  shop: ShopInfo;
  variants: Array<ProductVariant>;
  price: Price;
  option1?: Maybe<Scalars['String']>;
  option2?: Maybe<Scalars['String']>;
  option3?: Maybe<Scalars['String']>;
};

export type OfferPromotions = {
  __typename?: 'OfferPromotions';
  isNew: Scalars['Boolean'];
  isLatest: Scalars['Boolean'];
  isMisc: Scalars['Boolean'];
  isHotPrice: Scalars['Boolean'];
  isHotItem: Scalars['Boolean'];
  isRecommended: Scalars['Boolean'];
  isBestSeller: Scalars['Boolean'];
  isSpecialOffer: Scalars['Boolean'];
  isFeatured: Scalars['Boolean'];
  isMoreExposure: Scalars['Boolean'];
  isTodayDeal: Scalars['Boolean'];
  isScuti: Scalars['Boolean'];
  specialOfferType?: Maybe<OfferType>;
  specialOfferText?: Maybe<Scalars['String']>;
};

export type OfferReview = {
  __typename?: 'OfferReview';
  score: Scalars['Float'];
  count: Scalars['Int'];
};

export type OfferReward = {
  __typename?: 'OfferReward';
  scutis?: Maybe<Scalars['Float']>;
  scutiPercentage?: Maybe<Scalars['Float']>;
};

export type OffersByCategories = {
  __typename?: 'OffersByCategories';
  nodes: Array<OffersWithinCategory>;
};

export type OfferStatistics = {
  __typename?: 'OfferStatistics';
  byCategories: Array<OfferCategoryCount>;
  total: Scalars['Float'];
  newOffers: Scalars['Float'];
};

export type OffersWithinCategory = {
  __typename?: 'OffersWithinCategory';
  category: Scalars['String'];
  nodes: Array<Offer>;
};

export enum OfferType {
  FreeShipping = 'FREE_SHIPPING',
  BuyOneGetOne = 'BUY_ONE_GET_ONE',
  GiftWithPurchase = 'GIFT_WITH_PURCHASE',
  FreeSamples = 'FREE_SAMPLES',
  LimitedTimeOffer = 'LIMITED_TIME_OFFER',
  TripWire = 'TRIP_WIRE',
}

export type Order = {
  __typename?: 'Order';
  id: Scalars['ID'];
  ebridgeOrderId: Scalars['String'];
  vendorOrderId?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  trackingNumber?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  user: UserInfo;
  game: GameInfo;
  shop: ShopInfo;
  amount: Scalars['Float'];
  shippingInformation?: Maybe<OrderShippingInformationSnapshot>;
  items: Array<OrderItem>;
  status: OrderStatus;
  payoutStatus: PayoutStatus;
};

export type OrderCampaignSnapshot = {
  __typename?: 'OrderCampaignSnapshot';
  id: Scalars['ID'];
};

export type OrderFulfillmentMethodSnapshot = {
  __typename?: 'OrderFulfillmentMethodSnapshot';
  name: Scalars['String'];
  type: FulfillmentType;
};

export type OrderHistory = {
  __typename?: 'OrderHistory';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  user: UserInfo;
  status: OrderStatus;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  id: Scalars['ID'];
  product: OrderProductSnapshot;
  campaign: OrderCampaignSnapshot;
  fulfillmentMethod: OrderFulfillmentMethodSnapshot;
  quantity: Scalars['Money'];
  amount: Scalars['Money'];
  currency: Scalars['String'];
};

export type OrderPage = Page & {
  __typename?: 'OrderPage';
  sorting?: Maybe<Array<SortField>>;
  paging: Paging;
  nodes: Array<Order>;
};

export type OrderProductSnapshot = {
  __typename?: 'OrderProductSnapshot';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  reviewUrl?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  defaultImage?: Maybe<Scalars['String']>;
  option1?: Maybe<Scalars['String']>;
  option2?: Maybe<Scalars['String']>;
  option3?: Maybe<Scalars['String']>;
  scutisExclusive?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  variant: OrderProductVariantSnapshot;
  createdAt: Scalars['DateTime'];
};

export type OrderProductVariantSnapshot = {
  __typename?: 'OrderProductVariantSnapshot';
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  price: Price;
  compareAt?: Maybe<Price>;
  sku: Scalars['String'];
  barcode?: Maybe<Scalars['String']>;
  weight?: Maybe<Weight>;
  inStock: Scalars['Float'];
  option1?: Maybe<Scalars['String']>;
  option2?: Maybe<Scalars['String']>;
  option3?: Maybe<Scalars['String']>;
  variantPosition?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
};

export type OrderShippingInformationSnapshot = {
  __typename?: 'OrderShippingInformationSnapshot';
  phone?: Maybe<Scalars['String']>;
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  zipCode: Scalars['String'];
  country: Scalars['String'];
  state?: Maybe<Scalars['String']>;
};

export enum OrderStatus {
  Pending = 'PENDING',
  Shipping = 'SHIPPING',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED',
}

export type Organization = {
  __typename?: 'Organization';
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  zipCode: Scalars['String'];
  country: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  website: Scalars['String'];
  identificationNumber: Scalars['String'];
  owner: UserInfo;
  dbaName: Scalars['String'];
  phone: Scalars['String'];
  types: Array<OrganizationType>;
};

export type OrganizationAccess = {
  __typename?: 'OrganizationAccess';
  id: Scalars['String'];
  name: Scalars['String'];
  role: Scalars['String'];
  owner: UserInfo;
};

export type OrganizationInput = {
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  zipCode: Scalars['String'];
  country: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  website: Scalars['String'];
  identificationNumber: Scalars['String'];
  dbaName: Scalars['String'];
  phone: Scalars['String'];
  owner?: Maybe<OwnerInput>;
  types: Array<OrganizationType>;
};

export type OrganizationPage = Page & {
  __typename?: 'OrganizationPage';
  sorting?: Maybe<Array<SortField>>;
  paging: Paging;
  nodes: Array<Organization>;
};

export enum OrganizationType {
  GamePublisher = 'GAME_PUBLISHER',
  Retailer = 'RETAILER',
  Advertiser = 'ADVERTISER',
  Agency = 'AGENCY',
}

export type Overview = {
  __typename?: 'Overview';
  id: Scalars['ID'];
  author: UserInfo;
  name: Scalars['String'];
  text: Scalars['String'];
};

export type OwnerInput = {
  email: Scalars['String'];
  fullName: Scalars['String'];
};

export type Page = {
  sorting?: Maybe<Array<SortField>>;
  paging: Paging;
};

export type Paging = {
  __typename?: 'Paging';
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PagingInput = {
  offset?: Scalars['Int'];
  limit?: Scalars['Int'];
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  id: Scalars['ID'];
  shop: Shop;
  default: Scalars['Boolean'];
  cardNumber: Scalars['String'];
  nameOnCard: Scalars['String'];
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  cvc: Scalars['String'];
  address: BillingAddress;
  author: Shop;
};

export type PaymentMethodInput = {
  shopId: Scalars['String'];
  defaultMethod: Scalars['Boolean'];
  cardNumber: Scalars['String'];
  nameOnCard: Scalars['String'];
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  cvc: Scalars['String'];
  address: BillingAddressInput;
};

export type PaymentSource = {
  type: PaymentSourceType;
  id?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  encrypted?: Maybe<EncryptedInput>;
  card?: Maybe<CreditCard>;
  persist?: Maybe<Scalars['Boolean']>;
};

export enum PaymentSourceType {
  Token = 'TOKEN',
  Card = 'CARD',
  StoredCard = 'STORED_CARD',
  ScutiCrypto = 'SCUTI_CRYPTO',
}

export enum PayoutStatus {
  Pending = 'PENDING',
  Error = 'ERROR',
  Paid = 'PAID',
}

export type PersonalInfo = {
  __typename?: 'PersonalInfo';
  phone?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  age?: Maybe<Scalars['Float']>;
  birthDate?: Maybe<Scalars['LocalDate']>;
  dateTruncated?: Maybe<Scalars['Boolean']>;
};

export type PersonalInformationInput = {
  fullName: Scalars['String'];
  birthDate?: Maybe<Scalars['LocalDate']>;
  dateTruncated: Scalars['Boolean'];
  gender: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
};

export type PlatformSettings = {
  __typename?: 'PlatformSettings';
  scutiExchangeRate: Scalars['Float'];
  isNewOfferDays: Scalars['Float'];
  isMiscOfferCategoryLimit: Scalars['Float'];
};

export type Preferences = {
  __typename?: 'Preferences';
  categories?: Maybe<Array<Scalars['String']>>;
};

export type PreferencesInput = {
  categories: Array<Scalars['String']>;
};

export type Price = {
  __typename?: 'Price';
  amount: Scalars['Float'];
  currency: Scalars['String'];
};

export type PriceInput = {
  amount: Scalars['Float'];
  currency: Scalars['String'];
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  reviewUrl?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  images?: Maybe<Array<Scalars['String']>>;
  defaultImage?: Maybe<Scalars['String']>;
  option1?: Maybe<Scalars['String']>;
  option2?: Maybe<Scalars['String']>;
  option3?: Maybe<Scalars['String']>;
  scutisExclusive?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  shop: ShopInfo;
  chargeTaxes: Scalars['Boolean'];
  productListings: Array<CampaignInfo>;
  variants: Array<ProductVariant>;
};

export type ProductInfo = {
  __typename?: 'ProductInfo';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  reviewUrl?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  images?: Maybe<Array<Scalars['String']>>;
  defaultImage?: Maybe<Scalars['String']>;
  option1?: Maybe<Scalars['String']>;
  option2?: Maybe<Scalars['String']>;
  option3?: Maybe<Scalars['String']>;
  scutisExclusive?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type ProductInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  category: Scalars['String'];
  chargeTaxes: Scalars['Boolean'];
  tags?: Maybe<Array<Scalars['String']>>;
  images?: Maybe<Array<Scalars['String']>>;
  defaultImage?: Maybe<Scalars['String']>;
  reviewUrl?: Maybe<Scalars['String']>;
  taxCode?: Maybe<Scalars['String']>;
  variants: Array<ProductVariantInput>;
  option1?: Maybe<Scalars['String']>;
  option2?: Maybe<Scalars['String']>;
  option3?: Maybe<Scalars['String']>;
  shopId: Scalars['String'];
};

export type ProductPage = Page & {
  __typename?: 'ProductPage';
  sorting?: Maybe<Array<SortField>>;
  paging: Paging;
  nodes: Array<Product>;
};

export type ProductVariant = {
  __typename?: 'ProductVariant';
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  price: Price;
  compareAt?: Maybe<Price>;
  sku: Scalars['String'];
  barcode?: Maybe<Scalars['String']>;
  weight?: Maybe<Weight>;
  inStock: Scalars['Float'];
  fulfillmentMethod: FulfillmentMethod;
  option1?: Maybe<Scalars['String']>;
  option2?: Maybe<Scalars['String']>;
  option3?: Maybe<Scalars['String']>;
  variantPosition?: Maybe<Scalars['Float']>;
};

export type ProductVariantInput = {
  id?: Maybe<Scalars['ID']>;
  ebridgeProductId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  taxCode?: Maybe<Scalars['String']>;
  price: PriceInput;
  compareAt?: Maybe<PriceInput>;
  sku: Scalars['String'];
  barcode?: Maybe<Scalars['String']>;
  weight?: Maybe<WeightInput>;
  inStock: Scalars['Float'];
  fulfillmentMethodId: Scalars['String'];
  option1?: Maybe<Scalars['String']>;
  option2?: Maybe<Scalars['String']>;
  option3?: Maybe<Scalars['String']>;
};

export type PromoCodeCheckoutReward = {
  __typename?: 'PromoCodeCheckoutReward';
  discounts: PromoCodeCheckoutRewardDiscount;
  scutis: Scalars['Float'];
};

export type PromoCodeCheckoutRewardDiscount = {
  __typename?: 'PromoCodeCheckoutRewardDiscount';
  cart: Scalars['Float'];
  shop: Scalars['Float'];
};

export type PromoCodes = {
  __typename?: 'PromoCodes';
  id: Scalars['ID'];
  code: Scalars['String'];
  description: Scalars['String'];
  reward?: Maybe<Scalars['Float']>;
  rewardType?: Maybe<Scalars['String']>;
  rewardTypeSourceId?: Maybe<Scalars['ID']>;
  rewardMode?: Maybe<Scalars['String']>;
  gameId?: Maybe<Scalars['ID']>;
  stackable?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PromoCodesInput = {
  id: Scalars['ID'];
  code: Scalars['String'];
  description: Scalars['String'];
};

export enum PromotionType {
  HotPrice = 'HOT_PRICE',
  SpecialOffer = 'SPECIAL_OFFER',
}

export type Query = {
  __typename?: 'Query';
  /** [SCUTI_USER] - me/my profile */
  me: User;
  /** [SCUTI_USER] - my wallet */
  myWallet: Wallet;
  /** [SCUTI_USER?] - show platform settings */
  platformSettings: PlatformSettings;
  /** [DEBUG] [GAME_ADMIN,SHOP_ADMIN,ORGANIZATION_ADMIN,GAME_OWNER,SHOP_OWNER,ORGANIZATION_OWNER,SCUTI_ADMIN] - print my access */
  myAccess: AccessInfo;
  /** [SHOP_OWNER,SCUTI_ADMIN,SHOP_ADMIN,ORGANIZATION_ADMIN,ORGANIZATION_OWNER] - query for shops */
  shops: ShopPage;
  /** [SHOP_OWNER,SCUTI_ADMIN,SHOP_ADMIN,ORGANIZATION_ADMIN,ORGANIZATION_OWNER] - get shop by id */
  shop?: Maybe<Shop>;
  /** [SHOP_OWNER,SCUTI_ADMIN,SHOP_ADMIN,ORGANIZATION_ADMIN,ORGANIZATION_OWNER] - get shop brands */
  shopBrands: Array<ShopBrand>;
  /** [SCUTI_ADMIN,SHOP_ADMIN,SHOP_OWNER] - query for shop's products */
  shopProducts: ProductPage;
  /** [SCUTI_ADMIN,SHOP_ADMIN,SHOP_OWNER] - get product by id */
  product: Product;
  /** [SCUTI_USER,SCUTI_ADMIN,SHOP_ADMIN,GAME_ADMIN,SHOP_OWNER,GAME_OWNER,ORGANIZATION_OWNER,ORGANIZATION_ADMIN] - get available product categories */
  productCategories: Array<Scalars['String']>;
  /** [SCUTI_ADMIN,GAME_OWNER,GAME_ADMIN,SHOP_ADMIN,ORGANIZATION_ADMIN,ORGANIZATION_OWNER] - query for games */
  games: GamePage;
  /** [SCUTI_ADMIN,GAME_OWNER,GAME_ADMIN,GAME_SERVER,ORGANIZATION_ADMIN,ORGANIZATION_OWNER] - get game by id */
  game: Game;
  /** [SCUTI_ADMIN,GAME_OWNER,GAME_ADMIN] - get game token by game id */
  gameToken?: Maybe<Token>;
  /** [SCUTI_USER?] - get game info by id */
  gameInfo?: Maybe<GameInfo>;
  shopOverviews: Array<Overview>;
  /** [SCUTI_ADMIN] - query for campaigns */
  campaigns: CampaignPage;
  /** [SCUTI_ADMIN] - query for shop campaigns by shop id */
  shopCampaigns: CampaignPage;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - get campaign by id */
  campaign: Campaign;
  /** [SCUTI_ADMIN] - query for today deals campaigns */
  todayDeals: Array<Campaign>;
  /** [SCUTI_ADMIN] - query for advertisements */
  advertisements: AdvertisementPage;
  /** [SCUTI_ADMIN] - query for brand advertisements by brand id */
  brandAdvertisements: AdvertisementPage;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - get advertisement by id */
  advertisement: Advertisement;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - get all bank accounts by organization id */
  organizationBankAccounts?: Maybe<Array<BankAccount>>;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - get assigned shop bank account or default organization bank account */
  shopBankAccount?: Maybe<BankAccount>;
  /** @deprecated subject to change */
  shopFulfillmentMethods: Array<FulfillmentMethod>;
  shopPaymentMethods: Array<PaymentMethod>;
  /** [SCUTI_USER?] - query for offers */
  offers: OfferPage;
  /** [SCUTI_USER?] - query for offers by categories */
  offersByCategories: OffersByCategories;
  /** [SCUTI_USER?] - get offer by id */
  offer: Offer;
  /** [SCUTI_USER?] - get random offer */
  randomFancyOffer?: Maybe<Offer>;
  /** [SCUTI_USER?]  - get overall offer statistics */
  offerStatistics: OfferStatistics;
  /** [SCUTI_USER] - my rewards */
  myRewards: RewardPage;
  /** [SCUTI_ADMIN,ORGANIZATION_ADMIN,ORGANIZATION_OWNER,SHOP_OWNER,GAME_OWNER] - query for organizations */
  organizations: OrganizationPage;
  /** [SCUTI_ADMIN,ORGANIZATION_ADMIN,ORGANIZATION_OWNER,SHOP_OWNER,GAME_OWNER] - get organization by id */
  organization: Organization;
  /** [SCUTI_ADMIN,SCUTI_USER,SCUTI_ADMIN,SHOP_ADMIN,GAME_ADMIN,SHOP_OWNER,GAME_OWNER,ORGANIZATION_OWNER,ORGANIZATION_ADMIN] - query for invites */
  invites: InvitePage;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - query for shop invites */
  shopInvites: InvitePage;
  /** [SCUTI_ADMIN,ORGANIZATION_OWNER,ORGANIZATION_ADMIN] - query for organization invites */
  organizationInvites: InvitePage;
  /** [SCUTI_ADMIN,GAME_OWNER,GAME_ADMIN] - query for game invites */
  gameInvites: InvitePage;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - query for shop orders by shop id */
  shopOrders: OrderPage;
  /** [SCUTI_USER] - query for my orders */
  myOrders: OrderPage;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - get order by id */
  order: Order;
  /** [SCUTI_USER] - get my oder by id */
  myOrder: Order;
  /** [SCUTI_USER] - get my order history by order id */
  myOrderHistory: Array<OrderHistory>;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - get order history by order id */
  orderHistory: Array<OrderHistory>;
  /** [SCUTI_USER] - get my cards */
  myCards: Array<UserCard>;
  /** [SCUTI_USER] - get my card details */
  myCardDetails: UserCardDetails;
  /** [SCUTI_USER] - get encryption key */
  encryptionKey: CheckoutEncryption;
  /** [SCUTI_USER] - get stripe settings */
  stripeSettings: StripeSettings;
  /** [SCUTI_USER] - my scuti transactions */
  myScutiTransactions: ScutiTransactionPage;
  debugClaims?: Maybe<ClaimsInfo>;
  /** [SHOP_OWNER,SCUTI_ADMIN,SHOP_ADMIN,GAME_OWNER,GAME_ADMIN,ORGANIZATION_ADMIN,ORGANIZATION_OWNER] - query for entity requests */
  entityRequests: EntityRequestPage;
  /** [SHOP_OWNER,SCUTI_ADMIN,SHOP_ADMIN,GAME_OWNER,GAME_ADMIN,ORGANIZATION_ADMIN,ORGANIZATION_OWNER] - get entity request by id */
  entityRequest: EntityRequest;
  /** [SCUTI_USER,SCUTI_ADMIN,SHOP_ADMIN,GAME_ADMIN,SHOP_OWNER,GAME_OWNER,ORGANIZATION_OWNER,ORGANIZATION_ADMIN] - query for brands */
  brands: BrandPage;
  /** [SCUTI_USER,SCUTI_ADMIN,SHOP_ADMIN,GAME_ADMIN,SHOP_OWNER,GAME_OWNER,ORGANIZATION_OWNER,ORGANIZATION_ADMIN] - get brand by id */
  brand?: Maybe<Brand>;
  /** [SCUTI_USER] - get my cart */
  myCart: CartOutput;
  /** [SCUTI_ADMIN,SHOP_OWNER,SHOP_ADMIN] - get my refunded order */
  myRefundOrder: RefundOrder;
};

export type QueryShopsArgs = {
  paging?: Maybe<PagingInput>;
  sorting?: Maybe<Array<SortFieldInput>>;
  filters?: Maybe<Array<FilterInput>>;
};

export type QueryShopArgs = {
  id: Scalars['String'];
};

export type QueryShopBrandsArgs = {
  id: Scalars['String'];
};

export type QueryShopProductsArgs = {
  filters?: Maybe<Array<FilterInput>>;
  paging?: Maybe<PagingInput>;
  sorting?: Maybe<Array<SortFieldInput>>;
  id: Scalars['ID'];
};

export type QueryProductArgs = {
  id: Scalars['String'];
};

export type QueryGamesArgs = {
  paging?: Maybe<PagingInput>;
  sorting?: Maybe<Array<SortFieldInput>>;
  filters?: Maybe<Array<FilterInput>>;
};

export type QueryGameArgs = {
  id: Scalars['String'];
};

export type QueryGameTokenArgs = {
  id: Scalars['String'];
};

export type QueryGameInfoArgs = {
  id: Scalars['String'];
};

export type QueryShopOverviewsArgs = {
  paging?: Maybe<PagingInput>;
  sorting?: Maybe<Array<SortFieldInput>>;
  id: Scalars['ID'];
};

export type QueryCampaignsArgs = {
  paging?: Maybe<PagingInput>;
  sorting?: Maybe<Array<SortFieldInput>>;
  filters?: Maybe<Array<FilterInput>>;
};

export type QueryShopCampaignsArgs = {
  filters?: Maybe<Array<FilterInput>>;
  paging?: Maybe<PagingInput>;
  sorting?: Maybe<Array<SortFieldInput>>;
  id: Scalars['ID'];
};

export type QueryCampaignArgs = {
  id: Scalars['String'];
};

export type QueryTodayDealsArgs = {
  shopId: Scalars['String'];
};

export type QueryAdvertisementsArgs = {
  paging?: Maybe<PagingInput>;
  sorting?: Maybe<Array<SortFieldInput>>;
  filters?: Maybe<Array<FilterInput>>;
};

export type QueryBrandAdvertisementsArgs = {
  filters?: Maybe<Array<FilterInput>>;
  paging?: Maybe<PagingInput>;
  sorting?: Maybe<Array<SortFieldInput>>;
  id: Scalars['ID'];
};

export type QueryAdvertisementArgs = {
  id: Scalars['String'];
};

export type QueryOrganizationBankAccountsArgs = {
  id: Scalars['String'];
};

export type QueryShopBankAccountArgs = {
  id: Scalars['String'];
};

export type QueryShopFulfillmentMethodsArgs = {
  id: Scalars['String'];
};

export type QueryShopPaymentMethodsArgs = {
  paging?: Maybe<PagingInput>;
  sorting?: Maybe<Array<SortFieldInput>>;
  id: Scalars['ID'];
};

export type QueryOffersArgs = {
  filters?: Maybe<Array<FilterInput>>;
  paging?: Maybe<PagingInput>;
  sorting?: Maybe<Array<SortFieldInput>>;
  seed?: Maybe<Scalars['Int']>;
};

export type QueryOffersByCategoriesArgs = {
  seed?: Maybe<Scalars['Int']>;
};

export type QueryOfferArgs = {
  id: Scalars['String'];
};

export type QueryMyRewardsArgs = {
  paging?: Maybe<PagingInput>;
  sorting?: Maybe<Array<SortFieldInput>>;
  filters?: Maybe<Array<FilterInput>>;
};

export type QueryOrganizationsArgs = {
  paging?: Maybe<PagingInput>;
  sorting?: Maybe<Array<SortFieldInput>>;
  filters?: Maybe<Array<FilterInput>>;
};

export type QueryOrganizationArgs = {
  id: Scalars['String'];
};

export type QueryInvitesArgs = {
  paging?: Maybe<PagingInput>;
  sorting?: Maybe<Array<SortFieldInput>>;
  filters?: Maybe<Array<FilterInput>>;
};

export type QueryShopInvitesArgs = {
  id: Scalars['String'];
  paging?: Maybe<PagingInput>;
  sorting?: Maybe<Array<SortFieldInput>>;
  filters?: Maybe<Array<FilterInput>>;
};

export type QueryOrganizationInvitesArgs = {
  id: Scalars['String'];
  paging?: Maybe<PagingInput>;
  sorting?: Maybe<Array<SortFieldInput>>;
  filters?: Maybe<Array<FilterInput>>;
};

export type QueryGameInvitesArgs = {
  id: Scalars['String'];
  paging?: Maybe<PagingInput>;
  sorting?: Maybe<Array<SortFieldInput>>;
  filters?: Maybe<Array<FilterInput>>;
};

export type QueryShopOrdersArgs = {
  filters?: Maybe<Array<FilterInput>>;
  paging?: Maybe<PagingInput>;
  sorting?: Maybe<Array<SortFieldInput>>;
  id: Scalars['ID'];
};

export type QueryMyOrdersArgs = {
  paging?: Maybe<PagingInput>;
  sorting?: Maybe<Array<SortFieldInput>>;
  filters?: Maybe<Array<FilterInput>>;
};

export type QueryOrderArgs = {
  id: Scalars['String'];
};

export type QueryMyOrderArgs = {
  id: Scalars['String'];
};

export type QueryMyOrderHistoryArgs = {
  id: Scalars['String'];
};

export type QueryOrderHistoryArgs = {
  id: Scalars['String'];
};

export type QueryMyCardDetailsArgs = {
  id: Scalars['String'];
};

export type QueryMyScutiTransactionsArgs = {
  paging?: Maybe<PagingInput>;
  sorting?: Maybe<Array<SortFieldInput>>;
  filters?: Maybe<Array<FilterInput>>;
};

export type QueryEntityRequestsArgs = {
  paging?: Maybe<PagingInput>;
  sorting?: Maybe<Array<SortFieldInput>>;
  filters?: Maybe<Array<FilterInput>>;
};

export type QueryEntityRequestArgs = {
  id: Scalars['String'];
};

export type QueryBrandsArgs = {
  paging?: Maybe<PagingInput>;
  sorting?: Maybe<Array<SortFieldInput>>;
  filters?: Maybe<Array<FilterInput>>;
};

export type QueryBrandArgs = {
  id: Scalars['String'];
};

export type QueryMyRefundOrderArgs = {
  orderId: Scalars['String'];
  userId: Scalars['String'];
};

export type Receipt = {
  __typename?: 'Receipt';
  id: Scalars['String'];
  user: UserInfo;
  currency: Scalars['String'];
  items: Array<ReceiptItem>;
  breakdown: ReceiptBreakdown;
  promoCodes: Array<PromoCodes>;
  extraRewards: PromoCodeCheckoutReward;
};

export type ReceiptBreakdown = {
  __typename?: 'ReceiptBreakdown';
  total: Scalars['Money'];
  retail: Scalars['Money'];
  tax: Scalars['Money'];
  shipping: Scalars['Money'];
  discount: Scalars['Money'];
};

export type ReceiptItem = {
  __typename?: 'ReceiptItem';
  shop: ReceiptShop;
  products: Array<ReceiptProduct>;
  breakdown: ReceiptBreakdown;
};

export type ReceiptProduct = {
  __typename?: 'ReceiptProduct';
  variantId: Scalars['String'];
  offerId: Scalars['String'];
  productName: Scalars['String'];
  productDescription?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  price: Scalars['Money'];
  breakdown: ReceiptBreakdown;
  reward: ReceiptReward;
  country?: Maybe<Scalars['String']>;
};

export type ReceiptReward = {
  __typename?: 'ReceiptReward';
  scutis?: Maybe<Scalars['Int']>;
};

export type ReceiptShop = {
  __typename?: 'ReceiptShop';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type RefundOrder = {
  __typename?: 'RefundOrder';
  orderId: Scalars['String'];
  userId: Scalars['String'];
  orderItems: Array<RefundOrderItem>;
};

export type RefundOrderInput = {
  userId: Scalars['String'];
  orderId: Scalars['String'];
  items: Array<RefundOrderInputItem>;
};

export type RefundOrderInputItem = {
  orderItemId: Scalars['String'];
  activated?: Maybe<Scalars['Boolean']>;
  currencyName: Scalars['String'];
  refundQuantity?: Maybe<Scalars['Float']>;
  reason?: Maybe<Scalars['String']>;
};

export type RefundOrderItem = {
  __typename?: 'RefundOrderItem';
  orderItemId: Scalars['String'];
  activated?: Maybe<Scalars['Boolean']>;
  currencyName: Scalars['String'];
  scutis?: Maybe<Scalars['Int']>;
  refundQuantity?: Maybe<Scalars['Float']>;
  reason?: Maybe<Scalars['String']>;
  rewardId?: Maybe<Scalars['String']>;
};

export enum RequestActionName {
  Connect = 'CONNECT',
}

export enum RequestEntityType {
  Shop = 'SHOP',
  Game = 'GAME',
  Brand = 'BRAND',
}

export enum RequesterType {
  Organization = 'ORGANIZATION',
}

export type Reward = {
  __typename?: 'Reward';
  id: Scalars['ID'];
  activated: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  type: Scalars['String'];
  scutiAccountType: Scalars['String'];
  scutis?: Maybe<Scalars['Float']>;
  user: UserInfo;
};

export type RewardPage = Page & {
  __typename?: 'RewardPage';
  sorting?: Maybe<Array<SortField>>;
  paging: Paging;
  nodes: Array<Reward>;
};

export enum Role {
  ScutiUser = 'SCUTI_USER',
  ScutiAdmin = 'SCUTI_ADMIN',
  ShopAdmin = 'SHOP_ADMIN',
  GameAdmin = 'GAME_ADMIN',
  Login_2Fa = 'LOGIN_2FA',
  VerifyEmail = 'VERIFY_EMAIL',
  AcceptSystemInvite = 'ACCEPT_SYSTEM_INVITE',
  AcceptEntityInvite = 'ACCEPT_ENTITY_INVITE',
  Refresh = 'REFRESH',
  OrganizationOwner = 'ORGANIZATION_OWNER',
  OrganizationAdmin = 'ORGANIZATION_ADMIN',
  ShopOwner = 'SHOP_OWNER',
  GameOwner = 'GAME_OWNER',
  ChangePassword = 'CHANGE_PASSWORD',
  ChangePassword_2Fa = 'CHANGE_PASSWORD_2FA',
  GameServer = 'GAME_SERVER',
}

export type ScutiExchange = {
  __typename?: 'ScutiExchange';
  scutisSpent: Scalars['Float'];
  currencyEarned: Scalars['Float'];
  currencyName: Scalars['String'];
  id: Scalars['String'];
};

export type ScutiTransaction = {
  __typename?: 'ScutiTransaction';
  id: Scalars['ID'];
  purchaseBalanceChange: Scalars['Float'];
  promotionalBalanceChange: Scalars['Float'];
  type: Scalars['String'];
  receiptId?: Maybe<Scalars['String']>;
  rewardId?: Maybe<Scalars['String']>;
  rewardType?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
};

export type ScutiTransactionPage = Page & {
  __typename?: 'ScutiTransactionPage';
  sorting?: Maybe<Array<SortField>>;
  paging: Paging;
  nodes: Array<ScutiTransaction>;
};

export type ShippingInfo = {
  __typename?: 'ShippingInfo';
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  zipCode: Scalars['String'];
  country: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type ShippingInformationInput = {
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  zipCode: Scalars['String'];
  country: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type Shop = {
  __typename?: 'Shop';
  id: Scalars['ID'];
  name: Scalars['String'];
  thumbnail?: Maybe<Scalars['String']>;
  shopifyName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  owner: UserInfo;
  admins: Array<UserWithRoles>;
  analytics: ShopAnalytics;
  flatShippingRate: Scalars['Float'];
  freeShippingThreshold?: Maybe<Scalars['Float']>;
  country?: Maybe<Scalars['String']>;
  referralFee: Scalars['Float'];
  returnPolicy?: Maybe<Scalars['String']>;
  returnPolicyUrl?: Maybe<Scalars['String']>;
  shippings?: Maybe<Array<ShopShipping>>;
  brands?: Maybe<Array<ShopBrand>>;
  shopifyEnabled: Scalars['Boolean'];
  rewardsPercentage?: Maybe<Scalars['Float']>;
  rewardsIncluded: Scalars['Boolean'];
  mediaInvestmentEnabled: Scalars['Boolean'];
  directResponse?: Maybe<MediaInvestment>;
  engagement?: Maybe<MediaInvestment>;
  video?: Maybe<MediaInvestment>;
};

export type ShopAccess = {
  __typename?: 'ShopAccess';
  id: Scalars['String'];
  name: Scalars['String'];
  role: Scalars['String'];
  owner: UserInfo;
};

export type ShopAgeAnalytics = {
  __typename?: 'ShopAgeAnalytics';
  revenue: Scalars['Float'];
  units: Scalars['Float'];
  avgPurchaseValue: Scalars['Float'];
  itemsPerCheckout: Scalars['Float'];
  age: Scalars['Float'];
};

export type ShopAnalytics = {
  __typename?: 'ShopAnalytics';
  revenue: Scalars['Float'];
  units: Scalars['Float'];
  avgPurchaseValue: Scalars['Float'];
  itemsPerCheckout: Scalars['Float'];
  campaignAnalytics: Array<ShopCampaignAnalytics>;
  ageAnalytics: Array<ShopAgeAnalytics>;
  genderAnalytics: Array<ShopGenderAnalytics>;
};

export type ShopBrand = {
  __typename?: 'ShopBrand';
  id: Scalars['ID'];
  name: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
};

export type ShopBrandInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
};

export type ShopCampaignAnalytics = {
  __typename?: 'ShopCampaignAnalytics';
  revenue: Scalars['Float'];
  units: Scalars['Float'];
  avgPurchaseValue: Scalars['Float'];
  itemsPerCheckout: Scalars['Float'];
  campaignId: Scalars['ID'];
};

export type ShopGenderAnalytics = {
  __typename?: 'ShopGenderAnalytics';
  revenue: Scalars['Float'];
  units: Scalars['Float'];
  avgPurchaseValue: Scalars['Float'];
  itemsPerCheckout: Scalars['Float'];
  gender: Scalars['String'];
};

export type ShopInfo = {
  __typename?: 'ShopInfo';
  id: Scalars['ID'];
  name: Scalars['String'];
  thumbnail?: Maybe<Scalars['String']>;
  shopifyName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type ShopInput = {
  thumbnail?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  shopifyName?: Maybe<Scalars['String']>;
  shopifyEnabled?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  flatShippingRate: Scalars['Float'];
  freeShippingThreshold?: Maybe<Scalars['Float']>;
  referralFee?: Maybe<Scalars['Float']>;
  rewardsPercentage?: Maybe<Scalars['Float']>;
  rewardsIncluded?: Maybe<Scalars['Boolean']>;
  country?: Maybe<Scalars['String']>;
  returnPolicy?: Maybe<Scalars['String']>;
  returnPolicyUrl?: Maybe<Scalars['String']>;
  shippings?: Maybe<Array<ShopShippingInput>>;
  brands?: Maybe<Array<ShopBrandInput>>;
  mediaInvestmentEnabled?: Maybe<Scalars['Boolean']>;
  directResponse?: Maybe<MediaInvestmentInput>;
  engagement?: Maybe<MediaInvestmentInput>;
  video?: Maybe<MediaInvestmentInput>;
  organizationId?: Maybe<Scalars['String']>;
};

export type ShopPage = Page & {
  __typename?: 'ShopPage';
  sorting?: Maybe<Array<SortField>>;
  paging: Paging;
  nodes: Array<Shop>;
};

export type ShopShipping = {
  __typename?: 'ShopShipping';
  id: Scalars['ID'];
  amount: Scalars['Float'];
  freeShippingThreshold?: Maybe<Scalars['Float']>;
  country: Scalars['String'];
};

export type ShopShippingInput = {
  id: Scalars['ID'];
  amount?: Scalars['Float'];
  freeShippingThreshold?: Maybe<Scalars['Float']>;
  country?: Scalars['String'];
};

export type ShopWhitelist = {
  __typename?: 'ShopWhitelist';
  id: Scalars['ID'];
  name: Scalars['String'];
  thumbnail?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
};

export type SortField = {
  __typename?: 'SortField';
  name: Scalars['String'];
  dir: Scalars['String'];
};

export type SortFieldInput = {
  name: Scalars['String'];
  dir: Scalars['String'];
};

export type StripeCheckoutInput = {
  gameId: Scalars['String'];
  items: Array<CheckoutItemInput>;
  shippingInfo: ShippingInformationInput;
  scutisToApply?: Maybe<Scalars['Int']>;
  promoCodes: Array<PromoCodesInput>;
};

export type StripePaymentIntent = {
  __typename?: 'StripePaymentIntent';
  amount: Scalars['Float'];
  currency: Scalars['String'];
  paymentMethodTypes: Array<Scalars['String']>;
  clientSecret: Scalars['String'];
  paidInFullWithScutis?: Maybe<Scalars['Boolean']>;
};

export type StripeSettings = {
  __typename?: 'StripeSettings';
  publicKey: Scalars['String'];
};

export type SyncEbridgeInput = {
  id: Scalars['ID'];
};

export type SyncEbridgeResult = {
  __typename?: 'SyncEbridgeResult';
  date: Scalars['DateTime'];
};

export type ToggleCampaignInput = {
  id: Scalars['ID'];
  isPaused: Scalars['Boolean'];
};

export type Token = {
  __typename?: 'Token';
  id: Scalars['ID'];
  token: Scalars['String'];
  createdAt: Scalars['DateTime'];
};

export type UpdateAdvertisementInput = {
  name?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  cpa?: Maybe<Scalars['Float']>;
  appDownload?: Maybe<AdvertisementAppDownloadInput>;
  video?: Maybe<AdvertisementVideoInput>;
  leadGen?: Maybe<AdvertisementLeadGenInput>;
  coupon?: Maybe<AdvertisementCouponInput>;
  noAction?: Maybe<AdvertisementNoActionInput>;
  internal?: Maybe<AdvertisementInternalInput>;
  id: Scalars['ID'];
  type: AdvertisementType;
  brandId: Scalars['String'];
};

export type UpdateBankAccountInput = {
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  zipCode: Scalars['String'];
  country: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  bankName: Scalars['String'];
  routingNumber: Scalars['String'];
  accountNumber: Scalars['String'];
  isDefault: Scalars['Boolean'];
  id: Scalars['ID'];
  organizationId: Scalars['String'];
};

export type UpdateBrandInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  mediaInvestmentEnabled?: Maybe<Scalars['Boolean']>;
  directResponse?: Maybe<MediaInvestmentInput>;
  engagement?: Maybe<MediaInvestmentInput>;
  video?: Maybe<MediaInvestmentInput>;
  credentials: BrandCredentialsInput;
  id: Scalars['ID'];
};

export type UpdateCampaignInput = {
  name: Scalars['String'];
  category?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['ID']>;
  advertisementId?: Maybe<Scalars['ID']>;
  shopBrandId?: Maybe<Scalars['ID']>;
  productListingId?: Maybe<Scalars['String']>;
  duration: CampaignDurationInput;
  demographic: CampaignDemographicInput;
  location?: Maybe<CampaignLocationInput>;
  promotion?: Maybe<CampaignPromotionInput>;
  media?: Maybe<CampaignMediaInput>;
  budget?: Maybe<CampaignBudgetInput>;
  game?: Maybe<CampaignGameInput>;
  reward?: Maybe<CampaignRewardInput>;
  extras: CampaignExtrasInput;
  type: CampaignType;
  shopId: Scalars['String'];
  id: Scalars['ID'];
};

export type UpdateFulfillmentMethodInput = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type UpdateGameInput = {
  name: Scalars['String'];
  category: Scalars['String'];
  tags?: Maybe<Array<Scalars['String']>>;
  thumbnail?: Maybe<Scalars['String']>;
  banner?: Maybe<Scalars['String']>;
  currency?: Maybe<CurrencyInput>;
  ageFrom: Scalars['Int'];
  ageTo: Scalars['Int'];
  contentCategory: ContentCategory;
  genderPercent?: Maybe<GenderPercentInput>;
  id: Scalars['ID'];
};

export type UpdateOrderInput = {
  ebridgeOrderId?: Maybe<Scalars['String']>;
  vendorOrderId?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  payoutStatus: PayoutStatus;
  trackingNumber?: Maybe<Scalars['String']>;
};

export type UpdateOrganizationInput = {
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  zipCode: Scalars['String'];
  country: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  website: Scalars['String'];
  identificationNumber: Scalars['String'];
  dbaName: Scalars['String'];
  phone: Scalars['String'];
  id: Scalars['ID'];
  types: Array<OrganizationType>;
};

export type UpdatePaymentMethodInput = {
  id: Scalars['ID'];
  defaultMethod?: Maybe<Scalars['Boolean']>;
  cardNumber?: Maybe<Scalars['String']>;
  nameOnCard?: Maybe<Scalars['String']>;
  expirationMonth?: Maybe<Scalars['Int']>;
  expirationYear?: Maybe<Scalars['Int']>;
  cvc?: Maybe<Scalars['String']>;
  address?: Maybe<BillingAddressInput>;
};

export type UpdateProductInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  category: Scalars['String'];
  chargeTaxes: Scalars['Boolean'];
  tags?: Maybe<Array<Scalars['String']>>;
  images?: Maybe<Array<Scalars['String']>>;
  defaultImage?: Maybe<Scalars['String']>;
  reviewUrl?: Maybe<Scalars['String']>;
  taxCode?: Maybe<Scalars['String']>;
  variants: Array<ProductVariantInput>;
  option1?: Maybe<Scalars['String']>;
  option2?: Maybe<Scalars['String']>;
  option3?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type UpdateShopInput = {
  thumbnail?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  shopifyName?: Maybe<Scalars['String']>;
  shopifyEnabled?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  flatShippingRate: Scalars['Float'];
  freeShippingThreshold?: Maybe<Scalars['Float']>;
  referralFee?: Maybe<Scalars['Float']>;
  rewardsPercentage?: Maybe<Scalars['Float']>;
  rewardsIncluded?: Maybe<Scalars['Boolean']>;
  country?: Maybe<Scalars['String']>;
  returnPolicy?: Maybe<Scalars['String']>;
  returnPolicyUrl?: Maybe<Scalars['String']>;
  shippings?: Maybe<Array<ShopShippingInput>>;
  brands?: Maybe<Array<ShopBrandInput>>;
  mediaInvestmentEnabled?: Maybe<Scalars['Boolean']>;
  directResponse?: Maybe<MediaInvestmentInput>;
  engagement?: Maybe<MediaInvestmentInput>;
  video?: Maybe<MediaInvestmentInput>;
  id: Scalars['ID'];
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  email: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  roles: Array<Role>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  phone?: Maybe<Scalars['String']>;
  welcomeTourCompleted: Scalars['Boolean'];
  emailVerified: Scalars['Boolean'];
  phoneVerified: Scalars['Boolean'];
  twoFactorEnabled: Scalars['Boolean'];
  personalInfo?: Maybe<PersonalInfo>;
  preferences?: Maybe<Preferences>;
  shippingInfo?: Maybe<ShippingInfo>;
};

export type UserCard = {
  __typename?: 'UserCard';
  id: Scalars['String'];
  last4: Scalars['String'];
  expiryMonth: Scalars['Float'];
  expiryYear: Scalars['Float'];
  scheme: Scalars['String'];
  isDefault: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
};

export type UserCardDetails = {
  __typename?: 'UserCardDetails';
  id: Scalars['String'];
  last4: Scalars['String'];
  expiryMonth: Scalars['Float'];
  expiryYear: Scalars['Float'];
  scheme: Scalars['String'];
  isDefault: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  billingAddress: Address;
};

export type UserInfo = {
  __typename?: 'UserInfo';
  id: Scalars['ID'];
  email: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
};

export type UserRoleInput = {
  email: Scalars['String'];
  roles: Array<Role>;
};

export type UserWithRoles = {
  __typename?: 'UserWithRoles';
  id: Scalars['ID'];
  email: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  roles: Array<Role>;
};

export type Wallet = {
  __typename?: 'Wallet';
  promotional: Scalars['Float'];
  purchase: Scalars['Float'];
};

export type Weight = {
  __typename?: 'Weight';
  amount: Scalars['Float'];
  unit: WeightUnit;
};

export type WeightInput = {
  amount: Scalars['Float'];
  unit: WeightUnit;
};

export enum WeightUnit {
  Lb = 'LB',
  Kg = 'KG',
  G = 'G',
  FlOz = 'FL_OZ',
}
