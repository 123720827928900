import { Box } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

export const Page404 = () => {
  return (
    <Box>
      <h1>404 Ooops... Page is not found!</h1>
      <p className="text-center">
        Please proceed to <Link to="/">Home Page</Link>
      </p>
    </Box>
  );
};
