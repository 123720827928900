import { NString } from './util-types';
import { CampaignType } from './__generated__/types';
import moment from 'moment';

export interface AppPreloadedState {
  API_URL: NString;
}

export enum Sorting {
  ASC = 'ASC',
  DESC = 'DESC',
}

export const defaultSorting = [{ name: 'updatedAt', dir: Sorting.DESC }];

export type TableHeader = {
  label: string;
  key: string;
  sorting?: string;
}[];

export type Period = { startDate: Date | null; endDate: Date | null };
export type AnalyticsPeriod = 'week' | 'month' | 'year' | 'day' | 'hour';
export type AnalyticsRange = {
  from: moment.Moment;
  to: moment.Moment;
};

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum SHOP_PAYMENT_PLAN {
  STANDART = 'STANDART',
  PREMIUM = 'PREMIUM',
  ENTERPRICE = 'ENTERPRICE',
}

export const CURRENCIES: { [key: string]: string } = {
  USD: '$',
};

export enum PromotionType {
  HOT_PRICE = 'HOT_PRICE',
  SPECIAL_OFFER = 'SPECIAL_OFFER',
}

export const Offers = [
  'Free shipping',
  'Buy One Get One Free',
  'Gift With Purchase',
  'Free Samples',
  'Limited time offer, or until sold out',
  'TripWire (below cost)',
];

export const GAME_CATEGORIES = [
  'Action',
  'Adventure',
  'Arcade',
  'Board',
  'Card',
  'Casino',
  'Casual',
  'Family',
  'Indie',
  'Kids',
  'Music',
  'Puzzle',
  'Racing',
  'Role Playing',
  'Simulation',
  'Sports',
  'Strategy',
  'Trivia',
  'Word',
];

export const contentCategoryName: { [key: string]: string } = {
  ADULTS_ONLY: 'Adults (A)',
  EARLY_CHILDHOOD: 'Early Childhood (EC)',
  EVERYONE: 'Everyone (E)',
  EVERYONE_10_PLUS: 'Everyone Ten Plus (E10+)',
  MATURE: 'Mature (M)',
  RATING_PENDING: 'Rating Pending (RP)',
  TEEN: 'Teen (T)',
};

type NewCampaignType =
  | CampaignType.Organization
  | CampaignType.ShopAdvertisement
  | CampaignType.ShopBrand
  | CampaignType.SingleItem;

export const campaignColors: Record<NewCampaignType, { name: string; color: string }> = {
  [CampaignType.Organization]: {
    name: 'Organization Campaign',
    color: '#FFC107',
  },
  [CampaignType.ShopAdvertisement]: {
    name: 'Shop Advertisement Campaign',
    color: '#9DA5B1',
  },
  [CampaignType.ShopBrand]: {
    name: 'Shop Brand Campaign',
    color: '#F86C6B',
  },
  [CampaignType.SingleItem]: {
    name: 'Single Item Listing',
    color: '#0059FF',
  },
};
