import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { InfoPopup } from 'components/InfoPopup';
import { Box, Grid } from '@material-ui/core';
import { TopNavigation } from 'components/material/TopNavigation';
import { PaginationLayout } from 'layouts/PaginationLayout';
// import {
//   BrandItemFgFragment,
//   useDeleteBrandMutation,
//   useBrandItemsQuery,
// } from './__generated__/brands-list-page.hooks';
import { Filter_Type } from 'types/__generated__/types';
import { useDebounce } from 'use-debounce/lib';
import { usePaging } from 'hooks/usePaging';
import { useTable, useSortBy } from 'react-table';
import { ScutiInput } from 'components/material/ScutiInput';
import { useConnectsTableColumns } from './useConnectsTableColumns';
import { useSorting } from 'hooks/useSorting';
import { ScutiTable } from 'components/ScutiTable';
import { defaultSorting } from 'types/app-types';

export const ConnectsListPage: React.FC = observer(() => {
  const paging = usePaging();
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [searchInput, setSearchInput] = useState<string>('');
  const [inputValue] = useDebounce(searchInput, 500);

  //const useDeleteBrand = useDeleteBrandMutation();
  // const onArchive = useCallback(() => {
  //   if (deleteId) useDeleteBrand.mutateAsync({ id: deleteId });
  //   setDeleteId(null);
  // }, [deleteId, useDeleteBrand]);
  const onCancel = useCallback(() => setDeleteId(null), []);
  const [connects, setConnects] = useState<any[]>([]);
  const columns = useConnectsTableColumns(setDeleteId);
  const tableInstance = useTable({ columns, data: connects, manualSortBy: true }, useSortBy);
  const sorting = useSorting(tableInstance.state.sortBy, defaultSorting);

  // const useBrandItems = useBrandItemsQuery(
  //   {
  //     paging,
  //     filters: inputValue
  //       ? [
  //           {
  //             name: 'search',
  //             operator: Filter_Type.Like,
  //             value: [inputValue],
  //           },
  //         ]
  //       : undefined,
  //     sorting,
  //   },
  //   {
  //     keepPreviousData: true,
  //     onSuccess: ({ brands }) => setBrands(brands.nodes),
  //   },
  // );

  // const isLoading = useBrandItems.isLoading;
  // const pagingResponse = useBrandItems.data?.brands.paging;
  const isLoading = false;
  const pagingResponse = { offset: 1, limit: 12, totalCount: 15 };
  const onArchive = () => {};
  const showConnects = !!connects.length && !isLoading;

  return (
    <PaginationLayout
      isLoading={isLoading}
      paging={pagingResponse}
      header={
        <TopNavigation title="Connect Management">
          <Grid container justifyContent="flex-end">
            <Grid item md={6}>
              <ScutiInput
                placeholder="Search"
                value={searchInput}
                onChange={({ target }) => setSearchInput(target.value)}
              />
            </Grid>
          </Grid>
        </TopNavigation>
      }
      content={
        showConnects ? (
          <Box>
            {!!deleteId && (
              <InfoPopup
                title="Archive connect?"
                description="This connect will be archived. You’ll be able to access them under your archived connectss."
                proceedText="Archive"
                cancelText="Cancel"
                onProceed={onArchive}
                onCancel={onCancel}
              />
            )}
            {connects && <ScutiTable table={tableInstance} />}
          </Box>
        ) : (
          <div>Nothing here</div>
        )
      }
    />
  );
});
