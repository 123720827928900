import * as Types from '../../types/__generated__/types';

import { ApolloError } from 'apollo-client';
import { useQuery, UseQueryOptions, useMutation, UseMutationOptions } from 'react-query';
import { fetcher } from 'api';
export type ConnectionListItemFragment = { id: string; requesterType: string; entityType: string; actionName: string };

export type ConnectionFragment = {
  id: string;
  createdAt: any;
  updatedAt: any;
  requesterId: string;
  requesterType: string;
  entityId: string;
  entityType: string;
  actionName: string;
  params: {
    controlLevel: string;
    referralPercentage?: Types.Maybe<number>;
    directResponse?: Types.Maybe<{ unit: string; amount: number }>;
    engagement?: Types.Maybe<{ unit: string; amount: number }>;
    video?: Types.Maybe<{ unit: string; amount: number }>;
  };
};

export type ConnectionsQueryVariables = Types.Exact<{
  paging?: Types.Maybe<Types.PagingInput>;
  sorting?: Types.Maybe<Array<Types.SortFieldInput> | Types.SortFieldInput>;
  filters?: Types.Maybe<Array<Types.FilterInput> | Types.FilterInput>;
}>;

export type ConnectionsQuery = {
  entityRequests: {
    sorting?: Types.Maybe<Array<{ name: string; dir: string }>>;
    paging: { offset: number; limit: number; totalCount: number };
    nodes: Array<ConnectionListItemFragment>;
  };
};

export type ConnectionQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type ConnectionQuery = { entityRequest: ConnectionFragment };

export type CreateConnectionMutationVariables = Types.Exact<{
  input: Types.EntityRequestInput;
}>;

export type CreateConnectionMutation = { createEntityRequest: { id: string } };

export const ConnectionListItemFragmentDoc = `
    fragment ConnectionListItem on EntityRequest {
  id
  requesterType
  entityType
  actionName
}
    `;
export const ConnectionFragmentDoc = `
    fragment Connection on EntityRequest {
  id
  createdAt
  updatedAt
  requesterId
  requesterType
  entityId
  entityType
  actionName
  params {
    controlLevel
    referralPercentage
    directResponse {
      unit
      amount
    }
    engagement {
      unit
      amount
    }
    video {
      unit
      amount
    }
  }
}
    `;
export const ConnectionsDocument = `
    query Connections($paging: PagingInput, $sorting: [SortFieldInput!], $filters: [FilterInput!]) {
  entityRequests(paging: $paging, sorting: $sorting, filters: $filters) {
    sorting {
      name
      dir
    }
    paging {
      offset
      limit
      totalCount
    }
    nodes {
      ...ConnectionListItem
    }
  }
}
    ${ConnectionListItemFragmentDoc}`;
export const useConnectionsQuery = <TData = ConnectionsQuery, TError = ApolloError>(
  variables?: ConnectionsQueryVariables,
  options?: UseQueryOptions<ConnectionsQuery, TError, TData>,
) =>
  useQuery<ConnectionsQuery, TError, TData>(
    ['Connections', variables],
    fetcher<ConnectionsQuery, ConnectionsQueryVariables>(ConnectionsDocument, variables),
    options,
  );
export const ConnectionDocument = `
    query Connection($id: String!) {
  entityRequest(id: $id) {
    ...Connection
  }
}
    ${ConnectionFragmentDoc}`;
export const useConnectionQuery = <TData = ConnectionQuery, TError = ApolloError>(
  variables: ConnectionQueryVariables,
  options?: UseQueryOptions<ConnectionQuery, TError, TData>,
) =>
  useQuery<ConnectionQuery, TError, TData>(
    ['Connection', variables],
    fetcher<ConnectionQuery, ConnectionQueryVariables>(ConnectionDocument, variables),
    options,
  );
export const CreateConnectionDocument = `
    mutation CreateConnection($input: EntityRequestInput!) {
  createEntityRequest(input: $input) {
    id
  }
}
    `;
export const useCreateConnectionMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<CreateConnectionMutation, TError, CreateConnectionMutationVariables, TContext>,
) =>
  useMutation<CreateConnectionMutation, TError, CreateConnectionMutationVariables, TContext>(
    (variables?: CreateConnectionMutationVariables) =>
      fetcher<CreateConnectionMutation, CreateConnectionMutationVariables>(CreateConnectionDocument, variables)(),
    options,
  );
