import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { InfoPopup } from 'components/InfoPopup';
import { OrganizationEditPage } from '../OrganizationEditPage';
import { useToasts } from 'react-toast-notifications';
import { ScutiInput } from 'components/material/ScutiInput';
import { useDebounce } from 'use-debounce';
import { Filter_Type } from 'types/__generated__/types';
import { Box, Grid } from '@material-ui/core';
import { TopNavigation } from 'components/material/TopNavigation';
import { PaginationLayout } from 'layouts/PaginationLayout';
import { usePaging } from 'hooks/usePaging';
import { useOrganizationsTableColumns } from './useOrganizationsTableColumns';
import { useSortBy, useTable } from 'react-table';
import { useSorting } from 'hooks/useSorting';
import { ScutiTable } from 'components/ScutiTable';
import { defaultSorting } from 'types/app-types';
import { OrganizationListItemFragment, useOrganizationsQuery } from 'graphql/__generated__/organization.hooks';
import { Link } from 'react-router-dom';
import { organizationsRoutes } from 'routing';

export const OrganizationsListPage: React.FC = observer(() => {
  const { addToast } = useToasts();
  const paging = usePaging();

  const [organizations, setOrganizations] = useState<OrganizationListItemFragment[]>([]);

  const columns = useOrganizationsTableColumns();
  const tableInstance = useTable({ columns, data: organizations, manualSortBy: true }, useSortBy);
  const sorting = useSorting(tableInstance.state.sortBy, defaultSorting);

  const [searchInput, setSearchInput] = useState<string>('');
  const [inputValue] = useDebounce(searchInput, 500);
  const [deleteId, setDeleteId] = useState<string | null>(null);

  const { data, error, isLoading } = useOrganizationsQuery(
    {
      paging,
      filters: inputValue
        ? [
            {
              name: 'search',
              operator: Filter_Type.Like,
              value: [inputValue],
            },
          ]
        : undefined,
      sorting,
    },
    {
      enabled: !!sorting.length,
      keepPreviousData: true,
      onSuccess: ({ organizations }) => setOrganizations(organizations.nodes),
    },
  );

  useEffect(() => {
    if (error) addToast(error.message, { appearance: 'error', autoDismiss: false });
  }, [addToast, error]);

  const showOrganizations = !!organizations.length && !isLoading;
  const pagingResponse = data?.organizations.paging;

  return (
    <PaginationLayout
      isLoading={isLoading}
      paging={pagingResponse}
      header={
        <TopNavigation title="Organization Management">
          <Grid container justifyContent="flex-end" alignItems="center" spacing={1}>
            <Grid item md={5}>
              <ScutiInput
                placeholder="Search..."
                value={searchInput}
                onChange={({ target }) => setSearchInput(target.value)}
              />
            </Grid>
            <Grid item md={3}>
              <Link className="btn btn-primary d-block" to={organizationsRoutes.ORGANIZATION_NEW}>
                Add new Organization
              </Link>
            </Grid>
          </Grid>
        </TopNavigation>
      }
      content={
        showOrganizations && (
          <Box>
            {!!deleteId && (
              <InfoPopup
                title="Archive shop?"
                description="This shop will be archived. You’ll be able to access them under your archived shops."
                proceedText="Archive"
                cancelText="Cancel"
                onProceed={() => setDeleteId(null)}
                onCancel={() => setDeleteId(null)}
              />
            )}
            <ScutiTable table={tableInstance} />
          </Box>
        )
      }
    />
  );
});
