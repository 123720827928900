import React from 'react';
import { ScutiInput } from 'components/material/ScutiInput';
import { ScutiImgInput } from 'components/material/ScutiImgInput';
import { Box, Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import ImageIcon from '@mui/icons-material/Image';
import { CustomInput } from 'reactstrap';
import { UseFormReturn, Controller } from 'react-hook-form';
import { ShopFormData } from './ShopEditForm.utils';
import { FormHelperText } from '@mui/material';

interface Props {
  form: UseFormReturn<ShopFormData>;
}

export const ShopEditDetails: React.FC<Props> = ({ form }) => {
  return (
    <Card>
      <CardHeader title="Details" />
      <CardContent>
        <Box>
          <Controller
            control={form.control}
            name="name"
            render={({ field, fieldState }) => (
              <ScutiInput
                {...field}
                title="Title *"
                placeholder="Title of your shop"
                error={fieldState.error?.message}
              />
            )}
          />
        </Box>
        <Box mt={2}>
          <Controller
            control={form.control}
            name="description"
            render={({ field, fieldState }) => (
              <ScutiInput
                {...field}
                title="Description"
                multiline
                minRows={8}
                placeholder="Description of your shop"
                error={fieldState.error?.message}
              />
            )}
          />
        </Box>
        <Box display="flex" flexDirection="column" mt={2}>
          <label>Shop Image</label>
          <Controller
            control={form.control}
            name="thumbnail"
            render={({ field, fieldState }) => {
              const error = fieldState.error?.message;
              return (
                <Box>
                  <ScutiImgInput image={field.value} onChange={field.onChange} error={!!error}>
                    <ImageIcon
                      color="secondary"
                      style={{ display: 'block', width: '50%', height: '50%', margin: '0 auto' }}
                    />
                  </ScutiImgInput>
                  <FormHelperText>1x1 img aspect ratio is expected</FormHelperText>
                  {error && <FormHelperText error>{error}</FormHelperText>}
                </Box>
              );
            }}
          />
        </Box>
        <Box mt={2}>
          <Box mb={1} display="flex" justifyContent="space-between">
            eBridge ID
            <Controller
              control={form.control}
              name="shopifyEnabled"
              render={({ field }) => {
                return (
                  <CustomInput
                    id="shopify-box"
                    type="checkbox"
                    onChange={({ target }) => {
                      field.onChange(target.checked);
                    }}
                    checked={!!field.value}
                  />
                );
              }}
            />
          </Box>
          <Controller
            control={form.control}
            name="shopifyName"
            render={({ field, fieldState }) => (
              <ScutiInput
                {...field}
                disabled={!form.watch('shopifyEnabled')}
                placeholder="Name of eCommerce Store"
                error={fieldState.error?.message}
              />
            )}
          />
        </Box>
      </CardContent>
    </Card>
  );
};
