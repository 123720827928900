import React from 'react';
import { useLocation, useHistory } from 'react-router';
import queryString from 'query-string';
import { PagingInput } from 'types/__generated__/types';

const getPagingQueryStr = ({ offset, limit }: PagingInput): string => {
  return queryString.stringify({ offset, limit });
};

export function usePageClick(): (paging: PagingInput) => void {
  const { push } = useHistory();
  const { pathname } = useLocation();
  return React.useCallback(
    (paging: PagingInput) => {
      push(`${pathname}?${getPagingQueryStr(paging)}`);
    },
    [pathname, push],
  );
}
