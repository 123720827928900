import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { InfoPopup } from 'components/InfoPopup';
import { Box, Grid } from '@material-ui/core';
import { TopNavigation } from 'components/material/TopNavigation';
import { PaginationLayout } from 'layouts/PaginationLayout';
import { Filter_Type } from 'types/__generated__/types';
import { useDebounce } from 'use-debounce/lib';
import { usePaging } from 'hooks/usePaging';
import { useTable, useSortBy } from 'react-table';
import { ScutiInput } from 'components/material/ScutiInput';
import { useBrandsTableColumns } from './useBrandsTableColumns';
import { useSorting } from 'hooks/useSorting';
import { ScutiTable } from 'components/ScutiTable';
import { defaultSorting } from 'types/app-types';
import { useBrandsQuery, useDeleteBrandMutation } from 'graphql/__generated__/brand.hooks';

export const BrandsListPage: React.FC = observer(() => {
  const paging = usePaging();
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [searchInput, setSearchInput] = useState<string>('');
  const [inputValue] = useDebounce(searchInput, 500);

  const onCancel = useCallback(() => setDeleteId(null), []);
  const [brands, setBrands] = useState<any[]>([]);
  const columns = useBrandsTableColumns(setDeleteId);
  const tableInstance = useTable({ columns, data: brands, manualSortBy: true }, useSortBy);
  const sorting = useSorting(tableInstance.state.sortBy, defaultSorting);

  const useDeleteBrand = useDeleteBrandMutation();

  const useBrands = useBrandsQuery(
    {
      paging,
      filters: inputValue
        ? [
            {
              name: 'search',
              operator: Filter_Type.Like,
              value: [inputValue],
            },
          ]
        : undefined,
      sorting,
    },
    {
      keepPreviousData: true,
      onSuccess: ({ brands }) => {
        setBrands(brands.nodes);
      },
    },
  );

  const onArchive = useCallback(async () => {
    if (deleteId) {
      await useDeleteBrand.mutateAsync({ id: deleteId });
      await useBrands.refetch();
    }
    setDeleteId(null);
  }, [deleteId, useBrands, useDeleteBrand]);

  const isLoading = useBrands.isLoading;

  const pagingResponse = useBrands.data?.brands.paging;
  const showBrands = !!brands.length && !isLoading;

  return (
    <PaginationLayout
      isLoading={isLoading}
      paging={pagingResponse}
      header={
        <TopNavigation title="Brand Management">
          <Grid container justifyContent="flex-end">
            <Grid item md={6}>
              <ScutiInput
                placeholder="Search"
                value={searchInput}
                onChange={({ target }) => setSearchInput(target.value)}
              />
            </Grid>
          </Grid>
        </TopNavigation>
      }
      content={
        showBrands && (
          <>
            {!!deleteId && (
              <InfoPopup
                title="Archive brand?"
                description="This brand will be archived. You’ll be able to access them under your archived brands."
                proceedText="Archive"
                cancelText="Cancel"
                onProceed={onArchive}
                onCancel={onCancel}
              />
            )}
            {showBrands && <ScutiTable table={tableInstance} />}
          </>
        )
      }
    />
  );
});
