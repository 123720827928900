import React from 'react';
import classNames from 'classnames';
import {
  Button,
  Card,
  CardBody,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupButtonDropdown,
} from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { PhoneRegisterRequest } from '../../../types/auth-types';
import { CodesDropDown } from './CodesDropDown';
import { validate, validators } from 'utils/form-utils';

interface FormValues {
  phone: string;
  countryCode: number;
}

const validationSchema = () =>
  Yup.object().shape({
    phone: validators.phone,
    countryCode: validators.countryCode,
  });

const initialValues = {
  phone: '',
  countryCode: 1,
};

interface RegisterPhoneFormProps {
  onRegister: (params: PhoneRegisterRequest) => void;
  error?: string;
}

export const RegisterPhoneForm: React.FC<RegisterPhoneFormProps> = ({ onRegister, error }) => {
  const [isOpen, toggleIsOpen] = React.useState(false);
  const toggleDropDown = () => toggleIsOpen(!isOpen);

  const onCountryCodeChange = (setFieldValue: any) => (code: number) => {
    setFieldValue('countryCode', code, true);
  };

  const onSubmit = (values: FormValues) => {
    onRegister({ countryCode: values.countryCode, phone: `+${values.countryCode}${values.phone}` });
  };

  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
    },
    onSubmit: onSubmit,
    isInitialValid: false,
    validate: validate(validationSchema, (values) => ({ ...values })),
  });
  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isValid } = formik;
  return (
    <Card className="w-23">
      <CardBody>
        <div className="card-headline">
          <h1>What is your number?</h1>
          <p>Please provide your mobile number so we can text you a code. Data and messaging rates may apply.</p>
        </div>
        <Form onSubmit={handleSubmit} noValidate name="simpleForm">
          <FormGroup>
            <InputGroup
              className={classNames('input-group-dropdown', 'option-btn-left', 'd-flex', { 'show-drop': isOpen })}
            >
              <Input
                className="order-2"
                type="text"
                name="phone"
                id="phone"
                placeholder="Phone number"
                valid={touched.phone && !errors.phone}
                invalid={touched.phone && !!errors.phone}
                required
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone}
              />
              <InputGroupButtonDropdown className="order-1" addonType="prepend" isOpen={isOpen} toggle={toggleDropDown}>
                <CodesDropDown className="order-4" onChange={onCountryCodeChange(setFieldValue)} />
              </InputGroupButtonDropdown>
              <FormFeedback className="order-3">{errors.phone || errors.countryCode}</FormFeedback>
            </InputGroup>
          </FormGroup>
          {error && <div className="text-center text-danger mb-1">{error}</div>}
          <FormGroup className="actions">
            <Button type="submit" color="primary" className="btn-block" disabled={!isValid}>
              Send my a code
            </Button>
          </FormGroup>
        </Form>
      </CardBody>
    </Card>
  );
};
