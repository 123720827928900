import * as Yup from 'yup';
import { FormValidationSchema } from 'utils/form-utils';
import { ShopInput, UpdateShopInput } from 'types/__generated__/types';
import { v4 } from 'uuid';
import { getImgAspectRatio, uploadImage } from 'utils/upload-image.utils';

const FILE_SIZE = 3 * 1024 * 1024;
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

export const shopSchema = Yup.object().shape({
  id: Yup.string(),
  name: Yup.string()
    .min(2, 'Title is too short. Min size is 2 characters.')
    .max(100, 'Title is too long. Max size is 100 characters.')
    .required('Title is required'),
  description: Yup.string()
    .min(12, 'Description is too short. Min size is 12 characters.')
    .max(10000, 'Description is too long. Max size is 10000 characters.')
    .nullable(),
  thumbnail: Yup.mixed<File | string>()
    .nullable()
    .test('fileSize', 'File too large', (value) => {
      return value && typeof value !== 'string' ? value.size <= FILE_SIZE : true;
    })
    .test('fileFormat', 'Unsupported Format', (value) =>
      value && typeof value !== 'string' ? SUPPORTED_FORMATS.includes(value.type) : true,
    )
    .test('aspectRatio', 'Wrong aspect ratio 1x1 is expected', async (value) => {
      if (value && typeof value !== 'string') {
        const [width, height] = await getImgAspectRatio(value);
        return width === height;
      }
      return true;
    })
    .nullable()
    .notRequired(),
  rewardsIncluded: Yup.boolean().required(),
  shopifyEnabled: Yup.boolean(),
  shopifyName: Yup.string().nullable(),
  returnPolicy: Yup.string().nullable(),
  returnPolicyUrl: Yup.string()
    .when('returnPolicy', {
      is: (value: string) => !value,
      then: (schema) => schema.required('Return Policy or Return Policy URL is required').url('Invalid URL format'),
      otherwise: (schema) => schema.url('Invalid URL format').nullable(),
    })
    .nullable(),
  country: Yup.string().nullable(),
  referralFee: Yup.number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(0, 'Referral Fee cannot be negative')
    .max(100, 'Referral Fee cannot greater than 100')
    .nullable(),
  rewardsPercentage: Yup.number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(0, 'Rewards Fee cannot be negative')
    .max(100, 'Rewards Fee cannot greater than 100')
    .nullable(),
  flatShippingRate: Yup.number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(0, 'Flat Rate cannot be negative')
    .required('Flat Rate is required'),
  freeShippingThreshold: Yup.number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(0, 'Threshold cannot be negative')
    .test({
      name: 'requiredIfFlatShippingRate',
      message: 'Threshold is required if Flat Rate is provided',
      test: function (value) {
        const flatShippingRate: number = this.resolve(Yup.ref('flatShippingRate')) || 0;
        return flatShippingRate > 0 ? !!value : true;
      },
    })
    .transform((value) => (isNaN(value) ? null : value))
    .nullable(),
  shippings: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().required(),
        amount: Yup.number().required(),
        freeShippingThreshold: Yup.number().nullable(),
        country: Yup.string().required(),
      }),
    )
    .nullable(),
  brands: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().required(),
        name: Yup.string().required('Name is required'),
        imageUrl: Yup.mixed<File | string>()
          .nullable()
          .test('fileSize', 'File too large', (value) => {
            return value && typeof value !== 'string' ? value.size <= FILE_SIZE : true;
          })
          .test('fileFormat', 'Unsupported Format', (value) =>
            value && typeof value !== 'string' ? SUPPORTED_FORMATS.includes(value.type) : true,
          )
          .test('aspectRatio', 'Wrong aspect ratio', async (value) => {
            if (value && typeof value !== 'string') {
              const [width, height] = await getImgAspectRatio(value);
            }
            return true;
          })
          .nullable(),
      }),
    )
    .nullable(),
  mediaInvestmentEnabled: Yup.boolean().nullable(),
  directResponse: Yup.object()
    .shape({
      unit: Yup.string().required(),
      amount: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .min(0)
        .required(),
    })
    .nullable(),
  engagement: Yup.object()
    .shape({
      unit: Yup.string().required(),
      amount: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .min(0)
        .required(),
    })
    .nullable(),
  video: Yup.object()
    .shape({
      unit: Yup.string().required(),
      amount: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .min(0)
        .required(),
    })
    .nullable(),
});

export type ShopFormData = Yup.InferType<typeof shopSchema>;

export const shopFormDefaults = (): ShopFormData => ({
  id: undefined,
  thumbnail: null,
  name: '',
  shopifyName: null,
  shopifyEnabled: false,
  description: null,
  flatShippingRate: 0,
  freeShippingThreshold: null,
  referralFee: 0,
  rewardsPercentage: 0,
  rewardsIncluded: false,
  country: null,
  returnPolicy: null,
  returnPolicyUrl: null,
  shippings: [],
  brands: [],
  mediaInvestmentEnabled: false,
  directResponse: { unit: 'cpm', amount: 0 },
  engagement: { unit: 'cpm', amount: 0 },
  video: { unit: 'cpm', amount: 0 },
});

export const mapShopToApiInput = async (formData: ShopFormData): Promise<ShopInput | UpdateShopInput> => {
  return {
    ...formData,
    brands: await Promise.all(
      formData.brands
        ?.filter((brand) => !!brand.name)
        .map(async (brand) => ({
          ...brand,
          imageUrl:
            brand.imageUrl && typeof brand.imageUrl === 'object'
              ? (await uploadImage(brand.imageUrl, { width: 300, height: 300 })).url
              : brand.imageUrl || null,
        })) || [],
    ),
    thumbnail:
      formData.thumbnail && typeof formData.thumbnail === 'object'
        ? (await uploadImage(formData.thumbnail, { width: 300, height: 300 })).url
        : formData.thumbnail,
    engagement: formData.engagement?.amount ? formData.engagement : null,
    directResponse: formData.directResponse?.amount ? formData.directResponse : null,
    video: formData.video?.amount ? formData.video : null,
    referralFee: formData.referralFee ? formData.referralFee / 100 : formData.referralFee,
    rewardsPercentage: formData.rewardsPercentage ? formData.rewardsPercentage / 100 : formData.rewardsPercentage,
  };
};
