import styles from './TodayDeals.module.scss';
import { Box, Card, CardContent, CardHeader } from '@material-ui/core';
import { ScutiAutocomplete } from 'components/material/ScutiAutoComplete';
import { useMemo, useState } from 'react';
import { CampaignType, Filter_Type } from 'types/__generated__/types';
import { useListingOptionsQuery, useTodayDealsQuery } from './__generated__/today-deals.hooks';
import { Grid } from '@mui/material';
import { BadgeClose } from 'components/material/BadgeClose';
import { Button } from 'reactstrap';
import { Loader } from 'components/Loader/Loader';
import { useAppStore } from 'store/app-store.hook';

interface SelectedOption {
  value: string[];
  label: string;
}

interface Props {
  onSave: (ids: string[]) => void;
  onCancel: () => void;
}

export const TodayDeals: React.FC<Props> = ({ onSave, onCancel }: Props) => {
  const { selectedItem } = useAppStore().uiStore;
  const [searchInput, setSearchInput] = useState<string>('');
  const [selectedDeals, setSelectedDeals] = useState<SelectedOption[]>([]);
  const useListingOptions = useListingOptionsQuery(
    {
      id: selectedItem.id,
      filters: [
        {
          name: 'type',
          operator: Filter_Type.In,
          value: [
            CampaignType.Organization,
            CampaignType.ShopAdvertisement,
            CampaignType.ShopBrand,
            CampaignType.SingleItem,
          ],
        },
      ],
    },
    {
      keepPreviousData: true,
    },
  );

  const useTodayDeals = useTodayDealsQuery(
    { shopId: selectedItem.id },
    {
      onSuccess: (data) => {
        const todayDealsProducts =
          data.todayDeals.reduce((acc: { id: string; name: string; campaignId: string }[], campaign) => {
            const { id, name } = campaign.product!;
            return [
              ...acc,
              {
                id,
                name: name + `${campaign.type === CampaignType.Organization ? ` - ${campaign.name} (Campaign)` : ''}`,
                campaignId: campaign.id,
              },
            ];
          }, []) || [];
        const todayDealsProductsOptions = todayDealsProducts.map((p) => ({
          value: [p.id, p.campaignId],
          label: p.name,
        }));
        setSelectedDeals(todayDealsProductsOptions);
      },
    },
  );

  const products = useMemo(() => {
    return (
      useListingOptions.data?.shopCampaigns.nodes?.reduce(
        (
          acc: { id: string; name: string; campaignName: string; type: CampaignType; campaignId: string }[],
          { id, product, name, type },
        ) => {
          return product
            ? [
                ...acc,
                {
                  ...product,
                  campaignId: id,
                  type,
                  campaignName: name,
                },
              ]
            : acc;
        },
        [],
      ) || []
    );
  }, [useListingOptions.data?.shopCampaigns.nodes]);

  const productOptions = useMemo(() => {
    const notProductCampaigns = (useListingOptions.data?.shopCampaigns.nodes || []).filter(({ type }) =>
      ['PRODUCT_LISTING', 'PRODUCT'].every((t) => t !== type),
    );

    return [
      ...products
        .map((p) => ({
          value: [p.id, p.campaignId],
          label: p.name + `${p.type === CampaignType.Organization ? ` - ${p.campaignName} (Campaign)` : ''}`,
        }))
        .filter(({ label }) => label.toLowerCase().includes(searchInput.toLowerCase()))
        .filter(({ label }) => !selectedDeals.some((s) => s.label === label))
        .sort((a, b) => a.label.localeCompare(b.label)),
      ...notProductCampaigns
        .map((c) => ({
          value: [c.id, c.id],
          label: c.name,
        }))
        .filter(({ label }) => label.toLowerCase().includes(searchInput.toLowerCase()))
        .filter(({ label }) => !selectedDeals.some((s) => s.label === label)),
    ];
  }, [products, searchInput, selectedDeals, useListingOptions.data?.shopCampaigns.nodes]);

  const isLoading = useListingOptions.isLoading || useTodayDeals.isLoading;

  return isLoading ? (
    <Loader />
  ) : (
    <Card className={styles.today_deals}>
      <CardHeader title="Today Deals" />
      <CardContent>
        <Box display="flex" flexDirection="column" justifyContent="between">
          <Box>
            <ScutiAutocomplete
              placeholder="Select option"
              title="Deals options"
              options={productOptions}
              autoHighlight
              clearOnBlur
              isOptionEqualToValue={() => true}
              onInputChange={(_, value, reason) => {
                if ('input' === reason) setSearchInput(value);
              }}
              onChange={(_event, value, reason) => {
                if ('selectOption' === reason && !!value) {
                  if (selectedDeals.every((s) => s.value[1] !== value.value[1])) {
                    setSelectedDeals([...selectedDeals, value]);
                  }
                }
              }}
            />
            <Box mt={2}>
              <Grid container spacing={1}>
                {selectedDeals.map(({ label, value }, _index) => (
                  <Grid item key={value[1]}>
                    <BadgeClose
                      title={label}
                      onClose={() => {
                        setSelectedDeals(selectedDeals.filter((s) => s.value[1] !== value[1]));
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
          <Box mt={2} display="flex" alignItems="center" justifyContent="flex-end">
            <Button color="secondary" onClick={onCancel}>
              Cancel
            </Button>
            <Box ml={1}>
              <Button
                color="primary"
                onClick={() => {
                  onSave(selectedDeals.map(({ value }) => value[1]));
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
