import { CardHeaderClassKey, StyleRules } from '@material-ui/core';
import { scutiColors, scutiSizes } from './variables';

export const MuiCardHeader: Partial<StyleRules<CardHeaderClassKey, {}>> = {
  root: {
    backgroundColor: scutiColors.cardHeaderBackground,
    color: scutiColors.textMain,
    padding: '12px 20px',
  },
  title: {
    fontSize: scutiSizes.inputFontSize,
    fontWeight: 'bold',
    lineHeight: '20px',
  },
};
