import React from 'react';
import { Box, Card, CardHeader, CardContent } from '@material-ui/core';
import { ScutiSelect } from 'components/material/ScutiSelect';
import { ProductListingFormData } from './ProductListingEditForm.utils';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { ScutiAutocomplete } from 'components/material/ScutiAutoComplete';
import { useDebounce } from 'use-debounce/lib';
import { Filter_Type } from 'types/__generated__/types';
import { useProductCategories } from 'hooks/useProductCategories';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useListingBrandsQuery, useListingProductsQuery } from 'graphql/__generated__/product-listing.hooks';

interface Props {
  form: UseFormReturn<ProductListingFormData>;
  paused?: boolean;
}

export function ProductDetails({ form, paused }: Props) {
  const { search } = useLocation();
  const { shopId } = queryString.parse(search);
  const productCategoriesOptions = useProductCategories();
  const [searchInput, setSearchInput] = React.useState<string>('');
  const [inputValue] = useDebounce(searchInput, 500);
  const listingProductsQuery = useListingProductsQuery(
    {
      id: shopId as string,
      filters: [
        {
          name: 'name',
          operator: Filter_Type.Contains,
          value: [inputValue],
        },
      ],
    },
    { keepPreviousData: true },
  );

  const listingBrands = useListingBrandsQuery({ id: shopId as string });

  const products = listingProductsQuery.data?.shopProducts.nodes || [];
  const brands = listingBrands.data?.shopBrands || [];

  const shopBrand = form.watch('shopBrand');
  const product = form.watch('product');

  const productOptions = products.map((p) => ({ value: p.id, label: p.name }));
  const brandsOptions = brands.map((p) => ({ value: p.id, label: p.name }));
  const selectedBrand = shopBrand || { id: '', name: '' };
  const selectedProduct = product || { id: '', name: '' };

  return (
    <Card>
      <CardHeader title="Product" />
      <CardContent>
        <Box>
          <Controller
            control={form.control}
            name="product"
            render={({ field, fieldState }) => (
              <ScutiAutocomplete
                placeholder="Select Product"
                title="Product *"
                getOptionLabel={(option) => option.label}
                value={productOptions.find(({ value }) => value === selectedProduct.id) || null}
                options={productOptions}
                onInputChange={(_, value, reason) => {
                  if ('input' === reason) setSearchInput(value);
                }}
                onChange={(event, value, reason) => {
                  if ('selectOption' === reason && !!value) {
                    field.onChange({ id: value.value, name: value.label });
                  }
                  if ('clear' === reason || !value) {
                    field.onChange({ id: '', name: '' });
                  }
                }}
                onBlur={field.onBlur}
                error={fieldState.error?.message}
                disabled={!paused}
              />
            )}
          />
        </Box>
        <Box mt={2}>
          <Controller
            control={form.control}
            name="shopBrand"
            render={({ field, fieldState }) => (
              <ScutiAutocomplete
                placeholder="Select Brand"
                title="Brand *"
                getOptionLabel={(option) => option.label}
                value={brandsOptions.find(({ value }) => value === selectedBrand.id) || null}
                options={brandsOptions}
                onChange={(event, value, reason) => {
                  if ('selectOption' === reason && !!value) {
                    field.onChange({ id: value.value, name: value.label });
                  }
                  if ('clear' === reason || !value) {
                    field.onChange({ id: '', name: '' });
                  }
                }}
                onBlur={field.onBlur}
                error={fieldState.error?.message}
                disabled={!paused}
              />
            )}
          />
        </Box>
        <Box mt={2}>
          <Controller
            control={form.control}
            name="productListingCategory"
            render={({ field, fieldState }) => (
              <ScutiSelect
                title="Category *"
                placeholder="Select category"
                {...field}
                options={productCategoriesOptions}
                error={fieldState.error?.message}
                disabled={!paused}
              />
            )}
          />
        </Box>
      </CardContent>
    </Card>
  );
}
