import React, { Suspense } from 'react';
import './WithoutAuthLayout.scss';

import { ScutiLogo } from 'components/svg-icons/ScutiLogo';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from 'layouts/ErrorFallback';

export const WithoutAuthLayout: React.FC = ({ children }) => {
  return (
    <div className="non-auth-container">
      <strong className="logo">
        <ScutiLogo />
      </strong>

      {children}
    </div>
  );
};

const loading = () => (
  <div className="animated fadeIn pt-1 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);
export const withWithoutAuthLayout = (Page: React.ComponentType) => () => (
  <WithoutAuthLayout>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense fallback={loading()}>
        <Page />
      </Suspense>
    </ErrorBoundary>
  </WithoutAuthLayout>
);
