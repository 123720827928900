import { Box, List, Typography } from '@material-ui/core';
import { PropsWithChildren } from 'react';
import { SwitcherItem } from './SwitcherItem';

interface Props {
  thumbnail?: string | null;
  name: string;
  color: string;
}

interface SwitcherItemProps<T extends Props> {
  items: T[];
  selected?: T;
  onClick: (item: T) => void;
}

export const SwitcherSection = <T extends Props>({
  items,
  selected,
  onClick,
}: PropsWithChildren<SwitcherItemProps<T>>) => {
  return (
    <Box mt={2}>
      <List>
        {items.map((item, key) => (
          <SwitcherItem
            key={key}
            img={item.thumbnail}
            name={item.name}
            color={item.color}
            selected={selected?.name === item.name}
            onClick={() => onClick(item)}
          />
        ))}
      </List>
    </Box>
  );
};
