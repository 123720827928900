import React from 'react';
import { Box, Card, CardContent, CardHeader } from '@material-ui/core';
import { ScutiInput } from 'components/material/ScutiInput';
import { Controller, UseFormReturn } from 'react-hook-form';
import { BrandFormData } from './BrandEditForm.utils';

interface Props {
  form: UseFormReturn<BrandFormData>;
}

export const BrandEditScutiCredentials: React.FC<Props> = ({ form }) => {
  return (
    <Card>
      <CardHeader title="Posting Credentials for Scuti" />
      <CardContent>
        <Box>
          <Controller
            control={form.control}
            name="credentials.login"
            render={({ field, fieldState }) => (
              <ScutiInput title="Login" placeholder="sysSCI" error={fieldState.error?.message} {...field} />
            )}
          />
        </Box>
        <Box mt={2}>
          <Controller
            control={form.control}
            name="credentials.pin"
            render={({ field, fieldState }) => (
              <ScutiInput title="Pin" placeholder="SCI6899" error={fieldState.error?.message} {...field} />
            )}
          />
        </Box>
      </CardContent>
    </Card>
  );
};
