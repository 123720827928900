import React from 'react';
import { Box, Card, CardContent, CardHeader } from '@material-ui/core';
import { CustomInput, FormGroup, Input, Label } from 'reactstrap';
import { Controller, UseFormReturn } from 'react-hook-form';
import { CONTROL_LEVELS, ConnectFormData } from './ConnectEditForm.utils';

interface Props {
  form: UseFormReturn<ConnectFormData>;
}

export function ConnectControlLevel({ form }: Props) {
  const disabled = !!form.watch('id');

  return (
    <Card>
      <CardHeader title="Control Level" />
      <CardContent>
        <Box>
          <FormGroup check inline>
            <Label check style={{ paddingRight: '12px' }}>
              Full Admin Control
            </Label>
            <Controller
              control={form.control}
              name="controlLevel"
              render={({ field }) => (
                <CustomInput
                  type="checkbox"
                  id="full-admin-box"
                  checked={field.value === CONTROL_LEVELS.FULL_ADMIN}
                  disabled={disabled}
                  onChange={({ target }) => field.onChange(target.checked ? CONTROL_LEVELS.FULL_ADMIN : undefined)}
                />
              )}
            />
          </FormGroup>
        </Box>
        <Box mt={2}>
          <FormGroup check inline>
            <Label check style={{ paddingRight: '12px' }}>
              Campaign Control Only
            </Label>
            <Controller
              control={form.control}
              name="controlLevel"
              render={({ field }) => (
                <CustomInput
                  type="checkbox"
                  id="campaign-only-box"
                  checked={field.value === CONTROL_LEVELS.CAMPAIGN_ONLY}
                  disabled={disabled}
                  onChange={({ target }) => field.onChange(target.checked ? CONTROL_LEVELS.CAMPAIGN_ONLY : undefined)}
                />
              )}
            />
          </FormGroup>
        </Box>
        <Box mt={2}>
          <FormGroup check inline>
            <Label check style={{ paddingRight: '12px' }}>
              Read Only
            </Label>
            <Controller
              control={form.control}
              name="controlLevel"
              render={({ field }) => (
                <CustomInput
                  type="checkbox"
                  id="read-only-box"
                  checked={field.value === CONTROL_LEVELS.READ_ONLY}
                  disabled={disabled}
                  onChange={({ target }) => field.onChange(target.checked ? CONTROL_LEVELS.READ_ONLY : undefined)}
                />
              )}
            />
          </FormGroup>
        </Box>
      </CardContent>
    </Card>
  );
}
