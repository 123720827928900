import React from 'react';
import { ScutiInput } from 'components/material/ScutiInput';
import { FormikProps } from 'formik';
import { ConnectFormData } from './ConnectEditForm.utils';
import { Box, Card, CardContent, CardHeader } from '@material-ui/core';
import { Controller, UseFormReturn } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'store/app-store.hook';
import { RequestEntityType } from 'types/__generated__/types';

interface Props {
  form: UseFormReturn<ConnectFormData>;
}

export const ConnectEditDetails = observer(function ConnectEditDetails({ form }: Props) {
  const { uiStore } = useAppStore();

  const itemType = uiStore.selectedItem.itemType;

  const title = itemType !== undefined ? `${itemType} ID` : 'ID';
  const placeholder = itemType !== undefined ? `Scuti ${itemType} ID` : 'ID';

  const disabled = !!form.watch('id');

  return (
    <Card>
      <CardHeader title="Details" />
      <CardContent>
        <Box mt={2}>
          <Controller
            control={form.control}
            name="entityId"
            render={({ field, fieldState }) => (
              <ScutiInput
                title={title}
                placeholder={placeholder}
                {...field}
                error={fieldState.error?.message}
                disabled={disabled}
              />
            )}
          />
        </Box>
      </CardContent>
    </Card>
  );
});
