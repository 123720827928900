import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { ScutiInput } from 'components/material/ScutiInput';
import { ScutiSelect } from 'components/material/ScutiSelect';
import { Button } from 'reactstrap';
import { Role } from 'types/__generated__/types';
import { Controller, UseFormReturn } from 'react-hook-form';
import { InvitesFormData } from './InvitesEditForm.utils';

interface Props {
  rowIndex: number;
  form: UseFormReturn<InvitesFormData>;
  onRemove: () => void;
  onResend: (email: string) => void;
}

export const InviteEditRow: React.FC<Props> = ({ rowIndex, form, onRemove, onResend }) => {
  const invite = form.watch(`invites.${rowIndex}`);

  return (
    <Grid item container spacing={2} key={rowIndex}>
      <Grid item container xs={9} spacing={2}>
        <Grid item xs={8}>
          <Controller
            control={form.control}
            name={`invites.${rowIndex}.email`}
            render={({ field, fieldState }) => (
              <ScutiInput error={fieldState.error?.message} {...field} disabled={!!invite.id} />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          {invite.id ? (
            <ScutiInput defaultValue={invite.role} value={invite.role} disabled />
          ) : (
            <Controller
              control={form.control}
              name={`invites.${rowIndex}.role`}
              render={({ field, fieldState }) => (
                <ScutiSelect
                  placeholder="Select Role"
                  options={[{ label: 'Shop Admin', value: Role.ShopAdmin }]}
                  error={fieldState.error?.message}
                  {...field}
                />
              )}
            />
          )}
        </Grid>
      </Grid>
      <Grid item xs={2}>
        {!!invite.id && (
          <Box display="flex">
            <Box mt={1} mr={1}>
              <label>{invite.accepted ? '' : 'Not '}Accepted</label>
            </Box>
            {!invite.accepted && (
              <Button color="primary" onClick={() => onResend(invite.email)}>
                Resend Invite
              </Button>
            )}
          </Box>
        )}
      </Grid>
      <Grid item xs={1}>
        <Button className="btn-close" onClick={onRemove} />
      </Grid>
    </Grid>
  );
};
