import React from 'react';
import { FormGroup, Input, FormFeedback, Label } from 'reactstrap';
import { observer } from 'mobx-react-lite';
import InputRange, { Range } from 'react-input-range';
import { Card, CardContent, CardHeader, Box } from '@material-ui/core';
import { ScutiInput } from 'components/material/ScutiInput';
import { GameForm } from './GameEditForm.utils';
import { FormikProps } from 'formik';
import { ContentCategory } from 'types/__generated__/types';

export const GameEditDemographics: React.FC<FormikProps<GameForm>> = observer((props) => {
  const { values, touched, handleBlur, setFieldValue } = props;
  const errors = props.errors as any;
  const onRangeChange = React.useCallback(
    (v: Range | number) => {
      if (typeof v === 'number') return;
      setFieldValue(`ageFrom`, v.min, true);
      setFieldValue(`ageTo`, v.max, true);
    },
    [setFieldValue],
  );

  const onContentCategoryChange = React.useCallback(
    (v) => {
      setFieldValue(`contentCategory`, v.target.value, true);
    },
    [setFieldValue],
  );

  const onMaleChange = React.useCallback(
    (e) => {
      const { value } = e.target;
      const v = Math.min(Math.max(0, +value), 100);
      setFieldValue(`genderPercent.male`, v, true);
      setFieldValue(`genderPercent.female`, 100 - v, true);
    },
    [setFieldValue],
  );

  const onFemaleChange = React.useCallback(
    (e) => {
      const { value } = e.target;
      const v = Math.min(Math.max(0, +value), 100);
      setFieldValue(`genderPercent.female`, v, true);
      setFieldValue(`genderPercent.male`, 100 - v, true);
    },
    [setFieldValue],
  );

  return (
    <Card>
      <CardHeader title="Demographics" />
      <CardContent>
        <FormGroup>
          <i className="demographics-icon icon-male push-2"></i>
          <Box className="text-after">
            <ScutiInput
              title="Percent male"
              type="number"
              name="genderPercent.male"
              placeholder="%"
              onChange={onMaleChange}
              onBlur={handleBlur}
              error={touched.genderPercent && errors[`genderPercent.male`]}
              value={values.genderPercent?.male || ''}
              inputProps={{
                min: 0,
                max: 100,
              }}
            />
          </Box>
        </FormGroup>
        <FormGroup>
          <i className="demographics-icon icon-female push-2"></i>
          <Box className="text-after">
            <ScutiInput
              title="Percent female"
              type="number"
              name="genderPercent.female"
              placeholder="%"
              onChange={onFemaleChange}
              onBlur={handleBlur}
              error={touched.genderPercent && errors[`genderPercent.female`]}
              value={values.genderPercent?.female || ''}
              inputProps={{
                min: 0,
                max: 100,
              }}
            />
          </Box>
        </FormGroup>
        <FormGroup>
          <label htmlFor={`range`}>Age Range</label>
          <InputRange
            maxValue={100}
            minValue={0}
            value={{ min: values.ageFrom, max: values.ageTo }}
            onChange={onRangeChange}
          />
          <FormFeedback>{errors[`range`]}</FormFeedback>
        </FormGroup>
        <FormGroup tag="fieldset">
          <label htmlFor={`range`}>Game content category</label>
          <div className="game-categories">
            {Object.values(ContentCategory).map((k) => {
              const selected = values.contentCategory;
              return (
                <FormGroup className="radio" check key={k}>
                  <Label check>
                    <Input
                      type="radio"
                      name={`contentCategory`}
                      value={k}
                      checked={k === selected}
                      onChange={onContentCategoryChange}
                    />{' '}
                    <em className={k}>
                      <span />
                    </em>
                    <i className="radio-icon"></i>
                  </Label>
                </FormGroup>
              );
            })}
          </div>
        </FormGroup>
      </CardContent>
    </Card>
  );
});
