import React from 'react';
import { CardBody, Card, CardHeader } from 'reactstrap';

interface Props {
  name: string;
  thumbnail?: string | null;
  exchangeRate: number;
}

export const CurrencyOverview: React.FC<Props> = ({ name, thumbnail, exchangeRate }) => {
  return (
    <Card className="bg-custom">
      <CardHeader>Currency</CardHeader>
      <CardBody>
        <div className="currency-details">
          <div className="currency-pic">
            {thumbnail ? <img src={thumbnail} alt="" /> : <span className="no-pic-currency" />}
          </div>
          <strong className="currency-name">{name}</strong>
        </div>
        <div className="currency-info">
          <span className="currency-label">Currency value</span>
          <span className="currency-value">{exchangeRate} = $1.00</span>
        </div>
        <p className="currency-note">Uses external game server for granting rewards</p>
      </CardBody>
    </Card>
  );
};
