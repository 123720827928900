import { Box } from '@mui/material';
import { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import cn from 'classnames';
import styles from './ImgPreview.module.scss';

interface Props {
  image: string | File;
  isDefaultImage: boolean;
  onDelete: () => void;
  onDefaultImageUpdate: () => void;
  disabled?: boolean;
}

export const ImgPreview: React.FC<Props> = ({ image, isDefaultImage, disabled, onDelete, onDefaultImageUpdate }) => {
  const [hovered, setHovered] = useState<boolean>(false);

  return (
    <Box
      width="100%"
      height="100%"
      position="relative"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <img
        className={cn({ [styles.img_hovered]: hovered && !disabled, [styles.disabled]: disabled })}
        width="100%"
        height="100%"
        src={typeof image === 'string' ? image : URL.createObjectURL(image)}
        alt={'alt'}
      />
      {!disabled && hovered && (
        <div className={styles.flex}>
          {isDefaultImage ? (
            <CheckBoxIcon className={styles.default_img_icon} onClick={onDefaultImageUpdate} />
          ) : (
            <CheckBoxOutlineBlank className={styles.default_img_icon} onClick={onDefaultImageUpdate} />
          )}
          <DeleteIcon className={styles.delete_icon} onClick={onDelete} />
        </div>
      )}
    </Box>
  );
};
