import axios from 'axios';
import { IMG_API } from 'config';
import { tokenStorage } from 'utils/token-storage.utils';

interface Dimensions {
  width: number;
  height: number;
}

export const uploadImage = async (file: File, dimensions: Dimensions): Promise<{ url: string }> => {
  const token = await tokenStorage.getToken();
  const formData = new FormData();
  formData.append('file', file);
  const { data } = await axios.post(`${IMG_API}?width=${dimensions.width}&height=${dimensions.height}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const getHeightAndWidthFromFile = async (file: File): Promise<{ width: number; height: number }> => {
  const dataURL = window.URL.createObjectURL(file);
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width,
      });
    };
    img.src = dataURL;
  });
};

export const gcd = (a: number, b: number): number => {
  return b ? gcd(b, a % b) : a;
};

const aspectRatio = (width: number, height: number) => {
  const divisor = gcd(width, height);
  return [width / divisor, height / divisor];
};

export const getImgAspectRatio = async (file: File) => {
  const { width, height } = await getHeightAndWidthFromFile(file);
  return aspectRatio(width, height);
};

export const getImgDimensions = async (file: File) => {
  const { width, height } = await getHeightAndWidthFromFile(file);
  return { width, height };
};
