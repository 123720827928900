import { Period } from 'types/app-types';
import { FilterInput, Filter_Type, OrderStatus } from 'types/__generated__/types';

const applyStatus = (statuses: OrderStatus[] | null): FilterInput[] => {
  if (!statuses || !statuses.length) return [];
  return [{ name: 'status', operator: Filter_Type.In, value: statuses }];
};

const applyFilter = (filter: string | null): FilterInput[] => {
  if (!filter) return [];
  return [{ name: 'userFulltext', operator: Filter_Type.Contains, value: [filter] }];
};

const getDateString = (date: Date | null) => {
  if (!date) return '';
  return date.toISOString();
};

const applyPeriod = (period: Period | null): FilterInput[] => {
  if (!period) return [];
  return [
    {
      name: 'createdAt',
      operator: Filter_Type.Between,
      value: [getDateString(period.startDate), getDateString(period.endDate)],
    },
  ];
};

export const applyFilters = (
  statuses: OrderStatus[] | null,
  period: Period | null = null,
  filter: string | null = null,
): FilterInput[] => {
  return [...applyStatus(statuses), ...applyPeriod(period), ...applyFilter(filter)];
};
