import { ProductListingFormData } from './ProductListingEditForm.utils';
import { Card, CardHeader, CardContent, FormGroup, Box } from '@material-ui/core';
import { CustomInput } from 'reactstrap';
import { scutiColors } from 'material-theme/variables';
import { Controller, UseFormReturn } from 'react-hook-form';

interface Props {
  form: UseFormReturn<ProductListingFormData>;
  paused?: boolean;
}

export function EnhancementsDetails({ form, paused }: Props) {
  return (
    <Card>
      <CardHeader title="Enhancements" />
      <CardContent>
        <FormGroup>
          <label htmlFor={`extras-box`}>
            <Controller
              control={form.control}
              name="extras.moreExposure"
              render={({ field }) => (
                <CustomInput
                  id="extras-box"
                  type="checkbox"
                  label="More exposure"
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  checked={!!field.value}
                  disabled={!paused}
                />
              )}
            />
          </label>
          <Box color={scutiColors.grey200}>Grab more attention by adding a glowing border to your product.</Box>
        </FormGroup>
      </CardContent>
    </Card>
  );
}
