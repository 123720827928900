import React from 'react';
import { PrivateRoute } from 'components/PrivateRoute';
import { Switch } from 'react-router-dom';
import { productListingRoutes } from 'routing';
import { useAppStore } from 'store/app-store.hook';
import { observer } from 'mobx-react-lite';
import { ProductListingListPage } from './ProductListingListPage';
import { ProductListingOverviewPage } from './ProductListingOverviewPage';
import { ProductListingEditPage } from './ProductListingEditPage';

export const ProductListingRoutes: React.FC = observer(() => {
  const { permissions } = useAppStore();
  return (
    <Switch>
      {permissions.productsAccess.view && (
        <PrivateRoute exact path={productListingRoutes.PRODUCT_LISTING} component={ProductListingListPage} />
      )}
      {permissions.productsAccess.view && (
        <PrivateRoute
          exact
          path={productListingRoutes.PRODUCT_LISTING_OVERVIEW()}
          component={ProductListingOverviewPage}
        />
      )}
      {permissions.productsAccess.edit && (
        <PrivateRoute exact path={productListingRoutes.PRODUCT_LISTING_EDIT()} component={ProductListingEditPage} />
      )}
      {permissions.productsAccess.edit && (
        <PrivateRoute exact path={productListingRoutes.PRODUCT_LISTING_NEW} component={ProductListingEditPage} />
      )}
    </Switch>
  );
});
