import React from 'react';
import { Box, Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import { ScutiInput } from 'components/material/ScutiInput';
import AddIcon from '@mui/icons-material/Add';
import { ShopFormData } from './ShopEditForm.utils';
import { Controller, UseFormReturn, useFieldArray } from 'react-hook-form';
import { Button, Stack } from '@mui/material';
import { ScutiSelect } from 'components/material/ScutiSelect';
import { COUNTRIES_SELECT_OPTIONS } from 'types/select-types';
import { v4 } from 'uuid';

interface Props {
  form: UseFormReturn<ShopFormData>;
}

export const ShopEditShipping: React.FC<Props> = ({ form }) => {
  const { fields, remove, insert } = useFieldArray({
    name: 'shippings',
    control: form.control,
  });

  return (
    <Card>
      <CardHeader
        title={
          <Box display="flex" justifyContent="space-between">
            Shipping
            <Box
              onClick={() =>
                insert(fields.length, {
                  id: v4(),
                  amount: 0,
                  freeShippingThreshold: null,
                  country: '',
                })
              }
            >
              <AddIcon />
            </Box>
          </Box>
        }
      />
      <CardContent>
        <Stack spacing={2}>
          {fields.map((field, index) => {
            return (
              <Box key={field.id}>
                <Box display="flex" alignItems="flex-end">
                  <Controller
                    control={form.control}
                    name={`shippings.${index}.country`}
                    render={({ field, fieldState }) => (
                      <ScutiSelect
                        title="Country *"
                        placeholder="Country"
                        options={COUNTRIES_SELECT_OPTIONS}
                        error={fieldState.error?.message}
                        {...field}
                      />
                    )}
                    rules={{ required: true }}
                  />
                  <Box ml={2}>
                    <Button className="btn-close" type="button" onClick={() => remove(index)} />
                  </Box>
                </Box>
                <Stack spacing={2} mt={2}>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <label>Amount</label>
                    <Box>
                      <Controller
                        control={form.control}
                        name={`shippings.${index}.amount`}
                        render={({ field, fieldState }) => (
                          <ScutiInput placeholder="$" type="number" error={fieldState.error?.message} {...field} />
                        )}
                        rules={{ required: true }}
                      />
                    </Box>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <label>Free Shipping Threshold</label>
                    <Box>
                      <Controller
                        control={form.control}
                        name={`shippings.${index}.freeShippingThreshold`}
                        render={({ field, fieldState }) => (
                          <ScutiInput placeholder="$" type="number" error={fieldState.error?.message} {...field} />
                        )}
                        rules={{ required: true }}
                      />
                    </Box>
                  </Stack>
                </Stack>
              </Box>
            );
          })}
        </Stack>
      </CardContent>
    </Card>
  );
};
