import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from 'reactstrap';
import { NavTabs } from 'components/NavTabs';
import { Link } from 'react-router-dom';
import { ordersRoutes } from 'routing';
import { useParams } from 'react-router';
import { Loader } from 'components/Loader';
import { useShopOrderQuery } from '../__generated__/orders.hooks';
import { OrderStatus } from 'types/__generated__/types';
import { PageContentLayout } from 'layouts/PageContentLayout';
import { Grid } from '@material-ui/core';
import { NoData } from 'components/material/NoData';
import { OrderRefunds } from './OrderRefunds';
import styles from './OrderOverviewRefundPage.module.scss';

export const OrderOverviewRefundPage: React.FC = observer(() => {
  const { orderId } = useParams<{ orderId: string }>();
  const [tabs] = React.useState([
    {
      name: 'Fullfilment',
      linkTo: ordersRoutes.ORDER_OVERVIEW(orderId),
      active: (pathname: string = '') => pathname.split('/').slice(-1)[0] === 'fullfilment',
    },
    {
      name: 'Refunds',
      linkTo: ordersRoutes.ORDER_OVERVIEW_REFUNDS(orderId),
      active: (pathname: string = '') => pathname.split('/').slice(-1)[0] === 'refunds',
    },
  ]);

  const useOrder = useShopOrderQuery({ id: orderId });

  const order = useOrder.data?.order;
  const isLoading = useOrder.isLoading;
  const showNoData = !order && !isLoading;

  return (
    <PageContentLayout>
      {order && (
        <PageContentLayout.Header className="d-flex align-items-center justify-content-between mb-2">
          <div className="d-flex align-items-center">
            <Link className="btn btn-back" to={ordersRoutes.ORDERS} />
            <h1 className={styles.page_title}>Order - {order.id}</h1>
            <Button color="primary" className={styles.button}>
              {order.status.toLowerCase()}
            </Button>
          </div>
          <div className="d-flex align-items-center">
            <Button
              color="outline-secondary"
              className={styles.button}
              disabled={order.status === OrderStatus.Cancelled}
            >
              Cancel Order
            </Button>
            <Link
              to={ordersRoutes.ORDER_SUMMARY(order.id)}
              className={`${styles.button} btn btn-outline-secondary`}
              color="outline-secondary"
            >
              Show order summary
            </Link>
          </div>
        </PageContentLayout.Header>
      )}
      <NavTabs tabs={tabs} />
      <PageContentLayout.Content>
        {isLoading && <Loader />}
        {showNoData && <NoData />}
        {order && (
          <Grid container>
            <Grid item md={6}>
              <OrderRefunds order={order} />
            </Grid>
          </Grid>
        )}
      </PageContentLayout.Content>
    </PageContentLayout>
  );
});
