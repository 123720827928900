import React from 'react';
import { Form, Button } from 'reactstrap';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import { ProductEditDetail } from './ProductEditDetail';
import { ProductEditLogistics } from './ProductEditLogistics';
import { ProductVariantsTable } from './ProductVariantsTable';
import { FormFulfillmentMethod, ProductForm, productFormDefaults, validationSchema } from './ProductEditForm.utils';
import { Box, Grid } from '@material-ui/core';
import { ProductImages } from './ProductImages';

interface Props {
  product?: ProductForm;
  title: string;
  linkBack: string;
  fulfillmentMethods: FormFulfillmentMethod[];
  onSave: (values: any) => void;
}

export const ProductEditForm: React.FC<Props> = ({ product, title, linkBack, fulfillmentMethods, onSave }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={product || productFormDefaults(fulfillmentMethods[0])}
      validationSchema={validationSchema}
      onSubmit={onSave}
      validateOnMount
    >
      {(props) => {
        return (
          <Form className="w-100" onSubmit={props.handleSubmit}>
            <div className="topbar d-sm-flex justify-content-sm-between">
              <div className="col-heading">
                <Link className="btn btn-back" to={linkBack}></Link>
                <h1 className="page-title">{title}</h1>
              </div>
              <div className="col-action">
                <Button color="outline-secondary" onClick={props.handleReset}>
                  Cancel
                </Button>

                <Button color="primary" type="submit" disabled={props.isSubmitting}>
                  Save changes
                </Button>
              </div>
            </div>

            <Grid container spacing={4}>
              <Grid item md={4} sm={6}>
                <ProductEditDetail {...props} />
              </Grid>
              <Grid item md={4} sm={6}>
                <ProductEditLogistics {...props} />
              </Grid>
              <Grid item md={4} sm={12}>
                <ProductImages {...props} />
              </Grid>
            </Grid>
            <Box mt={2}>
              <Grid item>
                <ProductVariantsTable {...props} fulfillmentMethods={fulfillmentMethods} />
              </Grid>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};
