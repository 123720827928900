import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { DecodedToken } from 'types/auth-types';
import { API_URI } from '../config';

const unixTime = () => Math.round(Date.now()) / 1000;

export const readAccessTokenFromStorage = (): string | undefined =>
  JSON.parse(localStorage.getItem('user_info') || '{}')?.auth?.token;

export const readRefreshTokenFromStorage = (): string | undefined =>
  JSON.parse(localStorage.getItem('user_info') || '{}')?.auth?.refreshToken;

export const clearRefreshTokenFromStorage = () => localStorage.removeItem('user_info');

class TokenStorage {
  promise: Promise<string | null> | null = null;
  refreshToken: string | null = null;
  token: string | null = null;

  constructor() {
    this.refreshToken = readRefreshTokenFromStorage() || null;
  }

  async fetchToken(): Promise<string | null> {
    try {
      const response = await axios.post(`${API_URI}/auth/refresh`, null, {
        headers: { Authorization: `Bearer ${readRefreshTokenFromStorage()}` },
      });
      this.token = response.data.token;
      this.promise = null;
      return this.token;
    } catch (error) {
      localStorage.clear();
      window.location.href = '/';
    }
    return null;
  }

  getToken() {
    if (!this.isExpired()) return this.token;
    if (!this.promise) this.promise = this.fetchToken();
    return this.promise;
  }

  isExpired() {
    return !this.token || jwtDecode<DecodedToken>(this.token).exp < unixTime();
  }

  clearStorage() {
    this.promise = null;
    this.refreshToken = null;
    this.token = null;
  }
}

export const tokenStorage = new TokenStorage();
