import { Box } from '@mui/material';
// import DeleteIcon from '@mui/icons-material/Delete';
import styles from './ImgPreview.module.scss';
import { ImgPreview } from './ImgPreview';
import { Grid } from '@material-ui/core';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

interface Props {
  images: (string | File)[];
  defaultImage: string | File | null | undefined;
  onAdd: () => void;
  onDelete: (image: string | File) => void;
  onDefaultImageUpdate: (image: string | File) => void;
}

export const ImgListPreview: React.FC<Props> = ({ images, defaultImage, onAdd, onDelete, onDefaultImageUpdate }) => {
  return (
    <Grid container spacing={2} justifyContent="space-between">
      {images.map((image, index) => (
        <Grid item key={index}>
          <Box width="12rem" height="12.5rem">
            <ImgPreview
              image={image}
              isDefaultImage={image === defaultImage}
              onDelete={() => onDelete(image)}
              onDefaultImageUpdate={() => onDefaultImageUpdate(image)}
            />
          </Box>
        </Grid>
      ))}
      <Grid item>
        <Box
          className={styles.add_block}
          width="12rem"
          height="12.5rem"
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={onAdd}
        >
          <AddCircleOutlineIcon className={styles.add_icon} />
        </Box>
      </Grid>
    </Grid>
  );
};
