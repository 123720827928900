import React from 'react';
import { Box, Card, CardContent, CardHeader } from '@material-ui/core';
import { OrderFragment } from '../__generated__/orders.hooks';
import { addDecimals } from 'utils/string.utils';

interface Props {
  order: OrderFragment;
}

export const OrderSummary: React.FC<Props> = ({ order }) => {
  const { amount, items } = order;
  return (
    <Card>
      <CardHeader title="Order summary" />
      <CardContent>
        <Box>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <span>Items:</span>
            <span>{items.reduce((sum, i) => sum + i.quantity, 0)}</span>
          </Box>
          <Box mt={1} display="flex" alignItems="center" justifyContent="space-between">
            <span>Shipping:</span>
            <span>$ - </span>
          </Box>
          <Box mt={1} display="flex" alignItems="center" justifyContent="space-between">
            <span>Tax:</span>
            <span>$ - </span>
          </Box>
          <Box mt={1} display="flex" alignItems="center" justifyContent="space-between">
            <span>Order total:</span>
            <span>${addDecimals(amount)}</span>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
