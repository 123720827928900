import {
  CampaignStatus,
  CampaignType,
  NewCampaignInput,
  OfferType,
  PromotionType,
  UpdateCampaignInput,
} from 'types/__generated__/types';
import { transformedNumber } from 'utils/form-utils';
import * as Yup from 'yup';

export const isPaused = (status?: CampaignStatus | null) =>
  !status || status === CampaignStatus.Paused || status === CampaignStatus.Pending;

export const productListingSchema = Yup.object().shape({
  id: Yup.string(),
  name: Yup.string()
    .min(3, 'Name should be at least 3 characters.')
    .max(50, 'Name can be 50 characters or less.')
    .required('Name is required!'),
  productListingCategory: Yup.string().min(2, 'Category is required!').required('Category is required!'),
  status: Yup.mixed<CampaignStatus>().oneOf(Object.values(CampaignStatus)).nullable(),
  product: Yup.object()
    .shape({
      id: Yup.string().min(2, 'Product is required').required('Product is required'),
      name: Yup.string(),
    })
    .test('product', 'Product is required', (value) => !!value.id)
    .required('Product is required'),
  shopBrand: Yup.object()
    .shape({
      id: Yup.string().min(2, 'Product is required').required('Product is required'),
      name: Yup.string(),
    })
    .test('shopBrand', 'Brand is required', (value) => !!value.id)
    .required('Product is required'),
  promotion: Yup.object().shape({
    promotionType: Yup.mixed<PromotionType>().oneOf(Object.values(PromotionType)).nullable(),
    offerType: Yup.mixed<OfferType>().when('promotionType', {
      is: PromotionType.SpecialOffer,
      then: (schema) =>
        schema.oneOf(Object.values(OfferType), 'Please specify Offer Type').required('Offer type is required'),
      otherwise: () => Yup.string().nullable(),
    }),
    offerCopy: Yup.string().when('offerType', {
      is: (value: string) => !!value,
      then: (schema) => schema.required('Offer Copy is required').nullable(),
      otherwise: (schema) => schema.nullable(),
    }),
    discount: transformedNumber
      .when('promotionType', {
        is: PromotionType.HotPrice,
        then: (schema) =>
          schema
            .moreThan(0, 'Cannot be less then 0')
            .max(100, 'Cannot be greater then 100')
            .required('Discount % is required'),
        otherwise: (schema) => schema.nullable(),
      })
      .nullable(),
  }),
  demographic: Yup.object().shape({
    gender: Yup.string().nullable(),
    minAge: transformedNumber.min(0).max(100).required(),
    maxAge: transformedNumber.min(0).max(100).required(),
  }),
  duration: Yup.object().shape({
    start: Yup.date().nullable(),
    end: Yup.date().nullable(),
    runUntilStockZero: Yup.boolean().nullable(),
    inventory: transformedNumber.nullable(),
  }),
  location: Yup.object().shape({
    country: Yup.string().nullable(),
    states: Yup.array().of(Yup.string().required()).nullable(),
  }),
  reward: Yup.object()
    .shape({
      scutiPercentage: transformedNumber
        .min(0, 'Cannot be less then 0')
        .max(100, 'Cannot be greater then 100')
        .required('Reward % is required')
        .nullable(),
    })
    .required(),
  extras: Yup.object().shape({
    moreExposure: Yup.boolean().nullable(),
  }),
});

export type ProductListingFormData = Yup.InferType<typeof productListingSchema>;

export const productListingFormDefaults = (): ProductListingFormData => ({
  name: '',
  productListingCategory: '',
  product: {
    id: '',
    name: '',
  },
  shopBrand: {
    id: '',
    name: '',
  },
  promotion: {
    promotionType: null,
    offerType: undefined,
    offerCopy: undefined,
    discount: null,
  },
  demographic: {
    gender: null,
    minAge: 0,
    maxAge: 100,
  },
  duration: {
    start: null,
    end: null,
    inventory: 0,
    runUntilStockZero: null,
  },
  location: {},
  reward: {
    scutiPercentage: null,
  },
  extras: { moreExposure: false },
});

export const mapProductListingToApiInput =
  (shopId: string) =>
  (formData: ProductListingFormData): NewCampaignInput | UpdateCampaignInput => {
    return {
      id: formData.id,
      name: formData.name,
      category: formData.productListingCategory,
      productId: formData.product.id,
      promotion: formData.promotion,
      demographic: formData.demographic,
      duration: formData.duration,
      location: formData.location,
      reward: formData.reward,
      extras: formData.extras,
      shopBrandId: formData.shopBrand.id,
      type: CampaignType.ProductListing,
      shopId,
    };
  };
