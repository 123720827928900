import { useMemo, useState } from 'react';
import { Box, Card, CardContent, Grow } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { SwitcherHeader } from './SwitcherHeader';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useUserItems } from 'hooks/useUserItems/useUserItems';
import { Stack } from '@mui/material';
import { Button } from 'reactstrap';
import { SwitcherSection } from './SwitcherSection';
import { RequestEntityType } from 'types/__generated__/types';

export const Switcher = observer(() => {
  const { items, onSelect } = useUserItems();

  const [isOpen, setIsOpen] = useState(false);
  const ref = useOnclickOutside(() => setIsOpen(false));

  const showGames = !!items.games.length;
  const showShops = !!items.shops.length;
  const showBrands = !!items.brands.length;
  const showSwitcher = useMemo(() => [...items.games, ...items.shops, ...items.brands].length > 1, [items]);

  const [switcherToShow, setSwitcherToShow] = useState<RequestEntityType | null>(null);

  const itemsToShow = useMemo(() => {
    switch (switcherToShow) {
      case RequestEntityType.Game:
        return items.games;
      case RequestEntityType.Shop:
        return items.shops;
      case RequestEntityType.Brand:
        return items.brands;
      default:
        return [];
    }
  }, [switcherToShow]);

  return (
    <Box position="relative" width={200}>
      {!showSwitcher ? null : (
        <>
          <SwitcherHeader selectedItem={items.selectedItem} onClick={() => setIsOpen(!isOpen)} />
          <div ref={ref}>
            <Grow in={isOpen}>
              <Box position="absolute" mt={1} minWidth={360} left={-120}>
                <Card>
                  <CardContent>
                    <Stack spacing={2} direction="row" justifyContent="center">
                      {showGames && (
                        <Button
                          color={switcherToShow === RequestEntityType.Game ? 'primary' : 'secondary'}
                          onClick={() => setSwitcherToShow(RequestEntityType.Game)}
                        >
                          Games
                        </Button>
                      )}
                      {showShops && (
                        <Button
                          color={switcherToShow === RequestEntityType.Shop ? 'primary' : 'secondary'}
                          onClick={() => setSwitcherToShow(RequestEntityType.Shop)}
                        >
                          Shops
                        </Button>
                      )}
                      {showBrands && (
                        <Button
                          color={switcherToShow === RequestEntityType.Brand ? 'primary' : 'secondary'}
                          onClick={() => setSwitcherToShow(RequestEntityType.Brand)}
                        >
                          Brands
                        </Button>
                      )}
                    </Stack>
                    <Box maxHeight={400} overflow="auto">
                      <SwitcherSection items={itemsToShow} selected={items.selectedItem} onClick={onSelect} />
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </Grow>
          </div>
        </>
      )}
    </Box>
  );
});
