import React, { useEffect, useMemo } from 'react';
import { Form, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@material-ui/core';
import { ScutiInput } from 'components/material/ScutiInput';
import { Controller, useForm } from 'react-hook-form';
import {
  CouponAdvertisementFormData,
  couponAdvertisementFormDataDefaults,
  couponAdvertisementSchema,
  mapAdvertisementCouponToApiInput,
} from './CouponAdvertisementEditForm.utils';
import { AdvertisementFragment } from 'graphql/__generated__/advertisement.hooks';
import { NewAdvertisementInput, UpdateAdvertisementInput } from 'types/__generated__/types';
import { CouponAdvertisementDetails } from './CouponAdvertisementDetails';
import { CouponAdvertisementImages } from './CouponAdvertisementImages';

interface Props {
  linkBack: string;
  brandId: string;
  advertisement?: AdvertisementFragment;
  onSave: (advertisement: UpdateAdvertisementInput | NewAdvertisementInput) => void;
}

export const CouponAdvertisementEditForm: React.FC<Props> = ({ advertisement, brandId, linkBack, onSave }) => {
  const shopFormDefaultValues = useMemo<CouponAdvertisementFormData>(() => {
    const defaults = couponAdvertisementFormDataDefaults();

    if (!advertisement) return defaults;

    const couponImages = advertisement.coupon?.couponImages;

    return {
      ...defaults,
      id: advertisement.id,
      name: advertisement.name,
      category: advertisement.category || defaults.category,
      couponLabel: advertisement.coupon?.couponLabel || defaults.couponLabel,
      couponDescription: advertisement.coupon?.couponDescription || defaults.couponDescription,
      couponImages: couponImages || defaults.couponImages,
      cpa: advertisement.cpa || defaults.cpa,
    };
  }, [advertisement]);

  const couponAdvertisementForm = useForm<CouponAdvertisementFormData>({
    resolver: yupResolver(couponAdvertisementSchema),
    defaultValues: shopFormDefaultValues,
    mode: 'all',
  });

  useEffect(() => couponAdvertisementForm.reset(shopFormDefaultValues), [shopFormDefaultValues]);

  const handleSubmit = async (formData: CouponAdvertisementFormData) => {
    return onSave(await mapAdvertisementCouponToApiInput(brandId)(formData));
  };

  const { isDirty, isSubmitting } = couponAdvertisementForm.formState;

  return (
    <Form className="w-100" onSubmit={couponAdvertisementForm.handleSubmit(handleSubmit)}>
      <div className="topbar d-sm-flex justify-content-sm-between">
        <div className="col-heading">
          <Link className="btn btn-back" to={linkBack}></Link>
          <Box ml={5} width={'50%'}>
            <Controller
              control={couponAdvertisementForm.control}
              name="name"
              render={({ field, fieldState }) => (
                <ScutiInput placeholder="Advertisement name" {...field} error={fieldState.error?.message} />
              )}
            />
          </Box>
        </div>
        <div className="col-action">
          <Button color="outline-secondary" onClick={() => couponAdvertisementForm.reset()} disabled={!isDirty}>
            Cancel
          </Button>
          <Button color="primary" type="submit" disabled={isSubmitting}>
            Save changes
          </Button>
        </div>
      </div>
      <Box pb={2}>
        <Grid container spacing={4}>
          <Grid item container spacing={4}>
            <Grid item xs={12} md={6} lg={4}>
              <CouponAdvertisementDetails form={couponAdvertisementForm} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <CouponAdvertisementImages form={couponAdvertisementForm} />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Form>
  );
};
