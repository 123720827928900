import React from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';
import { GameOverview } from './GameOverview';
import { GameDemographicsESRB } from './GameDemographicsESRB';
import { GameIntegration } from './GameIntegration';
import { CurrencyOverview } from './CurrencyOverview';
import { Link } from 'react-router-dom';
import { gamesRoutes } from 'routing';
import {
  useCreateGameTokenMutation,
  useGameOverviewQuery,
  useGameTokenQuery,
} from './__generated__/game-overview-page.hooks';
import { Loader } from 'components/Loader';
import { Box, Grid } from '@mui/material';
import { useToasts } from 'react-toast-notifications';

export const GameOverviewPage: React.FC = observer(() => {
  const { gameId } = useParams<{ gameId: string }>();
  const { addToast } = useToasts();

  const useGameOverview = useGameOverviewQuery({ id: gameId });
  const useGameToken = useGameTokenQuery({ id: gameId });
  const useCreateGameToken = useCreateGameTokenMutation();

  const game = useGameOverview.data?.game;
  const gameToken = useGameToken.data?.gameToken;
  const isLoading = useGameOverview.isLoading || useGameToken.isLoading || useCreateGameToken.isLoading;

  return (
    <Box>
      <Box className="topbar d-lg-flex justify-content-sm-between">
        <Box className="col-heading d-flex">
          <Link className="btn btn-back" to={gamesRoutes.GAMES}></Link>
          <h1 className="page-title">Back to games</h1>
          <Box className="btn-edit-holder">
            <Link className="btn btn-outline-secondary" to={gamesRoutes.GAME_EDIT(gameId)}>
              Edit details
            </Link>
          </Box>
        </Box>
      </Box>
      {isLoading && <Loader />}
      {game && (
        <Grid container spacing={2}>
          <Grid item md={7} lg={8}>
            <Grid container spacing={2}>
              <Grid item lg={12}>
                <GameOverview game={game} />
              </Grid>
              <Grid item lg={12}>
                <GameDemographicsESRB game={game} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={5} lg={4}>
            <Grid container spacing={2}>
              <Grid item lg={12}>
                <GameIntegration
                  gameId={gameId}
                  gameToken={gameToken?.token}
                  generateToken={async () => {
                    await useCreateGameToken.mutateAsync({ id: gameId });
                    await useGameToken.refetch();
                    addToast('Token has been updated!', { appearance: 'success', autoDismiss: true });
                  }}
                />
              </Grid>
              <Grid item lg={12}>
                {game.currency && <CurrencyOverview {...game.currency} />}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Box>
  );
});
