import * as Types from '../../types/__generated__/types';

import { ApolloError } from 'apollo-client';
import { useQuery, UseQueryOptions, useMutation, UseMutationOptions } from 'react-query';
import { fetcher } from 'api';
export type GameListItemFragment = {
  id: string;
  name: string;
  tags: Array<string>;
  thumbnail?: Types.Maybe<string>;
  category: string;
  contentCategory: Types.ContentCategory;
  admins: Array<{ fullName?: Types.Maybe<string> }>;
};

export type GameFragment = {
  id: string;
  name: string;
  category: string;
  tags: Array<string>;
  thumbnail?: Types.Maybe<string>;
  banner?: Types.Maybe<string>;
  contentCategory: Types.ContentCategory;
  ageFrom: number;
  ageTo: number;
  currency?: Types.Maybe<{
    name: string;
    thumbnail?: Types.Maybe<string>;
    exchangeRate: number;
    scutiExchangeRate: number;
  }>;
  genderPercent?: Types.Maybe<{ male: number; female: number }>;
  admins: Array<{ fullName?: Types.Maybe<string>; email: string; roles: Array<Types.Role> }>;
};

export type GamesQueryVariables = Types.Exact<{
  paging?: Types.Maybe<Types.PagingInput>;
  filters?: Types.Maybe<Array<Types.FilterInput> | Types.FilterInput>;
  sorting?: Types.Maybe<Array<Types.SortFieldInput> | Types.SortFieldInput>;
}>;

export type GamesQuery = {
  games: { paging: { offset: number; limit: number; totalCount: number }; nodes: Array<GameListItemFragment> };
};

export type GameQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type GameQuery = { game: GameFragment };

export type GameTokenQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type GameTokenQuery = { gameToken?: Types.Maybe<{ id: string; token: string }> };

export type UpdateGameMutationVariables = Types.Exact<{
  input: Types.UpdateGameInput;
}>;

export type UpdateGameMutation = { updateGame: { id: string } };

export type CreateGameMutationVariables = Types.Exact<{
  input: Types.GameInput;
}>;

export type CreateGameMutation = { createGame: { id: string } };

export type DeleteGameMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type DeleteGameMutation = { deleteGame?: Types.Maybe<string> };

export type CreateGameTokenMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type CreateGameTokenMutation = { createGameToken: { id: string; token: string } };

export type GameInviteFragment = {
  id: string;
  createdAt: any;
  updatedAt: any;
  email: string;
  roles: Array<Types.Role>;
  entityId?: Types.Maybe<string>;
  entityType?: Types.Maybe<string>;
  accepted: boolean;
  hide: boolean;
};

export type GameInvitesQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
  paging?: Types.Maybe<Types.PagingInput>;
  sorting?: Types.Maybe<Array<Types.SortFieldInput> | Types.SortFieldInput>;
  filters?: Types.Maybe<Array<Types.FilterInput> | Types.FilterInput>;
}>;

export type GameInvitesQuery = {
  gameInvites: { paging: { offset: number; limit: number; totalCount: number }; nodes: Array<GameInviteFragment> };
};

export type InviteGameUserMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
  user: Types.UserRoleInput;
}>;

export type InviteGameUserMutation = { inviteToGame: Array<Types.Role> };

export type RemoveFromGameMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
  gameId: Types.Scalars['String'];
}>;

export type RemoveFromGameMutation = { removeFromGame: boolean };

export type ResendInviteMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
}>;

export type ResendInviteMutation = { resendInviteMail: boolean };

export const GameListItemFragmentDoc = `
    fragment GameListItem on Game {
  id
  name
  tags
  thumbnail
  category
  contentCategory
  admins {
    fullName
  }
}
    `;
export const GameFragmentDoc = `
    fragment Game on Game {
  id
  name
  category
  tags
  thumbnail
  banner
  currency {
    name
    thumbnail
    exchangeRate
    scutiExchangeRate
  }
  contentCategory
  ageFrom
  ageTo
  genderPercent {
    male
    female
  }
  admins {
    fullName
    email
    roles
  }
}
    `;
export const GameInviteFragmentDoc = `
    fragment GameInvite on Invite {
  id
  createdAt
  updatedAt
  email
  roles
  entityId
  entityType
  accepted
  hide
}
    `;
export const GamesDocument = `
    query Games($paging: PagingInput, $filters: [FilterInput!], $sorting: [SortFieldInput!]) {
  games(paging: $paging, filters: $filters, sorting: $sorting) {
    paging {
      offset
      limit
      totalCount
    }
    nodes {
      ...GameListItem
    }
  }
}
    ${GameListItemFragmentDoc}`;
export const useGamesQuery = <TData = GamesQuery, TError = ApolloError>(
  variables?: GamesQueryVariables,
  options?: UseQueryOptions<GamesQuery, TError, TData>,
) =>
  useQuery<GamesQuery, TError, TData>(
    ['Games', variables],
    fetcher<GamesQuery, GamesQueryVariables>(GamesDocument, variables),
    options,
  );
export const GameDocument = `
    query Game($id: String!) {
  game(id: $id) {
    ...Game
  }
}
    ${GameFragmentDoc}`;
export const useGameQuery = <TData = GameQuery, TError = ApolloError>(
  variables: GameQueryVariables,
  options?: UseQueryOptions<GameQuery, TError, TData>,
) =>
  useQuery<GameQuery, TError, TData>(
    ['Game', variables],
    fetcher<GameQuery, GameQueryVariables>(GameDocument, variables),
    options,
  );
export const GameTokenDocument = `
    query GameToken($id: String!) {
  gameToken(id: $id) {
    id
    token
  }
}
    `;
export const useGameTokenQuery = <TData = GameTokenQuery, TError = ApolloError>(
  variables: GameTokenQueryVariables,
  options?: UseQueryOptions<GameTokenQuery, TError, TData>,
) =>
  useQuery<GameTokenQuery, TError, TData>(
    ['GameToken', variables],
    fetcher<GameTokenQuery, GameTokenQueryVariables>(GameTokenDocument, variables),
    options,
  );
export const UpdateGameDocument = `
    mutation UpdateGame($input: UpdateGameInput!) {
  updateGame(input: $input) {
    id
  }
}
    `;
export const useUpdateGameMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<UpdateGameMutation, TError, UpdateGameMutationVariables, TContext>,
) =>
  useMutation<UpdateGameMutation, TError, UpdateGameMutationVariables, TContext>(
    (variables?: UpdateGameMutationVariables) =>
      fetcher<UpdateGameMutation, UpdateGameMutationVariables>(UpdateGameDocument, variables)(),
    options,
  );
export const CreateGameDocument = `
    mutation CreateGame($input: GameInput!) {
  createGame(input: $input) {
    id
  }
}
    `;
export const useCreateGameMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<CreateGameMutation, TError, CreateGameMutationVariables, TContext>,
) =>
  useMutation<CreateGameMutation, TError, CreateGameMutationVariables, TContext>(
    (variables?: CreateGameMutationVariables) =>
      fetcher<CreateGameMutation, CreateGameMutationVariables>(CreateGameDocument, variables)(),
    options,
  );
export const DeleteGameDocument = `
    mutation DeleteGame($id: String!) {
  deleteGame(id: $id)
}
    `;
export const useDeleteGameMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<DeleteGameMutation, TError, DeleteGameMutationVariables, TContext>,
) =>
  useMutation<DeleteGameMutation, TError, DeleteGameMutationVariables, TContext>(
    (variables?: DeleteGameMutationVariables) =>
      fetcher<DeleteGameMutation, DeleteGameMutationVariables>(DeleteGameDocument, variables)(),
    options,
  );
export const CreateGameTokenDocument = `
    mutation CreateGameToken($id: String!) {
  createGameToken(id: $id) {
    id
    token
  }
}
    `;
export const useCreateGameTokenMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<CreateGameTokenMutation, TError, CreateGameTokenMutationVariables, TContext>,
) =>
  useMutation<CreateGameTokenMutation, TError, CreateGameTokenMutationVariables, TContext>(
    (variables?: CreateGameTokenMutationVariables) =>
      fetcher<CreateGameTokenMutation, CreateGameTokenMutationVariables>(CreateGameTokenDocument, variables)(),
    options,
  );
export const GameInvitesDocument = `
    query GameInvites($id: String!, $paging: PagingInput, $sorting: [SortFieldInput!], $filters: [FilterInput!]) {
  gameInvites(id: $id, paging: $paging, sorting: $sorting, filters: $filters) {
    paging {
      offset
      limit
      totalCount
    }
    nodes {
      ...GameInvite
    }
  }
}
    ${GameInviteFragmentDoc}`;
export const useGameInvitesQuery = <TData = GameInvitesQuery, TError = ApolloError>(
  variables: GameInvitesQueryVariables,
  options?: UseQueryOptions<GameInvitesQuery, TError, TData>,
) =>
  useQuery<GameInvitesQuery, TError, TData>(
    ['GameInvites', variables],
    fetcher<GameInvitesQuery, GameInvitesQueryVariables>(GameInvitesDocument, variables),
    options,
  );
export const InviteGameUserDocument = `
    mutation InviteGameUser($id: String!, $user: UserRoleInput!) {
  inviteToGame(id: $id, user: $user)
}
    `;
export const useInviteGameUserMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<InviteGameUserMutation, TError, InviteGameUserMutationVariables, TContext>,
) =>
  useMutation<InviteGameUserMutation, TError, InviteGameUserMutationVariables, TContext>(
    (variables?: InviteGameUserMutationVariables) =>
      fetcher<InviteGameUserMutation, InviteGameUserMutationVariables>(InviteGameUserDocument, variables)(),
    options,
  );
export const RemoveFromGameDocument = `
    mutation RemoveFromGame($email: String!, $gameId: String!) {
  removeFromGame(email: $email, gameId: $gameId)
}
    `;
export const useRemoveFromGameMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<RemoveFromGameMutation, TError, RemoveFromGameMutationVariables, TContext>,
) =>
  useMutation<RemoveFromGameMutation, TError, RemoveFromGameMutationVariables, TContext>(
    (variables?: RemoveFromGameMutationVariables) =>
      fetcher<RemoveFromGameMutation, RemoveFromGameMutationVariables>(RemoveFromGameDocument, variables)(),
    options,
  );
export const ResendInviteDocument = `
    mutation ResendInvite($email: String!) {
  resendInviteMail(email: $email)
}
    `;
export const useResendInviteMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<ResendInviteMutation, TError, ResendInviteMutationVariables, TContext>,
) =>
  useMutation<ResendInviteMutation, TError, ResendInviteMutationVariables, TContext>(
    (variables?: ResendInviteMutationVariables) =>
      fetcher<ResendInviteMutation, ResendInviteMutationVariables>(ResendInviteDocument, variables)(),
    options,
  );
