import { Avatar, Box, ListItem, makeStyles, Typography } from '@material-ui/core';
import { getFirstTwo } from 'utils/string.utils';

const useStyles = makeStyles((theme) => ({
  selected: {
    border: `2px solid ${theme.palette.primary.main}`,
  },
  listItem: {
    background: '#3B3D44',
    borderRadius: 4,
    marginTop: 5,
  },
}));

interface SwitcherItemProps {
  img?: string | null;
  name: string;
  color: string;
  selected?: boolean;
  onClick: () => void;
}

export const SwitcherItem: React.FC<SwitcherItemProps> = ({ img, name, color, selected, onClick }) => {
  const classes = useStyles();
  return (
    <ListItem className={`${classes.listItem} ${selected ? classes.selected : ''}`} onClick={onClick}>
      <Box display="flex" alignItems="center" style={{ cursor: 'default' }}>
        <Box mr={2}>
          <Avatar variant="rounded" src={img || undefined} style={{ backgroundColor: color }}>
            {name && getFirstTwo(name)}
          </Avatar>
        </Box>
        <Box>
          <Typography color="textPrimary">
            <Box component="span" fontWeight="bold">
              {name}
            </Box>
          </Typography>
        </Box>
      </Box>
    </ListItem>
  );
};
