import { Card, CardHeader, CardContent } from '@material-ui/core';
import { Row, Col } from 'reactstrap';
import { contentCategoryName } from 'types/app-types';
import { GameDetailsFragment } from './__generated__/game-overview-page.hooks';

interface Props {
  game: GameDetailsFragment;
}

export const GameDemographicsESRB: React.FC<Props> = (props) => {
  const { ageFrom, ageTo, genderPercent, contentCategory } = props.game;
  return (
    <Card>
      <CardHeader title="Demographics" />
      <CardContent>
        <Row>
          <Col>
            <div className="age-info">
              <span className="age-label">Age range</span>
              <strong className="age-value">{`${ageFrom}-${ageTo}`}</strong>
            </div>
          </Col>
          <Col>
            <div className="demographics-data d-flex dd-sm">
              <div className="demographics-item">
                <i className="demographics-icon icon-male"></i>
                <div className="demographics-info">
                  <span className="demographics-label">Male</span>
                  {genderPercent?.male && (
                    <strong className="demographics-value">{genderPercent.male || 'auto'}%</strong>
                  )}
                </div>
              </div>
              <div className="demographics-item">
                <i className="demographics-icon icon-female"></i>
                <div className="demographics-info">
                  <span className="demographics-label">Female</span>
                  {genderPercent?.female && <strong className="demographics-value">{genderPercent.female}%</strong>}
                </div>
              </div>
            </div>
          </Col>
          <Col>
            <div className="game-cat-info d-flex">
              <div className="game-cat-pic">
                <em className={contentCategory}>
                  <span />
                </em>
              </div>
              <div>
                <span className="game-cat-label">Game content category</span>
                <strong className="game-cat-value">{contentCategoryName[contentCategory]}</strong>
              </div>
            </div>
          </Col>
        </Row>
      </CardContent>
    </Card>
  );
};
