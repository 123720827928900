import React from 'react';
import { FormikProps } from 'formik';
import { BrandEditDetails } from './BrandEditDetails';
import { BrandEditScutiCredentials } from './BrandEditScutiCredentials';
import { BrandMediaInvestment } from './BrandMediaInvestment';
import { Grid } from '@material-ui/core';
import { UseFormReturn } from 'react-hook-form';
import { BrandFormData } from './BrandEditForm.utils';

interface Props {
  form: UseFormReturn<BrandFormData>;
}

export const BrandEditForm: React.FC<Props> = ({ form }) => {
  return (
    <Grid container spacing={2}>
      <Grid container item spacing={2}>
        <Grid item sm={12} md={4} lg={4}>
          <BrandEditDetails form={form} />
        </Grid>
        <Grid item sm={12} md={4} lg={4}>
          <BrandEditScutiCredentials form={form} />
        </Grid>
        <Grid item sm={12} md={4} lg={4}>
          <BrandMediaInvestment form={form} />
        </Grid>
      </Grid>
    </Grid>
  );
};
