import React from 'react';
import { COUNTRIES_SELECT_OPTIONS, USA_STATES_SELECT_OPTIONS } from 'types/select-types';
import { Box, Card, CardContent, CardHeader } from '@material-ui/core';
import { ScutiInput } from 'components/material/ScutiInput';
import { ScutiSelect } from 'components/material/ScutiSelect';
import { OrganizationFormData } from './OrganizationEditForm.utils';
import { Controller, UseFormReturn } from 'react-hook-form';

interface Props {
  form: UseFormReturn<OrganizationFormData>;
}

export const OrganizationAddressDetails: React.FC<Props> = ({ form }) => {
  const isUS = form.watch().country === 'US';

  return (
    <Card>
      <CardHeader title="Address Details" />
      <CardContent>
        <Box>
          <Controller
            control={form.control}
            name="address1"
            render={({ field, fieldState }) => (
              <ScutiInput
                title="Address 1 *"
                placeholder="Address of your organization"
                error={fieldState.error?.message}
                {...field}
              />
            )}
          />
        </Box>
        <Box mt={2}>
          <Controller
            control={form.control}
            name="address2"
            render={({ field, fieldState }) => (
              <ScutiInput
                title="Address 2"
                placeholder="Address 2 of your organization"
                error={fieldState.error?.message}
                {...field}
              />
            )}
          />
        </Box>
        <Box mt={2}>
          <Controller
            control={form.control}
            name="country"
            render={({ field, fieldState }) => (
              <ScutiSelect
                title="Country *"
                placeholder="Country"
                options={COUNTRIES_SELECT_OPTIONS}
                error={fieldState.error?.message}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.target.value);
                  form.resetField('state');
                }}
                onBlur={field.onBlur}
              />
            )}
            rules={{ required: true }}
          />
        </Box>
        <Box mt={2}>
          {isUS ? (
            <Controller
              control={form.control}
              name="state"
              render={({ field, fieldState }) => (
                <ScutiSelect
                  title="State *"
                  placeholder="State"
                  options={USA_STATES_SELECT_OPTIONS}
                  error={fieldState.error?.message}
                  {...field}
                />
              )}
              rules={{ required: true }}
            />
          ) : (
            <Controller
              control={form.control}
              name="state"
              render={({ field, fieldState }) => (
                <ScutiInput
                  title="Province/State *"
                  placeholder="Province"
                  error={fieldState.error?.message}
                  {...field}
                />
              )}
              rules={{ required: true }}
            />
          )}
        </Box>
        <Box mt={2}>
          <Controller
            control={form.control}
            name="city"
            render={({ field, fieldState }) => (
              <ScutiInput title="City *" placeholder="City" error={fieldState.error?.message} {...field} />
            )}
            rules={{ required: true }}
          />
        </Box>
        <Box mt={2}>
          <Controller
            control={form.control}
            name="zipCode"
            render={({ field, fieldState }) => (
              <ScutiInput
                title={`${isUS ? 'Zip' : 'Postal'} code*`}
                placeholder="Zip code"
                error={fieldState.error?.message}
                {...field}
              />
            )}
            rules={{ required: true }}
          />
        </Box>
      </CardContent>
    </Card>
  );
};
