import React from 'react';
import { Box, Card, CardContent, CardHeader, TextField } from '@material-ui/core';
import { ScutiSelect } from 'components/material/ScutiSelect';
import { FormikProps } from 'formik';
import { ConnectFormData } from './ConnectEditForm.utils';
import { Controller, UseFormReturn } from 'react-hook-form';
import { ScutiInput } from 'components/material/ScutiInput';

interface Props {
  form: UseFormReturn<ConnectFormData>;
}

export function ConnectMediaInvestment({ form }: Props) {
  const disabled = !!form.watch('id');

  return (
    <Card>
      <CardHeader
        title={
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box component="span" fontWeight="bold">
              Media Investment
            </Box>
            {/* <Controller
              control={form.control}
              name="mediaInvestmentEnabled"
              render={({ field }) => (
                <CustomInput
                  type="checkbox"
                  id="investment-box"
                  checked={field.value || undefined}
                  onChange={({ target }) => field.onChange(target.checked)}
                />
              )}
            /> */}
          </Box>
        }
      />
      <CardContent>
        <Box>
          <label>Direct Response</label>
          <Box sx={{ display: 'flex' }}>
            <Controller
              control={form.control}
              name="directResponse.unit"
              render={({ field, fieldState }) => (
                <ScutiSelect
                  {...field}
                  placeholder="CPM"
                  options={[
                    { label: 'CPM', value: 'cpm' },
                    { label: 'CPC', value: 'cpc' },
                  ]}
                  error={fieldState.error?.message}
                  disabled={disabled}
                  // disabled={!mediaInvestmentEnabled}
                />
              )}
            />
            <Controller
              control={form.control}
              name="directResponse.amount"
              render={({ field, fieldState }) => (
                <ScutiInput
                  {...field}
                  size="small"
                  variant="outlined"
                  type="decimal"
                  placeholder="$0.10"
                  style={{ paddingLeft: '10px' }}
                  error={fieldState.error?.message}
                  disabled={disabled}
                  // disabled={!mediaInvestmentEnabled}
                />
              )}
            />
          </Box>
        </Box>
        <Box mt={2}>
          <label>Engagement</label>
          <Box sx={{ display: 'flex' }}>
            <Controller
              control={form.control}
              name="engagement.unit"
              render={({ field, fieldState }) => (
                <ScutiSelect
                  {...field}
                  placeholder="CPM"
                  options={[
                    { label: 'CPM', value: 'cpm' },
                    { label: 'CPC', value: 'cpc' },
                  ]}
                  error={fieldState.error?.message}
                  disabled={disabled}
                  // disabled={!mediaInvestmentEnabled}
                />
              )}
            />
            <Controller
              control={form.control}
              name="engagement.amount"
              render={({ field, fieldState }) => (
                <ScutiInput
                  {...field}
                  size="small"
                  variant="outlined"
                  type="decimal"
                  placeholder="$0.20"
                  style={{ paddingLeft: '10px' }}
                  error={fieldState.error?.message}
                  disabled={disabled}
                  // disabled={!mediaInvestmentEnabled}
                />
              )}
            />
          </Box>
        </Box>
        <Box mt={2}>
          <label>Video</label>
          <Box sx={{ display: 'flex' }}>
            <Controller
              control={form.control}
              name="video.unit"
              render={({ field, fieldState }) => (
                <ScutiSelect
                  {...field}
                  placeholder="CPM"
                  options={[
                    { label: 'CPM', value: 'cpm' },
                    { label: 'CPC', value: 'cpc' },
                  ]}
                  error={fieldState.error?.message}
                  disabled={disabled}
                  // disabled={!mediaInvestmentEnabled}
                />
              )}
            />
            <Controller
              control={form.control}
              name="video.amount"
              render={({ field, fieldState }) => (
                <ScutiInput
                  {...field}
                  size="small"
                  variant="outlined"
                  type="decimal"
                  placeholder="$0.20"
                  style={{ paddingLeft: '10px' }}
                  error={fieldState.error?.message}
                  disabled={disabled}
                  // disabled={!mediaInvestmentEnabled}
                />
              )}
            />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
