import { Card, CardHeader, CardContent, FormGroup, Box } from '@material-ui/core';
import { CustomInput } from 'reactstrap';
import { scutiColors } from 'material-theme/variables';
import { Controller, UseFormReturn } from 'react-hook-form';
import { CampaignFormData } from './CampaignEditForm.utils';

interface Props {
  form: UseFormReturn<CampaignFormData>;
  paused?: boolean;
}

export function EnhancementsDetails({ form, paused }: Props) {
  return (
    <Card>
      <CardHeader title="Extras" />
      <CardContent>
        <Box>
          <FormGroup>
            <label>
              <Controller
                control={form.control}
                name="extras.moreExposure"
                render={({ field }) => (
                  <CustomInput
                    id="extras-more-exposure"
                    type="checkbox"
                    label="More exposure"
                    checked={field.value}
                    onCheck={field.onChange}
                    onBlur={field.onBlur}
                    disabled={!paused}
                  />
                )}
              />
            </label>
            <Box color={scutiColors.grey200}>Grab more attention by adding a glowing border to your product.</Box>
          </FormGroup>
        </Box>
        <Box mt={2}>
          <FormGroup>
            <label>
              <Controller
                control={form.control}
                name="extras.interstitials"
                render={({ field }) => (
                  <CustomInput
                    id="extras-interstitials"
                    type="checkbox"
                    label="Interstitials"
                    name="extras.interstitials"
                    checked={!!field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    disabled={!paused}
                  />
                )}
              />
            </label>
            <Box color={scutiColors.grey200}>Must have 300 x 250 image available.</Box>
          </FormGroup>
        </Box>
      </CardContent>
    </Card>
  );
}
