import { SelectClassKey, StyleRules } from '@material-ui/core';
import { scutiColors, scutiSizes } from './variables';

export const MuiSelect: Partial<StyleRules<SelectClassKey, {}>> = {
  icon: {
    color: scutiColors.borderMain,
  },
  iconOpen: {
    color: scutiColors.borderFocused,
  },
  root: {
    paddingTop: scutiSizes.inputPadding,
    paddingBottom: scutiSizes.inputPadding,
    fontSize: scutiSizes.inputFontSize,
    color: scutiColors.textMain,
    border: `none`,
    '&:hover': {
      cursor: 'default',
    },
    '&+input[value=""]': {
      height: '100%',
      paddingLeft: 12,
      paddingRight: 12,
      opacity: 1,
      border: 'none',
      background: 'transparent',
    },
  },
};
