import { analyticsFetcher } from 'api/analytics-api';
import { useQuery } from 'react-query';
import { AnalyticsRange } from 'types/app-types';

export interface OpenedScuti {
  openedScuti: number;
  openedSdk: number;
}

export interface TotalOrders {
  totalOrders: number;
}

export type AvgProductsEngaged = {
  clickedOffer: number;
  openedScuti: number;
}[];

export type AvgAdsImpressions = {
  adsImpressions: number;
  openedScuti: number;
}[];

export type AvgAdsEngaged = {
  clickedOffer: number;
  openedScuti: number;
}[];

export type AvgTimeSpent = {
  avgDurationSeconds: number;
};

export type TopProducts = {
  productId: string;
  count: number;
  name: string;
  images: string[];
}[];

export type TopAds = {
  campaignId: string;
  count: number;
  mediaBanner?: string;
  mediaVertical?: string;
  mediaTile?: string;
}[];

interface Params {
  shopId: string;
  range: AnalyticsRange;
  metric:
    | 'opened-scuti'
    | 'total-orders'
    | 'avg-products-clicks'
    | 'avg-ads-clicks'
    | 'avg-ads-impressions'
    | 'top-products-impressions'
    | 'top-products-clicks'
    | 'top-ad-campaigns-clicks'
    | 'top-ad-campaigns-impressions';
}

export const useStatQuery = <T>({ range, metric, shopId }: Params) =>
  useQuery<T>(
    [range, metric, shopId],
    (() => {
      return analyticsFetcher('shop')<T>(metric, {
        date_from: range.from.format('YYYY-MM-DD'),
        // Add additional day to cover last minutes of `to` date
        date_to: range.to.clone().add(1, 'day').format('YYYY-MM-DD'),
        id: shopId,
      });
    })(),
    { enabled: !!shopId },
  );
