import React from 'react';
import classNames from 'classnames';
import { Button, Card, CardBody, Form, FormFeedback, FormGroup, Input, CardHeader } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { validators, validate } from 'utils/form-utils';
import { SuccessPopup } from 'components/SuccessPopup';

interface FormValues {
  oldPassword: string;
  password: string;
  repeatPassword: string;
}

const validationSchema = () =>
  Yup.object().shape({
    oldPassword: validators.password,
    password: validators.password,
    repeatPassword: validators.repeatPassword,
  });

const initialValues = {
  oldPassword: '',
  password: '',
  repeatPassword: '',
};

interface Props {
  error?: string;
  onSave: (params: Omit<FormValues, 'repeatPassword'>) => void;
}

export const ChangePassword: React.FC<Props> = ({ onSave, error }) => {
  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: { ...initialValues },
    onSubmit: onSave,
    isInitialValid: false,
    // @ts-ignore
    validate: validate(validationSchema),
  });
  const { values, errors, touched, handleChange, handleBlur, handleSubmit, isValid } = formik;
  return (
    <Card className="bg-custom">
      <CardHeader>Change Password</CardHeader>
      <CardBody>
        <Form onSubmit={handleSubmit} noValidate name="simpleForm">
          <FormGroup
            className={classNames(
              { 'input-active': values.oldPassword },
              { 'input-invalid': touched.oldPassword && !!errors.oldPassword },
              { 'input-touched': touched.oldPassword },
              { 'input-valid': touched.oldPassword && !errors.oldPassword },
            )}
          >
            <label htmlFor="oldPassword">Current password</label>
            <Input
              type="password"
              name="oldPassword"
              id="oldPassword"
              className="input-sm bg-light"
              placeholder="What is your current password?"
              valid={touched.oldPassword && !errors.oldPassword}
              invalid={touched.oldPassword && !!errors.oldPassword}
              required
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.oldPassword}
            />
            <FormFeedback>{errors.oldPassword}</FormFeedback>
          </FormGroup>
          <FormGroup
            className={classNames(
              { 'input-active': values.password },
              { 'input-invalid': touched.password && !!errors.password },
              { 'input-touched': touched.password },
              { 'input-valid': touched.password && !errors.password },
            )}
          >
            <label htmlFor="password">New password</label>
            <Input
              type="password"
              name="password"
              id="password"
              className="input-sm bg-light"
              placeholder="New password"
              valid={touched.password && !errors.password}
              invalid={touched.password && !!errors.password}
              required
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
            />
            <FormFeedback>{errors.password}</FormFeedback>
          </FormGroup>
          <FormGroup
            className={classNames(
              { 'input-active': values.repeatPassword },
              {
                'input-invalid': touched.repeatPassword && !!errors.password,
              },
              { 'input-touched': touched.repeatPassword },
              {
                'input-valid': touched.repeatPassword && !errors.password,
              },
            )}
          >
            <label htmlFor="repeatPassword">Repeat password</label>
            <Input
              type="password"
              name="repeatPassword"
              className="input-sm bg-light"
              id="repeatPassword"
              placeholder="Repeat password"
              valid={touched.repeatPassword && !errors.repeatPassword}
              invalid={touched.repeatPassword && !!errors.repeatPassword}
              required
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.repeatPassword}
            />
            <FormFeedback>{errors.repeatPassword}</FormFeedback>
          </FormGroup>
          {error && <div className="text-center text-danger mb-1">{error}</div>}
          <FormGroup className="actions">
            <Button type="submit" color="primary" className="btn-block" disabled={!isValid}>
              Change password
            </Button>
          </FormGroup>
        </Form>
      </CardBody>
      <SuccessPopup />
    </Card>
  );
};
