import React, { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'store/app-store.hook';
import { Period } from 'types/app-types';
import { PeriodSelector } from 'components/PeriodSelector';
import { FULFILLMENT_SELECT_OPTIONS } from 'types/select-types';
import { MultipleSelect } from 'components/common-components/MultipleSelect';
import { useDebounce } from 'use-debounce';
import { getExportAllToCsvUrl } from 'api';
import { OrderStatus } from 'types/__generated__/types';
import { ScutiInput } from 'components/material/ScutiInput';
import { OrderListItemFragment, useOrdersQuery } from '../__generated__/orders.hooks';
import { applyFilters } from '../Orders.utils';
import { PaginationLayout } from 'layouts/PaginationLayout';
import { usePaging } from 'hooks/usePaging';
import { useOrdersTableColumns } from './useOrdersTableColumns';
import { useSortBy, useTable } from 'react-table';
import { useSorting } from 'hooks/useSorting';
import { ScutiTable } from 'components/ScutiTable';

export const OrdersListPage: React.FC = observer(() => {
  // const { selectedItem } = useAppStore().uiStore;

  const [searchInput, setSearchInput] = useState<string>('');
  const [filterValue] = useDebounce(searchInput, 500);
  const [selectedPeriod, setSelectedPeriod] = useState<Period | null>(null);
  const [selectedStatuses, setSelectedStatuses] = useState<OrderStatus[] | null>(null);
  const paging = usePaging();
  // const shopId = selectedItem.id;

  const [orders, setOrders] = useState<OrderListItemFragment[]>([]);

  // const filters = useMemo(() => applyFilters(selectedStatuses, selectedPeriod, filterValue), [
  //   filterValue,
  //   selectedPeriod,
  //   selectedStatuses,
  // ]);

  const columns = useOrdersTableColumns(() => ({}));
  const tableInstance = useTable({ columns: [], data: [], manualSortBy: true }, useSortBy);
  // const tableSorting = useSorting(tableInstance.state.sortBy);

  // const useOrders = useOrdersQuery(
  //   {
  //     id: shopId,
  //     paging,
  //     filters,
  //     // sorting: tableSorting,
  //   },
  //   {
  //     keepPreviousData: true,
  //     onSuccess: ({ shopOrders }) => ({}),
  //   },
  // );

  // const onFilterChange = useCallback(
  //   data => setSelectedStatuses(Object.values(data).map(({ value }: any) => value)),
  //   [],
  // );

  // const onInputFilterChange = useCallback(e => {
  //   setSearchInput(e.target.value);
  // }, []);

  // const isLoading = useOrders.isLoading;
  // const showOrders = !!orders.length && !isLoading;
  return null;
  // return (
  //   <PaginationLayout
  //     isLoading={isLoading}
  //     paging={useOrders.data?.shopOrders.paging}
  //     header={
  //       <div className="topbar topbar--alt">
  //         <div className="col-heading">
  //           <h1 className="page-title">Orders</h1>
  //           <div className="col-search-alt">
  //             <ScutiInput placeholder="Search" value={searchInput} onChange={onInputFilterChange} />
  //           </div>
  //         </div>
  //         <div className="col-filter-btns">
  //           <PeriodSelector onSelect={setSelectedPeriod} />
  //           <div className="col-select-btn">
  //             <MultipleSelect
  //               className="react-select select_input-sm"
  //               classNamePrefix="react-select"
  //               placeholder="Filter by"
  //               options={FULFILLMENT_SELECT_OPTIONS}
  //               onChange={onFilterChange}
  //             />
  //             <div className="col-btn-alt">
  //               <a className="btn btn-primary" href={getExportAllToCsvUrl(shopId)} download>
  //                 Export All
  //               </a>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     }
  //     // content={showOrders && <ScutiTable table={tableInstance} />}
  //   />
  // );
});
