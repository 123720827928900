import React from 'react';
import { OrderFragment } from '../__generated__/orders.hooks';
import { Card, CardContent, CardHeader, Box } from '@material-ui/core';

interface Props {
  order: OrderFragment;
}

export const CustomerDetails: React.FC<Props> = ({ order }) => {
  const { user, shippingInformation } = order;
  return (
    <Card>
      <CardHeader title="Customer Details" />
      <CardContent>
        <Box fontWeight="bold">Full name</Box>
        <Box mt={1}> {user.fullName}</Box>
        <Box mt={2} fontWeight="bold">
          Address
        </Box>
        <Box mt={1}>
          <Box>
            {shippingInformation?.address1} {shippingInformation?.address2}
          </Box>
          <Box>
            {shippingInformation?.city}, {shippingInformation?.state}
          </Box>
          <Box>
            {shippingInformation?.zipCode} {shippingInformation?.country}
          </Box>
        </Box>
        <Box mt={2} fontWeight="bold">
          Email address
        </Box>
        <Box mt={1}>
          <a href="mailto:{email}">{user.email}</a>
        </Box>
      </CardContent>
    </Card>
  );
};
