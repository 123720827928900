import React from 'react';

interface Props {
  className?: string;
}

export const CodeIcon: React.FC<Props> = ({ className = '' }) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        className={className}
        d="M9.17153 5.98681L12 4.35724L10.4143 1.61639L7.5859 3.24599V0H4.4141V3.24599L1.58571 1.61639L0 4.35724L2.82847 5.98681L2.64313e-05 7.61639L1.58571 10.3572L4.4141 8.72764V12H7.5859V8.72764L10.4143 10.3572L12 7.61639L9.17153 5.98681Z"
      />
    </svg>
  );
};
