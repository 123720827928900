export type Nullable<T> = T | null;
export type NString = string | null;
export type WithoutId<T> = Omit<T, 'id'>;
export type OptionalId<T> = Omit<T, 'id'> & { id?: string };

export const hasId = <T, K extends { id: string }>(value: T | K): value is K => {
  return (value as K).id !== undefined;
};

export type DeepPartialWithId<T> = {
  [K in keyof T]: K extends 'id' ? T[K] | null | undefined : T[K] extends Array<any> ? DeepPartialWithId<T[K]> : T[K];
};

// FIXME: EXCLUDE ARRAYS FROM UPDATING TYPES
export type DeepPartialBy<T, K extends keyof any> = T extends object
  ? { [P1 in Extract<keyof T, K>]?: DeepPartialBy<T[P1], K> } & {
      [P2 in keyof Pick<T, Exclude<keyof T, K>>]: DeepPartialBy<T[P2], K>;
    }
  : T;

// FIXME: EXCLUDE ARRAYS FROM UPDATING TYPES
export type DeepRequiredBy<T, K extends keyof any> = T extends object
  ? { [P1 in Extract<keyof T, K>]-?: DeepRequiredBy<T[P1], K> } & {
      [P2 in keyof Pick<T, Exclude<keyof T, K>>]: DeepRequiredBy<T[P2], K>;
    }
  : T;
