import React from 'react';

interface Props {
  className?: string;
}

export const LockIcon: React.FC<Props> = ({ className = '' }) => {
  return (
    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        className={className}
        d="M6.50944 0C3.60692 0 1.24546 2.36137 1.24546 5.26398V8.27038C1.24546 8.30454 1.2033 8.36211 1.17018 8.37312C0.885525 8.46804 0.654342 8.5503 0.442717 8.63188C0.190346 8.72918 0 9.00886 0 9.28248V16.1903C0 16.4621 0.189116 16.742 0.439888 16.8414C2.38016 17.6102 4.42225 18 6.50941 18C8.59658 18 10.6387 17.6101 12.579 16.8413C12.8297 16.7419 13.0187 16.4621 13.0187 16.1903V9.28248C13.0187 9.00886 12.8284 8.72918 12.5759 8.63179C12.3642 8.55021 12.1331 8.46795 11.8486 8.37303C11.8156 8.36202 11.7734 8.30442 11.7734 8.27026V5.26389C11.7733 2.36137 9.41196 0 6.50944 0ZM5.07567 11.3864C5.07567 10.594 5.71759 9.95272 6.50935 9.95272C7.30106 9.95272 7.94298 10.5939 7.94298 11.3864C7.94298 11.9114 7.64688 12.351 7.22615 12.6003V14.9705C7.22615 15.3668 6.90554 15.6874 6.50932 15.6874C6.11307 15.6874 5.79249 15.3668 5.79249 14.9705V12.6003C5.3718 12.351 5.07567 11.9114 5.07567 11.3864ZM9.96338 5.26389V7.85786C8.83072 7.61413 7.66868 7.4907 6.50991 7.49061C5.35165 7.49061 4.18986 7.61407 3.05544 7.85758V5.26389C3.05544 3.35935 4.60484 1.80998 6.50935 1.80998C8.41383 1.80995 9.96338 3.35935 9.96338 5.26389Z"
      />
    </svg>
  );
};
