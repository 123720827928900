import * as Types from '../../types/__generated__/types';

import { ApolloError } from 'apollo-client';
import { useQuery, UseQueryOptions, useMutation, UseMutationOptions } from 'react-query';
import { fetcher } from 'api';
export type AdvertisementListItemFragment = {
  id: string;
  name: string;
  brandId: string;
  category?: Types.Maybe<string>;
  type: Types.AdvertisementType;
};

export type AdvertisementFragment = {
  id: string;
  name: string;
  brandId: string;
  cpa?: Types.Maybe<number>;
  category?: Types.Maybe<string>;
  type: Types.AdvertisementType;
  appDownload?: Types.Maybe<{
    appleId?: Types.Maybe<number>;
    androidId?: Types.Maybe<string>;
    pcLink?: Types.Maybe<string>;
  }>;
  coupon?: Types.Maybe<{
    couponLabel?: Types.Maybe<string>;
    couponDescription?: Types.Maybe<string>;
    couponImages?: Types.Maybe<Array<string>>;
  }>;
  internal?: Types.Maybe<{
    internalLabel?: Types.Maybe<string>;
    internalHtml5Url?: Types.Maybe<string>;
    internalUnityUrl?: Types.Maybe<string>;
  }>;
  video?: Types.Maybe<{ videoUrl?: Types.Maybe<string> }>;
  noAction?: Types.Maybe<{ noActionLabel?: Types.Maybe<string> }>;
  leadGen?: Types.Maybe<{ affiliateUrl?: Types.Maybe<string> }>;
};

export type AdvertisementsQueryVariables = Types.Exact<{
  paging?: Types.Maybe<Types.PagingInput>;
  sorting?: Types.Maybe<Array<Types.SortFieldInput> | Types.SortFieldInput>;
  filters?: Types.Maybe<Array<Types.FilterInput> | Types.FilterInput>;
}>;

export type AdvertisementsQuery = {
  advertisements: {
    sorting?: Types.Maybe<Array<{ name: string; dir: string }>>;
    paging: { offset: number; limit: number; totalCount: number };
    nodes: Array<AdvertisementListItemFragment>;
  };
};

export type AdvertisementQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type AdvertisementQuery = { advertisement: AdvertisementFragment };

export type CreateAdvertisementMutationVariables = Types.Exact<{
  input: Types.NewAdvertisementInput;
}>;

export type CreateAdvertisementMutation = { createAdvertisement: AdvertisementFragment };

export type UpdateAdvertisementMutationVariables = Types.Exact<{
  input: Types.UpdateAdvertisementInput;
}>;

export type UpdateAdvertisementMutation = { updateAdvertisement: AdvertisementFragment };

export const AdvertisementListItemFragmentDoc = `
    fragment AdvertisementListItem on Advertisement {
  id
  name
  brandId
  category
  type
}
    `;
export const AdvertisementFragmentDoc = `
    fragment Advertisement on Advertisement {
  id
  name
  brandId
  cpa
  category
  type
  appDownload {
    appleId
    androidId
    pcLink
  }
  coupon {
    couponLabel
    couponDescription
    couponImages
  }
  internal {
    internalLabel
    internalHtml5Url
    internalUnityUrl
  }
  video {
    videoUrl
  }
  noAction {
    noActionLabel
  }
  leadGen {
    affiliateUrl
  }
}
    `;
export const AdvertisementsDocument = `
    query Advertisements($paging: PagingInput, $sorting: [SortFieldInput!], $filters: [FilterInput!]) {
  advertisements(paging: $paging, sorting: $sorting, filters: $filters) {
    sorting {
      name
      dir
    }
    paging {
      offset
      limit
      totalCount
    }
    nodes {
      ...AdvertisementListItem
    }
  }
}
    ${AdvertisementListItemFragmentDoc}`;
export const useAdvertisementsQuery = <TData = AdvertisementsQuery, TError = ApolloError>(
  variables?: AdvertisementsQueryVariables,
  options?: UseQueryOptions<AdvertisementsQuery, TError, TData>,
) =>
  useQuery<AdvertisementsQuery, TError, TData>(
    ['Advertisements', variables],
    fetcher<AdvertisementsQuery, AdvertisementsQueryVariables>(AdvertisementsDocument, variables),
    options,
  );
export const AdvertisementDocument = `
    query Advertisement($id: String!) {
  advertisement(id: $id) {
    ...Advertisement
  }
}
    ${AdvertisementFragmentDoc}`;
export const useAdvertisementQuery = <TData = AdvertisementQuery, TError = ApolloError>(
  variables: AdvertisementQueryVariables,
  options?: UseQueryOptions<AdvertisementQuery, TError, TData>,
) =>
  useQuery<AdvertisementQuery, TError, TData>(
    ['Advertisement', variables],
    fetcher<AdvertisementQuery, AdvertisementQueryVariables>(AdvertisementDocument, variables),
    options,
  );
export const CreateAdvertisementDocument = `
    mutation CreateAdvertisement($input: NewAdvertisementInput!) {
  createAdvertisement(input: $input) {
    ...Advertisement
  }
}
    ${AdvertisementFragmentDoc}`;
export const useCreateAdvertisementMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<CreateAdvertisementMutation, TError, CreateAdvertisementMutationVariables, TContext>,
) =>
  useMutation<CreateAdvertisementMutation, TError, CreateAdvertisementMutationVariables, TContext>(
    (variables?: CreateAdvertisementMutationVariables) =>
      fetcher<CreateAdvertisementMutation, CreateAdvertisementMutationVariables>(
        CreateAdvertisementDocument,
        variables,
      )(),
    options,
  );
export const UpdateAdvertisementDocument = `
    mutation UpdateAdvertisement($input: UpdateAdvertisementInput!) {
  updateAdvertisement(input: $input) {
    ...Advertisement
  }
}
    ${AdvertisementFragmentDoc}`;
export const useUpdateAdvertisementMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<UpdateAdvertisementMutation, TError, UpdateAdvertisementMutationVariables, TContext>,
) =>
  useMutation<UpdateAdvertisementMutation, TError, UpdateAdvertisementMutationVariables, TContext>(
    (variables?: UpdateAdvertisementMutationVariables) =>
      fetcher<UpdateAdvertisementMutation, UpdateAdvertisementMutationVariables>(
        UpdateAdvertisementDocument,
        variables,
      )(),
    options,
  );
