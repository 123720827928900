import React from 'react';
import { ScutiInput } from 'components/material/ScutiInput';
import { BrandFormData } from './BrandEditForm.utils';
import { Box, Card, CardContent, CardHeader } from '@material-ui/core';
import { Controller, UseFormReturn } from 'react-hook-form';

interface Props {
  form: UseFormReturn<BrandFormData>;
}

export const BrandEditDetails: React.FC<Props> = ({ form }) => {
  return (
    <Card>
      <CardHeader title="Details" />
      <CardContent>
        <Box mt={2}>
          <Controller
            control={form.control}
            name="name"
            render={({ field, fieldState }) => (
              <ScutiInput title="Title" placeholder="Advertiser Name" error={fieldState.error?.message} {...field} />
            )}
          />
        </Box>
        <Box mt={2}>
          <Controller
            control={form.control}
            name="description"
            render={({ field, fieldState }) => (
              <ScutiInput
                title="Description"
                placeholder="Description of Advertiser"
                multiline
                minRows={8}
                error={fieldState.error?.message}
                {...field}
              />
            )}
          />
        </Box>
      </CardContent>
    </Card>
  );
};
