import { RouterStore } from 'mobx-react-router';
import { AuthStore } from './auth-store';
import { observable, action } from 'mobx';
import { appRoutes } from 'routing';
import { UiStore } from './ui.store';
import { PermissionStore } from './permission.store';

export class AppStore {
  @observable authStore: AuthStore;
  @observable uiStore: UiStore;
  @observable permissions: PermissionStore;
  routerStore: RouterStore;

  constructor(routerStore: RouterStore) {
    this.authStore = new AuthStore();
    this.uiStore = new UiStore();
    this.permissions = new PermissionStore(this.authStore);
    this.routerStore = routerStore;
  }

  @action
  logOut = () => {
    this.authStore.logOut();
    this.routerStore.push(appRoutes.LOGIN);
  };
}
