import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { InfoPopup } from 'components/InfoPopup';
import { useDebounce } from 'use-debounce/lib';
import { Filter_Type } from 'types/__generated__/types';
import { PaginationLayout } from 'layouts/PaginationLayout';
import { TopNavigation } from 'components/material/TopNavigation';
import { Box, Grid } from '@mui/material';
import { ScutiInput } from 'components/material/ScutiInput';
import { usePaging } from 'hooks/usePaging';
import { NoData } from 'components/material/NoData';
import { GameItemFragment, useDeleteGameMutation, useGameItemsQuery } from '../__generated__/games-list-page.hooks';
import { useSortBy, useTable } from 'react-table';
import { useGamesTableColumns } from './useGamesTableColumns';
import { ScutiTable } from 'components/ScutiTable';
import { useSorting } from 'hooks/useSorting';
import { defaultSorting } from 'types/app-types';

export const GamesListPage: React.FC = observer(() => {
  const paging = usePaging();
  const [deleteId, setDeleteId] = React.useState<string | null>(null);
  const [searchInput, setSearchInput] = React.useState<string>('');
  const [inputValue] = useDebounce(searchInput, 500);
  const [games, setGames] = useState<GameItemFragment[]>([]);
  const columns = useGamesTableColumns(setDeleteId);
  const tableInstance = useTable({ columns, data: games, manualSortBy: true }, useSortBy);
  const sorting = useSorting(tableInstance.state.sortBy, defaultSorting);

  const useGameItems = useGameItemsQuery(
    {
      paging,
      filters: inputValue
        ? [
            {
              name: 'search',
              operator: Filter_Type.Like,
              value: [inputValue],
            },
          ]
        : undefined,
      sorting,
    },
    {
      keepPreviousData: true,
      onSuccess: ({ games }) => setGames(games.nodes),
    },
  );

  const useDeleteGame = useDeleteGameMutation();
  const onArchive = useCallback(() => {
    if (deleteId) {
      useDeleteGame.mutateAsync({ id: deleteId });
      useGameItems.refetch();
    }
    setDeleteId(null);
  }, [deleteId, useDeleteGame, useGameItems]);
  const onCancel = useCallback(() => setDeleteId(null), []);

  const isLoading = useGameItems.isLoading;
  const pagingResponse = useGameItems.data?.games.paging;
  const showGames = !!games.length && !isLoading;

  return (
    <PaginationLayout
      isLoading={isLoading}
      paging={pagingResponse}
      header={
        <TopNavigation title="Games Management">
          <Grid container justifyContent="flex-end">
            <Grid item md={6}>
              <ScutiInput
                placeholder="Search"
                value={searchInput}
                onChange={({ target }) => setSearchInput(target.value)}
              />
            </Grid>
          </Grid>
        </TopNavigation>
      }
      content={
        showGames && (
          <Box>
            {!!deleteId && (
              <InfoPopup
                title="Delete game?"
                description="This game will be permanently deleted and this action cannot be undone."
                proceedText="Archive"
                cancelText="Cancel"
                onProceed={onArchive}
                onCancel={onCancel}
              />
            )}
            {games.length && <ScutiTable table={tableInstance} />}
          </Box>
        )
      }
    />
  );
});
