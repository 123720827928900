import React from 'react';
import { FormikProps } from 'formik';
import { Box, Typography, Card, CardHeader, CardContent } from '@material-ui/core';
import { ScutiImgInput } from 'components/material/ScutiImgInput';
import { scutiColors } from 'material-theme/variables';
import { CampaignBudgetOption } from 'types/__generated__/types';
import { FormHelperText } from '@mui/material';
import { values } from 'mobx';
import { UseFormReturn, Controller } from 'react-hook-form';
import { CampaignFormData } from './CampaignEditForm.utils';

interface Props {
  form: UseFormReturn<CampaignFormData>;
  paused?: boolean;
}

export function BannerDetails({ form, paused }: Props) {
  return (
    <Card>
      <CardHeader title="Images" />
      <CardContent>
        <Box>
          <Controller
            control={form.control}
            name={`media.banner`}
            render={({ field, fieldState }) => {
              const error = fieldState.error?.message;
              return (
                <Box
                  width="100%"
                  height="7.5rem"
                  bgcolor="#3b3d44"
                  sx={{ borderRadius: '8px', overflow: 'hidden' }}
                  p={2}
                >
                  <ScutiImgInput image={field.value} onChange={field.onChange} disabled={!paused} error={!!error}>
                    <Box
                      height="100%"
                      display="flex"
                      flexDirection="column"
                      color="text.white"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography variant="body2">Upload a 728x90 image</Typography>
                    </Box>
                  </ScutiImgInput>
                  {error && <FormHelperText error>{error}</FormHelperText>}
                </Box>
              );
            }}
          />
          <Box mt={1} color={scutiColors.grey200}>
            Proper file format is .jpeg or .png. Max file size is 5MB.
          </Box>
        </Box>
        <Box mt={2}>
          <Controller
            control={form.control}
            name={`media.banner`}
            render={({ field, fieldState }) => {
              const error = fieldState.error?.message;
              return (
                <Box width="100%" height="7.5rem" bgcolor="#3b3d44" sx={{ borderRadius: '8px', overflow: 'hidden' }}>
                  <ScutiImgInput image={field.value} onChange={field.onChange} disabled={!paused} error={!!error}>
                    <Box
                      height="100%"
                      display="flex"
                      flexDirection="column"
                      color="text.white"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography variant="body2">Upload a 300x250 image</Typography>
                    </Box>
                  </ScutiImgInput>
                  {error && <FormHelperText error>{error}</FormHelperText>}
                </Box>
              );
            }}
          />
        </Box>
        <Box mt={1} color={scutiColors.grey200}>
          Proper file format is .jpeg or .png. Max file size is 5MB.
        </Box>
        <Box mt={2}>
          <Controller
            control={form.control}
            name={`media.banner`}
            render={({ field, fieldState }) => {
              const error = fieldState.error?.message;
              return (
                <Box width="100%" height="7.5rem" bgcolor="#3b3d44" sx={{ borderRadius: '8px', overflow: 'hidden' }}>
                  <ScutiImgInput image={field.value} onChange={field.onChange} disabled={!paused} error={!!error}>
                    <Box
                      height="100%"
                      display="flex"
                      flexDirection="column"
                      color="text.white"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography variant="body2">Upload a 300x600 image</Typography>
                    </Box>
                  </ScutiImgInput>
                  {error && <FormHelperText error>{error}</FormHelperText>}
                </Box>
              );
            }}
          />
          <Box mt={1} color={scutiColors.grey200}>
            Proper file format is .jpeg or .png. Max file size is 5MB.
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
