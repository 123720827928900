import * as Types from '../../../types/__generated__/types';

import { ApolloError } from 'apollo-client';
import { useQuery, UseQueryOptions, useMutation, UseMutationOptions } from 'react-query';
import { fetcher } from 'api';
export type GameItemFragment = {
  id: string;
  name: string;
  tags: Array<string>;
  thumbnail?: Types.Maybe<string>;
  category: string;
  contentCategory: Types.ContentCategory;
  admins: Array<{ fullName?: Types.Maybe<string> }>;
};

export type GameItemsQueryVariables = Types.Exact<{
  paging?: Types.Maybe<Types.PagingInput>;
  filters?: Types.Maybe<Array<Types.FilterInput> | Types.FilterInput>;
  sorting?: Types.Maybe<Array<Types.SortFieldInput> | Types.SortFieldInput>;
}>;

export type GameItemsQuery = {
  games: { paging: { offset: number; limit: number; totalCount: number }; nodes: Array<GameItemFragment> };
};

export type DeleteGameMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type DeleteGameMutation = { deleteGame?: Types.Maybe<string> };

export const GameItemFragmentDoc = `
    fragment GameItem on Game {
  id
  name
  tags
  thumbnail
  category
  contentCategory
  admins {
    fullName
  }
}
    `;
export const GameItemsDocument = `
    query GameItems($paging: PagingInput, $filters: [FilterInput!], $sorting: [SortFieldInput!]) {
  games(paging: $paging, filters: $filters, sorting: $sorting) {
    paging {
      offset
      limit
      totalCount
    }
    nodes {
      ...GameItem
    }
  }
}
    ${GameItemFragmentDoc}`;
export const useGameItemsQuery = <TData = GameItemsQuery, TError = ApolloError>(
  variables?: GameItemsQueryVariables,
  options?: UseQueryOptions<GameItemsQuery, TError, TData>,
) =>
  useQuery<GameItemsQuery, TError, TData>(
    ['GameItems', variables],
    fetcher<GameItemsQuery, GameItemsQueryVariables>(GameItemsDocument, variables),
    options,
  );
export const DeleteGameDocument = `
    mutation DeleteGame($id: String!) {
  deleteGame(id: $id)
}
    `;
export const useDeleteGameMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<DeleteGameMutation, TError, DeleteGameMutationVariables, TContext>,
) =>
  useMutation<DeleteGameMutation, TError, DeleteGameMutationVariables, TContext>(
    (variables?: DeleteGameMutationVariables) =>
      fetcher<DeleteGameMutation, DeleteGameMutationVariables>(DeleteGameDocument, variables)(),
    options,
  );
