import { getRandomColor } from 'utils/color-utils';
import { getFromStorage, saveToStorage, StorageKeys } from 'utils/local-storage.utils';
import { useCallback, useMemo, useState } from 'react';
import { UserItem, UserItems } from 'types/user-types';
import { useAppStore } from 'store/app-store.hook';
import { hasOneOfRoles } from 'utils/roles.utils';
import { RequestEntityType, Role } from 'types/__generated__/types';
import { useGamesQuery } from 'graphql/__generated__/game.hooks';
import { useShopsQuery } from 'graphql/__generated__/shop.hooks';
import { useBrandsQuery } from 'graphql/__generated__/brand.hooks';

export const useUserItems = () => {
  const { uiStore, permissions } = useAppStore();
  const { userRoles } = permissions;
  const selectedItem = getFromStorage<UserItem>(StorageKeys.selectedItem);
  const [items, setItems] = useState<UserItems>({ games: [], shops: [], brands: [], selectedItem });

  const useUserGameItems = useGamesQuery(undefined, {
    onSuccess: ({ games }) => {
      const gGames = games.nodes.map(({ id, name }) => ({ id, name, color: getRandomColor() }));
      const mappedGames = gGames.map((i) => ({ ...i, itemType: RequestEntityType.Game }));
      const selectedItem = items.selectedItem || mappedGames[0] || { name: '', id: '', color: '#FFF' };
      setItems((prev) => ({
        ...prev,
        games: mappedGames,
        selectedItem,
      }));
      uiStore.setSelectedItem(selectedItem);
      saveToStorage(StorageKeys.selectedItem, selectedItem);
    },
    enabled: hasOneOfRoles(userRoles, [Role.ScutiAdmin, Role.GameOwner, Role.GameAdmin]),
  });

  const useUserShopItems = useShopsQuery(
    { sorting: [{ name: 'updatedAt', dir: 'DESC' }] },
    {
      onSuccess: ({ shops }) => {
        const sShops = shops.nodes.map(({ id, name, shopifyName }) => ({
          id,
          name,
          color: getRandomColor(),
          shopifyName,
        }));
        const mappedShops = sShops.map((i) => ({ ...i, itemType: RequestEntityType.Shop }));
        const selectedItem = items.selectedItem || mappedShops[0] || { name: '', id: '', color: '#FFF' };
        setItems((prev) => ({
          ...prev,
          shops: mappedShops,
          selectedItem: items.selectedItem || mappedShops[0],
        }));
        uiStore.setSelectedItem(selectedItem);
        saveToStorage(StorageKeys.selectedItem, selectedItem);
      },
      enabled: hasOneOfRoles(userRoles, [Role.ScutiAdmin, Role.ShopOwner, Role.ShopAdmin]),
    },
  );

  const useUserBrandsItems = useBrandsQuery(undefined, {
    onSuccess: ({ brands }) => {
      const sBrands = brands.nodes.map(({ id, name }) => ({
        id,
        name,
        color: getRandomColor(),
      }));
      const mappedBrands = sBrands.map((i) => ({ ...i, itemType: RequestEntityType.Brand }));
      const selectedItem = items.selectedItem || mappedBrands[0] || { name: '', id: '', color: '#FFF' };
      setItems((prev) => ({
        ...prev,
        brands: mappedBrands,
        selectedItem: items.selectedItem || mappedBrands[0],
      }));
      uiStore.setSelectedItem(selectedItem);
      saveToStorage(StorageKeys.selectedItem, selectedItem);
    },
    enabled: hasOneOfRoles(userRoles, [Role.ScutiAdmin, Role.ShopOwner, Role.ShopAdmin]),
  });

  const onSelect = useCallback(
    (selectedItem: UserItem) => {
      setItems({ ...items, selectedItem });
      uiStore.setSelectedItem(selectedItem);
      saveToStorage(StorageKeys.selectedItem, selectedItem);
    },
    [items, uiStore],
  );

  return {
    isLoading: useUserGameItems.isLoading || useUserShopItems.isLoading || useUserBrandsItems.isLoading,
    items: {
      ...items,
      selectedItem: items.selectedItem || items.games[0] || items.shops[0] || items.brands[0],
    },
    onSelect,
  };
};
