import { Avatar, Box, Typography } from '@material-ui/core';
import { getFirstTwo } from 'utils/string.utils';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface Props {
  selectedItem: { thumbnail?: string | null; name: string; color: string };
  onClick: () => void;
}

export const SwitcherHeader: React.FC<Props> = ({ selectedItem, onClick }) => {
  return (
    <Box display="flex" alignItems="center" onClick={onClick} justifyContent="flex-end" width="100%">
      <Avatar
        src={selectedItem?.thumbnail || undefined}
        style={{ backgroundColor: selectedItem.color, cursor: 'pointer' }}
        variant="rounded"
      >
        {selectedItem.name && getFirstTwo(selectedItem.name)}
      </Avatar>
      <Typography color="textPrimary">
        <Box
          ml={1}
          mr={1}
          component="span"
          fontWeight="bold"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {selectedItem.name}
        </Box>
      </Typography>
      <ArrowDropDownIcon />
    </Box>
  );
};
