import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Loader } from 'components/Loader';
import { InfoPopup } from 'components/InfoPopup';
import { gamesRoutes } from 'routing';
import { useDeleteGameMutation, useGameItemsQuery } from '../__generated__/games-list-page.hooks';
import { NoData } from 'components/material/NoData';
import { GamesGrid } from './GamesGrid';
import { PaginationLayout } from 'layouts/PaginationLayout';
import { Box, Grid } from '@mui/material';
import { TopNavigation } from 'components/material/TopNavigation';

export const GamesGridPage: React.FC = observer(() => {
  const [deleteId, setDeleteId] = React.useState<string | null>(null);

  const useGameItems = useGameItemsQuery();

  const useDeleteGame = useDeleteGameMutation();
  const onDelete = React.useCallback((id: string) => setDeleteId(id), []);
  const onArchive = React.useCallback(() => {
    if (deleteId) {
      useDeleteGame.mutateAsync({ id: deleteId });
      useGameItems.refetch();
    }
    setDeleteId(null);
  }, [deleteId, useDeleteGame, useGameItems]);
  const onCancel = React.useCallback(() => setDeleteId(null), []);

  const games = useGameItems.data?.games.nodes || [];
  const isLoading = useGameItems.isLoading;
  const pagingResponse = useGameItems.data?.games.paging;
  const showGames = !!games.length && !isLoading;

  return (
    <PaginationLayout
      isLoading={isLoading}
      paging={pagingResponse}
      header={
        <TopNavigation title="Games Management">
          <Grid container justifyContent="flex-end">
            <Grid item md={2}>
              <Link className="btn btn-primary text-nowrap w-100" to={gamesRoutes.GAME_NEW}>
                Add new game
              </Link>
            </Grid>
          </Grid>
        </TopNavigation>
      }
      content={
        showGames && (
          <Box>
            {!!deleteId && (
              <InfoPopup
                title="Delete game?"
                description="This game will be permanently deleted and this action cannot be undone."
                proceedText="Archive"
                cancelText="Cancel"
                onProceed={onArchive}
                onCancel={onCancel}
              />
            )}
            <div className="animated fadeIn">{useGameItems.isLoading && <Loader />}</div>
            {!!games?.length && <GamesGrid games={games} onDelete={onDelete} />}
            {!games?.length && <NoData />}
          </Box>
        )
      }
    />
  );
});
