import { ProductListingFormData } from './ProductListingEditForm.utils';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import { ScutiInput } from 'components/material/ScutiInput';
import { Controller, UseFormReturn } from 'react-hook-form';

interface Props {
  form: UseFormReturn<ProductListingFormData>;
  paused?: boolean;
}

export function RewardsDetails({ form, paused }: Props) {
  return (
    <Card>
      <CardHeader title="Rewards" />

      <CardContent>
        <Controller
          control={form.control}
          name="reward.scutiPercentage"
          render={({ field, fieldState }) => (
            <ScutiInput
              title="Scuti rewards percentage *"
              placeholder="%"
              type="number"
              {...field}
              error={fieldState.error?.message}
              disabled={!paused}
            />
          )}
        />
      </CardContent>
    </Card>
  );
}
