import React, { useEffect, useMemo } from 'react';
import { Form, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@material-ui/core';
import { ScutiInput } from 'components/material/ScutiInput';
import { AppDownloadAdvertisementDetails } from './AppDownloadAdvertisementDetails';
import { Controller, useForm } from 'react-hook-form';
import {
  AppDownloadAdvertisementFormData,
  appDownloadAdvertisementFormDataDefaults,
  appDownloadAdvertisementSchema,
  mapAdvertisementAppDownloadToApiInput,
} from './AppDownloadAdvertisementEditForm.utils';
import { AdvertisementFragment } from 'graphql/__generated__/advertisement.hooks';
import { NewAdvertisementInput, UpdateAdvertisementInput } from 'types/__generated__/types';
import { AppDownloadAdvertisementIds } from './AppDownloadAdvertisementIds';

interface Props {
  linkBack: string;
  brandId: string;
  advertisement?: AdvertisementFragment;
  onSave: (advertisement: UpdateAdvertisementInput | NewAdvertisementInput) => void;
}

export const AppDownloadAdvertisementEditForm: React.FC<Props> = ({ advertisement, brandId, linkBack, onSave }) => {
  const shopFormDefaultValues = useMemo<AppDownloadAdvertisementFormData>(() => {
    const defaults = appDownloadAdvertisementFormDataDefaults();

    if (!advertisement) return defaults;

    return {
      id: advertisement.id,
      name: advertisement.name,
      androidId: advertisement.appDownload?.androidId || defaults.androidId,
      appleId: advertisement.appDownload?.appleId || defaults.appleId,
      pcLink: advertisement.appDownload?.pcLink || defaults.pcLink,
      category: advertisement.category || defaults.category,
      cpa: advertisement.cpa || defaults.cpa,
    };
  }, [advertisement]);

  const appDownloadAdvertisementForm = useForm<AppDownloadAdvertisementFormData>({
    resolver: yupResolver(appDownloadAdvertisementSchema),
    defaultValues: shopFormDefaultValues,
    mode: 'all',
  });

  useEffect(() => appDownloadAdvertisementForm.reset(shopFormDefaultValues), [shopFormDefaultValues]);

  const handleSubmit = async (formData: AppDownloadAdvertisementFormData) => {
    return onSave(mapAdvertisementAppDownloadToApiInput(brandId)(formData));
  };

  const { isDirty, isSubmitting } = appDownloadAdvertisementForm.formState;

  return (
    <Form className="w-100" onSubmit={appDownloadAdvertisementForm.handleSubmit(handleSubmit)}>
      <div className="topbar d-sm-flex justify-content-sm-between">
        <div className="col-heading">
          <Link className="btn btn-back" to={linkBack}></Link>
          <Box ml={5} width={'50%'}>
            <Controller
              control={appDownloadAdvertisementForm.control}
              name="name"
              render={({ field, fieldState }) => (
                <ScutiInput placeholder="Advertisement name" {...field} error={fieldState.error?.message} />
              )}
            />
          </Box>
        </div>
        <div className="col-action">
          <Button color="outline-secondary" onClick={() => appDownloadAdvertisementForm.reset()} disabled={!isDirty}>
            Cancel
          </Button>
          <Button color="primary" type="submit" disabled={isSubmitting}>
            Save changes
          </Button>
        </div>
      </div>
      <Box pb={2}>
        <Grid container spacing={4}>
          <Grid item container spacing={4}>
            <Grid item xs={12} md={6} lg={4}>
              <AppDownloadAdvertisementIds form={appDownloadAdvertisementForm} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <AppDownloadAdvertisementDetails form={appDownloadAdvertisementForm} />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Form>
  );
};
