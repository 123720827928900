// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:400,400i,500,600,700,700i&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:400,400i,500,600,700,700i&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PageContentLayout_page_wrapper__ytsup {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.PageContentLayout_page_content__Aiwqr {
  height: 100%;
}

.PageContentLayout_page_footer__4a\\+5t {
  margin-top: 1.875rem;
  margin-bottom: 1.25rem;
}`, "",{"version":3,"sources":["webpack://./src/layouts/PageContentLayout/PageContentLayout.module.scss"],"names":[],"mappings":"AAGA;EACE,YAAA;EACA,aAAA;EACA,sBAAA;AAAF;;AAOA;EACE,YAAA;AAJF;;AAOA;EACE,oBAAA;EACA,sBAAA;AAJF","sourcesContent":["@import '@scss/vendors/_variables';\n@import '@scss/_variables';\n\n.page_wrapper {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.page_header {\n  // margin-bottom: rem(30);\n}\n\n.page_content {\n  height: 100%;\n}\n\n.page_footer {\n  margin-top: rem(30);\n  margin-bottom: rem(20);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page_wrapper": `PageContentLayout_page_wrapper__ytsup`,
	"page_content": `PageContentLayout_page_content__Aiwqr`,
	"page_footer": `PageContentLayout_page_footer__4a+5t`
};
export default ___CSS_LOADER_EXPORT___;
