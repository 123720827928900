import React from 'react';
import cn from 'classnames';
import styles from './NoData.module.scss';

interface Props {
  className?: string;
  text?: string;
  size?: 'sm' | 'md' | 'lg' | 'xlg';
}

export const NoData: React.FC<Props> = ({ className, size = 'lg', text = 'No data to display' }) => {
  return <div className={cn(styles.no_data, className, { [styles[size]]: !!size })}>{text}</div>;
};
