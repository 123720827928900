import { useMemo } from 'react';
import { Column } from 'react-table';
import { Link } from 'react-router-dom';
import { campaignRoutes } from 'routing';
import capitalize from 'lodash/capitalize';
import { Progress } from 'components/Progress';
import moment from 'moment';
import { CURRENCIES } from 'types/app-types';
import { CampaignListItemFragment } from 'graphql/__generated__/campaign.hooks';

export function useCampaignsTableColumns(onDelete?: (id: string) => void): Column<CampaignListItemFragment>[] {
  return useMemo<Column<CampaignListItemFragment>[]>(
    () => [
      {
        Header: 'Title',
        accessor: 'name',
      },
      {
        Header: 'Type',
        id: 'type',
        accessor: (row) => capitalize(row.type).replace(/_/g, ' '),
      },
      {
        Header: 'Status',
        id: 'status',
        accessor: (row) => capitalize(row.status).replace(/_/g, ' '),
      },
      {
        Header: 'Demographic',
        id: 'demographic',
        disableSortBy: true,
        Cell: ({ row }: any) => {
          const { demographic } = row.original;
          return (
            <>
              {demographic.gender && capitalize(demographic.gender)} {demographic.minAge}-{demographic.maxAge}
            </>
          );
        },
      },
      {
        Header: 'Duration',
        id: 'duration',
        disableSortBy: true,
        Cell: ({ row }: any) => {
          const { duration } = row.original;
          const campaignDuration =
            duration.start && duration.end ? moment(duration.end).diff(duration.start, 'days') : 0;
          const campaignDaysPass = duration.start
            ? Math.max(moment(new Date()).diff(moment(duration.start), 'days'), 0)
            : 0;
          const campaignDaysProgress =
            campaignDaysPass && campaignDuration ? Math.round((campaignDaysPass / campaignDuration) * 100) : 0;
          return (
            <>
              <Progress value={campaignDaysProgress} />
              <strong className="progress-title">{`${campaignDaysPass || 0}/ ${campaignDuration || 0} days`}</strong>
            </>
          );
        },
      },
      {
        Header: 'Budget',
        id: 'budget',
        disableSortBy: true,
        Cell: ({ row }: any) => {
          const { statistics, budget } = row.original;
          return (
            <>
              <Progress value={Math.round((statistics?.totalSpend || 0 / (budget?.maxSpend || 1)) * 100)} />
              <strong className="progress-title">{`${CURRENCIES.USD}${statistics?.totalSpend || 0}/ ${CURRENCIES.USD}${
                budget?.maxSpend || 0
              }`}</strong>
            </>
          );
        },
      },
      {
        Header: 'Sales',
        id: 'sales',
        disableSortBy: true,
        Cell: ({ row }: any) => {
          const { statistics } = row.original;
          return (
            <>
              <strong className="progress-title">{`${CURRENCIES.USD}${statistics?.totalRevenue || 0}`}</strong>
            </>
          );
        },
      },
      {
        Header: '',
        id: 'delete',
        width: '6%',
        disableSortBy: true,
        Cell: ({ row }: any) => {
          const { id, shop } = row.original;
          return (
            <div className="row-actions">
              <Link className="link-edit" title="Edit" to={`${campaignRoutes.CAMPAIGN_EDIT(id)}?shopId=${shop?.id}`}>
                Edit
              </Link>
              {onDelete && (
                <span className="link-delete" title="Delete" onClick={() => onDelete(id)}>
                  Delete
                </span>
              )}
            </div>
          );
        },
      },
    ],
    [onDelete],
  );
}
