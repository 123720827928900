import React from 'react';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import { CustomInput, FormGroup, Input, Label } from 'reactstrap';
import { ContentCategory } from 'types/__generated__/types';
import { CampaignFormData } from './CampaignEditForm.utils';
import { Controller, UseFormReturn } from 'react-hook-form';

interface Props {
  form: UseFormReturn<CampaignFormData>;
  paused?: boolean;
}

export function TargetedGamesDetails({ form, paused }: Props) {
  const values = form.watch();

  const onContentCategoryChange = React.useCallback((v) => {
    form.setValue(`game.contentCategory`, v.target.value);
  }, []);

  return (
    <Card>
      <CardHeader title="Targeted games" />
      <CardContent>
        <FormGroup>
          <label htmlFor={`extras-box`}>
            <Controller
              control={form.control}
              name="game.aiTargetRun"
              render={({ field }) => (
                <CustomInput
                  id="extras-box"
                  type="checkbox"
                  label="AI Targeted Run of Network"
                  checked={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={!paused}
                />
              )}
            />
          </label>
        </FormGroup>
        <FormGroup tag="fieldset">
          <label htmlFor={`range`}>Game content category</label>
          <div className="game-categories">
            {Object.values(ContentCategory).map((k) => {
              const selected = values.game.contentCategory;
              return (
                <FormGroup className="radio" check key={k}>
                  <Label check>
                    <Input
                      type="radio"
                      name={`contentCategory`}
                      value={k}
                      checked={k === selected}
                      onChange={onContentCategoryChange}
                      disabled={!paused}
                    />
                    <em className={k}>
                      <span />
                    </em>
                    <i className="radio-icon"></i>
                  </Label>
                </FormGroup>
              );
            })}
          </div>
        </FormGroup>
      </CardContent>
    </Card>
  );
}
