import React from 'react';
import { Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import { InfoPopup } from 'components/InfoPopup';
import { UseFormReturn, useFieldArray } from 'react-hook-form';
import { InvitesFormData } from './InvitesEditForm.utils';
import { InviteEditRow } from './InviteEditRow';
import { Role } from 'types/__generated__/types';
import { Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

interface Props {
  form: UseFormReturn<InvitesFormData>;
  onResend: (email: string) => void;
}

export const InvitesEditForm: React.FC<Props> = ({ form, onResend }) => {
  const [deleteEmail, setDeleteEmail] = React.useState<string | null>(null);

  const { fields, remove, insert } = useFieldArray({
    name: 'invites',
    control: form.control,
  });

  return (
    <Card>
      <CardHeader
        title={
          <Box display="flex" justifyContent="space-between" alignItems="center">
            Invite Users
            <Button
              disabled={!form.formState.isValid}
              onClick={() => {
                insert(fields.length, {
                  email: '',
                  role: Role.OrganizationAdmin,
                });
              }}
            >
              <AddIcon />
            </Button>
          </Box>
        }
      />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item container spacing={2}>
            <Grid item container xs={9} spacing={2}>
              <Grid item xs={8}>
                <label>Email</label>
              </Grid>
              <Grid item xs={4}>
                <label>Permission</label>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <label>Status</label>
            </Grid>
            <Grid item xs={1} />
          </Grid>
          <Grid container item spacing={2}>
            {fields.map((_, index) => {
              return (
                <InviteEditRow
                  key={index}
                  rowIndex={index}
                  form={form}
                  onRemove={() => remove(index)}
                  onResend={onResend}
                />
              );
            })}
          </Grid>
        </Grid>
        {deleteEmail && (
          <InfoPopup
            title="Remove user from organization?"
            description=""
            proceedText="Remove"
            cancelText="Cancel"
            onProceed={() => setDeleteEmail(null)}
            onCancel={() => setDeleteEmail(null)}
          />
        )}
      </CardContent>
    </Card>
  );
};
