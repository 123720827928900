import React from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory, useLocation, useParams } from 'react-router';
import { ProductEditForm } from './ProductEditForm';
import { productsRoutes } from 'routing';
import { useToasts } from 'react-toast-notifications';
import {
  useCreateProductMutation,
  useFulfillmentMethodsQuery,
  useProductQuery,
  useUpdateProductMutation,
} from './__generated__/product-edit-page.hooks';
import { hasId } from 'types/util-types';
import { mapProductFormToInput, ProductForm } from './ProductEditForm/ProductEditForm.utils';
import * as queryString from 'query-string';
import { Loader } from 'components/Loader';
import _ from 'lodash';

export const ProductEditPage: React.FC = observer(() => {
  const { productId } = useParams<{ productId: string }>();
  const { search } = useLocation();
  const searchQuery = queryString.parse(search);
  const history = useHistory();
  const { addToast } = useToasts();
  const title = `${productId ? 'Edit' : 'Create new'} product`;

  const productQuery = useProductQuery({ id: productId }, { enabled: !!productId });
  const product = productQuery.data?.product;
  const shopId = (searchQuery.shopId as string) || product?.shop.id;
  const fulfillmentMethodsQuery = useFulfillmentMethodsQuery({ id: shopId as string }, { enabled: !!shopId });

  const useUpdateProduct = useUpdateProductMutation();
  const useCreateProduct = useCreateProductMutation();
  const fulfillmentMethods = fulfillmentMethodsQuery.data?.shopFulfillmentMethods || [];

  const isLoading = fulfillmentMethodsQuery.isLoading || productQuery.isLoading;

  const saveOrUpdate = React.useCallback(
    async (product: ProductForm) => {
      if (hasId(product)) {
        try {
          const { updateProduct } = await useUpdateProduct.mutateAsync({
            input: { ...(await mapProductFormToInput(product)), id: product.id },
          });
          addToast('Product has been updated!', { appearance: 'success', autoDismiss: true });
          history.push(productsRoutes.PRODUCT_OVERVIEW(updateProduct.id));
        } catch (e) {
          // @ts-expect-error
          addToast(e.message, { appearance: 'error', autoDismiss: false });
        }
      } else {
        try {
          const { createProduct } = await useCreateProduct.mutateAsync({
            input: {
              ...(await mapProductFormToInput(product)),
              tags: _.uniq(product.tags),
              shopId: shopId as string,
            },
          });
          addToast('Product has created!', { appearance: 'success', autoDismiss: true });
          history.push(productsRoutes.PRODUCT_OVERVIEW(createProduct.id));
        } catch (e) {
          // @ts-expect-error
          addToast(e.message, { appearance: 'error', autoDismiss: false });
        }
      }
    },
    [addToast, history, shopId, useCreateProduct, useUpdateProduct],
  );

  return (
    <div>
      {isLoading && <Loader />}
      {fulfillmentMethods.length ? (
        <ProductEditForm
          title={title}
          linkBack={productsRoutes.PRODUCTS}
          product={product}
          fulfillmentMethods={fulfillmentMethods}
          onSave={saveOrUpdate}
        />
      ) : null}
    </div>
  );
});
