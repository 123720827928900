export const scutiColors = {
  borderMain: '#404249',
  borderFocused: 'rgba(255, 255, 255, 0.5)',
  inputBackgroundDisabled: 'rgba(0,0,0, 0.1)',
  textMain: '#FFFFFF',
  textError: '#f86c6b',
  menuBackground: '#1f2129',
  menuItemBackgroundHover: '#2a2c34',
  cardBackground: 'rgb(42, 44, 52)',
  cardHeaderBackground: 'rgb(31, 33, 41)',

  // Figma color palette
  blue400: '#00C0E9',
  blue500: '#1D35EE',
  blue600: '#1024A3',
  white: '#FFFFFF',
  grey200: '#AEAEAE',
  grey400: '#6A6B70',
  grey500: '#1F2129',
  grey600: '#1B1B22',
  grey800: '#101015',
  systemGrey: '#2A2C34',
  systemRed: '#FF1463',
  systemOrange: '#FF7500',
  systemYellow: '#EDEA00',
  systemGreen: '#7ED321',
};

export const scutiSizes = {
  inputFontSize: 14,
  inputPadding: 8,
  borderRadius: 4,
};
