import { AnalyticsRange } from 'types/app-types';
import React from 'react';
import { ButtonToolbar, ButtonGroup, Button } from 'reactstrap';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import moment from 'moment';
import { Box } from '@mui/material';

interface Props {
  range: AnalyticsRange;
  onSelect: (range: AnalyticsRange) => void;
}

export const AnalyticsPeriodSelector: React.FC<Props> = ({ range, onSelect }) => {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [focusedInput, setFocusedInput] = React.useState<FocusedInputShape | null>(null);

  const selectPeriod = React.useCallback(
    (tabIndex: number, newRange: AnalyticsRange) => () => {
      onSelect(newRange);
      setSelectedTab(tabIndex);
    },
    [onSelect],
  );

  const onDatesChange = React.useCallback(
    (v: { startDate: moment.Moment | null; endDate: moment.Moment | null }) => {
      if (v.startDate && v.endDate) {
        setSelectedTab(0);
        onSelect({ from: v.startDate, to: v.endDate });
      }
    },
    [onSelect],
  );
  return (
    <Box display="flex">
      <ButtonToolbar>
        <ButtonGroup className="mr-3">
          <Button
            color="secondary"
            onClick={selectPeriod(1, { from: moment(), to: moment() })}
            active={selectedTab === 1}
          >
            Day
          </Button>
          <Button
            color="secondary"
            onClick={selectPeriod(2, {
              from: moment().startOf('month'),
              to: moment().endOf('month'),
            })}
            active={selectedTab === 2}
          >
            Month
          </Button>
          <Button
            color="secondary"
            onClick={selectPeriod(3, {
              from: moment().startOf('year'),
              to: moment().endOf('year'),
            })}
            active={selectedTab === 3}
          >
            Year
          </Button>
        </ButtonGroup>
      </ButtonToolbar>
      <DateRangePicker
        startDateId="startDate"
        endDateId="endDate"
        startDate={range.from}
        endDate={range.to}
        onDatesChange={onDatesChange}
        focusedInput={focusedInput}
        onFocusChange={setFocusedInput}
        isOutsideRange={() => false}
        minimumNights={0}
        small
      />
    </Box>
  );
};
