import React from 'react';
import { FormGroup, CustomInput } from 'reactstrap';
import { COUNTRIES_SELECT_OPTIONS, GENDER_SELECT_OPTIONS, USA_STATES_SELECT_OPTIONS } from 'types/select-types';
import InputRange, { Range } from 'react-input-range';
import { ProductListingFormData } from './ProductListingEditForm.utils';
import { Box, Card, CardContent, CardHeader } from '@material-ui/core';
import { ScutiSelect } from 'components/material/ScutiSelect';
import { ScutiMultipleSelect } from 'components/material/ScutiMultipleSelect';
import { Controller, UseFormReturn } from 'react-hook-form';

interface Props {
  form: UseFormReturn<ProductListingFormData>;
  paused?: boolean;
}

export function AudienceDetails({ form, paused }: Props) {
  const values = form.watch();

  const [genderSpecific, setGenderSpecific] = React.useState(!!values.demographic.gender);
  const [countrySpecific, setCountrySpecific] = React.useState(!!values.location?.country);
  const [stateSpecific, setStateSpecific] = React.useState(!!values.location?.states);
  const [ageRangeSpecific, setAgeRangeSpecific] = React.useState(
    values.demographic.minAge !== 0 || values.demographic.maxAge !== 100,
  );

  const toggleGender = () => {
    if (!genderSpecific) form.setValue('demographic.gender', GENDER_SELECT_OPTIONS[1].value);
    else form.setValue('demographic.gender', null);
    setGenderSpecific(!genderSpecific);
  };

  const toggleCountry = () => {
    if (countrySpecific) form.setValue('location', {});
    setCountrySpecific(!countrySpecific);
  };

  const toggleState = () => {
    if (stateSpecific) form.setValue('location.states', null);
    setStateSpecific(!stateSpecific);
  };

  const toggleAgeRange = () => {
    form.setValue(`demographic`, { ...values.demographic, minAge: 0, maxAge: 100 });
    setAgeRangeSpecific(!ageRangeSpecific);
  };

  const onRangeChange = (v: Range | number) => {
    if (typeof v === 'number') return;
    form.setValue(`demographic`, { ...values.demographic, minAge: Math.max(0, v.min), maxAge: Math.min(v.max, 100) });
  };

  const isUS = values.location?.country === 'US';

  return (
    <Card>
      <CardHeader title="Audience" />
      <CardContent>
        <FormGroup>
          <label htmlFor={`demographic.gender`}>
            <CustomInput
              id="gender-box"
              type="checkbox"
              label="Gender specific"
              onChange={toggleGender}
              checked={genderSpecific}
              disabled={!paused}
            />
          </label>
          <Controller
            control={form.control}
            name="duration.inventory"
            render={({ field, fieldState }) => (
              <ScutiSelect
                placeholder="Select gender"
                value={field.value || ''}
                disabled={!genderSpecific || !paused}
                options={GENDER_SELECT_OPTIONS.slice(1)}
                onChange={field.onChange}
                onBlur={field.onBlur}
                error={fieldState.error?.message}
              />
            )}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor={`demographic.gender`}>
            <CustomInput
              id="age-rage-box"
              type="checkbox"
              label="Age Range"
              onChange={toggleAgeRange}
              checked={ageRangeSpecific}
              disabled={!paused}
            />
          </label>
          <Box pl={2} pr={2}>
            <InputRange
              maxValue={100}
              minValue={0}
              value={{ min: values.demographic.minAge, max: values.demographic.maxAge }}
              onChange={onRangeChange}
              disabled={!ageRangeSpecific || !paused}
            />
          </Box>
        </FormGroup>
        <Box mt={2}>
          <FormGroup>
            <label htmlFor={`location.country`}>
              <CustomInput
                id="country-box"
                type="checkbox"
                label="Country specific"
                onChange={toggleCountry}
                checked={countrySpecific}
                disabled={!paused}
              />
            </label>
            <Controller
              control={form.control}
              name="location.country"
              render={({ field, fieldState }) => (
                <ScutiSelect
                  placeholder="Country"
                  value={values?.location?.country || ''}
                  options={COUNTRIES_SELECT_OPTIONS}
                  onChange={(e) => {
                    field.onChange(e);
                    form.setValue('location.states', null);
                  }}
                  onBlur={field.onBlur}
                  error={fieldState.error?.message}
                  disabled={!countrySpecific || !paused}
                />
              )}
            />
          </FormGroup>
        </Box>
        <Box mt={2}>
          <FormGroup>
            <label htmlFor={`demographic.gender`}>
              <CustomInput
                id="state-box"
                type="checkbox"
                label="State specific"
                onChange={toggleState}
                checked={stateSpecific}
                disabled={!isUS || !paused}
              />
            </label>
            <Controller
              control={form.control}
              name="location.states"
              render={({ field, fieldState }) => (
                <ScutiMultipleSelect
                  placeholder="States"
                  value={(field.value || [])[0] || ''}
                  values={field.value || []}
                  options={USA_STATES_SELECT_OPTIONS}
                  onChange={({ target }) => {
                    field.onChange([...(values?.location?.states || []), target.value]);
                  }}
                  onBlur={field.onBlur}
                  disabled={!isUS || !stateSpecific || !paused}
                  onRemove={(value) => {
                    field.onChange([...(values?.location?.states || [])].filter((s) => s !== value));
                  }}
                  error={fieldState.error?.message}
                />
              )}
            />
          </FormGroup>
        </Box>
      </CardContent>
    </Card>
  );
}
