import React from 'react';
import { OrganizationAddressDetails } from './OrganizationAddressDetails';
import { OrganizationBusinessDetails } from './OrganizationBusinessDetails';
import { OrganizationOwnerDetails } from './OrganizationOwnerDetails';
import { Grid } from '@material-ui/core';
import { OrganizationFormData } from './OrganizationEditForm.utils';
import { UseFormReturn } from 'react-hook-form';

interface OrganizationEditFormProps {
  form: UseFormReturn<OrganizationFormData>;
}

export const OrganizationEditForm: React.FC<OrganizationEditFormProps> = ({ form }) => {
  return (
    <Grid container spacing={2}>
      <Grid item sm={12} md={4} lg={4}>
        <OrganizationOwnerDetails form={form} />
      </Grid>
      <Grid item sm={12} md={4} lg={4}>
        <OrganizationBusinessDetails form={form} />
      </Grid>
      <Grid item sm={12} md={4} lg={4}>
        <OrganizationAddressDetails form={form} />
      </Grid>
    </Grid>
  );
};
