import React from 'react';
import { Button, Card, CardBody } from 'reactstrap';

interface Props {
  title: string;
  description: string;
  proceedText: string;
  cancelText: string;
  onProceed?: () => void;
  onCancel?: () => void;
}

export const InfoPopup: React.FC<Props> = ({ title, description, proceedText, cancelText, onProceed, onCancel }) => {
  return (
    <div className="animated fadeIn popup-holder d-flex justify-content-center align-items-center">
      <Card className="d-flex flex-row align-items-center mh-315 bg-custom w-23">
        <CardBody>
          <div className="text-center">
            <div className="card-headline text-center">
              <h1>{title}</h1>
              <p>{description}</p>
            </div>
            <div className="actions">
              <Button onClick={onProceed} type="button" color="primary" className="btn-block">
                {proceedText}
              </Button>
              <Button onClick={onCancel} type="submit" color="outline-secondary" className="btn-block">
                {cancelText}
              </Button>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};
