import { ProductOverviewQuery } from 'pages/Products/ProductOverviewPage/__generated__/product-overview-page.hooks';
import React from 'react';
import { Table } from 'reactstrap';
import { ProductDisplayRow } from './ProductDisplayRow';

interface Props {
  product: ProductOverviewQuery['product'];
}

export const ProductVariantsViewTable: React.FC<Props> = ({ product }) => {
  return (
    <div className="products-options">
      <div className="headline">
        <strong>Variant &amp; Options</strong>
        <div className="headline-actions"></div>
      </div>
      <Table className="products-table" responsive striped>
        <thead>
          <tr>
            <th>SKU</th>
            <th>Image</th>
            <th>Option 1</th>
            <th>Option 2</th>
            <th>Option 3</th>
            <th>Price</th>
            <th>Compare at</th>
            <th>Barcode</th>
            <th className="col-weight">Weight</th>
            <th>In Stock</th>
            <th>Fulfillment</th>
          </tr>
        </thead>
        <tbody>
          {product.variants.map((variant) => (
            <ProductDisplayRow addClass="v-ico" key={variant.sku} variant={variant} />
          ))}
        </tbody>
      </Table>
    </div>
  );
};
