import * as Types from '../../types/__generated__/types';

import { ApolloError } from 'apollo-client';
import { useQuery, UseQueryOptions, useMutation, UseMutationOptions } from 'react-query';
import { fetcher } from 'api';
export type BrandListItemFragment = {
  id: string;
  name: string;
  mediaInvestmentEnabled: boolean;
  credentials: { login: string; pin: string };
  directResponse?: Types.Maybe<{ unit: string; amount: number }>;
  engagement?: Types.Maybe<{ unit: string; amount: number }>;
  video?: Types.Maybe<{ unit: string; amount: number }>;
};

export type BrandFragment = {
  id: string;
  name: string;
  description?: Types.Maybe<string>;
  mediaInvestmentEnabled: boolean;
  credentials: { login: string; pin: string };
  directResponse?: Types.Maybe<{ amount: number; unit: string }>;
  engagement?: Types.Maybe<{ amount: number; unit: string }>;
  video?: Types.Maybe<{ amount: number; unit: string }>;
};

export type BrandsQueryVariables = Types.Exact<{
  paging?: Types.Maybe<Types.PagingInput>;
  sorting?: Types.Maybe<Array<Types.SortFieldInput> | Types.SortFieldInput>;
  filters?: Types.Maybe<Array<Types.FilterInput> | Types.FilterInput>;
}>;

export type BrandsQuery = {
  brands: {
    sorting?: Types.Maybe<Array<{ name: string; dir: string }>>;
    paging: { offset: number; limit: number; totalCount: number };
    nodes: Array<BrandListItemFragment>;
  };
};

export type BrandQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type BrandQuery = { brand?: Types.Maybe<BrandFragment> };

export type CreateBrandMutationVariables = Types.Exact<{
  input: Types.CreateBrandInput;
}>;

export type CreateBrandMutation = { createBrand: { id: string } };

export type UpdateBrandMutationVariables = Types.Exact<{
  input: Types.UpdateBrandInput;
}>;

export type UpdateBrandMutation = { updateBrand: { id: string } };

export type DeleteBrandMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type DeleteBrandMutation = { deleteBrand?: Types.Maybe<string> };

export const BrandListItemFragmentDoc = `
    fragment BrandListItem on Brand {
  id
  name
  credentials {
    login
    pin
  }
  mediaInvestmentEnabled
  directResponse {
    unit
    amount
  }
  engagement {
    unit
    amount
  }
  video {
    unit
    amount
  }
}
    `;
export const BrandFragmentDoc = `
    fragment Brand on Brand {
  id
  name
  description
  mediaInvestmentEnabled
  credentials {
    login
    pin
  }
  directResponse {
    amount
    unit
  }
  engagement {
    amount
    unit
  }
  video {
    amount
    unit
  }
}
    `;
export const BrandsDocument = `
    query Brands($paging: PagingInput, $sorting: [SortFieldInput!], $filters: [FilterInput!]) {
  brands(paging: $paging, sorting: $sorting, filters: $filters) {
    sorting {
      name
      dir
    }
    paging {
      offset
      limit
      totalCount
    }
    nodes {
      ...BrandListItem
    }
  }
}
    ${BrandListItemFragmentDoc}`;
export const useBrandsQuery = <TData = BrandsQuery, TError = ApolloError>(
  variables?: BrandsQueryVariables,
  options?: UseQueryOptions<BrandsQuery, TError, TData>,
) =>
  useQuery<BrandsQuery, TError, TData>(
    ['Brands', variables],
    fetcher<BrandsQuery, BrandsQueryVariables>(BrandsDocument, variables),
    options,
  );
export const BrandDocument = `
    query Brand($id: String!) {
  brand(id: $id) {
    ...Brand
  }
}
    ${BrandFragmentDoc}`;
export const useBrandQuery = <TData = BrandQuery, TError = ApolloError>(
  variables: BrandQueryVariables,
  options?: UseQueryOptions<BrandQuery, TError, TData>,
) =>
  useQuery<BrandQuery, TError, TData>(
    ['Brand', variables],
    fetcher<BrandQuery, BrandQueryVariables>(BrandDocument, variables),
    options,
  );
export const CreateBrandDocument = `
    mutation CreateBrand($input: CreateBrandInput!) {
  createBrand(input: $input) {
    id
  }
}
    `;
export const useCreateBrandMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<CreateBrandMutation, TError, CreateBrandMutationVariables, TContext>,
) =>
  useMutation<CreateBrandMutation, TError, CreateBrandMutationVariables, TContext>(
    (variables?: CreateBrandMutationVariables) =>
      fetcher<CreateBrandMutation, CreateBrandMutationVariables>(CreateBrandDocument, variables)(),
    options,
  );
export const UpdateBrandDocument = `
    mutation UpdateBrand($input: UpdateBrandInput!) {
  updateBrand(input: $input) {
    id
  }
}
    `;
export const useUpdateBrandMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<UpdateBrandMutation, TError, UpdateBrandMutationVariables, TContext>,
) =>
  useMutation<UpdateBrandMutation, TError, UpdateBrandMutationVariables, TContext>(
    (variables?: UpdateBrandMutationVariables) =>
      fetcher<UpdateBrandMutation, UpdateBrandMutationVariables>(UpdateBrandDocument, variables)(),
    options,
  );
export const DeleteBrandDocument = `
    mutation DeleteBrand($id: String!) {
  deleteBrand(id: $id)
}
    `;
export const useDeleteBrandMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<DeleteBrandMutation, TError, DeleteBrandMutationVariables, TContext>,
) =>
  useMutation<DeleteBrandMutation, TError, DeleteBrandMutationVariables, TContext>(
    (variables?: DeleteBrandMutationVariables) =>
      fetcher<DeleteBrandMutation, DeleteBrandMutationVariables>(DeleteBrandDocument, variables)(),
    options,
  );
