import { stdnum } from 'stdnum';

export const taxIdValidator = (value: string, country: string): boolean => {
  if (country === 'CA') {
    const { bn, sin } = stdnum.CA as any;
    return bn.validate(value).isValid || sin.validate(value).isValid;
  }
  if (country === 'US') {
    const { ein, ssn } = stdnum.US as any;
    return ein.validate(value).isValid || ssn.validate(value).isValid;
  }
  return /^\d+$/.test(value);
};
