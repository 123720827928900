import React, { forwardRef } from 'react';
import { Skeleton } from '@mui/material';
import { FormControl, FormHelperText, MenuItem, Select, SelectProps } from '@material-ui/core';

interface Props extends Omit<SelectProps, 'error'> {
  title?: string;
  error?: React.ReactNode;
  options?: { value: string[] | string | number | null; label: React.ReactNode }[];
  isLoading?: boolean;
}

export const ScutiSelect = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { title, error, options = [], isLoading, value, ...other } = props;

  return (
    <FormControl error={!!error} fullWidth>
      {title && <label>{title}</label>}
      {isLoading ? (
        <Skeleton height={58} />
      ) : (
        <Select variant="outlined" error={!!error} ref={ref} {...other} value={value || ''}>
          {options.map((option, key) => (
            <MenuItem key={`${key}_${option.value}`} value={option.value || undefined}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      )}
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
});
