import * as Types from '../../types/__generated__/types';

import { ApolloError } from 'apollo-client';
import { useQuery, UseQueryOptions, useMutation, UseMutationOptions } from 'react-query';
import { fetcher } from 'api';
export type OrganizationListItemFragment = {
  id: string;
  name: string;
  website: string;
  owner: { fullName?: Types.Maybe<string> };
};

export type OrganizationFragment = {
  id: string;
  address1: string;
  address2?: Types.Maybe<string>;
  city: string;
  zipCode: string;
  country: string;
  state?: Types.Maybe<string>;
  name: string;
  website: string;
  types: Array<Types.OrganizationType>;
  phone: string;
  identificationNumber: string;
  dbaName: string;
  owner: { id: string; fullName?: Types.Maybe<string>; email: string };
};

export type OrganizationInviteFragment = {
  id: string;
  createdAt: any;
  updatedAt: any;
  email: string;
  roles: Array<Types.Role>;
  entityId?: Types.Maybe<string>;
  entityType?: Types.Maybe<string>;
  accepted: boolean;
  hide: boolean;
};

export type OrganizationsQueryVariables = Types.Exact<{
  paging?: Types.Maybe<Types.PagingInput>;
  sorting?: Types.Maybe<Array<Types.SortFieldInput> | Types.SortFieldInput>;
  filters?: Types.Maybe<Array<Types.FilterInput> | Types.FilterInput>;
}>;

export type OrganizationsQuery = {
  organizations: {
    sorting?: Types.Maybe<Array<{ name: string; dir: string }>>;
    paging: { offset: number; limit: number; totalCount: number };
    nodes: Array<OrganizationListItemFragment>;
  };
};

export type OrganizationQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type OrganizationQuery = { organization: OrganizationFragment };

export type UpdateOrganizationMutationVariables = Types.Exact<{
  input: Types.UpdateOrganizationInput;
}>;

export type UpdateOrganizationMutation = { updateOrganization: { id: string } };

export type CreateOrganizationMutationVariables = Types.Exact<{
  input: Types.OrganizationInput;
}>;

export type CreateOrganizationMutation = { createOrganization: { id: string } };

export type OrganizationInvitesQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
  paging?: Types.Maybe<Types.PagingInput>;
  sorting?: Types.Maybe<Array<Types.SortFieldInput> | Types.SortFieldInput>;
  filters?: Types.Maybe<Array<Types.FilterInput> | Types.FilterInput>;
}>;

export type OrganizationInvitesQuery = {
  organizationInvites: {
    paging: { offset: number; limit: number; totalCount: number };
    nodes: Array<OrganizationInviteFragment>;
  };
};

export type InviteToOrganizationMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
  user: Types.UserRoleInput;
}>;

export type InviteToOrganizationMutation = { inviteToOrganization: Array<Types.Role> };

export type RemoveFromOrganizationMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
  organizationId: Types.Scalars['String'];
}>;

export type RemoveFromOrganizationMutation = { removeFromOrganization: boolean };

export type ResendInviteMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
}>;

export type ResendInviteMutation = { resendInviteMail: boolean };

export const OrganizationListItemFragmentDoc = `
    fragment OrganizationListItem on Organization {
  id
  name
  website
  owner {
    fullName
  }
}
    `;
export const OrganizationFragmentDoc = `
    fragment Organization on Organization {
  id
  address1
  address2
  city
  zipCode
  country
  state
  name
  website
  types
  phone
  identificationNumber
  dbaName
  owner {
    id
    fullName
    email
  }
}
    `;
export const OrganizationInviteFragmentDoc = `
    fragment OrganizationInvite on Invite {
  id
  createdAt
  updatedAt
  email
  roles
  entityId
  entityType
  accepted
  hide
}
    `;
export const OrganizationsDocument = `
    query Organizations($paging: PagingInput, $sorting: [SortFieldInput!], $filters: [FilterInput!]) {
  organizations(paging: $paging, sorting: $sorting, filters: $filters) {
    sorting {
      name
      dir
    }
    paging {
      offset
      limit
      totalCount
    }
    nodes {
      ...OrganizationListItem
    }
  }
}
    ${OrganizationListItemFragmentDoc}`;
export const useOrganizationsQuery = <TData = OrganizationsQuery, TError = ApolloError>(
  variables?: OrganizationsQueryVariables,
  options?: UseQueryOptions<OrganizationsQuery, TError, TData>,
) =>
  useQuery<OrganizationsQuery, TError, TData>(
    ['Organizations', variables],
    fetcher<OrganizationsQuery, OrganizationsQueryVariables>(OrganizationsDocument, variables),
    options,
  );
export const OrganizationDocument = `
    query Organization($id: String!) {
  organization(id: $id) {
    ...Organization
  }
}
    ${OrganizationFragmentDoc}`;
export const useOrganizationQuery = <TData = OrganizationQuery, TError = ApolloError>(
  variables: OrganizationQueryVariables,
  options?: UseQueryOptions<OrganizationQuery, TError, TData>,
) =>
  useQuery<OrganizationQuery, TError, TData>(
    ['Organization', variables],
    fetcher<OrganizationQuery, OrganizationQueryVariables>(OrganizationDocument, variables),
    options,
  );
export const UpdateOrganizationDocument = `
    mutation UpdateOrganization($input: UpdateOrganizationInput!) {
  updateOrganization(input: $input) {
    id
  }
}
    `;
export const useUpdateOrganizationMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<UpdateOrganizationMutation, TError, UpdateOrganizationMutationVariables, TContext>,
) =>
  useMutation<UpdateOrganizationMutation, TError, UpdateOrganizationMutationVariables, TContext>(
    (variables?: UpdateOrganizationMutationVariables) =>
      fetcher<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>(UpdateOrganizationDocument, variables)(),
    options,
  );
export const CreateOrganizationDocument = `
    mutation CreateOrganization($input: OrganizationInput!) {
  createOrganization(input: $input) {
    id
  }
}
    `;
export const useCreateOrganizationMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<CreateOrganizationMutation, TError, CreateOrganizationMutationVariables, TContext>,
) =>
  useMutation<CreateOrganizationMutation, TError, CreateOrganizationMutationVariables, TContext>(
    (variables?: CreateOrganizationMutationVariables) =>
      fetcher<CreateOrganizationMutation, CreateOrganizationMutationVariables>(CreateOrganizationDocument, variables)(),
    options,
  );
export const OrganizationInvitesDocument = `
    query OrganizationInvites($id: String!, $paging: PagingInput, $sorting: [SortFieldInput!], $filters: [FilterInput!]) {
  organizationInvites(id: $id, paging: $paging, sorting: $sorting, filters: $filters) {
    paging {
      offset
      limit
      totalCount
    }
    nodes {
      ...OrganizationInvite
    }
  }
}
    ${OrganizationInviteFragmentDoc}`;
export const useOrganizationInvitesQuery = <TData = OrganizationInvitesQuery, TError = ApolloError>(
  variables: OrganizationInvitesQueryVariables,
  options?: UseQueryOptions<OrganizationInvitesQuery, TError, TData>,
) =>
  useQuery<OrganizationInvitesQuery, TError, TData>(
    ['OrganizationInvites', variables],
    fetcher<OrganizationInvitesQuery, OrganizationInvitesQueryVariables>(OrganizationInvitesDocument, variables),
    options,
  );
export const InviteToOrganizationDocument = `
    mutation InviteToOrganization($id: String!, $user: UserRoleInput!) {
  inviteToOrganization(id: $id, user: $user)
}
    `;
export const useInviteToOrganizationMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<InviteToOrganizationMutation, TError, InviteToOrganizationMutationVariables, TContext>,
) =>
  useMutation<InviteToOrganizationMutation, TError, InviteToOrganizationMutationVariables, TContext>(
    (variables?: InviteToOrganizationMutationVariables) =>
      fetcher<InviteToOrganizationMutation, InviteToOrganizationMutationVariables>(
        InviteToOrganizationDocument,
        variables,
      )(),
    options,
  );
export const RemoveFromOrganizationDocument = `
    mutation RemoveFromOrganization($email: String!, $organizationId: String!) {
  removeFromOrganization(email: $email, organizationId: $organizationId)
}
    `;
export const useRemoveFromOrganizationMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<
    RemoveFromOrganizationMutation,
    TError,
    RemoveFromOrganizationMutationVariables,
    TContext
  >,
) =>
  useMutation<RemoveFromOrganizationMutation, TError, RemoveFromOrganizationMutationVariables, TContext>(
    (variables?: RemoveFromOrganizationMutationVariables) =>
      fetcher<RemoveFromOrganizationMutation, RemoveFromOrganizationMutationVariables>(
        RemoveFromOrganizationDocument,
        variables,
      )(),
    options,
  );
export const ResendInviteDocument = `
    mutation ResendInvite($email: String!) {
  resendInviteMail(email: $email)
}
    `;
export const useResendInviteMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<ResendInviteMutation, TError, ResendInviteMutationVariables, TContext>,
) =>
  useMutation<ResendInviteMutation, TError, ResendInviteMutationVariables, TContext>(
    (variables?: ResendInviteMutationVariables) =>
      fetcher<ResendInviteMutation, ResendInviteMutationVariables>(ResendInviteDocument, variables)(),
    options,
  );
