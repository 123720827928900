// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ImgPreview_img_hovered__mCGRf {
  opacity: 0.3;
}

.ImgPreview_img_disabled__XIjhk {
  opacity: 0.4;
  cursor: default;
}

.ImgPreview_delete_icon__HE1MA {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  border: 1px solid #f86c6b;
  color: #f86c6b;
  border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/components/material/ScutiImgInput/ImgPreview.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,YAAA;EACA,eAAA;AACF;;AAEA;EACE,kBAAA;EACA,SAAA;EACA,QAAA;EACA,gCAAA;EACA,eAAA;EACA,yBAAA;EACA,cAAA;EACA,kBAAA;AACF","sourcesContent":[".img_hovered {\n  opacity: 0.3;\n}\n\n.img_disabled {\n  opacity: 0.4;\n  cursor: default;\n}\n\n.delete_icon {\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  transform: translate(-50%, -50%);\n  cursor: pointer;\n  border: 1px solid #f86c6b;\n  color: #f86c6b;\n  border-radius: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"img_hovered": `ImgPreview_img_hovered__mCGRf`,
	"img_disabled": `ImgPreview_img_disabled__XIjhk`,
	"delete_icon": `ImgPreview_delete_icon__HE1MA`
};
export default ___CSS_LOADER_EXPORT___;
