import React from 'react';
import { Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ordersRoutes } from 'routing';
import { NoData } from 'components/common-components/NoData';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import { OrderListItemFragment } from '../__generated__/orders.hooks';

interface Props {
  orders: OrderListItemFragment[];
}

export const PreviousOrdersTable: React.FC<Props> = ({ orders }) => {
  return (
    <Card>
      <CardHeader title="Previous orders" />
      <CardContent>
        <Table responsive striped hover>
          <thead>
            {/* <th>Item</th> */}
            <tr>
              <th>Date</th>
              <th>ID</th>
              <th>Total</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order) => {
              const { id, amount, status, createdAt } = order;
              return (
                <tr key={id}>
                  <td>{createdAt}</td>
                  <td>
                    <Link to={ordersRoutes.ORDER_OVERVIEW(id)}>{id}</Link>
                  </td>
                  <td>${amount}</td>
                  <td>{status}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        {!orders.length && <NoData text="No previous orders yet" />}
      </CardContent>
    </Card>
  );
};
