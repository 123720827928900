import React from 'react';
import { Box, Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import { ScutiInput } from 'components/material/ScutiInput';
import { ScutiImgInput } from 'components/material/ScutiImgInput';
import ImageIcon from '@mui/icons-material/Image';
import AddIcon from '@mui/icons-material/Add';
import { ShopFormData } from './ShopEditForm.utils';
import { Controller, UseFormReturn, useFieldArray } from 'react-hook-form';
import { Button, FormHelperText, Icon, Stack } from '@mui/material';
import { v4 } from 'uuid';

interface Props {
  form: UseFormReturn<ShopFormData>;
}

export const ShopEditBrands: React.FC<Props> = ({ form }) => {
  const { fields, remove, insert } = useFieldArray({
    name: 'brands',
    control: form.control,
  });

  return (
    <Card>
      <CardHeader
        title={
          <Box display="flex" justifyContent="space-between">
            Brands
            <Box
              onClick={() =>
                insert(fields.length, {
                  id: v4(),
                  name: '',
                  imageUrl: null,
                })
              }
            >
              <AddIcon />
            </Box>
          </Box>
        }
      />
      <CardContent>
        <Stack spacing={2}>
          {fields.map((field, index) => {
            return (
              <Box display="flex" justifyContent="space-between" alignItems="center" key={field.id}>
                <Controller
                  control={form.control}
                  name={`brands.${index}.name`}
                  render={({ field, fieldState }) => (
                    <ScutiInput
                      title="Brand Name"
                      placeholder="Brand Name"
                      style={{ width: '90%' }}
                      error={fieldState.error?.message}
                      {...field}
                    />
                  )}
                />
                <Box color="text.secondary" width="50%" display="flex" flexDirection="column" alignItems="center">
                  <label>Brand Image</label>
                  <Box>
                    <Controller
                      control={form.control}
                      name={`brands.${index}.imageUrl`}
                      render={({ field, fieldState }) => {
                        const error = fieldState.error?.message;
                        return (
                          <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                            <Box width="4rem" height="4rem">
                              <ScutiImgInput image={field.value} error={!!error} {...field}>
                                <ImageIcon
                                  color="secondary"
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                  }}
                                />
                              </ScutiImgInput>
                            </Box>
                            {error && <FormHelperText error>{error}</FormHelperText>}
                          </Box>
                        );
                      }}
                    />
                  </Box>
                </Box>
                <Button className="btn-close" type="button" onClick={() => remove(index)} />
              </Box>
            );
          })}
        </Stack>
      </CardContent>
    </Card>
  );
};
