import React from 'react';
import { Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import { brandsRoutes } from 'routing';
import { Brand } from 'types/__generated__/types';

interface Props {
  brands?: Brand[];
  organizationId: string;
  onDelete?: (shopId: string) => void;
  onEdit?: (shopId: string) => void;
}

export const BrandsTable: React.FC<Props> = ({ brands = [], organizationId, onEdit, onDelete }) => {
  const deleteBrand = onDelete ? (id: string) => () => onDelete(id) : undefined;
  const editBrand = onEdit ? (id: string) => () => onEdit(id) : undefined;

  return (
    <Table responsive striped hover>
      <thead>
        <tr>
          <th>Name</th>
          <th>Owner</th>
          <th>Attributes</th>
          <th className="col-90"></th>
        </tr>
      </thead>
      <tbody>
        {brands.map((brand, index) => {
          return (
            <tr key={`shop_${index}`}>
              <td data-label="Name :">
                <Link to={`${brandsRoutes.BRAND_EDIT(brand.id)}?organizationId=${organizationId}`}>{brand.name}</Link>
              </td>
              <td data-label="Owner :">{brand.description}</td>
              <td data-label="Attributes :">
                <div className="attr-list"></div>
              </td>
              <td>
                <div className="row-actions">
                  {editBrand && (
                    <span className="link-edit" title="Edit" onClick={editBrand(brand.id)}>
                      Edit
                    </span>
                  )}
                  {deleteBrand && (
                    <span className="link-delete" title="Delete" onClick={deleteBrand(brand.id)}>
                      Delete
                    </span>
                  )}
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
