import { AdvertisementType, NewAdvertisementInput, UpdateAdvertisementInput } from 'types/__generated__/types';
import * as Yup from 'yup';

export const videoPromoAdvertisementSchema = Yup.object().shape({
  id: Yup.string(),
  name: Yup.string().required('Field is required'),
  videoUrl: Yup.string().url('Url is not correct').required('Field is required'),
  category: Yup.string().required('Field is required'),
  cpa: Yup.number()
    .min(0, 'Cannot be less than 0')
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable(),
});

export type VideoPromoAdvertisementFormData = Yup.InferType<typeof videoPromoAdvertisementSchema>;

export const videoPromoAdvertisementSchemaFormDataDefaults = (): VideoPromoAdvertisementFormData => ({
  cpa: undefined,
  name: '',
  category: '',
  videoUrl: '',
});

export const mapVideoPromoAdvertisementToApiInput =
  (brandId: string) =>
  (formData: VideoPromoAdvertisementFormData): UpdateAdvertisementInput | NewAdvertisementInput => {
    return {
      brandId,
      id: formData.id,
      type: AdvertisementType.VideoPromo,
      name: formData.name,
      video: {
        videoUrl: formData.videoUrl,
      },
      category: formData.category,
      cpa: formData.cpa || 0,
    };
  };
