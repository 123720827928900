import { Avatar, Box, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import Logout from '@mui/icons-material/Logout';
import { yellow } from '@mui/material/colors';

interface Props {
  userName: string;
  onLogout: () => void;
}

export const AvatarMenu: React.FC<Props> = ({ userName = '', onLogout }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [first = '', last = ''] = userName.split(' ');

  return (
    <>
      <Box
        sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', ml: 2, cursor: 'pointer' }}
        onClick={handleClick}
      >
        <Avatar style={{ backgroundColor: yellow[700] }} variant="rounded">{`${first.charAt(0) || ''} ${
          last.charAt(0) || ''
        }`}</Avatar>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        PaperProps={{
          sx: {
            mt: 1,
          },
        }}
      >
        <MenuItem onClick={onLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};
