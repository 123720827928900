import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import { BrandsTable } from 'components/BrandsTable'; // Assuming this component exists
import { brandsRoutes } from 'routing'; // Assuming the routes are correctly defined for brands
import { Link } from 'react-router-dom';
import { Filter_Type } from 'types/__generated__/types';
import { Loader } from 'components/Loader';
import { useBrandsQuery } from 'graphql/__generated__/brand.hooks';

interface Props {
  organizationId: string;
}

export const OrganizationBrands: React.FC<Props> = observer(({ organizationId }) => {
  const { data, isLoading } = useBrandsQuery({
    filters: [{ name: 'organizationId', operator: Filter_Type.Eq, value: [organizationId] }],
  });

  const brands = data?.brands.nodes || [];

  return (
    <Card>
      <CardHeader
        title={
          <Link className="btn btn-primary" to={`${brandsRoutes.BRAND_NEW}?organizationId=${organizationId}`}>
            Add new Brand
          </Link>
        }
      />
      <CardContent>
        {isLoading && <Loader />}
        {brands.length ? (
          <BrandsTable organizationId={organizationId} brands={brands} />
        ) : (
          <Box pt={20} pb={20}>
            <Typography align="center" variant="h6">
              No created brands
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
});
