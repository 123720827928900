import React from 'react';
import queryString from 'query-string';
import { observer } from 'mobx-react-lite';
import { ResetPasswordForm } from '../../../components/auth/ResetPasswordForm';
import { useAppStore } from '../../../store/app-store.hook';
import { Redirect } from 'react-router';
import { TwoStepVerificationPhoneForm } from 'components/auth';
import { appRoutes } from 'routing';

export const ResetPasswordPage: React.FC = observer(() => {
  const { authStore, routerStore } = useAppStore();
  const { instructionsSent, resetPassword, error, cleanError } = authStore;
  const { search } = routerStore.location;
  const { token } = queryString.parse(search);
  const [loginRedirect, setLoginRedirect] = React.useState(false);

  React.useEffect(() => {
    if (instructionsSent) {
      setTimeout(() => setLoginRedirect(true), 1000);
    }
    if (authStore.twoFaNeeded(token as string)) {
      authStore.sendSms();
    }
  }, [authStore, instructionsSent, token]);

  React.useEffect(() => {
    cleanError();
  }, [cleanError]);

  if (token && !authStore.loginToken) {
    authStore.saveLoginToken(token as string);
    if (authStore.twoFaNeeded(token as string)) {
      return (
        <div className="animated fadeIn">
          <TwoStepVerificationPhoneForm onVerify={authStore.resetWith2FA} sendSms={authStore.sendSms} />
        </div>
      );
    }
  }

  if (loginRedirect) {
    return <Redirect to={appRoutes.LOGIN} />;
  }

  return (
    <div className="animated fadeIn">
      <ResetPasswordForm error={error} success={instructionsSent} onResetPassword={resetPassword} />
    </div>
  );
});
