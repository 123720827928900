import { Box, Card, CardHeader, CardContent } from '@material-ui/core';
import { ScutiSelect } from 'components/material/ScutiSelect';
import { ScutiInput } from 'components/material/ScutiInput';
import { useProductCategories } from 'hooks/useProductCategories';
import { CouponAdvertisementFormData } from './CouponAdvertisementEditForm.utils';
import { Controller, UseFormReturn } from 'react-hook-form';

interface Props {
  form: UseFormReturn<CouponAdvertisementFormData>;
}

export function CouponAdvertisementDetails({ form }: Props) {
  const productCategoriesOptions = useProductCategories();

  return (
    <Card>
      <CardHeader title="Details" />
      <CardContent>
        <Box mt={2}>
          <Controller
            control={form.control}
            name="couponLabel"
            render={({ field, fieldState }) => (
              <ScutiInput title="Label *" placeholder="Label of coupon" {...field} error={fieldState.error?.message} />
            )}
          />
        </Box>
        <Box mt={2}>
          <Controller
            control={form.control}
            name="couponDescription"
            render={({ field, fieldState }) => (
              <ScutiInput
                title="Description of Coupon *"
                placeholder="Description of Coupon"
                type="number"
                multiline
                minRows={8}
                {...field}
                error={fieldState.error?.message}
              />
            )}
          />
        </Box>
        <Box mt={2}>
          <Controller
            control={form.control}
            name="cpa"
            render={({ field, fieldState }) => (
              <ScutiInput title="CPA" type="number" placeholder="$1.00" {...field} error={fieldState.error?.message} />
            )}
          />
        </Box>
        <Box mt={2}>
          <Controller
            control={form.control}
            name="category"
            render={({ field, fieldState }) => (
              <ScutiSelect
                title="Category *"
                placeholder="Select category"
                options={productCategoriesOptions}
                {...field}
                error={fieldState.error?.message}
              />
            )}
          />
        </Box>
      </CardContent>
    </Card>
  );
}
