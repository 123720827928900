import * as Types from '../../../types/__generated__/types';

import { ApolloError } from 'apollo-client';
import { useQuery, UseQueryOptions, useMutation, UseMutationOptions } from 'react-query';
import { fetcher } from 'api';
export type OrderListItemFragment = {
  id: string;
  createdAt: any;
  amount: number;
  status: Types.OrderStatus;
  user: { id: string; fullName?: Types.Maybe<string>; email: string };
  game: { name: string };
  items: Array<{ quantity: any }>;
};

export type OrderProductFragment = {
  id: string;
  name: string;
  description?: Types.Maybe<string>;
  category?: Types.Maybe<string>;
  variant: {
    id: string;
    image?: Types.Maybe<string>;
    sku: string;
    barcode?: Types.Maybe<string>;
    inStock: number;
    price: { currency: string; amount: number };
    compareAt?: Types.Maybe<{ currency: string; amount: number }>;
    weight?: Types.Maybe<{ amount: number; unit: Types.WeightUnit }>;
  };
};

export type OrderFragment = {
  id: string;
  createdAt: any;
  amount: number;
  status: Types.OrderStatus;
  user: { id: string; email: string; fullName?: Types.Maybe<string> };
  shippingInformation?: Types.Maybe<{
    phone?: Types.Maybe<string>;
    address1: string;
    address2?: Types.Maybe<string>;
    city: string;
    zipCode: string;
    country: string;
    state?: Types.Maybe<string>;
  }>;
  items: Array<{
    id: string;
    amount: any;
    quantity: any;
    fulfillmentMethod: { name: string; type: Types.FulfillmentType };
    product: OrderProductFragment;
  }>;
};

export type ShopOrderQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type ShopOrderQuery = { order: OrderFragment };

export type OrdersQueryVariables = Types.Exact<{
  filters?: Types.Maybe<Array<Types.FilterInput> | Types.FilterInput>;
  sorting?: Types.Maybe<Array<Types.SortFieldInput> | Types.SortFieldInput>;
  paging?: Types.Maybe<Types.PagingInput>;
  id: Types.Scalars['ID'];
}>;

export type OrdersQuery = {
  shopOrders: { paging: { offset: number; limit: number; totalCount: number }; nodes: Array<OrderListItemFragment> };
};

export type SetOrderStatusMutationVariables = Types.Exact<{
  status: Types.OrderStatus;
  id: Types.Scalars['String'];
}>;

export type SetOrderStatusMutation = { setOrderStatus: { id: string; status: Types.OrderStatus } };

export const OrderListItemFragmentDoc = `
    fragment OrderListItem on Order {
  id
  createdAt
  amount
  user {
    id
    fullName
    email
  }
  game {
    name
  }
  status
  items {
    quantity
  }
}
    `;
export const OrderProductFragmentDoc = `
    fragment OrderProduct on OrderProductSnapshot {
  id
  name
  description
  category
  variant {
    id
    image
    price {
      currency
      amount
    }
    compareAt {
      currency
      amount
    }
    sku
    barcode
    weight {
      amount
      unit
    }
    inStock
  }
}
    `;
export const OrderFragmentDoc = `
    fragment Order on Order {
  id
  createdAt
  user {
    id
    email
    fullName
  }
  amount
  shippingInformation {
    phone
    address1
    address2
    city
    zipCode
    country
    state
  }
  items {
    id
    fulfillmentMethod {
      name
      type
    }
    amount
    quantity
    product {
      ...OrderProduct
    }
  }
  status
}
    ${OrderProductFragmentDoc}`;
export const ShopOrderDocument = `
    query ShopOrder($id: String!) {
  order(id: $id) {
    ...Order
  }
}
    ${OrderFragmentDoc}`;
export const useShopOrderQuery = <TData = ShopOrderQuery, TError = ApolloError>(
  variables: ShopOrderQueryVariables,
  options?: UseQueryOptions<ShopOrderQuery, TError, TData>,
) =>
  useQuery<ShopOrderQuery, TError, TData>(
    ['ShopOrder', variables],
    fetcher<ShopOrderQuery, ShopOrderQueryVariables>(ShopOrderDocument, variables),
    options,
  );
export const OrdersDocument = `
    query Orders($filters: [FilterInput!], $sorting: [SortFieldInput!], $paging: PagingInput, $id: ID!) {
  shopOrders(filters: $filters, sorting: $sorting, paging: $paging, id: $id) {
    paging {
      offset
      limit
      totalCount
    }
    nodes {
      ...OrderListItem
    }
  }
}
    ${OrderListItemFragmentDoc}`;
export const useOrdersQuery = <TData = OrdersQuery, TError = ApolloError>(
  variables: OrdersQueryVariables,
  options?: UseQueryOptions<OrdersQuery, TError, TData>,
) =>
  useQuery<OrdersQuery, TError, TData>(
    ['Orders', variables],
    fetcher<OrdersQuery, OrdersQueryVariables>(OrdersDocument, variables),
    options,
  );
export const SetOrderStatusDocument = `
    mutation SetOrderStatus($status: OrderStatus!, $id: String!) {
  setOrderStatus(status: $status, id: $id) {
    id
    status
  }
}
    `;
export const useSetOrderStatusMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<SetOrderStatusMutation, TError, SetOrderStatusMutationVariables, TContext>,
) =>
  useMutation<SetOrderStatusMutation, TError, SetOrderStatusMutationVariables, TContext>(
    (variables?: SetOrderStatusMutationVariables) =>
      fetcher<SetOrderStatusMutation, SetOrderStatusMutationVariables>(SetOrderStatusDocument, variables)(),
    options,
  );
