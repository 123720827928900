import React, { forwardRef } from 'react';
import { FormControl, TextField, TextFieldProps } from '@material-ui/core';

interface Props extends Omit<TextFieldProps, 'error' | 'helperText'> {
  title?: any;
  error?: React.ReactNode;
  hideHelper?: boolean;
}

export const ScutiInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { title, error, hideHelper, ...other } = props;

  return (
    <FormControl fullWidth>
      {title && <label style={{ opacity: other.disabled ? 0.4 : 1 }}>{title}</label>}
      <TextField
        ref={ref}
        fullWidth
        size="small"
        variant="outlined"
        error={!!error}
        helperText={hideHelper ? undefined : error}
        value={other.value || ''}
        {...other}
      />
    </FormControl>
  );
});
