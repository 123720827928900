import React from 'react';
import { ForgotPasswordForm } from '../../../components/auth/ForgotPasswordForm';
import { observer } from 'mobx-react-lite';
import { useAppStore } from '../../../store/app-store.hook';
import { Redirect } from 'react-router';
import { appRoutes } from 'routing';

export const ForgotPasswordPage: React.FC = observer(() => {
  const { authStore } = useAppStore();
  const { forgotPassword, instructionsSent, error, cleanError } = authStore;

  React.useEffect(() => {
    cleanError();
  }, [cleanError]);

  if (instructionsSent) {
    return <Redirect to={appRoutes.CHECK_EMAIL} />;
  }

  return (
    <div className="animated fadeIn">
      <ForgotPasswordForm onSend={forgotPassword} error={error} />
    </div>
  );
});
