import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ordersRoutes } from 'routing';
import { useParams } from 'react-router';
import { Loader } from 'components/Loader';
import { PageContentLayout } from 'layouts/PageContentLayout';
import { OrderCustomerInformation } from './OrderCustomerInformation';
import { OrderDetails } from './OrderDetails';
import { OrderSummary } from './OrderSummary';
import { NoData } from 'components/material/NoData';
import { Box, Grid } from '@material-ui/core';
import { OrderStatus } from 'types/__generated__/types';
import { useShopOrderQuery } from '../__generated__/orders.hooks';
import { NavTabs } from 'components/NavTabs/NavTabs';
import styles from './OrderOverviewPage.module.scss';

export const OrderOverviewPage: React.FC = observer(() => {
  const { orderId } = useParams<{ orderId: string }>();
  const [tabs] = React.useState([
    {
      name: 'Fulfillment',
      linkTo: ordersRoutes.ORDER_OVERVIEW(orderId),
      active: (pathname: string = '') => pathname.split('/').slice(-1)[0] === 'fulfillment',
    },
    {
      name: 'Refunds',
      linkTo: ordersRoutes.ORDER_OVERVIEW_REFUNDS(orderId),
      active: (pathname: string = '') => pathname.split('/').slice(-1)[0] === 'refunds',
    },
  ]);

  const useOrder = useShopOrderQuery({ id: orderId });

  const order = useOrder.data?.order;
  const isLoading = useOrder.isLoading;
  const showOrder = !!order && !isLoading;
  const showNoData = !order && !isLoading;
  return (
    <PageContentLayout>
      {order && (
        <PageContentLayout.Header className="d-flex align-items-center justify-content-between mb-2">
          <div className="d-flex align-items-center">
            <Link className="btn btn-back" to={ordersRoutes.ORDERS} />
            <h1 className={styles.page_title}>Order - {order.id}</h1>
            <Button color="primary" className={styles.button}>
              {order.status.toLowerCase()}
            </Button>
          </div>
          <div className="d-flex align-items-center">
            <Button
              color="outline-secondary"
              className={styles.button}
              disabled={order.status === OrderStatus.Cancelled}
            >
              Cancel Order
            </Button>
            <Link
              to={ordersRoutes.ORDER_SUMMARY(order.id)}
              className={`${styles.button} btn btn-outline-secondary`}
              color="outline-secondary"
            >
              Show order summary
            </Link>
          </div>
        </PageContentLayout.Header>
      )}
      <NavTabs tabs={tabs} />
      <PageContentLayout.Content>
        {isLoading && <Loader />}
        {showNoData && <NoData />}
        {showOrder && (
          <Box mt={1}>
            <Grid container spacing={2}>
              <Grid item md={7} lg={8}>
                <Grid>
                  <Grid item lg={12}>
                    {order && <OrderDetails order={order} onCancelGroup={() => {}} addTrackingNumber={() => {}} />}
                  </Grid>
                  <Grid item lg={12}>
                    {/* <OrderPayments payments={payments} /> */}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={5} lg={4}>
                <Grid item container spacing={2}>
                  <Grid item lg={12}>
                    <OrderSummary order={order!} />
                  </Grid>
                  <Grid item lg={12}>
                    <OrderCustomerInformation order={order!} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
      </PageContentLayout.Content>
    </PageContentLayout>
  );
});
