import { action, observable } from 'mobx';
import { RequestEntityType } from 'types/__generated__/types';
import { UserItem } from 'types/user-types';
import { getFromStorage, StorageKeys } from 'utils/local-storage.utils';

export class UiStore {
  @observable private _selectedItem: UserItem;

  constructor() {
    this._selectedItem = getFromStorage<UserItem>(StorageKeys.selectedItem) || {
      name: '',
      id: '',
      color: '#FFF',
      itemType: RequestEntityType.Shop,
    };
  }

  get selectedItem() {
    return this._selectedItem;
  }

  @action
  setSelectedItem = (item: UserItem) => {
    this._selectedItem = item;
  };
}
