import axios from 'axios';
import { API_URI } from '../config';
import {
  LoginRequest,
  RegisterRequest,
  ConfirmRequest,
  UserInfo,
  PhoneRegisterRequest,
  PhoneVerifyRequest,
  InviteVerifyRequest,
  UserToken,
  ForgotPasswordRequest,
  ResetPasswordRequest,
  ChangePasswordRequest,
  ChangeFullnameRequest,
} from '../types/auth-types';

export const API = axios.create({
  baseURL: API_URI,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const logIn = async (params: LoginRequest): Promise<UserToken> => {
  const { data } = await API.post('/auth/login', params);
  return data;
};

export const logInWith2FA = async (params: PhoneVerifyRequest, token: string): Promise<UserToken> => {
  const { data } = await API.post('/auth/2fa/login', params, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const register = async (params: RegisterRequest): Promise<UserInfo> => {
  const { data } = await API.post('/user/register', params);
  return data;
};

export const inviteVerify = async (params: InviteVerifyRequest, token: string): Promise<UserInfo> => {
  const { data } = await API.post('/user/invite/accept-system', params, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const acceptInvite = async (token: string): Promise<UserInfo> => {
  const { data } = await API.post(
    '/user/invite/accept-entity',
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return data;
};

export const phoneRegister = async (params: PhoneRegisterRequest, token: string): Promise<UserInfo> => {
  const { data } = await API.post('/user/phone/register', params, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const sendSMS = async (token: string): Promise<UserInfo> => {
  const { data } = await API.post(
    '/auth/2fa/code',
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return data;
};

export const phoneVerify = async (params: PhoneVerifyRequest, token: string): Promise<UserInfo> => {
  const { data } = await API.post('/user/phone/verify', params, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const confirm = async (params: ConfirmRequest): Promise<boolean> => {
  const { data } = await API.post('/auth/confirm', params);
  return data;
};

export const verifyEmail = async () => {
  const { data } = await API.get('/auth/verify-email');
  return data;
};

export const loadUserInfo = async (token: string) => {
  const { data } = await API.get('/user/me', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const forgotPassword = async (params: ForgotPasswordRequest) => {
  const { data } = await API.post('/user/password/reset', params);
  return data;
};

export const resetPassword = async (params: ResetPasswordRequest, token: string) => {
  const { data } = await API.post('/user/password', params, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const changePassword = async (params: ChangePasswordRequest, token: string) => {
  const { data } = await API.post('/user/change-password', params, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const changeFullname = async (params: ChangeFullnameRequest, token: string) => {
  const { data } = await API.post('/user/me', params, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const resetWith2FA = async (params: PhoneVerifyRequest, token: string): Promise<UserToken> => {
  const { data } = await API.post('/user/password/2fa', params, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const refreshToken = async (token: string): Promise<UserToken> => {
  const { data } = await API.post(
    '/auth/refresh',
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return data;
};
