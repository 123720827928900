import React from 'react';
import { Grid, FormGroup, Box } from '@material-ui/core';
import { ScutiInput } from 'components/material/ScutiInput';
import { FormikProps } from 'formik';
import { ScutiSelect } from 'components/material/ScutiSelect';
import { GameInvitesForm } from './GameInvitesEditForm.utils';
import { Button } from 'reactstrap';
import { Role } from 'types/__generated__/types';

interface Props {
  rowIndex: number;
  invite: GameInvitesForm['invites'][0];
  formik: FormikProps<GameInvitesForm>;
  onAdd: () => void;
  onRemove: () => void;
}

export const GameInvitesEditNewRow: React.FC<Props> = ({ rowIndex, invite, formik, onAdd, onRemove }) => {
  const { errors, touched } = formik;
  return (
    <Grid item container spacing={2} key={rowIndex} alignItems="center">
      <Grid item container xs={9} spacing={2}>
        <Grid item xs={8}>
          <ScutiInput
            name={`invites.${rowIndex}.email`}
            value={invite.email || ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              // @ts-ignore
              touched.invites && touched.invites[rowIndex]?.email && errors[`invites[${rowIndex}].email`]
            }
          />
        </Grid>
        <Grid item xs={4}>
          <ScutiSelect
            value={Role.GameAdmin}
            placeholder="Select Role"
            options={[{ label: 'Game Admin', value: Role.GameAdmin }]}
          />
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <Box display="flex">
          <Box mt={1} mr={1}>
            <label>{invite.accepted ? '' : 'Not '}Accepted</label>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={1}>
        <FormGroup className="d-flex justify-content-center">
          <Button
            className={`btn-${formik.isValid ? 'add' : 'close'}`}
            type="button"
            onClick={() => {
              formik.isValid ? onAdd() : onRemove();
            }}
            disabled={!formik.isValid}
          />
        </FormGroup>
      </Grid>
    </Grid>
  );
};
