import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { ShopEditForm } from './ShopEditForm';
import { Box } from '@mui/material';
import { Button } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ShopFormData, mapShopToApiInput, shopFormDefaults, shopSchema } from './ShopEditForm/ShopEditForm.utils';
import { useHistory, useLocation, useParams } from 'react-router';
import {
  useCreateShopMutation,
  useInviteToShopMutation,
  useRemoveFromShopMutation,
  useResendInviteMutation,
  useShopInvitesQuery,
  useShopQuery,
  useUpdateShopMutation,
} from 'graphql/__generated__/shop.hooks';
import { hasId } from 'types/util-types';
import { shopsRoutes } from 'routing';
import { Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import * as queryString from 'query-string';
import {
  InvitesFormData,
  getInvites,
  inviteFormDefaults,
  invitesSchema,
} from 'components/InvitesEditForm/InvitesEditForm.utils';
import { InvitesEditForm } from 'components/InvitesEditForm';
import { Role } from 'types/__generated__/types';

export const ShopEditPage: React.FC = observer(() => {
  const { addToast } = useToasts();
  const { id } = useParams<{ id: string }>();
  const { search } = useLocation();
  const organizationId = queryString.parse(search).organizationId as string;

  const history = useHistory();

  const useShop = useShopQuery({ id }, { enabled: !!id });
  const useUpdateShop = useUpdateShopMutation();
  const useCreateShop = useCreateShopMutation();

  const useInvites = useShopInvitesQuery({ id });
  const { mutateAsync: inviteToShop } = useInviteToShopMutation();
  const { mutateAsync: removeFromShop } = useRemoveFromShopMutation();
  const { mutateAsync: resendInvite } = useResendInviteMutation();

  const shopFormDefaultValues = useMemo<ShopFormData>(() => {
    const shop = useShop.data?.shop;

    const defaults = shopFormDefaults();

    if (!shop) return defaults;

    const { admins, owner, ...other } = shop;

    return {
      ...other,
      referralFee: shop.referralFee ? Math.round(shop.referralFee * 100) : shop.referralFee,
      rewardsPercentage: shop.rewardsPercentage ? Math.round(shop.rewardsPercentage * 100) : shop.rewardsPercentage,
      video: shop.video || defaults.video,
      directResponse: shop.directResponse || defaults.directResponse,
      engagement: shop.engagement || defaults.engagement,
      returnPolicy: shop.returnPolicy || defaults.returnPolicy,
      returnPolicyUrl: shop.returnPolicyUrl || defaults.returnPolicyUrl,
      flatShippingRate: shop.flatShippingRate || defaults.flatShippingRate,
      brands: shop.brands || defaults.brands,
    };
  }, [useShop.data]);

  const shopForm = useForm<ShopFormData>({
    resolver: yupResolver(shopSchema),
    defaultValues: shopFormDefaultValues,
    mode: 'all',
  });

  useEffect(() => shopForm.reset(shopFormDefaultValues), [shopFormDefaultValues]);

  const invitesFormDefaultValues = useMemo<InvitesFormData>(() => {
    const invites = useInvites.data?.shopInvites.nodes;

    if (!invites) return inviteFormDefaults(Role.ShopAdmin);

    return {
      invites: invites.map((invite) => ({
        ...invite,
        role: invite.roles.find((r) => r.startsWith('SHOP'))!,
      })),
    };
  }, [useInvites.data]);

  const invitesForm = useForm<InvitesFormData>({
    resolver: yupResolver(invitesSchema),
    defaultValues: invitesFormDefaultValues,
    mode: 'all',
  });

  useEffect(() => invitesForm.reset(invitesFormDefaultValues), [invitesFormDefaultValues]);

  const handleSubmit = async (formData: ShopFormData) => {
    try {
      const input = await mapShopToApiInput(formData);
      const { toInvite, toRemove } = getInvites(invitesFormDefaultValues, invitesForm.getValues());

      if (hasId(input)) {
        await useUpdateShop.mutateAsync({ input });

        await Promise.all([
          ...toInvite.map((invite) => inviteToShop({ id, user: { email: invite.email, roles: [invite.role] } })),
          ...toRemove.map((invite) => removeFromShop({ email: invite.email, shopId: id })),
        ]);

        addToast('Shop has been updated!', { appearance: 'success', autoDismiss: true });
        useShop.refetch();
      } else {
        await useCreateShop.mutateAsync({ input: { ...input, organizationId } });

        await Promise.all(
          toInvite.map((invite) => inviteToShop({ id, user: { email: invite.email, roles: [invite.role] } })),
        );

        addToast('Shop has been created!', { appearance: 'success', autoDismiss: true });

        history.push(shopsRoutes.SHOPS);
      }
    } catch (err) {
      // @ts-expect-error
      addToast(err.message, { appearance: 'error', autoDismiss: false });
    }
  };

  const handleResend = async (email: string) => {
    await resendInvite({ email });

    addToast(`Invite to ${email} has been resent!`, { appearance: 'success', autoDismiss: true });
  };

  const isDirty = shopForm.formState.isDirty || invitesForm.formState.isDirty;
  const isValid = shopForm.formState.isValid && invitesForm.formState.isValid;
  const isSubmitting = shopForm.formState.isSubmitting && invitesForm.formState.isSubmitting;

  const title = `${id ? 'Edit' : 'Create new'} shop`;

  console.log('errors', shopForm.formState.errors);

  return (
    <Box>
      <form onSubmit={shopForm.handleSubmit(handleSubmit)}>
        <div className="topbar d-sm-flex justify-content-sm-between">
          <div className="col-heading">
            <Link className="btn btn-back" to={shopsRoutes.SHOPS} />
            <h1 className="page-title">{title}</h1>
          </div>
          <div className="col-action">
            <Button color={!isDirty ? 'outline-secondary' : 'secondary'} onClick={() => shopForm.reset()}>
              Cancel
            </Button>
            <Button color="primary" type="submit" disabled={isSubmitting}>
              Save changes
            </Button>
          </div>
        </div>
        <Box sx={{ mt: 2 }}>
          <ShopEditForm form={shopForm} />
          <Box sx={{ mt: 2 }}>
            <InvitesEditForm form={invitesForm} onResend={handleResend} />
          </Box>
        </Box>
      </form>
    </Box>
  );
});
