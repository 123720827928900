import React from 'react';
import { PrivateRoute } from 'components/PrivateRoute';
import { ConnectsListPage } from './ConnectsListPage';
import { ConnectEditPage } from './ConnectEditPage';
import { Switch } from 'react-router';
import { connectsRoutes } from 'routing';
import { useAppStore } from 'store/app-store.hook';

export const ConnectsRoutes: React.FC = () => {
  const { permissions } = useAppStore();
  return (
    <Switch>
      {permissions.shopsAccess.view && (
        <PrivateRoute exact path={connectsRoutes.CONNECTS} component={ConnectsListPage} />
      )}
      {permissions.shopsAccess.view && (
        <PrivateRoute exact path={connectsRoutes.CONNECT_NEW} component={ConnectEditPage} />
      )}
      {permissions.shopsAccess.view && (
        <PrivateRoute exact path={connectsRoutes.CONNECT_EDIT()} component={ConnectEditPage} />
      )}
    </Switch>
  );
};
