import React, { useState } from 'react';
import { Collapse, Button, Nav, NavItem, NavLink } from 'reactstrap';

import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';

interface Props {
  tabs: {
    name: string;
    linkTo: string;
    active: (pathname: string) => boolean;
  }[];
}

export const NavTabs: React.FC<Props> = ({ tabs }) => {
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = React.useCallback(() => setIsOpen(!isOpen), [isOpen]);
  return (
    <div className="tabset-area">
      <Button className="btn-show-tabset w-100 d-sm-none" color="secondary" onClick={toggle}>
        {pathname.slice(pathname.lastIndexOf('/') + 1)}
      </Button>
      <Collapse className="tabset-holder d-sm-block" isOpen={isOpen}>
        <nav>
          <Nav tabs>
            {tabs.map((item, index) => {
              return (
                <NavItem key={`nav_${index}`} className={item.active(pathname) ? 'active' : ''}>
                  <NavLink tag={Link} to={item.linkTo}>
                    <span>{item.name}</span>
                  </NavLink>
                </NavItem>
              );
            })}
          </Nav>
        </nav>
      </Collapse>
    </div>
  );
};
