import * as Types from '../../types/__generated__/types';

import { ApolloError } from 'apollo-client';
import { useQuery, UseQueryOptions, useMutation, UseMutationOptions } from 'react-query';
import { fetcher } from 'api';
export type ProductListingFragment = {
  id: string;
  name: string;
  status: Types.CampaignStatus;
  category?: Types.Maybe<string>;
  shop?: Types.Maybe<{ id: string; name: string }>;
  product?: Types.Maybe<{ id: string; name: string }>;
  promotion?: Types.Maybe<{
    promotionType?: Types.Maybe<Types.PromotionType>;
    offerType?: Types.Maybe<Types.OfferType>;
    offerCopy?: Types.Maybe<string>;
    discount?: Types.Maybe<number>;
  }>;
  shopBrand?: Types.Maybe<{ id: string; name: string }>;
  demographic: { gender?: Types.Maybe<string>; minAge: number; maxAge: number };
  duration: {
    start?: Types.Maybe<any>;
    end?: Types.Maybe<any>;
    inventory?: Types.Maybe<number>;
    runUntilStockZero?: Types.Maybe<boolean>;
  };
  location?: Types.Maybe<{ country?: Types.Maybe<string>; states?: Types.Maybe<Array<string>> }>;
  reward?: Types.Maybe<{ scutiPercentage: number }>;
  extras: { moreExposure?: Types.Maybe<boolean> };
};

export type ListingShopsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ListingShopsQuery = { shops: { nodes: Array<{ id: string; name: string }> } };

export type ListingProductsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  filters?: Types.Maybe<Array<Types.FilterInput> | Types.FilterInput>;
}>;

export type ListingProductsQuery = { shopProducts: { nodes: Array<{ id: string; name: string }> } };

export type ListingBrandsQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type ListingBrandsQuery = { shopBrands: Array<{ id: string; name: string }> };

export type ProductListingQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type ProductListingQuery = { campaign: ProductListingFragment };

export type CreateProductListingMutationVariables = Types.Exact<{
  input: Types.NewCampaignInput;
}>;

export type CreateProductListingMutation = { createCampaign: { id: string } };

export type UpdateProductListingMutationVariables = Types.Exact<{
  input: Types.UpdateCampaignInput;
}>;

export type UpdateProductListingMutation = { updateCampaign: { id: string } };

export type ToggleListingMutationVariables = Types.Exact<{
  input: Types.ToggleCampaignInput;
}>;

export type ToggleListingMutation = { toggleCampaign: { id: string } };

export const ProductListingFragmentDoc = `
    fragment ProductListing on Campaign {
  id
  name
  shop {
    id
    name
  }
  status
  category
  product {
    id
    name
  }
  promotion {
    promotionType
    offerType
    offerCopy
    discount
  }
  shopBrand {
    id
    name
  }
  demographic {
    gender
    minAge
    maxAge
  }
  duration {
    start
    end
    inventory
    runUntilStockZero
  }
  location {
    country
    states
  }
  reward {
    scutiPercentage
  }
  extras {
    moreExposure
  }
}
    `;
export const ListingShopsDocument = `
    query ListingShops {
  shops {
    nodes {
      id
      name
    }
  }
}
    `;
export const useListingShopsQuery = <TData = ListingShopsQuery, TError = ApolloError>(
  variables?: ListingShopsQueryVariables,
  options?: UseQueryOptions<ListingShopsQuery, TError, TData>,
) =>
  useQuery<ListingShopsQuery, TError, TData>(
    ['ListingShops', variables],
    fetcher<ListingShopsQuery, ListingShopsQueryVariables>(ListingShopsDocument, variables),
    options,
  );
export const ListingProductsDocument = `
    query ListingProducts($id: ID!, $filters: [FilterInput!]) {
  shopProducts(id: $id, filters: $filters) {
    nodes {
      id
      name
    }
  }
}
    `;
export const useListingProductsQuery = <TData = ListingProductsQuery, TError = ApolloError>(
  variables: ListingProductsQueryVariables,
  options?: UseQueryOptions<ListingProductsQuery, TError, TData>,
) =>
  useQuery<ListingProductsQuery, TError, TData>(
    ['ListingProducts', variables],
    fetcher<ListingProductsQuery, ListingProductsQueryVariables>(ListingProductsDocument, variables),
    options,
  );
export const ListingBrandsDocument = `
    query ListingBrands($id: String!) {
  shopBrands(id: $id) {
    id
    name
  }
}
    `;
export const useListingBrandsQuery = <TData = ListingBrandsQuery, TError = ApolloError>(
  variables: ListingBrandsQueryVariables,
  options?: UseQueryOptions<ListingBrandsQuery, TError, TData>,
) =>
  useQuery<ListingBrandsQuery, TError, TData>(
    ['ListingBrands', variables],
    fetcher<ListingBrandsQuery, ListingBrandsQueryVariables>(ListingBrandsDocument, variables),
    options,
  );
export const ProductListingDocument = `
    query ProductListing($id: String!) {
  campaign(id: $id) {
    ...ProductListing
  }
}
    ${ProductListingFragmentDoc}`;
export const useProductListingQuery = <TData = ProductListingQuery, TError = ApolloError>(
  variables: ProductListingQueryVariables,
  options?: UseQueryOptions<ProductListingQuery, TError, TData>,
) =>
  useQuery<ProductListingQuery, TError, TData>(
    ['ProductListing', variables],
    fetcher<ProductListingQuery, ProductListingQueryVariables>(ProductListingDocument, variables),
    options,
  );
export const CreateProductListingDocument = `
    mutation CreateProductListing($input: NewCampaignInput!) {
  createCampaign(input: $input) {
    id
  }
}
    `;
export const useCreateProductListingMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<CreateProductListingMutation, TError, CreateProductListingMutationVariables, TContext>,
) =>
  useMutation<CreateProductListingMutation, TError, CreateProductListingMutationVariables, TContext>(
    (variables?: CreateProductListingMutationVariables) =>
      fetcher<CreateProductListingMutation, CreateProductListingMutationVariables>(
        CreateProductListingDocument,
        variables,
      )(),
    options,
  );
export const UpdateProductListingDocument = `
    mutation UpdateProductListing($input: UpdateCampaignInput!) {
  updateCampaign(input: $input) {
    id
  }
}
    `;
export const useUpdateProductListingMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<UpdateProductListingMutation, TError, UpdateProductListingMutationVariables, TContext>,
) =>
  useMutation<UpdateProductListingMutation, TError, UpdateProductListingMutationVariables, TContext>(
    (variables?: UpdateProductListingMutationVariables) =>
      fetcher<UpdateProductListingMutation, UpdateProductListingMutationVariables>(
        UpdateProductListingDocument,
        variables,
      )(),
    options,
  );
export const ToggleListingDocument = `
    mutation ToggleListing($input: ToggleCampaignInput!) {
  toggleCampaign(input: $input) {
    id
  }
}
    `;
export const useToggleListingMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<ToggleListingMutation, TError, ToggleListingMutationVariables, TContext>,
) =>
  useMutation<ToggleListingMutation, TError, ToggleListingMutationVariables, TContext>(
    (variables?: ToggleListingMutationVariables) =>
      fetcher<ToggleListingMutation, ToggleListingMutationVariables>(ToggleListingDocument, variables)(),
    options,
  );
