import * as Types from '../../types/__generated__/types';

import { ApolloError } from 'apollo-client';
import { useQuery, UseQueryOptions, useMutation, UseMutationOptions } from 'react-query';
import { fetcher } from 'api';
export type CampaignFragment = {
  id: string;
  name: string;
  type: Types.CampaignType;
  contentCategory?: Types.Maybe<Types.ContentCategory>;
  category?: Types.Maybe<string>;
  status: Types.CampaignStatus;
  productListingId?: Types.Maybe<string>;
  shop?: Types.Maybe<{ id: string; name: string }>;
  product?: Types.Maybe<{ id: string; name: string }>;
  promotion?: Types.Maybe<{
    promotionType?: Types.Maybe<Types.PromotionType>;
    offerType?: Types.Maybe<Types.OfferType>;
    offerCopy?: Types.Maybe<string>;
    discount?: Types.Maybe<number>;
  }>;
  reward?: Types.Maybe<{ scutiPercentage: number }>;
  demographic: { gender?: Types.Maybe<string>; minAge: number; maxAge: number };
  duration: {
    start?: Types.Maybe<any>;
    end?: Types.Maybe<any>;
    inventory?: Types.Maybe<number>;
    runUntilStockZero?: Types.Maybe<boolean>;
  };
  location?: Types.Maybe<{ country?: Types.Maybe<string>; states?: Types.Maybe<Array<string>> }>;
  budget?: Types.Maybe<{
    option?: Types.Maybe<Types.CampaignBudgetOption>;
    maxSpend: number;
    maxDailySpend: number;
    dailyFrequencyCap: number;
  }>;
  extras: { moreExposure?: Types.Maybe<boolean>; interstitials?: Types.Maybe<boolean> };
  media?: Types.Maybe<{ banner?: Types.Maybe<string>; vertical?: Types.Maybe<string>; tile?: Types.Maybe<string> }>;
};

export type CampaignListItemFragment = {
  id: string;
  name: string;
  contentCategory?: Types.Maybe<Types.ContentCategory>;
  category?: Types.Maybe<string>;
  type: Types.CampaignType;
  status: Types.CampaignStatus;
  shop?: Types.Maybe<{ id: string }>;
  product?: Types.Maybe<{ id: string; name: string; images?: Types.Maybe<Array<string>> }>;
  promotion?: Types.Maybe<{
    promotionType?: Types.Maybe<Types.PromotionType>;
    offerType?: Types.Maybe<Types.OfferType>;
    offerCopy?: Types.Maybe<string>;
    discount?: Types.Maybe<number>;
  }>;
  demographic: { gender?: Types.Maybe<string>; minAge: number; maxAge: number };
  budget?: Types.Maybe<{
    option?: Types.Maybe<Types.CampaignBudgetOption>;
    maxSpend: number;
    maxDailySpend: number;
    dailyFrequencyCap: number;
  }>;
  duration: {
    start?: Types.Maybe<any>;
    end?: Types.Maybe<any>;
    inventory?: Types.Maybe<number>;
    runUntilStockZero?: Types.Maybe<boolean>;
  };
  statistics?: Types.Maybe<{ totalSpend: number; totalRevenue: number; roi: number }>;
};

export type CampaignsQueryVariables = Types.Exact<{
  paging?: Types.Maybe<Types.PagingInput>;
  sorting?: Types.Maybe<Array<Types.SortFieldInput> | Types.SortFieldInput>;
  filters?: Types.Maybe<Array<Types.FilterInput> | Types.FilterInput>;
  id: Types.Scalars['ID'];
}>;

export type CampaignsQuery = {
  shopCampaigns: {
    sorting?: Types.Maybe<Array<{ name: string; dir: string }>>;
    paging: { offset: number; limit: number; totalCount: number };
    nodes: Array<CampaignListItemFragment>;
  };
};

export type CampaignQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type CampaignQuery = { campaign: CampaignFragment };

export type CreateCampaignMutationVariables = Types.Exact<{
  input: Types.NewCampaignInput;
}>;

export type CreateCampaignMutation = { createCampaign: { id: string } };

export type UpdateCampaignMutationVariables = Types.Exact<{
  input: Types.UpdateCampaignInput;
}>;

export type UpdateCampaignMutation = { updateCampaign: { id: string } };

export type CampaignProductsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  filters?: Types.Maybe<Array<Types.FilterInput> | Types.FilterInput>;
}>;

export type CampaignProductsQuery = {
  shopCampaigns: { nodes: Array<{ id: string; product?: Types.Maybe<{ id: string; name: string }> }> };
};

export type ToggleCampaignMutationVariables = Types.Exact<{
  input: Types.ToggleCampaignInput;
}>;

export type ToggleCampaignMutation = { toggleCampaign: { id: string } };

export const CampaignFragmentDoc = `
    fragment Campaign on Campaign {
  id
  name
  type
  contentCategory
  category
  status
  productListingId
  shop {
    id
    name
  }
  product {
    id
    name
  }
  promotion {
    promotionType
    offerType
    offerCopy
    discount
  }
  reward {
    scutiPercentage
  }
  demographic {
    gender
    minAge
    maxAge
  }
  duration {
    start
    end
    inventory
    runUntilStockZero
  }
  location {
    country
    states
  }
  budget {
    option
    maxSpend
    maxDailySpend
    dailyFrequencyCap
  }
  extras {
    moreExposure
    interstitials
  }
  media {
    banner
    vertical
    tile
  }
  shop {
    id
    name
  }
}
    `;
export const CampaignListItemFragmentDoc = `
    fragment CampaignListItem on Campaign {
  id
  name
  contentCategory
  category
  type
  status
  shop {
    id
  }
  product {
    id
    name
    images
  }
  promotion {
    promotionType
    offerType
    offerCopy
    discount
  }
  demographic {
    gender
    minAge
    maxAge
  }
  budget {
    option
    maxSpend
    maxDailySpend
    dailyFrequencyCap
  }
  duration {
    start
    end
    inventory
    runUntilStockZero
  }
  statistics {
    totalSpend
    totalRevenue
    roi
  }
}
    `;
export const CampaignsDocument = `
    query Campaigns($paging: PagingInput, $sorting: [SortFieldInput!], $filters: [FilterInput!], $id: ID!) {
  shopCampaigns(id: $id, paging: $paging, sorting: $sorting, filters: $filters) {
    sorting {
      name
      dir
    }
    paging {
      offset
      limit
      totalCount
    }
    nodes {
      ...CampaignListItem
    }
  }
}
    ${CampaignListItemFragmentDoc}`;
export const useCampaignsQuery = <TData = CampaignsQuery, TError = ApolloError>(
  variables: CampaignsQueryVariables,
  options?: UseQueryOptions<CampaignsQuery, TError, TData>,
) =>
  useQuery<CampaignsQuery, TError, TData>(
    ['Campaigns', variables],
    fetcher<CampaignsQuery, CampaignsQueryVariables>(CampaignsDocument, variables),
    options,
  );
export const CampaignDocument = `
    query Campaign($id: String!) {
  campaign(id: $id) {
    ...Campaign
  }
}
    ${CampaignFragmentDoc}`;
export const useCampaignQuery = <TData = CampaignQuery, TError = ApolloError>(
  variables: CampaignQueryVariables,
  options?: UseQueryOptions<CampaignQuery, TError, TData>,
) =>
  useQuery<CampaignQuery, TError, TData>(
    ['Campaign', variables],
    fetcher<CampaignQuery, CampaignQueryVariables>(CampaignDocument, variables),
    options,
  );
export const CreateCampaignDocument = `
    mutation CreateCampaign($input: NewCampaignInput!) {
  createCampaign(input: $input) {
    id
  }
}
    `;
export const useCreateCampaignMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<CreateCampaignMutation, TError, CreateCampaignMutationVariables, TContext>,
) =>
  useMutation<CreateCampaignMutation, TError, CreateCampaignMutationVariables, TContext>(
    (variables?: CreateCampaignMutationVariables) =>
      fetcher<CreateCampaignMutation, CreateCampaignMutationVariables>(CreateCampaignDocument, variables)(),
    options,
  );
export const UpdateCampaignDocument = `
    mutation UpdateCampaign($input: UpdateCampaignInput!) {
  updateCampaign(input: $input) {
    id
  }
}
    `;
export const useUpdateCampaignMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<UpdateCampaignMutation, TError, UpdateCampaignMutationVariables, TContext>,
) =>
  useMutation<UpdateCampaignMutation, TError, UpdateCampaignMutationVariables, TContext>(
    (variables?: UpdateCampaignMutationVariables) =>
      fetcher<UpdateCampaignMutation, UpdateCampaignMutationVariables>(UpdateCampaignDocument, variables)(),
    options,
  );
export const CampaignProductsDocument = `
    query CampaignProducts($id: ID!, $filters: [FilterInput!]) {
  shopCampaigns(id: $id, filters: $filters) {
    nodes {
      id
      product {
        id
        name
      }
    }
  }
}
    `;
export const useCampaignProductsQuery = <TData = CampaignProductsQuery, TError = ApolloError>(
  variables: CampaignProductsQueryVariables,
  options?: UseQueryOptions<CampaignProductsQuery, TError, TData>,
) =>
  useQuery<CampaignProductsQuery, TError, TData>(
    ['CampaignProducts', variables],
    fetcher<CampaignProductsQuery, CampaignProductsQueryVariables>(CampaignProductsDocument, variables),
    options,
  );
export const ToggleCampaignDocument = `
    mutation ToggleCampaign($input: ToggleCampaignInput!) {
  toggleCampaign(input: $input) {
    id
  }
}
    `;
export const useToggleCampaignMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<ToggleCampaignMutation, TError, ToggleCampaignMutationVariables, TContext>,
) =>
  useMutation<ToggleCampaignMutation, TError, ToggleCampaignMutationVariables, TContext>(
    (variables?: ToggleCampaignMutationVariables) =>
      fetcher<ToggleCampaignMutation, ToggleCampaignMutationVariables>(ToggleCampaignDocument, variables)(),
    options,
  );
