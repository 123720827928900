import React from 'react';

import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'store/app-store.hook';
import { getNavigation } from './nav-items-by-role';
import { Box, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { scutiColors } from 'material-theme/variables';

export const LeftNavigation: React.FC = observer(() => {
  const { routerStore, permissions, uiStore } = useAppStore();
  const { pathname } = routerStore.location;

  return (
    <Box sx={{ backgroundColor: scutiColors.grey500, width: '100%', height: '100%' }}>
      <List>
        {getNavigation(permissions.userRoles, uiStore.selectedItem).map((item, key) => {
          const isActive = item.isActive(pathname);

          return (
            <ListItem
              key={key}
              sx={{
                padding: '0px !important',
                color: scutiColors.grey200,
                '& .Mui-selected': { backgroundColor: `${scutiColors.blue500} !important`, color: scutiColors.white },
              }}
            >
              {/* component={Link} to={item.route} selected={item.isActive(pathname)} */}
              <Box
                component={Link}
                to={item.route}
                display="flex"
                alignItems="center"
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  paddingY: 1,
                  paddingX: 2,
                  backgroundColor: isActive ? `${scutiColors.blue500} !important` : undefined,
                  color: scutiColors.white,
                }}
              >
                <ListItemIcon sx={{ minWidth: '32px', color: scutiColors.white }}>{item.icon}</ListItemIcon>
                <ListItemText primary={item.title} />
              </Box>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
});
