import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    color: '#FFF',
    background: '#0059FF',
    borderRadius: 6,
  },
  close: {
    transform: 'rotate(45deg)',
  },
});

interface Props {
  title: string | React.ReactNode;
  onClose: () => void;
}

export const BadgeClose: React.FC<Props> = ({ title, onClose }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root} pt={0.2} pb={0.2} pr={1} pl={1} display="flex" alignItems="center">
      <Box fontWeight="bold" display="inline" mr={0.5}>
        {title}
      </Box>
      <Box className={classes.close} width={10} fontWeight="bold" onClick={onClose} mt={0.2}>
        +
      </Box>
    </Box>
  );
};
