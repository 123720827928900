import React from 'react';
import { Box, Card, CardContent, CardHeader } from '@material-ui/core';
import { ScutiInput } from 'components/material/ScutiInput';
import { Controller, UseFormReturn } from 'react-hook-form';
import { OrganizationFormData } from './OrganizationEditForm.utils';

interface Props {
  form: UseFormReturn<OrganizationFormData>;
}

export const OrganizationOwnerDetails: React.FC<Props> = ({ form }) => {
  return (
    <Card>
      <CardHeader title="Owner Details" />
      <CardContent>
        <Controller
          control={form.control}
          name="owner.fullName"
          render={({ field, fieldState, formState }) => (
            <ScutiInput
              title="Full Name"
              placeholder="Full Name"
              error={fieldState.error?.message}
              disabled={!!formState.defaultValues?.id}
              {...field}
            />
          )}
        />
        <Box mt={2}>
          <Controller
            control={form.control}
            name="owner.email"
            render={({ field, fieldState, formState }) => (
              <ScutiInput
                title="Email"
                placeholder="Email"
                error={fieldState.error?.message}
                disabled={!!formState.defaultValues?.id}
                {...field}
              />
            )}
          />
        </Box>
      </CardContent>
    </Card>
  );
};
