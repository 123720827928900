import React from 'react';
import { CardBody, Card } from 'reactstrap';
import { Link } from 'react-router-dom';
import { gamesRoutes } from 'routing';
import { GameItemFragment } from 'pages/Games/__generated__/games-list-page.hooks';

interface Props {
  game: GameItemFragment;
  onDelete: (shopId: string) => void;
}

export const GameGridItem: React.FC<Props> = ({ game, onDelete }) => {
  const deleteGame = (id: string) => () => onDelete(id);
  const { name, thumbnail, category, id } = game;
  return (
    <Card className="bg-custom game-item">
      <CardBody>
        <Link className="game-item-link" to={gamesRoutes.GAME_OVERVIEW(id)}>
          <div className="game-item-pic">
            {thumbnail ? <img src={thumbnail} alt={thumbnail} /> : <span className="no-game-img" />}
          </div>
          <div className="game-item-info">
            <strong className="game-item-title">{name}</strong>
            <em className="game-item-category">{category}</em>
          </div>
        </Link>
        <div className="game-item-action">
          <Link className="link-edit" title="Edit" to={gamesRoutes.GAME_EDIT(game.id)}>
            Edit
          </Link>
          <span className="link-delete" title="Delete" onClick={deleteGame(id)}>
            Delete
          </span>
        </div>
      </CardBody>
    </Card>
  );
};
