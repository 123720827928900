import React from 'react';
import { ScutiLogo } from 'components/svg-icons/ScutiLogo';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'store/app-store.hook';
import { Switcher } from 'components/material/Switcher';
import { AvatarMenu } from 'components/material/AvatarMenu';
import { Box, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

type Props = {
  handleDrawerToggle: () => void;
  drawerWidth: number;
};

export const DefaultHeader: React.FC<Props> = observer(({ handleDrawerToggle, drawerWidth }) => {
  const { authStore, logOut } = useAppStore();
  const { userInfo } = authStore;
  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      pr={2}
      sx={{
        width: '100%',
        mr: '0px',
      }}
    >
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ mx: 2, display: { lg: 'none' } }}
      >
        <MenuIcon />
      </IconButton>
      <Box pl={2}>
        <ScutiLogo />
      </Box>
      <Box ml="auto">
        <Box display="flex" alignItems="center">
          <Switcher />
          <AvatarMenu userName={userInfo.fullName} onLogout={logOut} />
        </Box>
      </Box>
    </Box>
  );
});
