import * as Types from '../../../types/__generated__/types';

import { ApolloError } from 'apollo-client';
import { useQuery, UseQueryOptions } from 'react-query';
import { fetcher } from 'api';
export type ProductCategoriesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ProductCategoriesQuery = { productCategories: Array<string> };

export const ProductCategoriesDocument = `
    query productCategories {
  productCategories
}
    `;
export const useProductCategoriesQuery = <TData = ProductCategoriesQuery, TError = ApolloError>(
  variables?: ProductCategoriesQueryVariables,
  options?: UseQueryOptions<ProductCategoriesQuery, TError, TData>,
) =>
  useQuery<ProductCategoriesQuery, TError, TData>(
    ['productCategories', variables],
    fetcher<ProductCategoriesQuery, ProductCategoriesQueryVariables>(ProductCategoriesDocument, variables),
    options,
  );
