import { OutlinedInputClassKey, StyleRules } from '@material-ui/core';
import { scutiColors, scutiSizes } from './variables';

export const MuiOutlinedInput: Partial<StyleRules<OutlinedInputClassKey, {}>> = {
  root: {
    fontSize: scutiSizes.inputFontSize,
    color: scutiColors.textMain,
    border: `1px solid ${scutiColors.borderMain}`,
    '&$focused': {
      borderColor: scutiColors.borderFocused,
    },
    '&$disabled': {
      background: scutiColors.inputBackgroundDisabled,
      color: scutiColors.textMain,
    },
    '&$error': {
      borderColor: scutiColors.textError,
    },
  },
  notchedOutline: {
    border: 'none',
  },
  inputMarginDense: {
    paddingTop: scutiSizes.inputPadding,
    paddingBottom: scutiSizes.inputPadding,
  },
  input: {
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
};
