import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { OrganizationEditForm } from './OrganizationEditForm';
import { Box } from '@mui/material';
import { Button } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  OrganizationFormData,
  organizationFormDefaults,
  organizationSchema,
} from './OrganizationEditForm/OrganizationEditForm.utils';
import { useHistory, useParams } from 'react-router';
import {
  useCreateOrganizationMutation,
  useInviteToOrganizationMutation,
  useOrganizationInvitesQuery,
  useOrganizationQuery,
  useRemoveFromOrganizationMutation,
  useUpdateOrganizationMutation,
} from 'graphql/__generated__/organization.hooks';
import { hasId } from 'types/util-types';
import { organizationsRoutes } from 'routing';
import { Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { InvitesEditForm } from '../../../components/InvitesEditForm';
import {
  InvitesFormData,
  getInvites,
  inviteFormDefaults,
  invitesSchema,
} from '../../../components/InvitesEditForm/InvitesEditForm.utils';
import { useResendInviteMutation } from 'graphql/__generated__/game.hooks';
import { Role } from 'types/__generated__/types';

export const OrganizationEditPage: React.FC = observer(() => {
  const { addToast } = useToasts();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const useOrganization = useOrganizationQuery({ id }, { enabled: !!id });
  const useUpdateOrganization = useUpdateOrganizationMutation();
  const useCreateOrganization = useCreateOrganizationMutation();

  const useInvites = useOrganizationInvitesQuery({ id });
  const { mutateAsync: inviteToOrganization } = useInviteToOrganizationMutation();
  const { mutateAsync: removeFromOrganization } = useRemoveFromOrganizationMutation();
  const { mutateAsync: resendInvite } = useResendInviteMutation();

  const organizationFormDefaultValues = useMemo<OrganizationFormData>(() => {
    const organization = useOrganization.data?.organization;

    if (!organization) return organizationFormDefaults();

    const { owner } = organization;

    return { ...organization, owner: { fullName: owner.fullName!, email: owner.email } };
  }, [useOrganization.data]);

  const organizationForm = useForm<OrganizationFormData>({
    resolver: yupResolver(organizationSchema),
    defaultValues: organizationFormDefaultValues,
    mode: 'all',
  });

  useEffect(() => organizationForm.reset(organizationFormDefaultValues), [organizationFormDefaultValues]);

  const invitesFormDefaultValues = useMemo<InvitesFormData>(() => {
    const organizationInvites = useInvites.data?.organizationInvites.nodes;

    if (!organizationInvites) return inviteFormDefaults(Role.OrganizationAdmin);

    return {
      invites: organizationInvites.map((invite) => ({
        ...invite,
        role: invite.roles.find((r) => r.startsWith('ORGANIZATION'))!,
      })),
    };
  }, [useInvites.data]);

  const invitesForm = useForm<InvitesFormData>({
    resolver: yupResolver(invitesSchema),
    defaultValues: invitesFormDefaultValues,
    mode: 'all',
  });

  useEffect(() => invitesForm.reset(invitesFormDefaultValues), [invitesFormDefaultValues]);

  const handleSubmit = async (formData: OrganizationFormData) => {
    try {
      const { toInvite, toRemove } = getInvites(invitesFormDefaultValues, invitesForm.getValues());

      if (hasId(formData)) {
        const { owner, ...input } = formData;

        await useUpdateOrganization.mutateAsync({ input });

        await Promise.all([
          ...toInvite.map((invite) =>
            inviteToOrganization({ id, user: { email: invite.email, roles: [invite.role] } }),
          ),
          ...toRemove.map((invite) => removeFromOrganization({ email: invite.email, organizationId: id })),
        ]);

        addToast('Organization has been updated!', { appearance: 'success', autoDismiss: true });
        useOrganization.refetch();
      } else {
        const { createOrganization } = await useCreateOrganization.mutateAsync({ input: formData });

        await Promise.all(
          toInvite.map((invite) => inviteToOrganization({ id, user: { email: invite.email, roles: [invite.role] } })),
        );

        addToast('Organization has been created!', { appearance: 'success', autoDismiss: true });

        history.push(organizationsRoutes.ORGANIZATION_OVERVIEW(createOrganization.id));
      }
    } catch (err) {
      // @ts-expect-error
      addToast(err.message, { appearance: 'error', autoDismiss: false });
    }
  };

  const handleResend = async (email: string) => {
    await resendInvite({ email });

    addToast(`Invite to ${email} has been resent!`, { appearance: 'success', autoDismiss: true });
  };

  const isDirty = organizationForm.formState.isDirty || invitesForm.formState.isDirty;
  const isValid = organizationForm.formState.isValid && invitesForm.formState.isValid;
  const isSubmitting = organizationForm.formState.isSubmitting && invitesForm.formState.isSubmitting;

  const title = `${id ? 'Edit' : 'Create new'} organization`;

  return (
    <Box>
      <form onSubmit={organizationForm.handleSubmit(handleSubmit)}>
        <div className="topbar d-sm-flex justify-content-sm-between">
          <div className="col-heading">
            <Link className="btn btn-back" to={organizationsRoutes.ORGANIZATIONS} />
            <h1 className="page-title">{title}</h1>
          </div>
          <div className="col-action">
            <Button
              color={!isDirty ? 'outline-secondary' : 'secondary'}
              onClick={() => {
                organizationForm.reset();
                invitesForm.reset();
              }}
            >
              Cancel
            </Button>
            <Button color="primary" type="submit" disabled={isSubmitting || !isValid}>
              Save changes
            </Button>
          </div>
        </div>
        <Box sx={{ mt: 2 }}>
          <OrganizationEditForm form={organizationForm} />
          <Box sx={{ mt: 2 }}>
            <InvitesEditForm form={invitesForm} onResend={handleResend} />
          </Box>
        </Box>
      </form>
    </Box>
  );
});
