//import { BrandItemFgFragment } from './__generated__/brand-list-page.hooks';
import { useMemo } from 'react';
import { Column } from 'react-table';
import { Link } from 'react-router-dom';
import { brandsRoutes } from 'routing';

export function useBrandsTableColumns(onDelete: (id: string) => void): Column<any>[] {
  return useMemo<Column<any>[]>(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Response',
        accessor: ({ directResponse }) => {
          const { unit, amount } = directResponse;
          return `${unit || '-'} / ${amount || '-'}`;
        },
      },
      {
        Header: 'Engagement',
        accessor: ({ engagement }) => {
          const { unit, amount } = engagement;
          return `${unit || '-'} / ${amount || '-'}`;
        },
      },
      {
        Header: 'Video',
        accessor: ({ video }) => {
          const { unit, amount } = video;
          return `${unit || '-'} / ${amount || '-'}`;
        },
      },
      {
        Header: '',
        id: 'delete',
        disableSortBy: true,
        width: '6%',
        Cell: ({ row }: any) => {
          return (
            <div className="row-actions">
              <Link to={brandsRoutes.BRAND_EDIT(row.original.id)} className="link-edit" title="Edit">
                Edit
              </Link>
              <span className="link-delete" title="Delete" onClick={() => onDelete(row.original.id)}>
                Delete
              </span>
            </div>
          );
        },
      },
    ],
    [onDelete],
  );
}
