import React from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory, useLocation, useParams } from 'react-router';
import { Loader } from 'components/Loader';
import { campaignRoutes } from 'routing';
import { Box } from '@material-ui/core';
import { CampaignEditForm } from './CampaignEditForms';
import queryString from 'query-string';
import { useToasts } from 'react-toast-notifications';
import { hasId } from 'types/util-types';
import { ToggleCampaignInput } from 'types/__generated__/types';
import {
  useCampaignQuery,
  useCreateCampaignMutation,
  useToggleCampaignMutation,
  useUpdateCampaignMutation,
} from 'graphql/__generated__/campaign.hooks';
import { CampaignFormData, mapCampaignToApiInput } from './CampaignEditForms/CampaignEditForm.utils';

export const CampaignEditPage: React.FC = observer(() => {
  const { campaignId } = useParams<{ campaignId: string }>();
  const { search } = useLocation();
  const searchQuery = queryString.parse(search);
  const history = useHistory();
  const { addToast } = useToasts();

  const campaignQuery = useCampaignQuery({ id: campaignId }, { enabled: !!campaignId });
  const campaign = campaignQuery.data?.campaign;
  const shopId = (searchQuery.shopId as string) || campaign?.shop?.id;

  const useUpdateCampaign = useUpdateCampaignMutation();
  const useCreateCampaign = useCreateCampaignMutation();

  React.useEffect(() => {
    if (campaignQuery.error) addToast(campaignQuery.error.message, { appearance: 'error', autoDismiss: false });
  }, [addToast, campaignQuery.error]);

  const saveOrUpdate = React.useCallback(
    async (formData: CampaignFormData) => {
      const input = await mapCampaignToApiInput(shopId!)(formData);

      if (hasId(input)) {
        try {
          await useUpdateCampaign.mutateAsync({ input });
          await campaignQuery.refetch();
          addToast('Campaign has been updated!', { appearance: 'success', autoDismiss: true });
        } catch (e) {
          // @ts-expect-error
          addToast(e.message, { appearance: 'error', autoDismiss: false });
        }
      } else {
        try {
          await useCreateCampaign.mutateAsync({ input });
          addToast('Campaign has been created!', { appearance: 'success', autoDismiss: true });
          history.push(campaignRoutes.CAMPAIGNS);
        } catch (e) {
          // @ts-expect-error
          addToast(e.message, { appearance: 'error', autoDismiss: false });
        }
      }
    },
    [addToast, campaignQuery, history, shopId, useCreateCampaign, useUpdateCampaign],
  );

  const toggleCampaignMutation = useToggleCampaignMutation();

  const onToggleCampaign = React.useCallback(
    async (input: ToggleCampaignInput) => {
      try {
        await toggleCampaignMutation.mutateAsync({ input });
        await campaignQuery.refetch();
        addToast(`Campaign has been ${input.isPaused ? 'paused' : 'unpaused'}!`, {
          appearance: 'success',
          autoDismiss: true,
        });
      } catch (e) {
        // @ts-expect-error
        addToast(e.message, { appearance: 'error', autoDismiss: false });
      }
    },
    [addToast, campaignQuery, toggleCampaignMutation],
  );

  const isLoading = campaignQuery.isLoading;

  if (isLoading) return <Loader />;

  return (
    <Box>
      <CampaignEditForm
        campaign={campaign}
        linkBack={campaignRoutes.CAMPAIGNS}
        onSave={saveOrUpdate}
        onToggle={onToggleCampaign}
      />
    </Box>
  );
});
