import React, { useEffect, useMemo } from 'react';
import { Form, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@material-ui/core';
import { ScutiInput } from 'components/material/ScutiInput';
import { Controller, useForm } from 'react-hook-form';
import { AdvertisementFragment } from 'graphql/__generated__/advertisement.hooks';
import { NewAdvertisementInput, UpdateAdvertisementInput } from 'types/__generated__/types';
import {
  InternalAdvertisementFormData,
  mapInternalAdvertisementToApiInput,
  internalAdvertisementSchema,
  internalAdvertisementSchemaFormDataDefaults,
} from './InternalAdvertisementEditForm.utils';
import { InternalAdvertisementDetails } from './InternalAdvertisementDetails';

interface Props {
  linkBack: string;
  brandId: string;
  advertisement?: AdvertisementFragment;
  onSave: (advertisement: UpdateAdvertisementInput | NewAdvertisementInput) => void;
}

export const InternalAdvertisementEditForm: React.FC<Props> = ({ advertisement, brandId, linkBack, onSave }) => {
  const formDefaultValues = useMemo<InternalAdvertisementFormData>(() => {
    const defaults = internalAdvertisementSchemaFormDataDefaults();

    if (!advertisement) return defaults;

    return {
      ...defaults,
      id: advertisement.id,
      name: advertisement.name,
      category: advertisement.category || defaults.category,
      internalLabel: advertisement.internal?.internalLabel || defaults.internalLabel,
      internalHtml5Url: advertisement.internal?.internalHtml5Url || defaults.internalHtml5Url,
      internalUnityUrl: advertisement.internal?.internalUnityUrl || defaults.internalUnityUrl,
    };
  }, [advertisement]);

  const InternalAdvertisementForm = useForm<InternalAdvertisementFormData>({
    resolver: yupResolver(internalAdvertisementSchema),
    defaultValues: formDefaultValues,
    mode: 'all',
  });

  useEffect(() => InternalAdvertisementForm.reset(formDefaultValues), [formDefaultValues]);

  const handleSubmit = async (formData: InternalAdvertisementFormData) => {
    return onSave(mapInternalAdvertisementToApiInput(brandId)(formData));
  };

  const { isDirty, isSubmitting } = InternalAdvertisementForm.formState;

  return (
    <Form className="w-100" onSubmit={InternalAdvertisementForm.handleSubmit(handleSubmit)}>
      <div className="topbar d-sm-flex justify-content-sm-between">
        <div className="col-heading">
          <Link className="btn btn-back" to={linkBack}></Link>
          <Box ml={5} width={'50%'}>
            <Controller
              control={InternalAdvertisementForm.control}
              name="name"
              render={({ field, fieldState }) => (
                <ScutiInput placeholder="Advertisement name" {...field} error={fieldState.error?.message} />
              )}
            />
          </Box>
        </div>
        <div className="col-action">
          <Button color="outline-secondary" onClick={() => InternalAdvertisementForm.reset()} disabled={!isDirty}>
            Cancel
          </Button>
          <Button color="primary" type="submit" disabled={isSubmitting}>
            Save changes
          </Button>
        </div>
      </div>
      <Box pb={2}>
        <Grid container spacing={4}>
          <Grid item container spacing={4}>
            <Grid item xs={12} md={6} lg={4}>
              <InternalAdvertisementDetails form={InternalAdvertisementForm} />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Form>
  );
};
