import { AdvertisementType, NewAdvertisementInput, UpdateAdvertisementInput } from 'types/__generated__/types';
import * as Yup from 'yup';

export const leadGenAdvertisementSchema = Yup.object().shape({
  id: Yup.string(),
  name: Yup.string().required('Field is required'),
  affiliateUrl: Yup.string().url('Url is not correct').required('Field is required'),
  category: Yup.string().required('Field is required'),
  cpa: Yup.number()
    .min(0, 'Cannot be less than 0')
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable(),
});

export type LeadGenAdvertisementFormData = Yup.InferType<typeof leadGenAdvertisementSchema>;

export const leadGenAdvertisementSchemaFormDataDefaults = (): LeadGenAdvertisementFormData => ({
  name: '',
  category: '',
  affiliateUrl: '',
  cpa: undefined,
});

export const mapLeadGenAdvertisementToApiInput =
  (brandId: string) =>
  (formData: LeadGenAdvertisementFormData): UpdateAdvertisementInput | NewAdvertisementInput => {
    return {
      brandId,
      type: AdvertisementType.LeadGen,
      id: formData.id,
      name: formData.name,
      category: formData.category,
      cpa: formData.cpa || 0,
      leadGen: {
        affiliateUrl: formData.affiliateUrl,
      },
    };
  };
