import { AdvertisementType, NewAdvertisementInput, UpdateAdvertisementInput } from 'types/__generated__/types';
import * as Yup from 'yup';

export const appDownloadAdvertisementSchema = Yup.object()
  .shape({
    id: Yup.string(),
    name: Yup.string().required(),
    category: Yup.string().required(),
    cpa: Yup.number()
      .min(0, 'Cannot be less than 0')
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable(),
    androidId: Yup.string().nullable(),
    appleId: Yup.number().nullable(),
    pcLink: Yup.string().url().nullable(),
  })
  .test('at-least-one-required', '', function (values) {
    const { androidId, appleId, pcLink } = values;
    if (!androidId && !appleId && !pcLink) {
      return this.createError({
        message: 'At least one of androidId, appleId, or pcLink is required',
        path: 'androidId',
      });
    }
    return true;
  });

export type AppDownloadAdvertisementFormData = Yup.InferType<typeof appDownloadAdvertisementSchema>;

export const appDownloadAdvertisementFormDataDefaults = (): AppDownloadAdvertisementFormData => ({
  name: '',
  cpa: undefined,
  category: '',
  androidId: '',
  appleId: null,
  pcLink: '',
});

export const mapAdvertisementAppDownloadToApiInput =
  (brandId: string) =>
  (formData: AppDownloadAdvertisementFormData): UpdateAdvertisementInput | NewAdvertisementInput => {
    return {
      brandId,
      id: formData.id,
      type: AdvertisementType.AppDownload,
      name: formData.name,
      cpa: formData.cpa || 0,
      category: formData.category,
      appDownload: {
        androidId: formData.androidId,
        appleId: formData.appleId,
        pcLink: formData.pcLink,
      },
    };
  };
