import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { CampaignStatus } from 'types/__generated__/types';
import moment from 'moment';

interface Props {
  product: {
    id: string;
    name: string;
    images?: string[] | null;
    defaultImage?: string | null;
    variants: {
      price: {
        amount: number;
        currency: string;
      };
      inStock: number;
    }[];
    productListings:
      | {
          id: string;
          status: CampaignStatus;
          durationStartDate?: Date;
          durationEndDate?: Date;
        }[]
      | undefined;
  };
  url: string;
}

const priceLabel = (minPrice: number | null, maxPrice: number | null): string => {
  if (minPrice === maxPrice) return minPrice ? `$${minPrice}` : '';
  if (minPrice && maxPrice) return `$${minPrice}-$${maxPrice}`;
  return `$${minPrice || maxPrice}`;
};

const getProductQuantity = (
  variants: Array<{ inStock: number; price: { amount: number; currency: string } }>,
): number => {
  return variants.reduce((prevVal, product) => prevVal + product.inStock, 0);
};

const getLabelColor = (variants: Array<{ inStock: number; price: { amount: number; currency: string } }>): string => {
  if (getProductQuantity(variants) < 10) return 'label-danger';

  variants?.length > 0 &&
    variants.map((variant) => {
      if (variant.inStock <= 0) return 'label-warn';
      if (variant.inStock < 10) return '';
      return '';
    });
  return 'label-success';
};

const getStatus = (
  productListing: Array<{ id: string; status: CampaignStatus; durationStartDate?: Date; durationEndDate?: Date }>,
): string => {
  let status = '';
  productListing?.length > 0 &&
    // better use a for loop so we can exit quicker
    productListing.map((pl) => {
      if (
        (!pl.durationStartDate || moment(pl.durationStartDate).isSameOrBefore()) &&
        (!pl.durationEndDate || moment(pl.durationEndDate).isAfter())
      ) {
        switch (pl.status) {
          case CampaignStatus.Active:
            status = 'A';
            break;
          case CampaignStatus.Paused:
            status = 'P';
            break;
          default:
            status = '';
            break;
        }
      }
      return '';
    });
  return status;
};

export const ProductGridItem: React.FC<Props> = ({ url, product }) => {
  // Check for default image before selecting the first image if images are available
  const img = product.defaultImage ? product.defaultImage : product.images && product.images[0];
  const max = _.maxBy(product.variants, (v) => v.price.amount) || { price: { amount: 0 } };
  const min = _.minBy(product.variants, (v) => v.price.amount) || { price: { amount: 0 } };

  return (
    <div className="product-grid-item">
      <Link className="product-grid-link" to={url}>
        <div className="product-grid-pic">{img ? <img src={img || ''} alt="" /> : <span className="no-image" />}</div>
        <div className="product-grid-body">
          <strong className="product-grid-name">{product.name}</strong>
          <div className="product-grid-labels">
            <span className="product-grid-label">{priceLabel(min.price.amount, max.price.amount)}</span>
            <span className={`product-grid-label ${getLabelColor(product.variants)}`}>
              {getProductQuantity(product.variants)}
            </span>
            {product.productListings && product.productListings.length > 0 && (
              <span className="product-grid-label label-status">{getStatus(product.productListings)}</span>
            )}
          </div>
        </div>
      </Link>
    </div>
  );
};
