// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ScutiImgInput_scuti_img_input__eKT6Q {
  border: 1px solid #53555b;
  border-radius: 4px;
}

.ScutiImgInput_scuti_img_input_wrapper__5LzVG {
  cursor: pointer;
}

.ScutiImgInput_scuti_img_input_wrapper_disabled__z5wsm {
  opacity: 0.5;
}

.ScutiImgInput_scuti_img_input_error__-sPaB {
  border: 1px solid #f86c6b;
}

.ScutiImgInput_file_input__62M3Q {
  visibility: hidden;
  height: 100%;
  width: 100%;
}

.ScutiImgInput_new_img_icon__c23G1 {
  width: 10rem !important;
  height: 10rem !important;
  left: 50%;
  top: 50%;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/material/ScutiMultipleImgInput/ScutiImgInput.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,kBAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,kBAAA;EACA,YAAA;EACA,WAAA;AACF;;AAEA;EACE,uBAAA;EACA,wBAAA;EACA,SAAA;EACA,QAAA;EACA,eAAA;AACF","sourcesContent":[".scuti_img_input {\n  border: 1px solid #53555b;\n  border-radius: 4px;\n}\n\n.scuti_img_input_wrapper {\n  cursor: pointer;\n}\n\n.scuti_img_input_wrapper_disabled {\n  opacity: 0.5;\n}\n\n.scuti_img_input_error {\n  border: 1px solid #f86c6b;\n}\n\n.file_input {\n  visibility: hidden;\n  height: 100%;\n  width: 100%;\n}\n\n.new_img_icon {\n  width: 10rem !important;\n  height: 10rem !important;\n  left: 50%;\n  top: 50%;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scuti_img_input": `ScutiImgInput_scuti_img_input__eKT6Q`,
	"scuti_img_input_wrapper": `ScutiImgInput_scuti_img_input_wrapper__5LzVG`,
	"scuti_img_input_wrapper_disabled": `ScutiImgInput_scuti_img_input_wrapper_disabled__z5wsm`,
	"scuti_img_input_error": `ScutiImgInput_scuti_img_input_error__-sPaB`,
	"file_input": `ScutiImgInput_file_input__62M3Q`,
	"new_img_icon": `ScutiImgInput_new_img_icon__c23G1`
};
export default ___CSS_LOADER_EXPORT___;
