import { API_URI } from '../config';
import { readAccessTokenFromStorage } from 'utils/token-storage.utils';

export const getExportAllToCsvUrl = (shopId: string) => {
  const token = readAccessTokenFromStorage();
  return `${API_URI}/export/orders?shop_id=${shopId}&access_token=${token}`;
};

export const getExportAllEventsToCsvUrl = () => {
  const token = readAccessTokenFromStorage();
  return `${API_URI}/export/all-events?access_token=${token}`;
};
