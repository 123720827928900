import React from 'react';
import { PrivateRoute } from 'components/PrivateRoute';
import { OrganizationsListPage } from './OrganizationsListPage';
import { OrganizationEditPage } from './OrganizationEditPage';
import { Switch } from 'react-router';
import { organizationsRoutes } from 'routing';
import { useAppStore } from 'store/app-store.hook';
import { OrganizationOverviewPage } from './OrganizationOverviewPage';

export const OrganizationsRoutes: React.FC = () => {
  const { permissions } = useAppStore();
  return (
    <Switch>
      {permissions.organizationsAccess.view && (
        <PrivateRoute exact path={organizationsRoutes.ORGANIZATIONS} component={OrganizationsListPage} />
      )}
      {permissions.organizationsAccess.view && (
        <PrivateRoute exact path={organizationsRoutes.ORGANIZATION_OVERVIEW()} component={OrganizationOverviewPage} />
      )}
      {permissions.organizationsAccess.edit && (
        <PrivateRoute exact path={organizationsRoutes.ORGANIZATION_NEW} component={OrganizationEditPage} />
      )}
      {permissions.organizationsAccess.edit && (
        <PrivateRoute exact path={organizationsRoutes.ORGANIZATION_EDIT()} component={OrganizationEditPage} />
      )}
    </Switch>
  );
};
