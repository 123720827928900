import React from 'react';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import { Card, FormGroup, CustomInput } from 'reactstrap';
import moment from 'moment';
import { ProductListingFormData } from './ProductListingEditForm.utils';
import { Box, CardHeader, CardContent } from '@material-ui/core';
import { ScutiInput } from 'components/material/ScutiInput';
import { Controller, UseFormReturn } from 'react-hook-form';

interface Props {
  form: UseFormReturn<ProductListingFormData>;
  paused?: boolean;
}

export function DurationDetails({ form, paused }: Props) {
  const values = form.watch();

  const [focusedInput, setFocusedInput] = React.useState<FocusedInputShape | null>(null);
  const [timeSpecific, setTimeSpecific] = React.useState(!!values.duration.start || !!values.duration.end);
  const [inventorySpecific, setInventorySpecific] = React.useState(!!values.duration.inventory);

  const toggleTime = () => {
    setTimeSpecific(!timeSpecific);
    form.setValue('duration', { ...values.duration, start: null, end: null });
  };

  const toggleInventory = () => {
    setInventorySpecific(!inventorySpecific);
    form.setValue('duration.inventory', 0);
  };

  const onDatesChange = React.useCallback((v: { startDate: moment.Moment | null; endDate: moment.Moment | null }) => {
    form.setValue('duration', { start: v.startDate && v.startDate.toDate(), end: v.endDate && v.endDate.toDate() });
    form.trigger('duration');
  }, []);

  const onRunStockZeroChange = React.useCallback(() => {
    form.setValue(`duration.runUntilStockZero`, !values.duration.runUntilStockZero);
  }, [values.duration.runUntilStockZero]);

  console.log('values.duration.runUntilStockZero', values.duration.runUntilStockZero);

  return (
    <Card className="bg-custom">
      <CardHeader title="Duration" />
      <CardContent>
        <FormGroup className="pt-6">
          <label htmlFor={`time-box`}>
            <CustomInput
              id="time-box"
              type="checkbox"
              label="Date specific"
              onChange={toggleTime}
              checked={timeSpecific}
              disabled={!paused}
            />
          </label>
          <DateRangePicker
            disabled={!timeSpecific || !paused}
            startDateId="start"
            endDateId="end"
            startDate={values.duration.start ? moment(values.duration.start) : null}
            endDate={values.duration.end ? moment(values.duration.end) : null}
            onDatesChange={onDatesChange}
            focusedInput={focusedInput}
            onFocusChange={setFocusedInput}
          />
        </FormGroup>
        <Box>
          <label htmlFor={`box-inventory`}>
            <CustomInput
              id="box-inventory"
              type="checkbox"
              label="Inventory specific"
              onChange={toggleInventory}
              checked={inventorySpecific}
              disabled={!paused}
            />
          </label>
        </Box>
        <Box>
          <Controller
            control={form.control}
            name="duration.inventory"
            render={({ field, fieldState }) => (
              <ScutiInput
                placeholder="How much inventory do you have?"
                {...field}
                inputProps={{ min: 0 }}
                error={fieldState.error?.message}
                disabled={!inventorySpecific || !paused}
              />
            )}
          />
        </Box>
        <Box mt={2}>
          <label htmlFor={`box-runUntilStockZero`}>
            <CustomInput
              id="box-runUntilStockZero"
              type="checkbox"
              label="Run listing until stock is low."
              onChange={onRunStockZeroChange}
              checked={!!values.duration.runUntilStockZero}
              disabled={!paused}
            />
          </label>
        </Box>
      </CardContent>
    </Card>
  );
}
