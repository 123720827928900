import React from 'react';
import { PrivateRoute } from 'components/PrivateRoute';
import { Switch } from 'react-router-dom';
import { productsRoutes } from 'routing';
import { useAppStore } from 'store/app-store.hook';
import { observer } from 'mobx-react-lite';
import { ProductsListPage } from './ProductsListPage';
import { ProductOverviewPage } from './ProductOverviewPage';
import { ProductEditPage } from './ProductEditPage';

export const ProductsRoutes: React.FC = observer(() => {
  const { permissions } = useAppStore();
  return (
    <Switch>
      {permissions.productsAccess.view && (
        <PrivateRoute exact path={productsRoutes.PRODUCTS} component={ProductsListPage} />
      )}
      {permissions.productsAccess.view && (
        <PrivateRoute exact path={productsRoutes.PRODUCT_OVERVIEW()} component={ProductOverviewPage} />
      )}
      {permissions.productsAccess.edit && (
        <PrivateRoute exact path={productsRoutes.PRODUCT_EDIT()} component={ProductEditPage} />
      )}
      {permissions.productsAccess.edit && (
        <PrivateRoute exact path={productsRoutes.PRODUCT_NEW} component={ProductEditPage} />
      )}
    </Switch>
  );
});
