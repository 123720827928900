import React from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { useParams } from 'react-router';
import { ProductOverview } from './ProductOverview';
import { ProductVariantsViewTable } from './ProductVariantsViewTable';
import { productsRoutes } from 'routing';
import { useProductOverviewQuery } from './__generated__/product-overview-page.hooks';
import { Loader } from 'components/Loader';
import { Box } from '@material-ui/core';

export const ProductOverviewPage: React.FC = observer(() => {
  const { productId } = useParams<{ productId: string }>();

  const { data, isLoading } = useProductOverviewQuery({ id: productId });

  const product = !isLoading ? data?.product : undefined;

  return (
    <div className="animated fadeIn">
      <div className="topbar d-sm-flex justify-content-sm-between">
        <div className="col-heading">
          <Link className="btn btn-back" to={productsRoutes.PRODUCTS}></Link>
          <h1 className="page-title">Back to products</h1>
        </div>
        <div className="col-action">
          <Link to={productsRoutes.PRODUCT_EDIT(productId)}>
            <Button color="outline-secondary">Edit</Button>
          </Link>
        </div>
      </div>
      {isLoading && <Loader />}
      {product && <ProductOverview product={product} />}
      {product && (
        <Box mt={2}>
          <ProductVariantsViewTable product={product} />
        </Box>
      )}
    </div>
  );
});
