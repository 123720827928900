import React from 'react';
import { PrivateRoute } from 'components/PrivateRoute';
import { BrandsListPage } from './BrandsListPage';
import { BrandEditPage } from './BrandEditPage';
import { Switch } from 'react-router';
import { brandsRoutes } from 'routing';
import { useAppStore } from 'store/app-store.hook';

export const BrandsRoutes: React.FC = () => {
  const { permissions } = useAppStore();
  return (
    <Switch>
      {permissions.shopsAccess.view && <PrivateRoute exact path={brandsRoutes.BRANDS} component={BrandsListPage} />}
      {permissions.shopsAccess.view && <PrivateRoute exact path={brandsRoutes.BRAND_NEW} component={BrandEditPage} />}
      {permissions.shopsAccess.view && (
        <PrivateRoute exact path={brandsRoutes.BRAND_EDIT()} component={BrandEditPage} />
      )}
    </Switch>
  );
};
