import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { InfoPopup } from 'components/InfoPopup';
import { Box, Grid } from '@material-ui/core';
import { TopNavigation } from 'components/material/TopNavigation';
import { PaginationLayout } from 'layouts/PaginationLayout';
import { Filter_Type } from 'types/__generated__/types';
import { useDebounce } from 'use-debounce/lib';
import { usePaging } from 'hooks/usePaging';
import { useTable, useSortBy } from 'react-table';
import { ScutiInput } from 'components/material/ScutiInput';
import { useShopsTableColumns } from './useShopsTableColumns';
import { useSorting } from 'hooks/useSorting';
import { ScutiTable } from 'components/ScutiTable';
import { defaultSorting } from 'types/app-types';
import { ShopListItemFragment, useDeleteShopMutation, useShopsQuery } from 'graphql/__generated__/shop.hooks';

export const ShopsListPage: React.FC = observer(() => {
  const paging = usePaging();
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [searchInput, setSearchInput] = useState<string>('');
  const [inputValue] = useDebounce(searchInput, 500);

  const useDeleteShop = useDeleteShopMutation();
  const onArchive = useCallback(() => {
    if (deleteId) useDeleteShop.mutateAsync({ id: deleteId });
    setDeleteId(null);
  }, [deleteId, useDeleteShop]);
  const onCancel = useCallback(() => setDeleteId(null), []);
  const [shops, setShops] = useState<ShopListItemFragment[]>([]);
  const columns = useShopsTableColumns(setDeleteId);
  const tableInstance = useTable<ShopListItemFragment>({ columns, data: shops, manualSortBy: true }, useSortBy);
  const sorting = useSorting(tableInstance.state.sortBy, defaultSorting);

  const useShopItems = useShopsQuery(
    {
      paging,
      filters: inputValue
        ? [
            {
              name: 'search',
              operator: Filter_Type.Like,
              value: [inputValue],
            },
          ]
        : undefined,
      sorting,
    },
    {
      keepPreviousData: true,
      onSuccess: ({ shops }) => setShops(shops.nodes),
    },
  );

  const isLoading = useShopItems.isLoading;
  const pagingResponse = useShopItems.data?.shops.paging;
  const showShops = !!shops.length && !isLoading;

  return (
    <PaginationLayout
      isLoading={isLoading}
      paging={pagingResponse}
      header={
        <TopNavigation title="Shop Management">
          <Grid container justifyContent="flex-end">
            <Grid item md={6}>
              <ScutiInput
                placeholder="Search"
                value={searchInput}
                onChange={({ target }) => setSearchInput(target.value)}
              />
            </Grid>
          </Grid>
        </TopNavigation>
      }
      content={
        showShops && (
          <Box>
            {!!deleteId && (
              <InfoPopup
                title="Archive shop?"
                description="This shop will be archived. You’ll be able to access them under your archived shops."
                proceedText="Archive"
                cancelText="Cancel"
                onProceed={onArchive}
                onCancel={onCancel}
              />
            )}
            {shops && <ScutiTable table={tableInstance} />}
          </Box>
        )
      }
    />
  );
});
