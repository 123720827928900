import { Box, Card, CardHeader, CardContent } from '@material-ui/core';
import { CircularProgress, Link } from '@mui/material';
import { Table } from 'reactstrap';
import noPicUrl from 'assets/svgs/no-pic-sm.svg';
import { NoData } from 'components/material/NoData';

interface Props {
  title: string;
  items: { id: string; href: string; image?: string; name: string; count: number }[];
  isLoading?: boolean;
}

export const TopFive: React.FC<Props> = ({ title, items, isLoading }) => {
  return (
    <Card>
      <CardHeader title={title} />
      <CardContent>
        <Box display="flex" width="100%" flexDirection="column" height={340}>
          {isLoading && (
            <Box display="flex" width="100%" height="100%" alignItems="center" justifyContent="center">
              <CircularProgress color="inherit" />
            </Box>
          )}
          {!isLoading && !items.length && <NoData />}
          {!isLoading && !!items.length && (
            <Table className="fixed-layout campaigns-table" responsive striped hover>
              <tbody>
                {items.map(({ id, image, name, count, href }) => {
                  return (
                    <tr key={`campaign_${id}`}>
                      <td className="col-promoted-item" data-label="Title: ">
                        <Link href={href}>
                          <div className="variant-pic">
                            <img src={image || noPicUrl} alt="Product Pic" />
                          </div>
                        </Link>
                      </td>
                      <td className="col-promoted-item" data-label="Type: " style={{ verticalAlign: 'middle' }}>
                        <div className="promoted-link">{name}</div>
                      </td>
                      <td className="col-promoted-item" data-label="Type: ">
                        <div className="promoted-link">{count}</div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};
