import { Card, CardHeader, CardContent, Grid } from '@material-ui/core';
import { Badge } from 'reactstrap';
import { GameDetailsFragment } from './__generated__/game-overview-page.hooks';

interface Props {
  game: GameDetailsFragment;
}

export const GameOverview: React.FC<Props> = ({ game }) => {
  const { name, thumbnail, category, banner, tags } = game;
  return (
    <Card>
      <CardHeader title={name} />
      <CardContent>
        <Grid container spacing={6}>
          <Grid item xs={4}>
            <div className="c-white mb-10">Thumbnail</div>
            {thumbnail ? <img src={thumbnail} alt={thumbnail} /> : <span className="no-game-img" />}
          </Grid>
          <Grid item xs={8}>
            <div className="c-white mb-10">Promotion Banner</div>
            {banner ? <img width="100%" src={banner} alt={banner} /> : <span className="no-banner-img" />}
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item xs={4}>
            <em className="d-block c-white">Category</em>
            <strong className="fs-18 c-white">{category}</strong>
          </Grid>
          <Grid item xs={8}>
            <div className="c-white mb-10">Attributes</div>
            {tags.map((attribute, index) => {
              return (
                <Badge key={`game_attr_${index}`} color="success">
                  {attribute}
                </Badge>
              );
            })}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
