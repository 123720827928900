import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

interface Props {
  title?: string;
}

export const TopNavigation: React.FC<Props> = ({ title, children }) => {
  return (
    <Box>
      <Grid container>
        <Grid item md={4} sm={6} xs={12}>
          <Typography variant="h5">
            <Box fontWeight="bold">{title}</Box>
          </Typography>
        </Grid>
        <Grid item md={8} sm={6} xs={12}>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
};
