import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { ScutiLogo } from 'components/svg-icons/ScutiLogo';

export const NoData = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%" height="100%">
      <ScutiLogo />
      <Box mt={2}>
        <Typography variant="h5">There is no data yet</Typography>
      </Box>
    </Box>
  );
};
