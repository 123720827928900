import React from 'react';

import { Card, CardBody } from 'reactstrap';
import { CheckEmailIcon } from 'components/svg-icons/CheckEmail';
import { Box } from '@material-ui/core';
import { scutiColors } from 'material-theme/variables';

export const EmailVerifiedPage: React.FC = () => {
  return (
    <Card className="d-flex flex-row align-items-center mh-315">
      <CardBody>
        <div className="text-center">
          <div className="icon mb-med">
            <CheckEmailIcon className="icon" />
          </div>
          <div className="card-headline">
            <Box color={scutiColors.white} pl={5} pr={5} mt={4} mb={4} textAlign="left" fontSize={14}>
              <h1 className={'mb-4'}>CONGRATULATIONS!</h1>
              <Box component="p">Your email has been verified!</Box>
              <Box component="p">
                Welcome to Scuti... the Gamer's Marketplace.
                <br />
                Scuti is the place where gamers shop
              </Box>
              <Box component="p">
                <strong>DISCOVER</strong> curated products and deals
              </Box>
              <Box component="p">
                <strong>EARN</strong> Scuti$ rewards every time your shop
              </Box>
              <Box component="p">
                <strong>COLLECT</strong> more Scuti$ every day you visit
              </Box>
              <Box component="p">
                <strong>REDEEM</strong> your Scuti$ for in-game items
              </Box>
              <Box component="p">
                <strong>ACCESS</strong> Scuti from any connected game
              </Box>
              <Box component="p">
                <strong>SPEND</strong> your Scuti$ in any game
              </Box>
              <Box component="p">
                <strong>GUARANTEED</strong> price parity on all products
              </Box>
              <Box component="p">
                <strong>SHIPPING</strong> is direct from each vendor
              </Box>
            </Box>
            <Box component="h1" textAlign="center" color={scutiColors.white}>
              GAME ON!
            </Box>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
