import * as Types from '../../types/__generated__/types';

import { ApolloError } from 'apollo-client';
import { useQuery, UseQueryOptions, useMutation, UseMutationOptions } from 'react-query';
import { fetcher } from 'api';
export type ShopListItemFragment = {
  id: string;
  name: string;
  description?: Types.Maybe<string>;
  shopifyName?: Types.Maybe<string>;
  thumbnail?: Types.Maybe<string>;
  admins: Array<{ id: string; email: string; roles: Array<Types.Role>; fullName?: Types.Maybe<string> }>;
};

export type ShopFragment = {
  id: string;
  name: string;
  thumbnail?: Types.Maybe<string>;
  shopifyName?: Types.Maybe<string>;
  description?: Types.Maybe<string>;
  flatShippingRate: number;
  freeShippingThreshold?: Types.Maybe<number>;
  country?: Types.Maybe<string>;
  referralFee: number;
  returnPolicy?: Types.Maybe<string>;
  returnPolicyUrl?: Types.Maybe<string>;
  shopifyEnabled: boolean;
  rewardsPercentage?: Types.Maybe<number>;
  rewardsIncluded: boolean;
  mediaInvestmentEnabled: boolean;
  owner: { id: string; email: string; fullName?: Types.Maybe<string> };
  admins: Array<{ id: string; email: string; fullName?: Types.Maybe<string>; roles: Array<Types.Role> }>;
  shippings?: Types.Maybe<
    Array<{ id: string; amount: number; freeShippingThreshold?: Types.Maybe<number>; country: string }>
  >;
  brands?: Types.Maybe<Array<{ id: string; name: string; imageUrl?: Types.Maybe<string> }>>;
  directResponse?: Types.Maybe<{ unit: string; amount: number }>;
  engagement?: Types.Maybe<{ unit: string; amount: number }>;
  video?: Types.Maybe<{ unit: string; amount: number }>;
};

export type ShopBrandFragment = { id: string; name: string };

export type ShopsQueryVariables = Types.Exact<{
  paging?: Types.Maybe<Types.PagingInput>;
  filters?: Types.Maybe<Array<Types.FilterInput> | Types.FilterInput>;
  sorting?: Types.Maybe<Array<Types.SortFieldInput> | Types.SortFieldInput>;
}>;

export type ShopsQuery = {
  shops: {
    paging: { offset: number; limit: number; totalCount: number };
    sorting?: Types.Maybe<Array<{ name: string; dir: string }>>;
    nodes: Array<ShopListItemFragment>;
  };
};

export type ShopQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type ShopQuery = { shop?: Types.Maybe<ShopFragment> };

export type DeleteShopMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type DeleteShopMutation = { deleteShop?: Types.Maybe<string> };

export type UpdateShopMutationVariables = Types.Exact<{
  input: Types.UpdateShopInput;
}>;

export type UpdateShopMutation = { updateShop?: Types.Maybe<{ id: string }> };

export type CreateShopMutationVariables = Types.Exact<{
  input: Types.ShopInput;
}>;

export type CreateShopMutation = { createShop: { id: string } };

export type ShopInviteFragment = {
  id: string;
  createdAt: any;
  updatedAt: any;
  email: string;
  roles: Array<Types.Role>;
  entityId?: Types.Maybe<string>;
  entityType?: Types.Maybe<string>;
  accepted: boolean;
  hide: boolean;
};

export type ShopInvitesQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
  paging?: Types.Maybe<Types.PagingInput>;
  sorting?: Types.Maybe<Array<Types.SortFieldInput> | Types.SortFieldInput>;
  filters?: Types.Maybe<Array<Types.FilterInput> | Types.FilterInput>;
}>;

export type ShopInvitesQuery = {
  shopInvites: { paging: { offset: number; limit: number; totalCount: number }; nodes: Array<ShopInviteFragment> };
};

export type InviteToShopMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
  user: Types.UserRoleInput;
}>;

export type InviteToShopMutation = { inviteToShop: Array<Types.Role> };

export type RemoveFromShopMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
  shopId: Types.Scalars['String'];
}>;

export type RemoveFromShopMutation = { removeFromShop: boolean };

export type ResendInviteMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
}>;

export type ResendInviteMutation = { resendInviteMail: boolean };

export type ShopBrandsQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type ShopBrandsQuery = { shopBrands: Array<ShopBrandFragment> };

export type EditShopBrandsMutationVariables = Types.Exact<{
  input: Types.EditShopBrandsInput;
}>;

export type EditShopBrandsMutation = { editShopBrands: Array<ShopBrandFragment> };

export const ShopListItemFragmentDoc = `
    fragment ShopListItem on Shop {
  id
  name
  description
  shopifyName
  thumbnail
  admins {
    id
    email
    roles
    fullName
  }
}
    `;
export const ShopFragmentDoc = `
    fragment Shop on Shop {
  id
  name
  thumbnail
  shopifyName
  description
  owner {
    id
    email
    fullName
  }
  admins {
    id
    email
    fullName
    roles
  }
  flatShippingRate
  freeShippingThreshold
  country
  referralFee
  returnPolicy
  returnPolicyUrl
  shippings {
    id
    amount
    freeShippingThreshold
    country
  }
  brands {
    id
    name
    imageUrl
  }
  shopifyEnabled
  rewardsPercentage
  rewardsIncluded
  mediaInvestmentEnabled
  directResponse {
    unit
    amount
  }
  engagement {
    unit
    amount
  }
  video {
    unit
    amount
  }
}
    `;
export const ShopBrandFragmentDoc = `
    fragment ShopBrand on ShopBrand {
  id
  name
}
    `;
export const ShopInviteFragmentDoc = `
    fragment ShopInvite on Invite {
  id
  createdAt
  updatedAt
  email
  roles
  entityId
  entityType
  accepted
  hide
}
    `;
export const ShopsDocument = `
    query Shops($paging: PagingInput, $filters: [FilterInput!], $sorting: [SortFieldInput!]) {
  shops(paging: $paging, filters: $filters, sorting: $sorting) {
    paging {
      offset
      limit
      totalCount
    }
    sorting {
      name
      dir
    }
    nodes {
      ...ShopListItem
    }
  }
}
    ${ShopListItemFragmentDoc}`;
export const useShopsQuery = <TData = ShopsQuery, TError = ApolloError>(
  variables?: ShopsQueryVariables,
  options?: UseQueryOptions<ShopsQuery, TError, TData>,
) =>
  useQuery<ShopsQuery, TError, TData>(
    ['Shops', variables],
    fetcher<ShopsQuery, ShopsQueryVariables>(ShopsDocument, variables),
    options,
  );
export const ShopDocument = `
    query Shop($id: String!) {
  shop(id: $id) {
    ...Shop
  }
}
    ${ShopFragmentDoc}`;
export const useShopQuery = <TData = ShopQuery, TError = ApolloError>(
  variables: ShopQueryVariables,
  options?: UseQueryOptions<ShopQuery, TError, TData>,
) =>
  useQuery<ShopQuery, TError, TData>(
    ['Shop', variables],
    fetcher<ShopQuery, ShopQueryVariables>(ShopDocument, variables),
    options,
  );
export const DeleteShopDocument = `
    mutation DeleteShop($id: String!) {
  deleteShop(id: $id)
}
    `;
export const useDeleteShopMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<DeleteShopMutation, TError, DeleteShopMutationVariables, TContext>,
) =>
  useMutation<DeleteShopMutation, TError, DeleteShopMutationVariables, TContext>(
    (variables?: DeleteShopMutationVariables) =>
      fetcher<DeleteShopMutation, DeleteShopMutationVariables>(DeleteShopDocument, variables)(),
    options,
  );
export const UpdateShopDocument = `
    mutation UpdateShop($input: UpdateShopInput!) {
  updateShop(input: $input) {
    id
  }
}
    `;
export const useUpdateShopMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<UpdateShopMutation, TError, UpdateShopMutationVariables, TContext>,
) =>
  useMutation<UpdateShopMutation, TError, UpdateShopMutationVariables, TContext>(
    (variables?: UpdateShopMutationVariables) =>
      fetcher<UpdateShopMutation, UpdateShopMutationVariables>(UpdateShopDocument, variables)(),
    options,
  );
export const CreateShopDocument = `
    mutation CreateShop($input: ShopInput!) {
  createShop(input: $input) {
    id
  }
}
    `;
export const useCreateShopMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<CreateShopMutation, TError, CreateShopMutationVariables, TContext>,
) =>
  useMutation<CreateShopMutation, TError, CreateShopMutationVariables, TContext>(
    (variables?: CreateShopMutationVariables) =>
      fetcher<CreateShopMutation, CreateShopMutationVariables>(CreateShopDocument, variables)(),
    options,
  );
export const ShopInvitesDocument = `
    query ShopInvites($id: String!, $paging: PagingInput, $sorting: [SortFieldInput!], $filters: [FilterInput!]) {
  shopInvites(id: $id, paging: $paging, sorting: $sorting, filters: $filters) {
    paging {
      offset
      limit
      totalCount
    }
    nodes {
      ...ShopInvite
    }
  }
}
    ${ShopInviteFragmentDoc}`;
export const useShopInvitesQuery = <TData = ShopInvitesQuery, TError = ApolloError>(
  variables: ShopInvitesQueryVariables,
  options?: UseQueryOptions<ShopInvitesQuery, TError, TData>,
) =>
  useQuery<ShopInvitesQuery, TError, TData>(
    ['ShopInvites', variables],
    fetcher<ShopInvitesQuery, ShopInvitesQueryVariables>(ShopInvitesDocument, variables),
    options,
  );
export const InviteToShopDocument = `
    mutation InviteToShop($id: String!, $user: UserRoleInput!) {
  inviteToShop(id: $id, user: $user)
}
    `;
export const useInviteToShopMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<InviteToShopMutation, TError, InviteToShopMutationVariables, TContext>,
) =>
  useMutation<InviteToShopMutation, TError, InviteToShopMutationVariables, TContext>(
    (variables?: InviteToShopMutationVariables) =>
      fetcher<InviteToShopMutation, InviteToShopMutationVariables>(InviteToShopDocument, variables)(),
    options,
  );
export const RemoveFromShopDocument = `
    mutation RemoveFromShop($email: String!, $shopId: String!) {
  removeFromShop(email: $email, shopId: $shopId)
}
    `;
export const useRemoveFromShopMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<RemoveFromShopMutation, TError, RemoveFromShopMutationVariables, TContext>,
) =>
  useMutation<RemoveFromShopMutation, TError, RemoveFromShopMutationVariables, TContext>(
    (variables?: RemoveFromShopMutationVariables) =>
      fetcher<RemoveFromShopMutation, RemoveFromShopMutationVariables>(RemoveFromShopDocument, variables)(),
    options,
  );
export const ResendInviteDocument = `
    mutation ResendInvite($email: String!) {
  resendInviteMail(email: $email)
}
    `;
export const useResendInviteMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<ResendInviteMutation, TError, ResendInviteMutationVariables, TContext>,
) =>
  useMutation<ResendInviteMutation, TError, ResendInviteMutationVariables, TContext>(
    (variables?: ResendInviteMutationVariables) =>
      fetcher<ResendInviteMutation, ResendInviteMutationVariables>(ResendInviteDocument, variables)(),
    options,
  );
export const ShopBrandsDocument = `
    query ShopBrands($id: String!) {
  shopBrands(id: $id) {
    ...ShopBrand
  }
}
    ${ShopBrandFragmentDoc}`;
export const useShopBrandsQuery = <TData = ShopBrandsQuery, TError = ApolloError>(
  variables: ShopBrandsQueryVariables,
  options?: UseQueryOptions<ShopBrandsQuery, TError, TData>,
) =>
  useQuery<ShopBrandsQuery, TError, TData>(
    ['ShopBrands', variables],
    fetcher<ShopBrandsQuery, ShopBrandsQueryVariables>(ShopBrandsDocument, variables),
    options,
  );
export const EditShopBrandsDocument = `
    mutation EditShopBrands($input: EditShopBrandsInput!) {
  editShopBrands(input: $input) {
    ...ShopBrand
  }
}
    ${ShopBrandFragmentDoc}`;
export const useEditShopBrandsMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<EditShopBrandsMutation, TError, EditShopBrandsMutationVariables, TContext>,
) =>
  useMutation<EditShopBrandsMutation, TError, EditShopBrandsMutationVariables, TContext>(
    (variables?: EditShopBrandsMutationVariables) =>
      fetcher<EditShopBrandsMutation, EditShopBrandsMutationVariables>(EditShopBrandsDocument, variables)(),
    options,
  );
