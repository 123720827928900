import React from 'react';
import noPicUrl from 'assets/svgs/no-pic-sm.svg';
import { ProductOverviewQuery } from '../__generated__/product-overview-page.hooks';

interface Props {
  variant: ProductOverviewQuery['product']['variants'][0];
  addClass?: string;
}

export const ProductDisplayRow: React.FC<Props> = ({ variant, addClass }) => {
  return (
    <tr className={`variant-row ${addClass || ''}`}>
      <td data-label="SKU :">{variant.sku}</td>
      <td>
        <div className="variant-pic">
          <span className={variant.image ? 'image-frame' : ''}>
            <img src={variant.image || noPicUrl} alt={variant.sku} />
          </span>
        </div>
      </td>
      <td data-label="Option 1 :">{variant.option1}</td>
      <td data-label="Option 2 :">{variant.option2}</td>
      <td data-label="Option 3 :">{variant.option3}</td>
      <td data-label="Price :">
        {`$`}
        {variant.price.amount || 0}
      </td>
      <td data-label="Compare&nbsp;at :">
        {`$`}
        {variant.compareAt?.amount || 0}
      </td>
      <td data-label="Barcode :">{variant.barcode}</td>
      <td data-label="Weight :">
        {variant.weight?.amount !== undefined ? variant.weight?.amount : ''}&nbsp;
        {`${variant.weight?.unit || ''} `}
      </td>
      <td data-label="In Stock :">{variant.inStock}</td>
      <td data-label="Fulfillment :">{variant.fulfillmentMethod?.name}</td>
    </tr>
  );
};
