import React, { useEffect, useMemo } from 'react';
import { Form, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Box, Grid, Typography } from '@material-ui/core';
import {
  isPaused,
  ProductListingFormData,
  productListingFormDefaults,
  productListingSchema,
} from './ProductListingEditForm.utils';
import { ProductDetails } from './ProductDetails';
import { DurationDetails } from './DurationDetails';
import { AudienceDetails } from './AudienceDetails';
import { RewardsDetails } from './RewardsDetails';
import { PromotionDetails } from './PromotionDetails';
import { EnhancementsDetails } from './EnhancementsDetails';
import { ScutiInput } from 'components/material/ScutiInput';
import { ToggleCampaignInput } from 'types/__generated__/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { ProductListingFragment } from 'graphql/__generated__/product-listing.hooks';

interface Props {
  productListing?: ProductListingFragment;
  linkBack: string;
  onSave: (values: ProductListingFormData) => void;
  onToggle: (input: ToggleCampaignInput) => void;
}

export const ProductListingEditForm: React.FC<Props> = ({ productListing, linkBack, onSave, onToggle }) => {
  const listingFormDefaultValues = useMemo<ProductListingFormData>(() => {
    const defaults = productListingFormDefaults();

    if (!productListing) return defaults;

    return {
      ...defaults,
      id: productListing.id,
      name: productListing.name,
      product: productListing.product || defaults.product,
      promotion: productListing.promotion
        ? {
            ...productListing.promotion,
            offerType: productListing.promotion.offerType || undefined,
            offerCopy: productListing.promotion.offerCopy || undefined,
          }
        : defaults.promotion,
      shopBrand: productListing.shopBrand || defaults.shopBrand,
      reward: productListing.reward || defaults.reward,
      demographic: productListing.demographic || defaults.reward,
      duration: productListing.duration || defaults.reward,
      extras: productListing.extras || defaults.extras,
      location: productListing.location || defaults.location,
      status: productListing.status || defaults.status,
      productListingCategory: productListing.category || defaults.productListingCategory,
    };
  }, [productListing]);

  const productListingForm = useForm<ProductListingFormData>({
    resolver: yupResolver(productListingSchema),
    defaultValues: listingFormDefaultValues,
    mode: 'all',
  });

  useEffect(() => productListingForm.reset(listingFormDefaultValues), [listingFormDefaultValues]);

  const { isSubmitting } = productListingForm.formState;

  const status = productListingForm.watch('status');
  const paused = isPaused(status);

  return (
    <Form className="w-100" onSubmit={productListingForm.handleSubmit(onSave)}>
      <div className="topbar d-sm-flex justify-content-sm-between">
        <div className="col-heading">
          <Link className="btn btn-back" to={linkBack}></Link>
          <Box ml={5} width={'50%'}>
            <Controller
              control={productListingForm.control}
              name="name"
              render={({ field, fieldState }) => (
                <ScutiInput placeholder="Product listing name" {...field} error={fieldState.error?.message} />
              )}
            />
          </Box>
        </div>
        <div className="col-action">
          <Button color="outline-secondary" onClick={() => productListingForm.reset()} disabled={!paused}>
            Cancel
          </Button>
          {productListing?.id && (
            <Button
              color={!paused ? 'primary' : 'outline-secondary'}
              onClick={() => onToggle({ id: productListing.id, isPaused: !paused })}
            >
              {paused ? 'Activate' : 'Pause'}
            </Button>
          )}
          <Button color="primary" type="submit" disabled={isSubmitting || !paused}>
            Save changes
          </Button>
        </div>
      </div>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6} lg={4}>
          <ProductDetails form={productListingForm} paused={paused} />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <DurationDetails form={productListingForm} paused={paused} />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <AudienceDetails form={productListingForm} paused={paused} />
        </Grid>
      </Grid>
      <Box mt={2} mb={2}>
        <Typography variant="h6">Add Extras</Typography>
        <Box>Please select the extras you'd like to create below.</Box>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6} lg={4}>
          <RewardsDetails form={productListingForm} paused={paused} />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <PromotionDetails form={productListingForm} paused={paused} />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <EnhancementsDetails form={productListingForm} paused={paused} />
        </Grid>
      </Grid>
    </Form>
  );
};
