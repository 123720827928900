import React from 'react';
import { TwoStepVerificationPhoneForm } from '../../../components/auth/TwoStepVerificationPhoneForm';
import { observer } from 'mobx-react-lite';
import { useAppStore } from '../../../store/app-store.hook';
import { Redirect } from 'react-router';
import { appRoutes } from 'routing';

export const TwoStepVerificationPhonePage: React.FC = observer(() => {
  const { authStore } = useAppStore();
  const { userInfo, error, cleanError } = authStore;

  React.useEffect(() => {
    cleanError();
  }, [cleanError]);

  if (userInfo.phoneVerified) {
    return <Redirect to={appRoutes.HOME} />;
  }
  return (
    <div className="animated fadeIn">
      <TwoStepVerificationPhoneForm error={error} onVerify={authStore.phoneVerify} sendSms={authStore.sendSms} />
    </div>
  );
});
