import { Box, Card, CardHeader, CardContent } from '@material-ui/core';
import { ScutiInput } from 'components/material/ScutiInput';
import { Controller, UseFormReturn } from 'react-hook-form';
import { InternalAdvertisementFormData } from './InternalAdvertisementEditForm.utils';
import { ScutiSelect } from 'components/material/ScutiSelect';
import { useProductCategories } from 'hooks/useProductCategories';

interface Props {
  form: UseFormReturn<InternalAdvertisementFormData>;
}

export function InternalAdvertisementDetails({ form }: Props) {
  const productCategoriesOptions = useProductCategories();

  return (
    <Card>
      <CardHeader title="Details" />
      <CardContent>
        <Box mt={2}>
          <Controller
            control={form.control}
            name="internalLabel"
            render={({ field, fieldState }) => (
              <ScutiInput title="Label *" placeholder="Title of Ad" {...field} error={fieldState.error?.message} />
            )}
          />
        </Box>
        <Box mt={2}>
          <Controller
            control={form.control}
            name="internalHtml5Url"
            render={({ field, fieldState }) => (
              <ScutiInput title="Internal HTML5 URL *" placeholder="URL" {...field} error={fieldState.error?.message} />
            )}
          />
        </Box>
        <Box mt={2}>
          <Controller
            control={form.control}
            name="internalUnityUrl"
            render={({ field, fieldState }) => (
              <ScutiInput title="Internal Unity URL *" placeholder="URL" {...field} error={fieldState.error?.message} />
            )}
          />
        </Box>
        <Box mt={2}>
          <Controller
            control={form.control}
            name="category"
            render={({ field, fieldState }) => (
              <ScutiSelect
                title="Category *"
                placeholder="Select category"
                options={productCategoriesOptions}
                {...field}
                error={fieldState.error?.message}
              />
            )}
          />
        </Box>
      </CardContent>
    </Card>
  );
}
