import { Box, Card, CardHeader, CardContent } from '@material-ui/core';
import { ScutiInput } from 'components/material/ScutiInput';
import { Controller, UseFormReturn } from 'react-hook-form';
import { LeadGenAdvertisementFormData } from './LeadGenAdvertisementEditForm.utils';

interface Props {
  form: UseFormReturn<LeadGenAdvertisementFormData>;
}

export function LeadGenAdvertisementDetails({ form }: Props) {
  return (
    <Card>
      <CardHeader title="Details" />
      <CardContent>
        <Box mt={2}>
          <Controller
            control={form.control}
            name="cpa"
            render={({ field, fieldState }) => (
              <ScutiInput title="CPA" type="number" placeholder="$1.00" {...field} error={fieldState.error?.message} />
            )}
          />
        </Box>
      </CardContent>
    </Card>
  );
}
