import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { BrandEditForm } from './BrandEditForm';
import { Box } from '@mui/material';
import { Button } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { BrandFormData, brandFormDefaults, brandSchema } from './BrandEditForm/BrandEditForm.utils';
import { useHistory, useLocation, useParams } from 'react-router';
import { useCreateBrandMutation, useBrandQuery, useUpdateBrandMutation } from 'graphql/__generated__/brand.hooks';
import { hasId } from 'types/util-types';
import { brandsRoutes } from 'routing';
import { Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import * as queryString from 'query-string';

export const BrandEditPage: React.FC = observer(() => {
  const { addToast } = useToasts();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { search } = useLocation();
  const organizationId = queryString.parse(search).organizationId as string;

  const useBrand = useBrandQuery({ id }, { enabled: !!id });
  const useUpdateBrand = useUpdateBrandMutation();
  const useCreateBrand = useCreateBrandMutation();

  const brandFormDefaultValues = useMemo<BrandFormData>(() => {
    const brand = useBrand.data?.brand;

    const defaults = brandFormDefaults();
    if (!brand) return defaults;

    return {
      ...brand,
      video: brand.video?.unit ? brand.video : defaults.video,
      directResponse: brand.directResponse?.unit ? brand.directResponse : defaults.directResponse,
      engagement: brand.engagement?.unit ? brand.engagement : defaults.engagement,
    };
  }, [useBrand.data]);

  const brandForm = useForm<BrandFormData>({
    resolver: yupResolver(brandSchema),
    defaultValues: brandFormDefaultValues,
    mode: 'all',
  });

  useEffect(() => brandForm.reset(brandFormDefaultValues), [brandFormDefaultValues]);

  const handleSubmit = async (formData: BrandFormData) => {
    const forSave: BrandFormData = {
      ...formData,
      engagement: formData.engagement?.amount ? formData.engagement : null,
      directResponse: formData.directResponse?.amount ? formData.directResponse : null,
      video: formData.video?.amount ? formData.video : null,
    };

    try {
      if (hasId(forSave)) {
        await useUpdateBrand.mutateAsync({ input: forSave });

        addToast('Brand has been updated!', { appearance: 'success', autoDismiss: true });
        useBrand.refetch();
      } else {
        const { createBrand } = await useCreateBrand.mutateAsync({
          input: { ...forSave, organizationId },
        });

        addToast('Brand has been created!', { appearance: 'success', autoDismiss: true });

        history.push(brandsRoutes.BRAND_EDIT(createBrand.id));
      }
    } catch (err) {
      // @ts-expect-error
      addToast(err.message, { appearance: 'error', autoDismiss: false });
    }
  };

  const { isDirty, isSubmitting } = brandForm.formState;

  const title = `${id ? 'Edit' : 'Create new'} brand`;

  return (
    <Box>
      <form onSubmit={brandForm.handleSubmit(handleSubmit)}>
        <div className="topbar d-sm-flex justify-content-sm-between">
          <div className="col-heading">
            <Link className="btn btn-back" to={brandsRoutes.BRANDS} />
            <h1 className="page-title">{title}</h1>
          </div>
          <div className="col-action">
            <Button color={!isDirty ? 'outline-secondary' : 'secondary'} onClick={() => brandForm.reset()}>
              Cancel
            </Button>
            <Button color="primary" type="submit" disabled={isSubmitting}>
              Save changes
            </Button>
          </div>
        </div>
        <Box sx={{ mt: 2 }}>
          <BrandEditForm form={brandForm} />
        </Box>
      </form>
    </Box>
  );
});
