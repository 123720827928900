import React from 'react';

export const Loader = () => {
  return (
    <div className="loader">
      <div className="circle circle_1"></div>
      <div className="circle circle_2"></div>
      <div className="circle circle_3"></div>
      <div className="circle circle_4"></div>
      <div className="circle circle_5"></div>
      <div className="circle circle_6"></div>
      <div className="circle circle_7"></div>
      <div className="circle circle_8"></div>
    </div>
  );
};
