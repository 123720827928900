import React from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory, useLocation, useParams } from 'react-router';
import { CreateAdvertisement } from './CreateAdvertisement';
import { Loader } from 'components/Loader';
import { advertisementRoutes } from 'routing';
import { Box } from '@material-ui/core';
import {
  AppDownloadAdvertisementEditForm,
  CouponAdvertisementEditForm,
  InternalAdvertisementEditForm,
  LeadGenAdvertisementEditForm,
  NoActionAdAdvertisementEditForm,
  VideoPromoAdvertisementEditForm,
} from './AdvertisementEditForms';
import { useToasts } from 'react-toast-notifications';
import { hasId } from 'types/util-types';
import { AdvertisementType, NewAdvertisementInput, UpdateAdvertisementInput } from 'types/__generated__/types';
import * as queryString from 'query-string';
import {
  useAdvertisementQuery,
  useCreateAdvertisementMutation,
  useUpdateAdvertisementMutation,
} from 'graphql/__generated__/advertisement.hooks';

export const AdvertisementEditPage: React.FC = observer(() => {
  const { advertisementId } = useParams<{
    advertisementId: string;
  }>();

  const [advertisementType, setAdvertisementType] = React.useState<AdvertisementType | null>(null);
  const history = useHistory();
  const { addToast } = useToasts();

  const advertisementQuery = useAdvertisementQuery({ id: advertisementId }, { enabled: !!advertisementId });

  const advertisement = advertisementQuery.data?.advertisement;

  const { search } = useLocation();
  const urlBrandId = queryString.parse(search).brandId as string;

  const useUpdateAdvertisement = useUpdateAdvertisementMutation();
  const useCreateAdvertisement = useCreateAdvertisementMutation();

  const advertisementToRender = advertisementType || advertisement?.type;
  const isNew = !(advertisementType || advertisementId);

  React.useEffect(() => {
    if (advertisementQuery.error)
      addToast(advertisementQuery.error.message, {
        appearance: 'error',
        autoDismiss: false,
      });
  }, [addToast, advertisementQuery.error]);

  const saveOrUpdate = React.useCallback(
    async (advertisementInput: UpdateAdvertisementInput | NewAdvertisementInput) => {
      if (hasId(advertisementInput)) {
        try {
          await useUpdateAdvertisement.mutateAsync({
            input: advertisementInput,
          });
          await advertisementQuery.refetch();
          addToast('Advertisement has been updated!', { appearance: 'success', autoDismiss: true });
        } catch (e) {
          // @ts-expect-error
          addToast(e.message, { appearance: 'error', autoDismiss: false });
        }
      } else {
        try {
          await useCreateAdvertisement.mutateAsync({
            input: advertisementInput,
          });
          addToast('Advertisement has been created!', { appearance: 'success', autoDismiss: true });
          history.push(advertisementRoutes.ADVERTISEMENTS);
        } catch (e) {
          // @ts-expect-error
          addToast(e.message, { appearance: 'error', autoDismiss: false });
        }
      }
    },
    [addToast, history, advertisementQuery, useCreateAdvertisement, useUpdateAdvertisement],
  );

  const isLoading = advertisementQuery.isLoading;

  if (isLoading) return <Loader />;

  const brandId = advertisement?.brandId || urlBrandId;

  if (!brandId) {
    return null;
  }

  if (isNew) {
    return <CreateAdvertisement linkBack={advertisementRoutes.ADVERTISEMENTS} onSelect={setAdvertisementType} />;
  }

  return (
    <Box>
      {advertisementToRender === AdvertisementType.AppDownload && (
        <AppDownloadAdvertisementEditForm
          brandId={brandId}
          advertisement={advertisement}
          linkBack={advertisementRoutes.ADVERTISEMENTS}
          onSave={saveOrUpdate}
        />
      )}
      {advertisementToRender === AdvertisementType.VideoPromo && (
        <VideoPromoAdvertisementEditForm
          brandId={brandId}
          advertisement={advertisement}
          linkBack={advertisementRoutes.ADVERTISEMENTS}
          onSave={saveOrUpdate}
        />
      )}
      {advertisementToRender === AdvertisementType.LeadGen && (
        <LeadGenAdvertisementEditForm
          brandId={brandId}
          advertisement={advertisement}
          linkBack={advertisementRoutes.ADVERTISEMENTS}
          onSave={saveOrUpdate}
        />
      )}
      {advertisementToRender === AdvertisementType.Coupon && (
        <CouponAdvertisementEditForm
          brandId={brandId}
          advertisement={advertisement}
          linkBack={advertisementRoutes.ADVERTISEMENTS}
          onSave={saveOrUpdate}
        />
      )}
      {advertisementToRender === AdvertisementType.NoActionAd && (
        <NoActionAdAdvertisementEditForm
          brandId={brandId}
          advertisement={advertisement}
          linkBack={advertisementRoutes.ADVERTISEMENTS}
          onSave={saveOrUpdate}
        />
      )}
      {advertisementToRender === AdvertisementType.Internal && (
        <InternalAdvertisementEditForm
          brandId={brandId}
          advertisement={advertisement}
          linkBack={advertisementRoutes.ADVERTISEMENTS}
          onSave={saveOrUpdate}
        />
      )}
    </Box>
  );
});
