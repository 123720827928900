import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import { connectsRoutes } from 'routing'; // Assuming the routes are correctly defined for brands
import { Link } from 'react-router-dom';
import { ConnectsTable } from 'components/ConnectsTable';
import { useConnectionsQuery } from 'graphql/__generated__/connection.hooks';
import { Loader } from 'components/Loader';
import { Filter_Type, RequesterType } from 'types/__generated__/types';

interface Props {
  organizationId: string;
}

export const OrganizationConnections: React.FC<Props> = observer(({ organizationId }) => {
  const { data, isLoading } = useConnectionsQuery({
    filters: [
      { name: 'requesterId', operator: Filter_Type.Eq, value: [organizationId] },
      { name: 'requesterType', operator: Filter_Type.Eq, value: [RequesterType.Organization] },
    ],
  });

  const connects = data?.entityRequests.nodes || [];

  return (
    <Card>
      <CardHeader
        title={
          <Link className="btn btn-primary" to={`${connectsRoutes.CONNECT_NEW}?organizationId=${organizationId}`}>
            Add new Connect
          </Link>
        }
      />
      <CardContent>
        {isLoading && <Loader />}
        {connects.length ? (
          <ConnectsTable organizationId={organizationId} connects={connects} />
        ) : (
          <Box pt={20} pb={20}>
            <Typography align="center" variant="h6">
              No created connects
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
});
