import { useMemo } from 'react';
import { Column } from 'react-table';
import { Link } from 'react-router-dom';
import { advertisementRoutes } from 'routing';
import capitalize from 'lodash/capitalize';
import { AdvertisementListItemFragment } from 'graphql/__generated__/advertisement.hooks';

export function useAdvertisementsTableColumns(
  onDelete?: (id: string) => void,
): Column<AdvertisementListItemFragment>[] {
  return useMemo<Column<AdvertisementListItemFragment>[]>(
    () => [
      {
        Header: 'Advertisement Name',
        accessor: 'name',
      },
      {
        Header: 'Type',
        id: 'type',
        accessor: (row) => capitalize(row.type).replace(/_/g, ' '),
      },
      {
        Header: 'Category',
        id: 'category',
        accessor: (row) => capitalize(row.category || '').replace(/_/g, ' '),
      },
      {
        Header: '',
        id: 'edit',
        width: '6%',
        disableSortBy: true,
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => {
          // eslint-disable-next-line react/prop-types
          const { id } = row.original;
          return (
            <div className="row-actions">
              <Link className="link-edit" title="Edit" to={advertisementRoutes.ADVERTISEMENT_EDIT(id)}>
                Edit
              </Link>
              {onDelete && (
                <span className="link-delete" title="Delete" onClick={() => onDelete(id)}>
                  Delete
                </span>
              )}
            </div>
          );
        },
      },
    ],
    [onDelete],
  );
}
