import * as React from 'react';
import classNames from 'classnames';
import { DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { flag } from 'country-emoji';
import countryTelephoneData from 'country-telephone-data';

interface Country {
  name: string; // Switzerland
  iso2: string; // ch
  dialCode: string; // "41"
  priority: number; // 0
  format: string; //"+...-..-...."
}

const countryInitial = {
  name: '',
  iso2: 'us',
  dialCode: '1',
  priority: 0,
  format: '',
};

const countries: Country[] = countryTelephoneData.allCountries;

interface Props {
  className?: string;
  onChange: (code: number) => void;
}

export const CodesDropDown: React.FC<Props> = ({ onChange, className }) => {
  const [country, setCountry] = React.useState({ ...countryInitial });

  const onSelect = (cntr: Country) => () => {
    setCountry(cntr);
    return onChange(Number(cntr.dialCode));
  };
  return (
    <>
      <DropdownToggle
        caret
        className={classNames(className, {
          'flag-inactive': !country.dialCode,
        })}
      >
        <span className="ico-flag">{flag(country.iso2)}</span> +{country.dialCode || 0}
      </DropdownToggle>
      <DropdownMenu>
        {countries.map((country, index) => {
          return (
            <DropdownItem
              className="d-flex justify-content-between align-items-center"
              key={`country_code_${index}`}
              onClick={onSelect(country)}
            >
              <div>
                <span className="ico-flag">{flag(country.iso2)}</span>
                {country.name}
              </div>
              <span className="country-code">{`+${country.dialCode}`}</span>
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </>
  );
};
