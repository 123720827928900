// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiPaginationItem-root.MuiPaginationItem-outlined {
  color: #fff;
  border-color: rgb(64, 66, 73);
}

.MuiPaginationItem-root.MuiPaginationItem-outlined:hover {
  color: #000;
  background: #fff;
  border-color: #000;
}

.MuiPaginationItem-root.MuiPaginationItem-outlined:focus {
  outline: none;
}

.MuiPaginationItem-root.MuiPaginationItem-outlined.Mui-selected {
  color: #000;
  background: #fff;
}

.MuiPaginationItem-root.MuiPaginationItem-outlined.Mui-selected:hover {
  color: #000;
  background: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/material-theme/MuiPaging.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,6BAA6B;AAC/B;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB","sourcesContent":[".MuiPaginationItem-root.MuiPaginationItem-outlined {\n  color: #fff;\n  border-color: rgb(64, 66, 73);\n}\n\n.MuiPaginationItem-root.MuiPaginationItem-outlined:hover {\n  color: #000;\n  background: #fff;\n  border-color: #000;\n}\n\n.MuiPaginationItem-root.MuiPaginationItem-outlined:focus {\n  outline: none;\n}\n\n.MuiPaginationItem-root.MuiPaginationItem-outlined.Mui-selected {\n  color: #000;\n  background: #fff;\n}\n\n.MuiPaginationItem-root.MuiPaginationItem-outlined.Mui-selected:hover {\n  color: #000;\n  background: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
