import React from 'react';
import { ConnectEditDetails } from './ConnectEditDetails';
import { Grid } from '@material-ui/core';
import { UseFormReturn } from 'react-hook-form';
import { ConnectFormData } from './ConnectEditForm.utils';
import { ConnectControlLevel } from './ConnectControlLevel';
import { ConnectReferralPercentage } from './ConnectReferralPercentage';
import { ConnectMediaInvestment } from './ConnectMediaInvestment';
import { Box } from '@mui/material';

interface Props {
  form: UseFormReturn<ConnectFormData>;
}

export const ConnectEditForm: React.FC<Props> = ({ form }) => {
  return (
    <Grid container spacing={2}>
      <Grid container item spacing={2}>
        <Grid item sm={12} md={4} lg={4}>
          <ConnectEditDetails form={form} />
        </Grid>
        <Grid item sm={12} md={4} lg={4}>
          <ConnectControlLevel form={form} />
        </Grid>
        <Grid item sm={12} md={4} lg={4}>
          <ConnectReferralPercentage form={form} />
        </Grid>
      </Grid>
      <Grid container item spacing={2} direction="row-reverse">
        <Grid item sm={12} md={4} lg={4}>
          <ConnectMediaInvestment form={form} />
        </Grid>
      </Grid>
    </Grid>
  );
};
