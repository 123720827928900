export const ScutiNewLogo = () => {
  return (
    <svg width="222" height="143" viewBox="0 0 582 143" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M140.963 27.1849H129.415L103.222 71.3685L108.763 81.5035L140.963 27.1849Z" fill="#001A69" />
      <path d="M145.918 71.0919H134.367L108.108 115.39L113.649 125.48L145.918 71.0919Z" fill="#001A69" />
      <path d="M88.6259 115.429H35.7324L29.7284 125.48H94.0466L88.6259 115.429Z" fill="#015E27" />
      <path d="M88.6259 115.429H35.7324L29.7284 125.48H94.0466L88.6259 115.429Z" fill="#001A69" />
      <path d="M93.283 88.2651L40.3955 88.2501L34.5208 98.3279H98.7849L93.283 88.2651Z" fill="#015E27" />
      <path d="M93.283 88.2651L40.3955 88.2501L34.5208 98.3279H98.7849L93.283 88.2651Z" fill="#001A69" />
      <path d="M145.918 71.0919H134.367L140.377 60.957L145.918 71.0919Z" fill="#001647" />
      <path d="M51.8803 16.9868L45.8913 27.0827L57.3882 27.0917L51.8803 16.9868Z" fill="#001647" />
      <path d="M34.5207 98.3279L28.9136 88.2501H40.3954L34.5207 98.3279Z" fill="#001647" />
      <path d="M16.0758 44.0664L21.5837 54.1653H74.2487L80.2377 44.0694L16.0758 44.0664Z" fill="#001A69" />
      <path d="M51.8803 16.9928L57.3883 27.0917H110.053L116.042 16.9958L51.8803 16.9928Z" fill="#001A69" />
      <path
        d="M135.422 17.0529L126.099 1.52588e-05L83.9207 71.1521L108.108 115.39L111.563 109.563L140.377 60.957L145.918 71.0919L113.652 125.525L122.972 142.578L165.151 71.4287L140.963 27.1849L131.054 43.9041L108.763 81.5035L103.222 71.3685L135.422 17.0529Z"
        fill="#109FFD"
      />
      <path
        d="M113.649 125.48H113.625L101.47 103.253L98.7849 98.3279H34.5207L28.9136 88.2501H93.2469L83.9207 71.152H-7.62939e-06L4.05878 78.4699L14.9514 98.3279L14.9604 98.3399L23.09 113.165H23.102L24.3467 115.429H88.6259L94.0466 125.48H29.7284L39.2079 142.578H122.972L113.649 125.48Z"
        fill="#109CE2"
      />
      <path
        d="M113.649 125.48H113.625L101.47 103.253L98.7849 98.3279H34.5207L28.9136 88.2501H93.2469L83.9207 71.152H-7.62939e-06L4.05878 78.4699L14.9514 98.3279L14.9604 98.3399L23.09 113.165H23.102L24.3467 115.429H88.6259L94.0466 125.48H29.7284L39.2079 142.578H122.972L113.649 125.48Z"
        fill="#0069D6"
      />
      <path
        d="M116.045 16.9868L126.123 0H42.2024L16.0668 44.0664H22.9487V44.0694H80.2377L74.2487 54.1653H10.0778L0 71.152H83.9207L110.062 27.0796H90.3125V27.0826H45.8914L51.8803 16.9868H116.045Z"
        fill="#27B7F7"
      />
      <path
        d="M39.2079 142.578L45.1969 132.521L35.7144 115.42L29.7284 125.48L39.2079 142.578Z"
        fill="url(#paint0_linear_4564_19681)"
      />
      <path
        d="M39.2079 142.578H122.972L117.473 132.521H45.1968L39.2079 142.578Z"
        fill="url(#paint1_linear_4564_19681)"
      />
      <path
        d="M94.8674 125.97L100.487 116.265L94.6118 105.369L88.9325 114.915L94.8674 125.97Z"
        fill="url(#paint2_linear_4564_19681)"
      />
      <path
        d="M94.6118 105.369H30.3327L24.3467 115.429H88.6259L94.6118 105.369Z"
        fill="url(#paint3_linear_4564_19681)"
      />
      <path
        d="M30.3327 105.369L5.98896 61.0923L-7.62939e-06 71.1521L24.3467 115.429L30.3327 105.369Z"
        fill="url(#paint4_linear_4564_19681)"
      />
      <path
        d="M28.0838 87.763L34.0697 77.7062H87.4984L93.0093 87.769L28.0838 87.763Z"
        fill="url(#paint5_linear_4564_19681)"
      />
      <path
        d="M42.2023 1.52588e-05L47.7103 10.0688L21.5717 54.1352L16.0668 44.0664L42.2023 1.52588e-05Z"
        fill="url(#paint6_linear_4564_19681)"
      />
      <path
        d="M15.5827 64.2341L10.0778 54.1653L-7.62939e-06 71.1521L11.5931 71.2904L15.5827 64.2341Z"
        fill="url(#paint7_linear_4564_19681)"
      />
      <path
        d="M74.2487 54.1653L79.7537 64.2341H15.5827L10.0778 54.1653H74.2487Z"
        fill="url(#paint8_linear_4564_19681)"
      />
      <path
        d="M81.0975 43.5793L86.2267 53.3204L79.7536 64.2341L74.5163 54.6553L81.0975 43.5793Z"
        fill="url(#paint9_linear_4564_19681)"
      />
      <path
        d="M45.0315 27.5727L50.332 37.1515L104.091 37.1485L109.78 27.5547L45.0315 27.5727Z"
        fill="url(#paint10_linear_4564_19681)"
      />
      <path
        d="M47.7103 10.0688L42.2024 1.52588e-05H126.123L120.131 10.0688H47.7103Z"
        fill="url(#paint11_linear_4564_19681)"
      />
      <path
        d="M165.151 71.4286H153.615L117.473 132.521L122.972 142.578L165.151 71.4286Z"
        fill="url(#paint12_linear_4564_19681)"
      />
      <path
        d="M140.963 27.1849L134.962 37.3078L153.615 71.4287H165.151L140.963 27.1849Z"
        fill="url(#paint13_linear_4564_19681)"
      />
      <path
        d="M128.838 59.9618H140.377L107.822 114.879L102.51 105.369L128.838 59.9618Z"
        fill="url(#paint14_linear_4564_19681)"
      />
      <path
        d="M123.883 17.0529H135.422L126.123 1.52588e-05L120.131 10.0688L123.883 17.0529Z"
        fill="url(#paint15_linear_4564_19681)"
      />
      <path
        d="M91.6865 71.3685H102.651L108.763 82.4806H97.2275L91.6865 71.3685Z"
        fill="url(#paint16_linear_4564_19681)"
      />
      <path
        d="M123.883 17.0529L91.6865 71.3685H103.222L135.422 17.0529H123.883Z"
        fill="url(#paint17_linear_4564_19681)"
      />
      <path
        d="M125.597 0.300651L115.877 16.6861H51.8803H51.709L51.6218 16.8334L45.6328 26.9293L45.3622 27.3833H45.8914H90.3095H90.4839H109.533L83.7493 70.8514H0.529146L10.2492 54.4659H74.2487H74.4201L74.5073 54.3186L80.4963 44.2227L80.7669 43.7688H80.2377H23.4718H22.9487H16.5929L42.3737 0.303658H125.597M126.123 0H42.2024L16.0668 44.0664H22.9517H80.2407L74.2518 54.1623H10.0778L0 71.149H83.9207L110.062 27.0796H90.3125H45.8914L51.8803 16.9838H116.045L126.123 0Z"
        fill="url(#paint18_radial_4564_19681)"
      />
      <path
        d="M83.7433 71.4527L92.7388 87.9524H28.9136H28.4025L28.652 88.4004L34.2592 98.4782L34.3464 98.6315H34.5237H98.6105L101.211 103.4L113.366 125.627L113.451 125.783H113.472L122.467 142.283H39.3853L30.2365 125.783H94.0466H94.5517L94.3142 125.341L88.8964 115.291L88.8123 115.131H88.6319H24.5241L23.3666 113.024L23.2704 112.87L15.2159 98.1836L4.32335 78.3256L0.514105 71.4527H83.7433ZM83.9207 71.152H-7.62939e-06L4.05878 78.4729L14.9514 98.3309L14.9574 98.3429L23.09 113.168H23.102L24.3467 115.432H88.6289L94.0466 125.483H29.7284L39.2079 142.584H122.972L113.649 125.483H113.628L101.473 103.256L98.7879 98.3309H34.5207L28.9136 88.2531H93.2439L83.9207 71.152Z"
        fill="url(#paint19_radial_4564_19681)"
      />
      <path
        d="M126.09 0.60733L135.076 17.0469L102.967 71.2152L102.88 71.3625L102.961 71.5128L108.502 81.6478L108.754 82.1108L109.025 81.6568L131.315 44.0574L131.402 43.9101L140.957 27.7922L164.808 71.4226L122.987 141.973L114.001 125.534L146.179 71.2483L146.267 71.101L146.185 70.9506L140.644 60.8157L140.392 60.3527L140.121 60.8067L111.575 108.965L111.31 109.413L108.126 114.786L84.2755 71.1611L126.09 0.60733ZM126.099 1.52588e-05L83.9207 71.1521L108.108 115.39L111.563 109.563L140.377 60.957L145.918 71.0919L113.652 125.525L122.975 142.578L165.151 71.4287L140.963 27.1849L131.054 43.9041L108.763 81.5035L103.222 71.3685L135.419 17.0529L126.099 1.52588e-05Z"
        fill="url(#paint20_radial_4564_19681)"
      />
      <path
        d="M215.924 81.5215C215.924 85.6614 216.339 87.0084 217.996 88.0426C219.962 89.2843 222.238 89.4917 237.039 89.4917C259.603 89.4917 259.603 89.4917 259.603 83.3855C259.603 79.2456 258.052 78.2113 251.323 78.1061L234.763 77.4867L223.585 76.9696L217.996 76.7622C200.919 76.2451 196.157 71.8976 196.157 56.6847C196.157 43.5403 199.882 37.6415 209.403 35.57C213.441 34.7432 222.963 34.3283 239.523 34.3283C259.6 34.3283 266.846 35.2603 271.71 38.5705C275.85 41.3635 277.507 45.7109 277.507 53.8887C277.507 54.3036 277.507 55.7527 277.405 57.0966H257.12C256.912 53.2663 256.602 52.3343 255.051 51.4053C253.292 50.371 250.704 50.1636 235.8 50.1636C217.999 50.1636 216.55 50.5785 216.55 55.6505C216.55 58.2391 217.482 59.583 219.656 60.1001C221.52 60.6172 227.211 60.9269 241.804 61.3418C264.573 62.0664 269.025 62.5835 273.475 65.3795C278.237 68.4853 279.996 73.1423 279.996 82.7662C279.996 94.7712 277.098 100.673 269.957 103.364C265.817 104.915 257.123 105.432 236.528 105.432C215.104 105.432 206.722 104.5 201.857 101.395C197.095 98.394 195.543 94.3563 195.543 84.8347V81.5215H215.924Z"
        fill="white"
      />
      <path
        d="M372.624 77.7934C372.726 81.7259 372.831 83.2803 372.831 84.3145C372.831 95.4927 369.933 100.565 362.275 103.048C356.686 104.807 349.131 105.429 333.813 105.429C306.799 105.429 298.104 104.602 292.515 101.392C287.96 98.8029 285.892 94.9756 285.062 87.9374C284.752 84.8316 284.545 79.1403 284.545 70.3433C284.545 54.9229 284.96 49.2286 286.511 45.4013C290.236 36.0871 297.792 34.3283 333.915 34.3283C352.339 34.3283 358.755 35.0529 363.517 37.5363C369.416 40.5367 371.487 45.6087 371.487 56.6847V60.1001H350.478C350.27 55.5452 350.063 54.6132 348.821 53.579C347.27 52.2321 341.161 51.6127 327.914 51.6127C314.769 51.6127 310.939 52.0276 308.871 53.6842C306.387 55.7527 305.558 59.7904 305.558 70.2441C305.558 87.9434 305.867 88.1508 328.016 88.1508C351.097 88.1508 351.407 88.0486 351.509 77.7994H372.624V77.7934Z"
        fill="white"
      />
      <path
        d="M398.392 35.3595V67.1323C398.6 78.0009 398.6 78.1031 399.117 80.8991C400.257 86.386 404.291 87.5225 422.198 87.5225C436.686 87.5225 441.034 86.9001 443.313 84.4167C444.969 82.6579 445.486 80.7939 445.796 74.2728C445.898 71.8916 446.004 68.5815 446.004 67.1323V35.3595H467.013V67.1323C466.703 82.7602 466.598 83.8996 465.979 88.7642C464.839 96.6292 459.563 102.116 451.695 103.773C445.486 105.014 438.55 105.429 422.508 105.429C396.117 105.429 387.837 103.98 382.659 98.4962C379.244 94.9786 378.105 90.7334 377.587 80.5895C377.485 78.3135 377.485 78.3135 377.38 67.1353V35.3595H398.392Z"
        fill="white"
      />
      <path d="M555.092 35.3595V52.9536H524.353V104.392H502.306V52.9536H471.568V35.3595H555.092Z" fill="white" />
      <path d="M582 35.3595V104.395H559.953V35.3595H582Z" fill="white" />
      <defs>
        <linearGradient
          id="paint0_linear_4564_19681"
          x1="30.0789"
          y1="137.181"
          x2="40.8181"
          y2="125.281"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0069D6" />
          <stop offset="1" stopColor="#0069D6" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4564_19681"
          x1="81.0911"
          y1="142.956"
          x2="81.0911"
          y2="131.411"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0069D6" />
          <stop offset="1" stopColor="#0069D6" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4564_19681"
          x1="87.9662"
          y1="120.185"
          x2="98.3608"
          y2="113.255"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0069D6" />
          <stop offset="1" stopColor="#0069D6" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_4564_19681"
          x1="59.4795"
          y1="117.269"
          x2="59.4795"
          y2="105.314"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0069D6" />
          <stop offset="1" stopColor="#0069D6" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_4564_19681"
          x1="7.76836"
          y1="92.8133"
          x2="19.088"
          y2="85.8473"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0069D6" />
          <stop offset="1" stopColor="#0069D6" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_4564_19681"
          x1="60.5472"
          y1="91.5181"
          x2="60.5472"
          y2="77.4614"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0069D6" />
          <stop offset="1" stopColor="#0069D6" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_4564_19681"
          x1="26.0821"
          y1="23.6032"
          x2="35.0799"
          y2="28.9728"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#27B7F7" />
          <stop offset="1" stopColor="#27B7F7" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_4564_19681"
          x1="3.91648"
          y1="61.722"
          x2="11.7532"
          y2="68.2526"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#27B7F7" />
          <stop offset="1" stopColor="#27B7F7" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_4564_19681"
          x1="44.916"
          y1="51.6483"
          x2="44.916"
          y2="62.8238"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#27B7F7" />
          <stop offset="1" stopColor="#27B7F7" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_4564_19681"
          x1="75.1064"
          y1="50.3805"
          x2="84.0779"
          y2="56.3268"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#27B7F7" />
          <stop offset="1" stopColor="#27B7F7" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_4564_19681"
          x1="77.4053"
          y1="25.3808"
          x2="77.4053"
          y2="35.8297"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#27B7F7" />
          <stop offset="1" stopColor="#27B7F7" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_4564_19681"
          x1="84.1636"
          y1="-1.17703"
          x2="84.1636"
          y2="10.0013"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#27B7F7" />
          <stop offset="1" stopColor="#27B7F7" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_4564_19681"
          x1="146.011"
          y1="108.256"
          x2="136.869"
          y2="102.379"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#109FFD" />
          <stop offset="1" stopColor="#109FFD" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_4564_19681"
          x1="156.192"
          y1="47.5425"
          x2="145.743"
          y2="53.42"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#109FFD" />
          <stop offset="1" stopColor="#109FFD" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_4564_19681"
          x1="125.534"
          y1="88.2515"
          x2="118.116"
          y2="83.9588"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#109FFD" />
          <stop offset="1" stopColor="#109FFD" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_4564_19681"
          x1="139.44"
          y1="8.52717"
          x2="121.458"
          y2="8.52717"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#109FFD" />
          <stop offset="1" stopColor="#109FFD" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_4564_19681"
          x1="112.229"
          y1="76.9248"
          x2="97.1949"
          y2="76.9248"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#109FFD" />
          <stop offset="1" stopColor="#109FFD" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_4564_19681"
          x1="118.93"
          y1="47.4759"
          x2="110.803"
          y2="42.5417"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#109FFD" />
          <stop offset="1" stopColor="#109FFD" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint18_radial_4564_19681"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(63.0625 35.5757) scale(51.1973 51.1973)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint19_radial_4564_19681"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(61.487 106.865) scale(50.279)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint20_radial_4564_19681"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(124.536 71.2894) scale(58.0159 58.0159)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
};
