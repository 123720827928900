// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ImgPreview_img_hovered__tl9dE {
  opacity: 0.3;
}

.ImgPreview_img_disabled__sqKg\\+ {
  opacity: 0.4;
  cursor: default;
}

.ImgPreview_default_img_icon__VZ6Fc {
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 4px;
  margin-right: 5px;
}

.ImgPreview_delete_icon__9iMfq {
  cursor: pointer;
  border: 1px solid #f86c6b;
  color: #f86c6b;
  border-radius: 4px;
}

.ImgPreview_add_block__AC8fu {
  border: 1px solid grey;
  border-radius: 4px;
  cursor: pointer;
}

.ImgPreview_add_icon__aXA\\+Q {
  width: 3rem !important;
  height: 3rem !important;
}

.ImgPreview_flex__-kWtg {
  display: flex;
  justify-content: space-evenly;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}`, "",{"version":3,"sources":["webpack://./src/components/material/ScutiMultipleImgInput/ImgPreview.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,YAAA;EACA,eAAA;AACF;;AAEA;EACE,eAAA;EACA,sBAAA;EACA,kBAAA;EACA,iBAAA;AACF;;AAEA;EACE,eAAA;EACA,yBAAA;EACA,cAAA;EACA,kBAAA;AACF;;AAEA;EACE,sBAAA;EACA,kBAAA;EACA,eAAA;AACF;;AAEA;EACE,sBAAA;EACA,uBAAA;AACF;;AAEA;EACE,aAAA;EACA,6BAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;AACF","sourcesContent":[".img_hovered {\n  opacity: 0.3;\n}\n\n.img_disabled {\n  opacity: 0.4;\n  cursor: default;\n}\n\n.default_img_icon {\n  cursor: pointer;\n  border: 1px solid #fff;\n  border-radius: 4px;\n  margin-right: 5px;\n}\n\n.delete_icon {\n  cursor: pointer;\n  border: 1px solid #f86c6b;\n  color: #f86c6b;\n  border-radius: 4px;\n}\n\n.add_block {\n  border: 1px solid grey;\n  border-radius: 4px;\n  cursor: pointer;\n}\n\n.add_icon {\n  width: 3rem !important;\n  height: 3rem !important;\n}\n\n.flex {\n  display: flex;\n  justify-content: space-evenly;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"img_hovered": `ImgPreview_img_hovered__tl9dE`,
	"img_disabled": `ImgPreview_img_disabled__sqKg+`,
	"default_img_icon": `ImgPreview_default_img_icon__VZ6Fc`,
	"delete_icon": `ImgPreview_delete_icon__9iMfq`,
	"add_block": `ImgPreview_add_block__AC8fu`,
	"add_icon": `ImgPreview_add_icon__aXA+Q`,
	"flex": `ImgPreview_flex__-kWtg`
};
export default ___CSS_LOADER_EXPORT___;
