import React from 'react';
import { FormGroup, CustomInput } from 'reactstrap';
import { GENDER_SELECT_OPTIONS } from 'types/select-types';
import InputRange, { Range } from 'react-input-range';
import { FormikProps } from 'formik';
import { Box, Card, CardContent, CardHeader } from '@material-ui/core';
import { ScutiSelect } from 'components/material/ScutiSelect';
import { CampaignFormData } from './CampaignEditForm.utils';
import { Controller, UseFormReturn } from 'react-hook-form';

interface Props {
  form: UseFormReturn<CampaignFormData>;
  paused?: boolean;
}

export function AudienceDetails({ form, paused }: Props) {
  const values = form.watch();

  const [genderSpecific, setGenderSpecific] = React.useState(!!values.demographic.gender);
  const [ageRangeSpecific, setAgeRangeSpecific] = React.useState(
    values.demographic.minAge !== 0 || values.demographic.maxAge !== 100,
  );
  const toggleGender = () => {
    if (!genderSpecific) form.setValue('demographic.gender', GENDER_SELECT_OPTIONS[1].value);
    else form.setValue('demographic.gender', null);
    setGenderSpecific(!genderSpecific);
  };

  const toggleAgeRange = () => {
    form.setValue(`demographic`, { ...values.demographic, minAge: 0, maxAge: 100 });
    setAgeRangeSpecific(!ageRangeSpecific);
  };

  const onRangeChange = (v: Range | number) => {
    if (typeof v === 'number') return;
    form.setValue(`demographic`, { ...values.demographic, minAge: Math.max(0, v.min), maxAge: Math.min(v.max, 100) });
  };

  return (
    <Card>
      <CardHeader title="Audience" />
      <CardContent>
        <FormGroup>
          <label htmlFor={`demographic.gender`}>
            <CustomInput
              id="gender-box"
              type="checkbox"
              label="Gender specific"
              onChange={toggleGender}
              checked={genderSpecific}
              disabled={!paused}
            />
          </label>
          <Controller
            control={form.control}
            name="demographic.gender"
            render={({ field, fieldState }) => (
              <ScutiSelect
                placeholder="Select gender"
                options={GENDER_SELECT_OPTIONS.slice(1)}
                disabled={!genderSpecific || !paused}
                {...field}
                error={fieldState.error?.message}
              />
            )}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor={`demographic.gender`}>
            <CustomInput
              id="age-rage-box"
              type="checkbox"
              label="Age Range"
              onChange={toggleAgeRange}
              checked={ageRangeSpecific}
              disabled={!paused}
            />
          </label>
          <Box pl={2} pr={2}>
            <InputRange
              maxValue={100}
              minValue={0}
              value={{ min: values.demographic.minAge, max: values.demographic.maxAge }}
              onChange={onRangeChange}
              disabled={!ageRangeSpecific || !paused}
            />
          </Box>
        </FormGroup>
      </CardContent>
    </Card>
  );
}
