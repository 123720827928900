import React from 'react';
import { Box } from '@material-ui/core';
import { useAppStore } from 'store/app-store.hook';
import queryString from 'query-string';
import { Loader } from 'components/Loader';

export const EntityInvite: React.FC = () => {
  const { authStore, routerStore } = useAppStore();
  const { search } = routerStore.location;
  const { invite } = queryString.parse(search);
  const [isAccepting, setIsAccepting] = React.useState(true);
  const [wasAccepted, setWasAccepted] = React.useState(false);

  React.useEffect(() => {
    (async function () {
      try {
        await authStore.acceptInvite(invite as string);
      } catch (e) {
        setWasAccepted(true);
      }
      setIsAccepting(false);
    })();
  }, [authStore, invite]);

  return isAccepting ? (
    <Loader />
  ) : (
    <Box>
      {wasAccepted ? <h1>You already accepted this invite!</h1> : <h1>Invite has been accepted!</h1>}
      <p className="text-center">
        Go to <a href="/">Home Page</a>
      </p>
    </Box>
  );
};
