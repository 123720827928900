import React from 'react';
import { Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connectsRoutes } from 'routing';
import { ConnectionListItemFragment } from 'graphql/__generated__/connection.hooks';

interface Props {
  connects?: ConnectionListItemFragment[];
  organizationId: string;
  onDelete?: (shopId: string) => void;
  onEdit?: (shopId: string) => void;
}

export const ConnectsTable: React.FC<Props> = ({ connects = [], organizationId, onEdit, onDelete }) => {
  const deleteConnect = onDelete ? (id: string) => () => onDelete(id) : undefined;
  const editConnect = onEdit ? (id: string) => () => onEdit(id) : undefined;

  return (
    <Table responsive striped hover>
      <thead>
        <tr>
          <th>Type</th>
          <th className="col-90"></th>
        </tr>
      </thead>
      <tbody>
        {connects.map((connect, index) => {
          return (
            <tr key={`shop_${index}`}>
              <td data-label="Name :">
                <Link to={`${connectsRoutes.CONNECT_EDIT(connect.id)}?organizationId=${organizationId}`}>
                  {connect.entityType}
                </Link>
              </td>
              <td>
                <div className="row-actions">
                  {editConnect && (
                    <span className="link-edit" title="Edit" onClick={editConnect(connect.id)}>
                      Edit
                    </span>
                  )}
                  {/* {deleteConnect && (
                    <span className="link-delete" title="Delete" onClick={deleteConnect(connect.id)}>
                      Delete
                    </span>
                  )} */}
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
