import React from 'react';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { PagingInput } from 'types/__generated__/types';

const getPagingFromQuery = (search: string) => (defaultLimit: number) => {
  const { offset, limit } = queryString.parse(search);
  return {
    offset: offset ? Number(offset) : 0,
    limit: limit ? Number(limit) : defaultLimit,
  };
};

export function usePaging(defaultLimit: number = 12): PagingInput {
  const location = useLocation();
  const [paging, setPaging] = React.useState<PagingInput>(getPagingFromQuery(location.search)(defaultLimit));

  React.useEffect(() => {
    const newPaging = getPagingFromQuery(location.search)(defaultLimit);
    if (paging.limit !== newPaging.limit || paging.offset !== newPaging.offset) {
      setPaging(getPagingFromQuery(location.search)(defaultLimit));
    }
  }, [defaultLimit, location, paging.limit, paging.offset]);

  return paging;
}
