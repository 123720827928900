import React from 'react';
import { BUSINESS_CATEGORY_SELECT_OPTIONS, BUSINESS_SELECT_OPTIONS } from 'types/select-types';
import { Box, Card, CardContent, CardHeader } from '@material-ui/core';
import { ScutiInput } from 'components/material/ScutiInput';
import { OrganizationFormData } from './OrganizationEditForm.utils';
import MuiPhoneNumber from 'material-ui-phone-number';
import { ScutiMultipleSelect } from 'components/material/ScutiMultipleSelect';
import { Controller, UseFormReturn } from 'react-hook-form';
import { hasOneOfRoles } from 'utils/roles.utils';
import { useAppStore } from 'store/app-store.hook';
import { observable } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Role } from 'types/__generated__/types';

interface Props {
  form: UseFormReturn<OrganizationFormData>;
}

export const OrganizationBusinessDetails: React.FC<Props> = observer(({ form }) => {
  const { userRoles } = useAppStore().permissions;
  const isAdmin = hasOneOfRoles(userRoles, [Role.ScutiAdmin, Role.OrganizationAdmin, Role.OrganizationOwner]);

  const canEditBusinessName = isAdmin || !form.watch().id;

  return (
    <Card>
      <CardHeader title="Business Details" />
      <CardContent>
        <Box>
          <Controller
            control={form.control}
            name="name"
            render={({ field, fieldState }) => (
              <ScutiInput
                title="Business name *"
                placeholder="Business name"
                error={fieldState.error?.message}
                {...field}
              />
            )}
            disabled={!canEditBusinessName}
          />
        </Box>
        <Box mt={2}>
          <Controller
            control={form.control}
            name="website"
            render={({ field, fieldState }) => (
              <ScutiInput
                title="Website *"
                placeholder="https://website.com"
                error={fieldState.error?.message}
                {...field}
              />
            )}
          />
        </Box>
        <Box mt={2}>
          <Controller
            control={form.control}
            name="types"
            render={({ field, fieldState }) => (
              <ScutiMultipleSelect
                title="Business Types *"
                name="types"
                placeholder="Business Types"
                value={field.value}
                values={field.value || []}
                error={fieldState.error?.message}
                options={BUSINESS_SELECT_OPTIONS}
                onBlur={field.onBlur}
                onChange={({ target }) => field.onChange([...(field.value || []), target.value])}
                onRemove={(value) => field.onChange([...(field.value || [])].filter((s) => s !== value))}
              />
            )}
          />
        </Box>
        <Box mt={2}>
          <Controller
            control={form.control}
            name="identificationNumber"
            render={({ field, fieldState }) => (
              <ScutiInput title="Tax ID *" placeholder="XXX-XX-XXXX" error={fieldState.error?.message} {...field} />
            )}
          />
        </Box>
        <Box mt={2}>
          <Controller
            control={form.control}
            name="dbaName"
            render={({ field, fieldState }) => (
              <ScutiInput title="DBA Name *" placeholder="DBA" error={fieldState.error?.message} {...field} />
            )}
          />
        </Box>
        <Box mt={2}>
          <Controller
            control={form.control}
            name="phone"
            render={({ field, fieldState }) => (
              <>
                <label>Phone Number *</label>
                <MuiPhoneNumber
                  fullWidth
                  variant="outlined"
                  size="small"
                  defaultCountry="us"
                  disableAreaCodes
                  placeholder="XXX-XXX-XXXX"
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                  {...field}
                />
              </>
            )}
          />
        </Box>
      </CardContent>
    </Card>
  );
});
