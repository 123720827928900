import { GridClassKey, StyleRules } from '@material-ui/core';

export const MuiGrid: Partial<StyleRules<GridClassKey, {}>> = {
  item: {
    '& .MuiCard-root': {
      height: '100%',
    },
    '& .card': {
      height: '100%',
    },
  },
};
