import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Grid, Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import { ShopsTable } from 'components/ShopsTable';
import { shopsRoutes } from 'routing';
import { Link } from 'react-router-dom';
import { Filter_Type } from 'types/__generated__/types';
import { useShopsQuery } from 'graphql/__generated__/shop.hooks';
import { Loader } from 'components/Loader';

interface Props {
  organizationId: string;
}

export const OrganizationShops: React.FC<Props> = observer(({ organizationId }) => {
  const { data, isLoading } = useShopsQuery({
    filters: [{ name: 'organizationId', operator: Filter_Type.Eq, value: [organizationId] }],
  });

  const shops = data?.shops.nodes || [];

  return (
    <Card>
      <CardHeader
        title={
          <Link className="btn btn-primary" to={`${shopsRoutes.SHOP_NEW}?organizationId=${organizationId}`}>
            Add new Shop
          </Link>
        }
      />
      <CardContent>
        {isLoading && <Loader />}
        {shops.length ? (
          <ShopsTable
            shops={shops.map((shop) => {
              return {
                ...shop,
                shopifyName: shop.shopifyName || null,
                description: shop.description || null,
                admins: shop.admins.map(({ fullName }) => fullName || null),
              };
            })}
          />
        ) : (
          <Box pt={20} pb={20}>
            <Typography align="center" variant="h6">
              No created shops
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
});
