import React from 'react';
import classNames from 'classnames';
import { Button, Card, CardBody, Form, FormFeedback, FormGroup, Input } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NavLink } from 'react-router-dom';
import { ForgotPasswordRequest } from '../../../types/auth-types';

import { EmailIcon } from 'components/svg-icons/Email';
import { appRoutes } from 'routing';
import { validate, validators } from 'utils/form-utils';

interface FormValues {
  email: string;
}

const validationSchema = () =>
  Yup.object().shape({
    email: validators.email,
  });

const initialValues = {
  email: '',
};

interface ForgotPasswordFormProps {
  error?: string;
  onSend: (params: ForgotPasswordRequest) => void;
}

export const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({ onSend, error }) => {
  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
    },
    onSubmit: onSend,
    isInitialValid: false,
    validate: validate<FormValues>(validationSchema),
  });
  const { values, errors, touched, handleChange, handleBlur, handleSubmit, isValid } = formik;
  return (
    <Card className="w-23">
      <CardBody>
        <div className="card-headline">
          <h1>I forgot my password</h1>
          <p>It happens, let us help by entering your email.</p>
        </div>
        <Form onSubmit={handleSubmit} noValidate name="simpleForm">
          <FormGroup
            className={classNames(
              'form-group-addon',
              { 'input-active': values.email },
              { 'input-invalid': touched.email && !!errors.email },
              { 'input-touched': touched.email },
              { 'input-valid': touched.email && !errors.email },
            )}
          >
            <label htmlFor="email" className="ico-label">
              <EmailIcon />
            </label>
            <Input
              type="email"
              name="email"
              id="email"
              placeholder="Email address"
              autoComplete="email"
              valid={touched.email && !errors.email}
              invalid={touched.email && !!errors.email}
              required
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />
            <FormFeedback>{errors.email}</FormFeedback>
          </FormGroup>
          {error && <div className="text-center text-danger mb-1">{error}</div>}
          <FormGroup className="actions">
            <Button
              type="button"
              color="primary"
              className="btn-block"
              disabled={!isValid}
              onClick={() => handleSubmit()}
            >
              Send reset instructions
            </Button>
          </FormGroup>
          <div className="d-flex align-items-center justify-content-center">
            <span className="mr-1">Remember your password?</span>
            <NavLink className="text-center" to={appRoutes.LOGIN}>
              Login
            </NavLink>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
};
