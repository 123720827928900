import { Role } from 'types/__generated__/types';
import * as Yup from 'yup';

export const invitesSchema = Yup.object({
  invites: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string(),
        accepted: Yup.boolean(),
        email: Yup.string().email('Email is not valid').required('Email is required'),
        role: Yup.string().oneOf(Object.values(Role), 'Invalid role').required('At least one role is required'),
      }),
    )
    .required('Invites are required'),
});

export type InvitesFormData = Yup.InferType<typeof invitesSchema>;

export const inviteFormDefaults = (role: Role): InvitesFormData => ({
  invites: [
    {
      email: '',
      role,
    },
  ],
});

export const getInvites = (initial: InvitesFormData, formData: InvitesFormData) => {
  const toInvite = formData.invites.filter(
    (invite) => !initial.invites.some((initialInvite) => initialInvite.email === invite.email),
  );

  const toRemove = initial.invites.filter(
    (invite) => !formData.invites.some((formDataInvite) => formDataInvite.email === invite.email),
  );

  return {
    toInvite,
    toRemove,
  };
};
