import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { observer } from 'mobx-react-lite';
import { advertisementRoutes } from 'routing';
import { useAppStore } from 'store/app-store.hook';
import { useDebounce } from 'use-debounce';
import { TopNavigation } from 'components/material/TopNavigation';
import { Grid } from '@material-ui/core';
import { ScutiInput } from 'components/material/ScutiInput';
import { useToasts } from 'react-toast-notifications';
import { PaginationLayout } from 'layouts/PaginationLayout';
import { usePaging } from 'hooks/usePaging';
import { useAdvertisementsTableColumns } from './useAdvertisementsTableColumns';
import { useSortBy, useTable } from 'react-table';
import { useSorting } from 'hooks/useSorting';
import { ScutiTable } from 'components/ScutiTable';
import { defaultSorting } from 'types/app-types';
import { AdvertisementListItemFragment, useAdvertisementsQuery } from 'graphql/__generated__/advertisement.hooks';
import { AdvertisementType, FilterInput, Filter_Type, RequestEntityType } from 'types/__generated__/types';

export const AdvertisementsListPage: React.FC = observer(() => {
  const { selectedItem } = useAppStore().uiStore;

  const { addToast } = useToasts();

  const paging = usePaging();
  const [searchInput, setSearchInput] = useState<string>('');
  const [inputValue] = useDebounce(searchInput, 500);
  const [advertisements, setAdvertisements] = useState<AdvertisementListItemFragment[]>([]);
  const columns = useAdvertisementsTableColumns();
  const tableInstance = useTable({ columns, data: advertisements, manualSortBy: true }, useSortBy);
  const sorting = useSorting(tableInstance.state.sortBy, defaultSorting);

  const filters = useMemo(() => {
    const result: FilterInput[] = [
      {
        name: 'type',
        operator: Filter_Type.In,
        value: [
          AdvertisementType.VideoPromo,
          AdvertisementType.Internal,
          AdvertisementType.Coupon,
          AdvertisementType.AppDownload,
          AdvertisementType.LeadGen,
          AdvertisementType.NoActionAd,
        ],
      },
    ];
    if (inputValue) {
      result.push({
        name: 'search',
        operator: Filter_Type.Like,
        value: [inputValue],
      });
    }
    return result;
  }, [inputValue]);

  const advertisementsQuery = useAdvertisementsQuery(
    {
      paging,
      filters,
      sorting,
    },
    {
      keepPreviousData: true,
      onSuccess: ({ advertisements }) => setAdvertisements(advertisements.nodes),
    },
  );

  useEffect(() => {
    if (advertisementsQuery.error)
      addToast(advertisementsQuery.error.message, {
        appearance: 'error',
        autoDismiss: false,
      });
  }, [addToast, advertisementsQuery.error]);

  const isLoading = advertisementsQuery.isLoading;
  const showAdvertisements = !!advertisements.length && !advertisementsQuery.isLoading;
  const pagingResponse = advertisementsQuery.data?.advertisements.paging;

  const canCreate = selectedItem.itemType === RequestEntityType.Brand;

  return (
    <PaginationLayout
      isLoading={isLoading}
      paging={pagingResponse}
      header={
        <TopNavigation title="Advertisements management">
          <Grid container justifyContent="flex-end" alignItems="center" spacing={1}>
            <Grid item md={4}>
              <ScutiInput
                placeholder="Search..."
                value={searchInput}
                onChange={({ target }) => setSearchInput(target.value)}
              />
            </Grid>
            <Grid item md={3}>
              {canCreate && (
                <Link to={`${advertisementRoutes.ADVERTISEMENT_NEW}?brandId=${selectedItem.id}`}>
                  <Button className="w-100" color="primary">
                    Add new advertisement
                  </Button>
                </Link>
              )}
            </Grid>
          </Grid>
        </TopNavigation>
      }
      content={showAdvertisements && <ScutiTable table={tableInstance} />}
    />
  );
});
